import { appConfig } from "_configs/appConfig";

export type TFileData = { path: string; blob: any; indexes?: number[] };
export type TFilesData = TFileData[];

const AUTH_HEADER_KEY = "Authorization";
const AUTH_PREFIX = "Bearer ";

function readResponse(response: Response) {
    if (response.headers.get("content-length") === "0") {
        return Promise.resolve(undefined);
    }

    const contentType = response.headers.get("content-type");
    if (contentType) {
        if (contentType.startsWith("application/json")) {
            return response.json();
        } else if (contentType.startsWith("multipart/form-data")) {
            return response.formData();
        }
    }
    return response.text();
}

function executeRequest<T>(url: string, fetchOptions: Partial<Request>, isExternalApi = false) {
    const headers = fetchOptions?.headers ?? new (Headers ?? (global as any).Headers)();
    try {
        const token = localStorage.getItem(appConfig.token.storageKey);
        if (!isExternalApi) headers.append(AUTH_HEADER_KEY, AUTH_PREFIX + token);
    } catch (e) {
        // nothing to do
    }
    return (fetch ?? (global as any).fetch)(url, {
        credentials: "same-origin",
        headers,
        ...fetchOptions,
    } as Request)
        .then(
            (response) =>
                readResponse(response).then(
                    (data) => ({ data, response }),
                    () => ({ data: null, response }),
                ),
            () => {
                // eslint-disable-next-line
                throw { key: "errors.global.network" };
            },
        )
        .then(({ data, response }) => {
            if (data) {
                if (data.error !== undefined) {
                    // generic API errors
                    // eslint-disable-next-line
                    throw { key: "errors.api." + data.error.key };
                }
                if (data.message === "File too large") {
                    // Multer error
                    throw { key: "errors.api.FILE_TOO_LARGE" };
                }
                if (response.status >= 400) {
                    // eslint-disable-next-line
                    throw { key: data.key ? "errors.api." + data.key : "errors.global.unknownLight" };
                }
            }
            return { data, response };
        }) as Promise<{ data: T; response: Response }>;
}

function executeRequestWithBody<T>(
    url: string,
    fetchOptions: Partial<Request>,
    body: any,
    isFormData: boolean,
    isExternalApi = false,
) {
    const headers = fetchOptions?.headers ?? new (Headers ?? (global as any).Headers)();
    if (!isFormData && !headers.get("Content-Type")) {
        headers.append("Content-Type", "application/json");
    }
    if (typeof body !== "string" && !isFormData) {
        body = JSON.stringify(body);
    }
    return executeRequest<T>(url, { body, headers, ...fetchOptions }, isExternalApi);
}

export const fetchUtils = {
    get<T>(url: string, fetchOptions?: Partial<Request>, isExternalApi = false) {
        return executeRequest<T>(url, { ...fetchOptions, method: "GET" }, isExternalApi);
    },

    patch<T>(url: string, body: any = {}, isFormData = false, fetchOptions?: Partial<Request>, isExternalApi = false) {
        return executeRequestWithBody<T>(url, { ...fetchOptions, method: "PATCH" }, body, isFormData, isExternalApi);
    },

    post<T>(url: string, body: any = {}, isFormData = false, fetchOptions?: Partial<Request>, isExternalApi = false) {
        return executeRequestWithBody<T>(url, { ...fetchOptions, method: "POST" }, body, isFormData, isExternalApi);
    },

    put<T>(url: string, body: any = {}, isFormData = false, fetchOptions?: Partial<Request>, isExternalApi = false) {
        return executeRequestWithBody<T>(url, { ...fetchOptions, method: "PUT" }, body, isFormData, isExternalApi);
    },

    delete<T>(url: string, fetchOptions?: Partial<Request>, isExternalApi = false) {
        return executeRequest<T>(
            url,
            {
                ...fetchOptions,
                method: "DELETE",
            },
            isExternalApi,
        );
    },

    createBodyWithFiles: (jsonData: Record<string, any>, files: TFilesData) => {
        let filesPathIndexes: { [path: string]: number[][] } | undefined = undefined;
        const body = new FormData();
        body.append("data", JSON.stringify(jsonData));
        files.forEach(({ path, blob, indexes }) => {
            body.append("files." + path, blob, blob.name);
            if (indexes) {
                filesPathIndexes = filesPathIndexes || {};
                filesPathIndexes[path] = filesPathIndexes[path] || [];
                filesPathIndexes[path].push(indexes);
            }
        });
        if (filesPathIndexes) {
            body.append("filesPathIndexes", JSON.stringify(filesPathIndexes));
        }
        return body;
    },
};
