import React, { ReactNode } from "react";
import { TLoadingStatus } from "shared/_common/loaders/_models/LoadingStateMdl";
import { TErrMdl } from "shared/_common/errors/_models/ErrMdl";
import { Loader } from "shared/_common/loaders/Loader";

type Props<TValue> = {
    status: TLoadingStatus;
    error?: TErrMdl;
    value?: TValue;
    renderer: (status: "IDLE" | "SUCCEEDED", error?: TErrMdl, value?: TValue) => ReactNode;
    noSpinner?: boolean;
    noText?: boolean;
    fallback?: ReactNode;
    className?: string;
    text?: string;
};

export function Loadable<TValue>(props: Props<TValue>) {
    if (props.status === "LOADING") {
        return (
            <Loader
                className={props.className}
                contentRender={props.fallback}
                noSpinner={props.noSpinner}
                noText={props.noText}
                text={props.text}
            />
        );
    }
    return <>{props.renderer(props.status, props.error, props.value)}</>;
}
