import React from "react";
import { DemandSuccessHeader } from "properties/propertyPage/demand/DemandSuccessHeader";
import { useParams } from "react-router";
import { PageNotFound } from "pages/PageNotFound";
import { DemandSuccessListing } from "properties/propertyPage/demand/DemandSuccessListing";
import { useLocation } from "react-router-dom";
import { isBuyingPage } from "_common/_utils/pageUtils";
import { propertiesBuyingStore } from "properties/_stores/propertiesBuyingStore";
import { propertiesRentingStore } from "properties/_stores/propertiesRentingStore";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { DemandSuccessContact } from "properties/propertyPage/demand/DemandSuccessContact";
import clsx from "clsx";
import styles from "./_css/demandSuccessPage.module.css";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const _DemandSuccessPage = ({ property }: { property: TPropertyListingMdl }) => {
    const { t } = useTranslation();
    const { loading } = useLoadingFromPromise(geoZonesStore.fetchRegionOfProperty(property._id));
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const purpose = urlParams.get("purpose");
    const type = urlParams.get("type");
    const id = `success${purpose ? purpose.toUpperCase() : ""}${type ?? ""}`;
    return (
        <div id={"successRequest"} className={clsx("flex_column gap_120 mv_120 ph_40", styles.container)}>
            <DemandSuccessHeader />
            {loading && (
                <div id={id}>
                    <h2 className={"text_big mb_25"}>{t("demandSuccess.listingTitle")}</h2>
                    <LoadableFromLoading
                        loading={loading}
                        renderer={(_status, error, value) => {
                            if (error || !value?.data) return null;
                            return <DemandSuccessListing property={property} geoZone={value.data} />;
                        }}
                    />
                </div>
            )}
            <DemandSuccessContact property={property} />
        </div>
    );
};

export const DemandSuccessPage = () => {
    const { propertyAlias } = useParams();
    const location = useLocation();
    const propertyOrFetch = isBuyingPage(location.pathname)
        ? propertiesBuyingStore.getByAliasUrl(propertyAlias)
        : propertiesRentingStore.getByAliasUrl(propertyAlias);
    const propertyPromise = propertyOrFetch && propertyOrFetch instanceof Promise ? propertyOrFetch : undefined;
    const { loading } = useLoadingFromPromise(propertyPromise);
    if (!propertyOrFetch) return null;
    if ("localized" in propertyOrFetch) {
        return <_DemandSuccessPage property={propertyOrFetch} />;
    }
    if (!loading) return null;
    return (
        <>
            <Helmet>
                <meta name="robots" content="NOINDEX,NOFOLLOW" />
            </Helmet>
            <LoadableFromLoading
                loading={loading}
                renderer={(_status, error, value) => {
                    if (error || !value || !value.data) {
                        return <PageNotFound />;
                    }
                    return <_DemandSuccessPage property={value.data} />;
                }}
            />
        </>
    );
};
