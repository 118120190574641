import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ProjectView } from "projects/ProjectView";
import { OrderContext } from "projects/OrderContext";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { ordersStore } from "payments/_stores/ordersStore";
import { OrderStore } from "payments/_stores/orderStore";
import { TPropertyMdl } from "properties/_models/PropertyMdl";
import { TOrderListingMdl } from "admin/orders/_models/OrderMdl";

type Props = {
    property: TPropertyMdl;
    order: TOrderListingMdl;
};
export function _ProjectPage(props: Props) {
    const orderStore = useMemo(() => new OrderStore(props.order), [props.order]);
    return (
        <OrderContext value={{ orderStore, selectedPropertyId: props.property._id }}>
            <ProjectView property={props.property} />
        </OrderContext>
    );
}

export function ProjectPage() {
    const location = useLocation();
    const orderId = location.pathname.split("/")[3];
    const search = new URLSearchParams(location.search);
    const propertyId = useMemo(() => search.get("propertyId"), [search]);

    ordersStore.fetchOrders();
    return (
        <LoadableFromLoading
            loading={ordersStore.ordersState}
            renderer={(_status, error) => {
                if (error || !ordersStore.orders.length) {
                    return <ErrorBlock error={error} />;
                }
                const order = ordersStore.orders.find((order) => orderId === order._id);
                if (order) {
                    const property = order.properties.find(
                        (property) => propertyId === property._id && !property.order?.isCompleted,
                    );
                    if (property) {
                        return <_ProjectPage property={property} order={order} />;
                    }
                }
            }}
        />
    );
}
