import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { placementsStore } from "placements/_store/placementsStore";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { PlacementSliderCard } from "placements/PlacementSliderCard";
import styles from "./_css/placementSlider.module.css";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import crossPopupIcon from "../assets/images/crossPopupIcon.svg";
import { observer } from "mobx-react";
import { URLS } from "_configs/URLS";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { PLACEMENT_EVENT } from "placements/_models/PlacementsMdl";
import { useLocation } from "react-router";

export const PlacementSlider = observer(() => {
    const propertyStore = usePropertyStore();
    const { t } = useTranslation();
    const url = useLocation().pathname;

    const [isCollapse, setCollapse] = useState(true);
    useEffect(() => {
        placementsStore.fetchSliderProperty(propertyStore.property._id);
    }, []);

    if (
        !placementsStore.placementSlider?.property ||
        propertyStore?.property?._id === placementsStore.placementSlider.property?._id
    ) {
        return null;
    }
    return (
        <div
            className={clsx(styles.container, "position_relative cursor_pointer", { [styles.collapse]: isCollapse })}
            onClick={() => {
                if (isCollapse) {
                    setCollapse(false);
                } else if (placementsStore.placementSlider?.property?.localized.urlAlias) {
                    void analyticsUtils.tracksEventOfItem(
                        PLACEMENT_EVENT.VISITED_CTA,
                        placementsStore.placementSlider._id,
                        url,
                    );
                    window.open(URLS.property(placementsStore.placementSlider.property.localized.urlAlias), "_blank");
                }
            }}
        >
            <div className={clsx(styles.collapsableSection, "flex_row_center justifyContent_spaceBetween mh_10")}>
                <div className={styles.seeProject}>
                    {isCollapse ? t("propertyPage.popup.forCurious") : t("propertyPage.popup.seeProject")}
                </div>
                {isCollapse ? (
                    <div
                        className={styles.arrow}
                        onClick={() => {
                            setCollapse(false);
                            if (placementsStore.placementSlider?._id) {
                                void analyticsUtils.tracksEventOfItem(
                                    PLACEMENT_EVENT.PRINTED_CTA,
                                    placementsStore.placementSlider._id,
                                    url,
                                );
                            }
                        }}
                    />
                ) : (
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setCollapse(true);
                        }}
                    >
                        <LazyLoadImage className={"cursor_pointer"} src={crossPopupIcon} height={15} />
                    </div>
                )}
            </div>
            <PlacementSliderCard placement={placementsStore.placementSlider} />
        </div>
    );
});
