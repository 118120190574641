import React, { RefObject, useImperativeHandle, useRef, useState } from "react";
import { observer } from "mobx-react";
import { PROPERTY_STATUS, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { ListStore } from "_common/list/ListStore";
import { SurfaceFilter } from "properties/searchBar/filters/SurfaceFilter";
import { StatusFilter } from "properties/searchBar/filters/StatusFilter";
import { FilterButtons } from "properties/searchBar/filters/FilterButtons";
import { AdvancedFilter } from "properties/searchBar/filters/AdvancedFilter";
import {
    getIsCommercialFilter,
    PROPERTY_FILTERS,
    updateSearchFilter,
} from "properties/searchBar/filters/_utils/filtersUtils";
import { fetchSearchProperties } from "_common/_utils/searchUtils";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { useHistory } from "react-router";
import { TFilter } from "admin/_common/filters/TFilter";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/moreFilters.module.css";
import { ExposedFiltersFunctions } from "properties/searchBar/MobileFilters";
import { useClickOutside } from "_common/_utils/hookUtils";
import { surfaceRangeToFilters } from "properties/searchBar/filters/_utils/surfaceUtils";
import { featuresToFilters } from "properties/searchBar/filters/_utils/featureUtils";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

const _MoreFilter = React.forwardRef<ExposedFiltersFunctions, Props>((props: Props, ref) => {
    const propertiesStore = usePropertiesStore();
    const history = useHistory();
    const { t } = useTranslation();
    const initialSurfaces: { min: string; max: string } = {
        min: (props.listStore.filters?.[props.listStore.getFilterIndex(`squareSurface.max`)]?.value ?? "").toString(),
        max: (props.listStore.filters?.[props.listStore.getFilterIndex(`squareSurface.min`)]?.value ?? "").toString(),
    };
    const initialStatus = props.listStore.getFilterByFilterId(PROPERTY_FILTERS.STATUS)
        ? props.listStore.getFilterByFilterId(PROPERTY_FILTERS.STATUS)?.value
        : "NONE";
    const initialFeatures = props.listStore.filters
        .filter(
            (filter) =>
                filter.id.startsWith(PROPERTY_FILTERS.FEATURES) || filter.id.startsWith(PROPERTY_FILTERS.AMENITIES),
        )
        .map((feature) => feature.id);
    const [selectedSurfaces, setSelectedSurfaces] = useState<{ min: string; max: string }>(initialSurfaces);
    const [selectedStatus, setSelectedStatus] = useState<PROPERTY_STATUS | "NONE">(initialStatus);
    const [selectedFeatures, setSelectedFeatures] = useState<string[]>(initialFeatures);
    const isCommercialFilter = getIsCommercialFilter(props.listStore.filters);

    function getFilterStatus(): TFilter | undefined {
        if (selectedStatus === "NONE") {
            props.listStore.removeFilterByFilterId(PROPERTY_FILTERS.STATUS);
        } else {
            return {
                id: PROPERTY_FILTERS.STATUS,
                type: TFilterType.ENUM,
                value: selectedStatus,
            };
        }
    }

    function applyFilters() {
        if (
            JSON.stringify(initialSurfaces) === JSON.stringify(selectedSurfaces) &&
            JSON.stringify(initialStatus) === JSON.stringify(selectedStatus) &&
            JSON.stringify(initialFeatures) === JSON.stringify(selectedFeatures)
        ) {
            return;
        }
        const filters: TFilter[] = surfaceRangeToFilters({ min: selectedSurfaces.min, max: selectedSurfaces.max });
        filters.push(
            ...featuresToFilters(selectedFeatures, () => {
                for (const initialFeature of initialFeatures) {
                    props.listStore.removeFilterByFilterId(initialFeature);
                }
            }),
        );
        const statusFilter: TFilter | undefined = getFilterStatus();
        if (statusFilter) filters.push(statusFilter);

        updateSearchFilter(props.listStore, filters).then(() => {
            fetchSearchProperties(propertiesStore, props.listStore, history, true);
        });
    }
    const toggleFeatures = (newFeatures: string[]) => {
        const currentFeatures = [...selectedFeatures];
        for (const feature of newFeatures) {
            const index = currentFeatures.indexOf(feature);
            if (index > -1) {
                currentFeatures.splice(index, 1);
            } else {
                currentFeatures.push(feature);
            }
        }
        setSelectedFeatures(currentFeatures);
    };

    const resetFilters = () => {
        setSelectedSurfaces({ min: "", max: "" });
        setSelectedStatus("NONE");
        setSelectedFeatures([]);
    };

    useImperativeHandle(
        ref,
        () => ({
            applyFilters,
            resetFilters,
        }),
        [selectedSurfaces, selectedStatus, selectedFeatures],
    );
    const containerRef: RefObject<HTMLDivElement> = useRef(null);
    useClickOutside(
        containerRef,
        () => {
            setTimeout(() => {
                applyFilters();
            }, 100);
        },
        "MuiAutocomplete-option",
    );
    return (
        <div ref={containerRef}>
            <div className={clsx(styles.title, "p_15 text_normal")}>{t("searchBar.filter.more")}</div>
            <SurfaceFilter {...props} onChange={(value) => setSelectedSurfaces(value)} value={selectedSurfaces} />
            <StatusFilter {...props} onChange={(value) => setSelectedStatus(value)} value={selectedStatus} />
            {!isCommercialFilter && (
                <AdvancedFilter {...props} onChange={(value) => toggleFeatures(value)} value={selectedFeatures} />
            )}
            <FilterButtons className={"p_15"} onReset={resetFilters} onApply={applyFilters} />
        </div>
    );
});

export const MoreFilter = observer(_MoreFilter);
