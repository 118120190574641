import React, { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";

type Props = {
    url?: string;
    className?: string;
    activeClassName?: string;
    onClick?: () => void | undefined;
    isBuyingOrIsRentingUrl?: boolean;
    nofollow?: boolean;
    disabled?: boolean;
};

export function ExternalOrNavLink(props: PropsWithChildren<Props>) {
    const url = props.url ?? "/";
    const rel = props.nofollow ? "noopener noreferrer nofollow" : "noopener noreferrer";
    if (props.disabled) return <div className={props.className}>{props.children}</div>;
    if (url === "#") {
        return (
            <a
                className={props.className}
                href={url}
                onClick={(e) => {
                    e.preventDefault();
                    props.onClick?.();
                }}
            >
                {props.children}
            </a>
        );
    }
    if (props.isBuyingOrIsRentingUrl) {
        return (
            <a className={props.className} href={url} onClick={props.onClick}>
                {props.children}
            </a>
        );
    }
    return url.startsWith("/") ? (
        <NavLink
            className={props.className}
            activeClassName={props.activeClassName}
            to={url}
            exact
            onClick={props.onClick}
        >
            {props.children}
        </NavLink>
    ) : (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a className={props.className} href={url} target="_blank" rel={rel} onClick={props.onClick}>
            {props.children}
        </a>
    );
}
