import React from "react";
import { TStepForm } from "projects/ProjectView";
import { useTranslation } from "react-i18next";
import styles from "./_css/selectStepFormProject.module.css";
import clsx from "clsx";

type Props = {
    items: TStepForm[];
    stepSelected: number;
    setStepSelected: (value: number) => void;
};

export function SelectStepFormProject(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={styles.content}>
            {props.items.map((item, index) => (
                <div
                    className={clsx(styles.text, {
                        [styles.selected]: item.value === props.stepSelected,
                    })}
                    key={index}
                    onClick={() => props.setStepSelected(item.value)}
                >
                    {t(`${item.title}`).toUpperCase()}
                </div>
            ))}
        </div>
    );
}
