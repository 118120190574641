import React from "react";
import { observer } from "mobx-react";
import { SignInModal } from "users/auth/blocks/SignInModal";
import { userStore } from "users/_stores/userStore";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { useLocation } from "react-router-dom";

export const SignInModalObserver = observer(() => {
    const location = useLocation();
    return (
        <Dialog
            closeCross={true}
            closeSize={"default"}
            maxWidth={"md"}
            open={userStore.isModalDisplayed}
            onClose={() => userStore.closeSignInWithModal()}
        >
            <SignInModal backUrl={location.pathname} />
        </Dialog>
    );
});
