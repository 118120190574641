import React from "react";
import { OrderHead } from "users/orders/OrderHead";
import { OrderLine } from "users/orders/OrderLine";
import { Table, TableBody, TableHead } from "@material-ui/core";
import { ordersStore } from "payments/_stores/ordersStore";
import { observer } from "mobx-react";

export const Orders = observer(() => (
    <div>
        <Table>
            <TableHead>
                <OrderHead />
            </TableHead>
            <TableBody>
                {ordersStore.orders.map((order) => {
                    return <OrderLine order={order} key={order._id} />;
                })}
            </TableBody>
        </Table>
    </div>
));
