import React from "react";
import clsx from "clsx";
import styles from "properties/gallery/_css/propertyGallery.module.css";
import { Label } from "properties/label/Label";
import { PROPERTY_PURPOSE, PROPERTY_STATUS, PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";

type Props = {
    indexForVideoOrVisit3D: number[];
    indexGallery: number;
};

export function PropertyGalleryLabels({ indexForVideoOrVisit3D, indexGallery }: Props) {
    const { t } = useTranslation();
    const propertyStore = usePropertyStore();
    const displaySoldLabel = propertyStore.property.sold && !propertyStore.property?.label?.override;
    const displayCustomLabel = !!(
        propertyStore.property?.label?.color && propertyStore.property.localized?.label?.text
    );

    return (
        <div
            className={clsx("position_absolute flex_row", styles.labels, {
                [styles.hiddenBtns]: indexForVideoOrVisit3D.find((index) => index === indexGallery),
            })}
        >
            {displaySoldLabel && (
                <>
                    {propertyStore.property.sold ? (
                        <Label
                            className={styles.purpose}
                            label={
                                propertyStore.property.purpose === PROPERTY_PURPOSE.BUY
                                    ? propertyStore.property.type === PROPERTY_TYPE.condo
                                        ? t("property.soldOutCondo")
                                        : t("property.soldOut")
                                    : t("property.rented")
                            }
                            bgColor="white"
                            textColor={"black"}
                        />
                    ) : (
                        <Label className={styles.purpose} purpose={propertyStore.property.purpose} />
                    )}
                </>
            )}
            {displayCustomLabel && !!propertyStore.property.localized?.label?.text && (
                <Label
                    className={"position_relative mr_10"}
                    bgColor={propertyStore.property.label.color}
                    label={propertyStore.property.localized.label.text}
                    textColor={propertyStore.property.label?.color === "#ffffff" ? "black" : "white"}
                />
            )}
            {!propertyStore.property?.statusIsNotDisplayed &&
            (propertyStore.property.status !== PROPERTY_STATUS.UNDER_CONSTRUCTION ||
                propertyStore.property.deliveryDates.length > 0) ? (
                <Label
                    className={"position_relative"}
                    bgColor="white"
                    textColor={"black"}
                    label={t(`admin.property.status.${propertyStore.property.status}`)}
                />
            ) : null}
        </div>
    );
}
