import React from "react";
import { SignInFormBlock } from "users/auth/blocks/SignInFormBlock";
import { URLS } from "_configs/URLS";
import { CardContent } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./_css/signInModal.module.css";
import { GoogleAuth } from "users/auth/GoogleAuth";
import { FacebookAuth } from "users/auth/FacebookAuth";
import clsx from "clsx";
import { userStore } from "users/_stores/userStore";

type Props = {
    onSuccess?: (success: boolean) => void;
    backUrl?: string;
};

export function SignInModal(props: Props) {
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.container)}>
            <div className="flex_column_center mb_20">
                <SignInFormBlock
                    backUrl={props.backUrl}
                    onSuccess={(success) => (props.onSuccess ? props.onSuccess(success) : null)}
                />
                <GoogleAuth
                    backUrl={props.backUrl}
                    onSuccess={(success) => (props.onSuccess ? props.onSuccess(success) : null)}
                />
                <FacebookAuth
                    backUrl={props.backUrl}
                    onSuccess={(success) => (props.onSuccess ? props.onSuccess(success) : null)}
                />
            </div>
            <hr />
            <CardContent className="flex_center_center flex_column">
                <div>{t("auth.signUp.notRegisteredYet")}</div>
                <div onClick={() => userStore.closeSignInWithModal()}>
                    <Link to={URLS.auth.signUp()} className="link">
                        {t("auth.signUp.title")}
                    </Link>
                </div>
            </CardContent>
        </div>
    );
}
