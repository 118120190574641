import React from "react";
import { observer } from "mobx-react";
import { userStore } from "users/_stores/userStore";
import { Container } from "@material-ui/core";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { FavoriteList } from "users/profile/FavoriteList";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { useTranslation } from "react-i18next";

export const FavoritesPage = observer(() => {
    if (!userStore.favoritesPropertiesStates.isSucceeded) userStore.fetchFavoritesProperties();
    if (!userStore.favoritesUnitsStates.isSucceeded) userStore.fetchFavoritesUnits();
    const { t } = useTranslation();

    return (
        <Container className={"mb_40"}>
            <Breadcrumb currentItem={{ itemLabel: t("menu.favorites") }} />
            <FavoriteList resourceType={MAIN_RESOURCE.PROPERTIES} />
            <FavoriteList resourceType={MAIN_RESOURCE.UNITS} />
        </Container>
    );
});
