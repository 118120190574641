import React, { RefObject, useImperativeHandle, useRef, useState } from "react";
import { PROPERTY_PURPOSE, PROPERTY_TYPE_BY_PURPOSE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { ListStore } from "_common/list/ListStore";
import { useHistory } from "react-router";
import { PROPERTY_FILTERS, updateSearchFilter } from "properties/searchBar/filters/_utils/filtersUtils";
import clsx from "clsx";
import styles from "./_css/propertyTypeFilter.module.css";
import { fetchSearchProperties } from "_common/_utils/searchUtils";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { observer } from "mobx-react";
import { Checkbox } from "@material-ui/core";
import { FilterButtons } from "properties/searchBar/filters/FilterButtons";
import { ExposedFiltersFunctions } from "properties/searchBar/MobileFilters";
import { useClickOutside } from "_common/_utils/hookUtils";
import { propertyTypesToFilters } from "properties/searchBar/filters/_utils/propertyTypeUtils";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
    filter: {
        key: string;
        labelKey: string;
    };
};

const _PropertyTypeFilter = React.forwardRef<ExposedFiltersFunctions, Props>((props: Props, ref) => {
    const { t } = useTranslation();
    const history = useHistory();
    const containerRef: RefObject<HTMLDivElement> = useRef(null);
    const listStore = props.listStore;
    const propertiesStore = usePropertiesStore();
    const currentFilter = listStore.filters?.[listStore.getFilterIndex(PROPERTY_FILTERS.TYPE)];
    const initialPropertyTypes =
        currentFilter && currentFilter.value && currentFilter.value.length > 0
            ? JSON.parse(JSON.stringify(currentFilter.value))
            : [];

    const [propertyTypes, setPropertyTypes] = useState<string[]>(initialPropertyTypes);

    const toggleProperty = (propertyType: string) => {
        const currentPropertyType = [...propertyTypes];
        const typeIndex = currentPropertyType.indexOf(propertyType);
        if (typeIndex > -1) {
            currentPropertyType.splice(typeIndex, 1);
        } else {
            currentPropertyType.push(propertyType);
        }
        setPropertyTypes(currentPropertyType);
    };

    const applyFilters = () => {
        if (JSON.stringify(initialPropertyTypes) === JSON.stringify(propertyTypes)) return;
        listStore.removeFilterByFilterId(PROPERTY_FILTERS.TYPE);
        listStore.removeFilterByFilterId(PROPERTY_FILTERS.UNIT_TYPE);
        updateSearchFilter(listStore, propertyTypesToFilters(propertyTypes), true).then(() => {
            fetchSearchProperties(propertiesStore, props.listStore, history);
        });
    };

    const resetFilters = () => {
        setPropertyTypes([]);
        applyFilters();
    };
    // for trigger filter on mobile with btn on parent
    useImperativeHandle(
        ref,
        () => ({
            applyFilters,
            resetFilters,
        }),
        [propertyTypes],
    );
    // for trigger filter on close filter
    useClickOutside(
        containerRef,
        () => {
            applyFilters();
        },
        "MuiAutocomplete-option",
    );

    return (
        <div className={clsx(styles.container, "flex_column position_relative")} ref={containerRef}>
            <div className={clsx(styles.title, "p_15")}>{t("searchBar.filter.propertyType")}</div>
            <div>
                {PROPERTY_TYPE_BY_PURPOSE[propertiesStore.purpose ?? PROPERTY_PURPOSE.BUY].map((propertyType) => {
                    return (
                        <div
                            key={propertyType}
                            className={clsx(
                                styles.checkbox,
                                "flex_row_center justifyContent_spaceBetween cursor_pointer pl_40 pr_10",
                            )}
                            onClick={() => toggleProperty(propertyType)}
                        >
                            <div>{t(`property.types.${propertyType}`)}</div>
                            <Checkbox
                                className={styles.btn}
                                checked={propertyTypes.includes(propertyType.toString())}
                            />
                        </div>
                    );
                })}
            </div>
            <FilterButtons className={"p_15"} onReset={resetFilters} onApply={applyFilters} />
        </div>
    );
});

export const PropertyTypeFilter = observer(_PropertyTypeFilter);
