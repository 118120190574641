import React, { useState } from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { useTranslation } from "react-i18next";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { HorizontalUnitCardLoader } from "properties/propertyPage/HorizontalUnitCardLoader";
import styles from "properties/propertyPage/_css/propertyUnitList.module.css";
import clsx from "clsx";
import { HorizontalUnitCard } from "properties/propertyPage/HorizontalUnitCard";
import { PropertyBtnSeeMore } from "properties/propertyPage/PropertyBtnSeeMore";
import { observer } from "mobx-react";

export const PropertyUnitsList = observer(() => {
    const { t } = useTranslation();
    const propertyStore = usePropertyStore();
    const [isCollapse, setIsCollapse] = useState(true);
    propertyStore.fetchUnitsOfProperty();
    if (propertyStore.units.length === 0) return null;
    return (
        <LoadableFromLoading
            loading={propertyStore.unitsState}
            fallback={<HorizontalUnitCardLoader />}
            renderer={() => {
                if (!propertyStore.units) return null;
                return (
                    <div className={styles.container}>
                        <h2 className={styles.title}>{t("propertyPage.header.unitTypes")}</h2>
                        <div
                            className={clsx({
                                [styles.collapse]: propertyStore.units.length > 3 && isCollapse,
                                [styles.open]: propertyStore.units.length > 3 && !isCollapse,
                            })}
                        >
                            <div className={clsx("flex_column", styles.content)}>
                                {propertyStore.sortedUnits.map((unit) => {
                                    if (!unit) return null;
                                    const notDisplayPrice =
                                        unit?.priceIsNotDisplayed || propertyStore.property.priceIsNotDisplayed;
                                    return (
                                        <HorizontalUnitCard
                                            unit={unit}
                                            notDisplayPrice={notDisplayPrice}
                                            key={unit._id}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        {propertyStore.units.length > 3 && (
                            <PropertyBtnSeeMore setIsCollapse={setIsCollapse} isCollapse={isCollapse} />
                        )}
                    </div>
                );
            }}
        />
    );
});
