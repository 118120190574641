import React from "react";
import { TextField as UiTextField, TextFieldProps } from "@material-ui/core";
import styles from "./_css/textField.module.css";

type Props = TextFieldProps & {};

export function TextField(props: Props) {
    return (
        <UiTextField
            {...props}
            InputProps={{
                ...props.InputProps,
                classes: {
                    root: styles.root,
                    input: styles.input,
                    focused: styles.focused,
                },
            }}
            InputLabelProps={{
                ...props.InputLabelProps,
                classes: {
                    root: styles.rootLabel,
                },
            }}
            color={"primary"}
        />
    );
}
