import React, { useState } from "react";
import { PricingBreadcrumb } from "payments/PricingBreadcrumb";
import { itemsForBreadcrumb } from "payments/PricingPage";
import { ordersStore } from "payments/_stores/ordersStore";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import styles from "./_css/summaryOrderPage.module.css";
import { Container } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { ToggleSignInOrSignUp } from "payments/_components/ToggleSignInOrSignUp";
import { SignUpFormFront } from "payments/_components/SignUpFormFront";
import { SignInFormFront } from "payments/_components/SignInFormFront";
import { userStore } from "users/_stores/userStore";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { cartStore } from "Cart/_stores/cartStore";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { SpinnerAlt } from "_common/loaders/SpinnerAlt";
import { ConfirmationDialog } from "_common/ui/dialogs/ConfirmationDialog";
import paymentStore from "payments/_stores/paymentStore";
import { formatPrice } from "_common/_utils/currencyUtils";
import sharedConfig from "_configs/sharedConfig";
import Cross from "projects/component/crossFormSummaryOrderPage.svg";

export const SummaryOrderPage = observer(() => {
    const { t } = useTranslation();
    const [signUp, setSignUp] = useState(true);
    const { loading, setPromise } = useLoadingFromPromise();
    const [isLoading, setIsLoading] = useState(!!loading?.isLoading);
    const [openDialog, setOpenDialog] = useState(false);
    const [validTermsAndConditions, setValidTermsAndConditions] = useState(false);
    const history = useHistory();
    const submitOrder = () => {
        setIsLoading(true);
        const promise = ordersStore.createOrder();
        setPromise(promise);
        promise
            .then(({ data }) => {
                if (data.properties.length) {
                    history.push({
                        pathname: URLS.project(data._id),
                        search: `?propertyId=${data.properties[0]._id}`,
                    });
                    cartStore.deleteCart();
                } else {
                    ordersStore
                        .createCheckoutSession(data)
                        .then(({ data }) => {
                            cartStore.deleteCart();
                            window.location.href = data.url;
                        })
                        .catch((e) => {
                            toast.error(e);
                        });
                }
            })
            .catch((e) => {
                setIsLoading(false);
                if (e.key === "errors.api.TOO_MANY_PROPERTIES") {
                    setOpenDialog(true);
                } else {
                    toast.error(e);
                }
            });
    };
    const tps = Math.round(cartStore.total * 5) / 100;
    const tvq = Math.round(cartStore.total * 9.975) / 100;
    const cartTotal = cartStore.total + tps + tvq;
    const isDisabled = cartStore.cart.length === 0 || !validTermsAndConditions;

    return (
        <div className={"position_relative"}>
            {isLoading && (
                <div className={styles.loaderContainer}>
                    <SpinnerAlt />
                </div>
            )}
            <PricingBreadcrumb items={itemsForBreadcrumb} currentItem={1} />
            <Container>
                <Link className={clsx("mb_15", styles.link)} to={URLS.pricing(paymentStore.pricingZone)}>
                    {t("paymentPage.goBack")}
                </Link>
            </Container>
            <div className={clsx("flex flex-1 mt_10 mb_80", styles.content)}>
                <div
                    className={clsx("flex_column_center flex-1 ", {
                        [styles.firstColumn]: !userStore.isLogged,
                        [styles.isLoggedIn]: userStore.isLogged,
                    })}
                >
                    <div className={clsx("mt_50", styles.title)}>{t("paymentPage.resumeOrder")}</div>
                    <div className={styles.contentProducts}>
                        {cartStore.cart.map((line, i) => {
                            return (
                                <div key={i} className={styles.line}>
                                    <div className={"flex_column"}>
                                        <div> {line.name}</div>
                                        <div>
                                            {line.productId === sharedConfig.stripe.products.INTEGRATION_CRM ? (
                                                <div>{t("projectPage.product.options.dependOfCrm")}</div>
                                            ) : line.price ? (
                                                formatPrice(line.price, false, true)
                                            ) : (
                                                0
                                            )}
                                        </div>
                                    </div>
                                    <div className={"flex_column"}>
                                        <div className={"flex"}>
                                            <div
                                                onClick={() =>
                                                    cartStore.deleteItemInCart({
                                                        productId: line.productId,
                                                        priceId: line.priceId,
                                                        price: line.price,
                                                        name: line.name,
                                                        quantity: 1,
                                                        type: line.type,
                                                    })
                                                }
                                                className={clsx(styles.btn, "mr_20")}
                                            >
                                                -
                                            </div>
                                            <div className={"mr_20"}> {line.quantity} </div>
                                            <div
                                                className={styles.btn}
                                                onClick={() => {
                                                    cartStore.addCart({
                                                        productId: line.productId,
                                                        priceId: line.priceId,
                                                        price: line.price,
                                                        name: line.name,
                                                        quantity: 1,
                                                        type: line.type,
                                                    });
                                                }}
                                            >
                                                +
                                            </div>
                                            <div
                                                className={"ml_40 flex_center_center"}
                                                onClick={() =>
                                                    cartStore.deleteAllUnitForOneItemInCart({
                                                        productId: line.productId,
                                                        priceId: line.priceId,
                                                        price: line.price,
                                                        name: line.name,
                                                        quantity: 1,
                                                        type: line.type,
                                                    })
                                                }
                                            >
                                                <img
                                                    src={Cross}
                                                    height={15}
                                                    width={15}
                                                    alt={"cross"}
                                                    className={"cursor_pointer"}
                                                    color={"white"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.yellowLine} />
                    <div className={styles.contentPrice}>
                        <div className={clsx("flex", styles.textPrice)}>
                            <div>{t("paymentPage.price")}</div>
                            <div> {formatPrice(cartStore.total, false, true)} </div>
                        </div>
                        <div className={clsx("flex", styles.textPrice)}>
                            <div>TPS</div>
                            <div>{formatPrice(tps, false, true)}</div>
                        </div>
                        <div className={clsx("flex", styles.textPrice)}>
                            <div>TVQ</div>
                            <div>{formatPrice(tvq, false, true)}</div>
                        </div>
                    </div>
                    <div className={styles.yellowLine} />
                    <div className={styles.totalPrice}>
                        <div> {t("paymentPage.totalPrice")}</div>
                        <div> {formatPrice(cartTotal, false, true)} </div>
                    </div>
                    {!userStore.isLogged && (
                        <div className={clsx(styles.text, "flex_center_center")}>{t("paymentPage.confirm")}</div>
                    )}
                    {userStore.isLogged && (
                        <>
                            <div className={clsx("flex_row mt_30 mb_10", styles.termsAndConditions)}>
                                <input
                                    checked={validTermsAndConditions}
                                    type={"checkbox"}
                                    className={styles.checkbox}
                                    onChange={() => {
                                        setValidTermsAndConditions(!validTermsAndConditions);
                                    }}
                                />
                                <div className={clsx("ml_20")}>
                                    <a
                                        className={styles.linkTermsAndConditions}
                                        target={"_blank"}
                                        href={t("routes.cgv")}
                                        rel="noreferrer"
                                    >
                                        {t("projectPage.acceptTermsAndConditions")}
                                    </a>
                                </div>
                            </div>
                            <UiButton
                                type="submit"
                                variant="contained"
                                className={clsx(styles.button, {
                                    [styles.buttonDisabled]: isDisabled,
                                })}
                                onClick={() => {
                                    if (isDisabled) {
                                        return;
                                    }
                                    submitOrder();
                                }}
                                disabled={isDisabled}
                            >
                                {t("paymentPage.confirmOrder")}
                            </UiButton>
                        </>
                    )}
                </div>
                {!userStore.isLogged && (
                    <div className={clsx("flex_column flex-1 ", styles.secondColumn)}>
                        <div className={clsx("mt_50 mb_40", styles.titleSecond)}> {t("paymentPage.yourProfile")}</div>
                        <div className={styles.contentSecondColumn}>
                            <div className={clsx("flex_column_center", styles.form)}>
                                <ToggleSignInOrSignUp signUp={signUp} setSignUp={setSignUp} />
                                <div className={styles.contentForm}>
                                    {signUp ? <SignUpFormFront /> : <SignInFormFront />}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <ConfirmationDialog
                title={t("profile.info")}
                open={openDialog}
                message={t("errors.api.TOO_MANY_PROPERTIES")}
                agreeLabel={t("footer.links.contactUs")}
                onAgree={() => history.push(URLS.contact())}
                disagreeLabel={t("words.cancel")}
                onDisagree={() => setOpenDialog(false)}
            />
        </div>
    );
});
