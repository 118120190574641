import React from "react";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import clsx from "clsx";
import { IconComponent } from "components/base/icon/IconComponent";
import { Tooltip } from "@material-ui/core";
import styles from "./_css/compareBtn.module.css";
import { comparisonStore } from "shared/compare/_store/ComparisonStore";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import compareIcon from "../assets/images/compareIcon.svg";

type Props = {
    id: string;
    type: MAIN_RESOURCE;
    isItemPage?: boolean;
    button?: boolean;
    colors?: "primary";
    notAbsolute?: boolean;
};

export function CompareBtn(props: Props) {
    return (
        <>
            {props.button ? (
                <Tooltip title={`Compare ${props.type}`}>
                    <UiButton
                        onClick={() => {
                            comparisonStore.addItem(props.type, props.id);
                            comparisonStore.setOverlayIsDisplayed(true);
                        }}
                        className={clsx("p_5 cursor_pointer", styles.button, { [styles.isItemPage]: props.isItemPage })}
                        variant={"contained"}
                    >
                        <IconComponent icon="compareArrow" />
                    </UiButton>
                </Tooltip>
            ) : (
                <Tooltip title={`Compare ${props.type}`}>
                    <img
                        src={compareIcon}
                        className={clsx("p_5 cursor_pointer", {
                            [styles.isItemPage]: props.isItemPage,
                            [styles.primaryColors]: !!props.colors,
                            [styles.iconCompare]: !props.notAbsolute,
                        })}
                        onClick={() => {
                            comparisonStore.addItem(props.type, props.id);
                            comparisonStore.setOverlayIsDisplayed(true);
                        }}
                    ></img>
                </Tooltip>
            )}
        </>
    );
}
