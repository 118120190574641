import React from "react";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { URLS } from "_configs/URLS";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import i18next from "i18next";
import { fetchSearchProperties, getNeswCoordinatesFromMapCoordinates } from "_common/_utils/searchUtils";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PROPERTY_FILTERS } from "properties/searchBar/filters/_utils/filtersUtils";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";

export function PropertyResetButton() {
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <UiButton
            onClick={async () => {
                const type = listStore.filters.find((filter) => filter.id === PROPERTY_FILTERS.TYPE)?.value?.[0];
                geoZonesStore.resetGeoZone();
                propertiesStore.addressParams.city = "";
                propertiesStore.addressParams.address = "";
                propertiesStore.addressParams.region = "";
                history.push(
                    URLS[propertiesStore.purpose === PROPERTY_PURPOSE.RENT ? "rent" : "buy"](
                        i18next.language,
                        type
                            ? reformatStringForUrls(t("property.typesUrl." + type))
                            : getNeswCoordinatesFromMapCoordinates(propertiesStore.searchParams.mapCoordinates),
                        type
                            ? getNeswCoordinatesFromMapCoordinates(propertiesStore.searchParams.mapCoordinates)
                            : undefined,
                    ) + location.search,
                );
                fetchSearchProperties(propertiesStore, listStore, history);
            }}
            variant={"contained"}
            color={"primary"}
        >
            {t("zone.reset")}
        </UiButton>
    );
}
