import { action, autorun, computed, observable } from "mobx";
import { tokenStore } from "users/_stores/tokenStore";
import { IFavoritesSearchMdl, IUserMdl, USER_ROLES } from "users/_models/UserMdl";
import { authStore } from "users/auth/_stores/authStore";
import { fetchUtils, TFilesData } from "shared/_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { TFilter } from "admin/_common/filters/TFilter";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import dayjs from "dayjs";
import { PROPERTY_EVENT, TPropertyCardMdl, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { TUnitListingMdl, UNIT_EVENT } from "units/_models/UnitMdl";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";

export enum FAVORITES_TYPE {
    PROPERTIES = "properties",
    UNITS = "units",
    SEARCHES = "searches",
}

export class UserStore {
    @observable user: IUserMdl | undefined = undefined;
    @observable favoritesPropertiesStates = new LoadingStateMdl<TPropertyListingMdl[]>();
    @observable favoritesProperties: (TPropertyListingMdl | TPropertyCardMdl)[] = [];
    @observable favoritesUnitsStates = new LoadingStateMdl<TUnitListingMdl[]>();
    @observable favoritesUnits: TUnitListingMdl[] = [];
    @observable favoritesSearchesState = new LoadingStateMdl<IFavoritesSearchMdl[]>();
    @observable favoritesSearches: IFavoritesSearchMdl[] = [];
    @observable isModalDisplayed = false;
    @observable developersState = new LoadingStateMdl<IUserMdl[]>();
    @observable allDevelopers: IUserMdl[] = [];
    @observable isDialogAdminFreeTrialOpened = false;

    private readonly _setDataFromToken = action((_token: string | undefined) => {
        const tokenData = tokenStore.getTokenData();
        if (!tokenData) {
            if (this.isLogged) authStore.signOut();
        } else {
            this.user = tokenData;
            analyticsUtils.setUserInfo(this.user.email);
        }
    });

    constructor() {
        autorun(() => {
            const token = tokenStore.token;
            this._setDataFromToken(token);
        });
        if (this.user) {
            this.fetchFavoritesProperties();
            this.fetchFavoritesUnits();
            this.fetchFavoritesSearches();
        }
    }

    @computed get isLogged() {
        return this.user !== undefined;
    }

    @computed get isAdmin() {
        return this.user?.roles.includes(USER_ROLES.ADMIN);
    }

    @computed get isDeveloper() {
        return this.isAdmin || this.user?.roles.includes(USER_ROLES.DEVELOPER);
    }

    @action closeDialogAdminFreeTrial() {
        this.isDialogAdminFreeTrialOpened = false;
    }
    @action openDialogAdminFreeTrial() {
        this.isDialogAdminFreeTrialOpened = true;
    }

    fetchAllDevelopers() {
        if (!this.developersState.isLoading && !this.developersState.isSucceeded) {
            this.developersState.startLoading();
            const filter = {
                id: "roles",
                value: USER_ROLES.DEVELOPER,
                type: TFilterType.IN,
            };
            const promise = fetchUtils.get<{ count: number; items: IUserMdl[] }>(
                sharedConfig.apiUrl + "/users" + `?filters=${JSON.stringify(filter)}`,
            );
            promise
                .then(
                    action(({ data }) => {
                        this.allDevelopers = data.items;
                        this.developersState.setSuccess(data.items);
                    }),
                )
                .catch(() => this.developersState.setError);
        }
    }

    static createFreeTrialAccess(email: string, freeTrialPeriod: number) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/users/createFreeTrialAccess", {
            email,
            freeTrialPeriod,
        });
    }

    fetchFavoritesProperties(userId?: string) {
        if (!this.favoritesPropertiesStates.isLoading && !this.favoritesPropertiesStates.isSucceeded) {
            this.favoritesPropertiesStates.startLoading();
            const promise = fetchUtils.get<TPropertyListingMdl[]>(
                sharedConfig.apiUrl + "/users/favorites/" + FAVORITES_TYPE.PROPERTIES + (userId ? `/${userId}` : ""),
            );
            promise
                .then(
                    action(({ data }) => {
                        this.favoritesProperties = data;
                        this.favoritesPropertiesStates.setSuccess(data);
                    }),
                )
                .catch(() => this.favoritesPropertiesStates.setError);
        }
    }

    fetchFavoritesUnits(userId?: string) {
        if (!this.favoritesUnitsStates.isLoading && !this.favoritesPropertiesStates.isSucceeded) {
            this.favoritesUnitsStates.startLoading();
            const promise = fetchUtils.get<TUnitListingMdl[]>(
                sharedConfig.apiUrl + "/users/favorites/" + FAVORITES_TYPE.UNITS + (userId ? `/${userId}` : ""),
            );
            promise
                .then(
                    action(({ data }) => {
                        this.favoritesUnits = data;
                        this.favoritesUnitsStates.setSuccess(data);
                    }),
                )
                .catch(() => this.favoritesUnitsStates.setError);
        }
    }

    fetchFavoritesSearches(userId?: string) {
        if (!this.favoritesSearchesState.isLoading) {
            this.favoritesSearchesState.startLoading();
            const promise = fetchUtils.get<IFavoritesSearchMdl[]>(
                sharedConfig.apiUrl + "/users/favorites/" + FAVORITES_TYPE.SEARCHES + (userId ? `/${userId}` : ""),
            );
            promise
                .then(
                    action(({ data }) => {
                        this.favoritesSearches = data;
                        this.favoritesSearchesState.setSuccess(data);
                    }),
                )
                .catch(() => this.favoritesSearchesState.setError);
        }
    }

    @action openSignInWithModal() {
        this.isModalDisplayed = true;
    }

    @action closeSignInWithModal() {
        this.isModalDisplayed = false;
    }

    @action togglePropertyToFavorites(property: TPropertyListingMdl | TPropertyCardMdl) {
        return fetchUtils.patch(sharedConfig.apiUrl + "/users/favorites/property/" + property._id).then(
            action(() => {
                const favoriteToDelete = this.favoritesProperties.find(
                    (favoriteProperty) => favoriteProperty._id === property._id,
                );
                if (favoriteToDelete) {
                    this.favoritesProperties.splice(this.favoritesProperties.indexOf(favoriteToDelete), 1);
                } else {
                    this.favoritesProperties.push(property);
                    analyticsUtils
                        .tracksEventOfItem(PROPERTY_EVENT.FAVORITED, property._id)
                        .catch((e) => console.error(e));
                }
            }),
        );
    }

    @action toggleUnitsToFavorites(unit: TUnitListingMdl) {
        return fetchUtils.patch(sharedConfig.apiUrl + "/users/favorites/unit/" + unit._id).then(
            action(() => {
                const favoriteToDelete = this.favoritesUnits.find((favoriteUnit) => favoriteUnit._id === unit._id);
                if (favoriteToDelete) {
                    this.favoritesUnits.splice(this.favoritesUnits.indexOf(favoriteToDelete), 1);
                } else {
                    this.favoritesUnits.push(unit);
                    analyticsUtils.tracksEventOfItem(UNIT_EVENT.FAVORITED, unit._id).catch((e) => console.error(e));
                }
            }),
        );
    }

    @action deleteSearchToFavorite(favorite: IFavoritesSearchMdl) {
        return fetchUtils.patch(sharedConfig.apiUrl + "/users/favorites/deleteSearch", { favorite }).then(
            action(({ data }) => {
                const favoriteToDelete = this.favoritesSearches.find(
                    (savedFavorite) => JSON.stringify(data) === JSON.stringify(savedFavorite),
                );
                if (favoriteToDelete) {
                    this.favoritesSearches.splice(this.favoritesSearches.indexOf(favoriteToDelete), 1);
                }
            }),
        );
    }

    @action patchSearchToFavorite(
        filters: TFilter[],
        search: string,
        region?: string,
        city?: string,
        neighborhood?: string,
    ) {
        return fetchUtils
            .patch(sharedConfig.apiUrl + "/users/favorites/search", {
                filters,
                search,
                region: region,
                city: city,
                neighborhood: neighborhood,
            })
            .then(
                action(() => {
                    this.favoritesSearches.push({
                        filters: JSON.stringify(filters),
                        lastSeenDate: dayjs().toDate(),
                        search,
                        region,
                        city,
                    });
                }),
            );
    }

    save(user: Partial<IUserMdl>, files?: TFilesData) {
        const body = files ? fetchUtils.createBodyWithFiles(user, files) : user;
        return fetchUtils.patch(sharedConfig.apiUrl + "/users/" + this.user?._id, body, !!files).then(({ data }) => {
            void tokenStore.refreshToken();
            this.user = data as IUserMdl;
        });
    }

    refreshToken() {
        return fetchUtils.get<{ token: string }>(sharedConfig.apiUrl + "/users/refreshToken");
    }

    hasRole(role: USER_ROLES) {
        return this.user?.roles.includes(role) ?? false;
    }
}

export const userStore = new UserStore();
