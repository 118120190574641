import { RefObject, useEffect } from "react";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { PROPERTY_EVENT, TPropertyCardMdl } from "properties/_models/PropertyMdl";
import { PLACEMENT_EVENT, PLACEMENT_PRINTED_EVENT } from "placements/_models/PlacementsMdl";
import { useOnScreen } from "_common/_utils/useOnScreen";
import { useLocation } from "react-router";

type Props = {
    property: TPropertyCardMdl;
    isMapCard?: boolean;
    isFeaturedCard?: boolean;
    isPrimaryCard?: boolean;
    isSimilarCard?: boolean;
    isEditable?: boolean;
    pageBuilder?: boolean;
    onEdit?: () => void;
    onMouseOver?: () => void;
    onMouseLeave?: () => void;
};

export function useTrackingPropertyCard(ref: RefObject<HTMLElement>, props: Props) {
    const url = useLocation().pathname;
    const { isIntersecting } = useOnScreen(ref, 0.5);

    useEffect(() => {
        if (isIntersecting) {
            void analyticsUtils.tracksEventOfItem(PROPERTY_EVENT.PRINTED, props.property._id, url);

            if (props.property.placementId && (props.isFeaturedCard || props.property.isPremium)) {
                if (props.isFeaturedCard) {
                    void analyticsUtils.tracksEventOfItem(
                        PLACEMENT_PRINTED_EVENT.PRINTED_FEATURED,
                        props.property.placementId,
                        url,
                    );
                } else if (props.property.isPremium) {
                    void analyticsUtils.tracksEventOfItem(
                        PLACEMENT_EVENT.PRINTED_PREMIUM,
                        props.property.placementId,
                        url,
                    );
                }
            }
        }
    }, [isIntersecting]);
}
