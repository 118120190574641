import React from "react";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";

export const DemandSuccessHeader = () => {
    const { t } = useTranslation();
    const { propertyAlias } = useParams();
    return (
        <div>
            <h1 className={"textAlign_center"}>{t("demandSuccess.title")}</h1>
            <div className={"flex_row_center flexWrap_wrap justifyContent_center text-2xl gap_40 mt_50"}>
                <Link to={URLS.property(propertyAlias)}>
                    <UiButton variant={"outlined"} color={"primary"}>
                        {t("demandSuccess.back")}
                    </UiButton>
                </Link>
                <Link to={URLS.virtualAssistant()}>
                    <UiButton variant={"contained"} color={"primary"}>
                        {t("demandSuccess.tryAssistant")}
                    </UiButton>
                </Link>
            </div>
        </div>
    );
};
