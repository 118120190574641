import { TFilesData } from "_common/_utils/fetchUtils";
import { TObjWithId } from "_common/types/GenericTypes";
import _ from "lodash";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { action, observable, reaction } from "mobx";
import { unitsAdminStore } from "admin/units/_stores/unitsAdminStore";
import eventsStore from "main/events/eventsStore";
import { UNKNOWN_ERROR } from "_common/errors/errorUtils";
import { createFilesData } from "_common/_utils/fileUtils";
import { propertiesAdminStore } from "admin/properties/_stores/propertiesAdminStore";
import { BaseResourceStore, MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { TImage } from "_common/types/baseTypes";

const PHOTOS_UPLOAD_BATCH_SIZE = 3;

export enum RESOURCE_KEY {
    PHOTOS = "photos",
    FLOOR_PLANS = "floorPlans",
}

function getStore<TResource extends TObjWithId>(resource: MAIN_RESOURCE): BaseResourceStore<TResource> {
    switch (resource) {
        case MAIN_RESOURCE.UNITS:
            return (unitsAdminStore as unknown) as BaseResourceStore<TResource>;
        case MAIN_RESOURCE.PROPERTIES:
            return (propertiesAdminStore as unknown) as BaseResourceStore<TResource>;
    }
}

export abstract class AdminPhotosStore<TResource extends TObjWithId & { [key: string]: TImage[] | undefined }> {
    @observable photosUploadingState: {
        url: string;
        loadingState: LoadingStateMdl<TResource | undefined>;
    }[] = [];
    @observable currentPhotosLoadingState: LoadingStateMdl<TResource | undefined> | undefined = undefined;
    readonly itemSavingState = new LoadingStateMdl<TResource | undefined>();
    @observable item: TResource;
    protected readonly resource: MAIN_RESOURCE;
    protected readonly store: BaseResourceStore<TResource>;

    protected constructor(resourceName: MAIN_RESOURCE, item: TResource) {
        this.resource = resourceName;
        this.store = getStore(this.resource);
        this.item = item;
        if (!this.item._id) {
            const disposer = eventsStore.on(`${this.resource}/created`, (event) => {
                this.item = event.payload.item;
                disposer();
            });
        }
    }

    save(unit: Partial<TResource>, files?: TFilesData) {
        return this.patch(unit, files);
    }

    addPhotos(urls: string[], photoResourceKey: RESOURCE_KEY) {
        const urlsBatchs = _.chunk(urls, PHOTOS_UPLOAD_BATCH_SIZE);
        return urlsBatchs.map((urlsBatch: string[]) => this.uploadPhotosBatchs(urlsBatch, photoResourceKey));
    }

    movePhoto(startIndex: number, endIndex: number, photoResourceKey: RESOURCE_KEY) {
        if (!this.currentPhotosLoadingState) {
            const photos = this.item[photoResourceKey] as TImage[];
            const updatedPhotos = [...(photos ?? [])];
            updatedPhotos.splice(endIndex, 0, ...updatedPhotos.splice(startIndex, 1));
            this.currentPhotosLoadingState = this.patch({ [photoResourceKey]: updatedPhotos } as Partial<TResource>);
            this.currentPhotosLoadingState?.promise?.finally(() => (this.currentPhotosLoadingState = undefined));
            return this.currentPhotosLoadingState;
        }
    }

    deletePhoto(photoIndex: number, photoResourceKey: RESOURCE_KEY) {
        const loadingState = new LoadingStateMdl<TResource | undefined>();
        const deletePromise = new Promise<TResource | undefined>((resolve) => {
            this.onNextPhotosLoadingState(() => {
                const updatedPhotos = [...(this.item[photoResourceKey] ?? [])];
                updatedPhotos.splice(photoIndex, 1);
                const patchLoadingState = this.patch({ [photoResourceKey]: updatedPhotos } as Partial<TResource>);
                loadingState.sync(patchLoadingState);
                resolve(patchLoadingState.promise);
            });
        });
        loadingState.startLoading(deletePromise);
        return loadingState;
    }

    getPhotoUploadingState(url: string) {
        return this.photosUploadingState.find((uploadingState) => uploadingState.url === url)?.loadingState;
    }

    @action deletePhotoUploadingState(url: string) {
        return this.photosUploadingState.splice(
            this.photosUploadingState.findIndex((uploadingState) => uploadingState.url === url),
            1,
        );
    }

    @action
    private uploadPhotosBatchs(urls: string[], photoResourceKey: RESOURCE_KEY) {
        const uploadingState = new LoadingStateMdl<TResource | undefined>("LOADING");
        for (let i = 0; i < urls.length; i++) {
            const url = urls[i];
            this.photosUploadingState.push({ url, loadingState: uploadingState });
        }
        this.onNextPhotosLoadingState(() => this.executePhotosUpload(uploadingState, urls, photoResourceKey));
        return uploadingState;
    }

    @action
    private async executePhotosUpload(
        uploadingState: LoadingStateMdl<TResource | undefined>,
        urls: string[],
        photoResourceKey: RESOURCE_KEY,
    ) {
        try {
            this.currentPhotosLoadingState = uploadingState;
            const updatedPhotos = [...(this.item[photoResourceKey] ?? []), ...urls.map((url) => ({ url }))];
            const filesData = await createFilesData(
                updatedPhotos.map(({ url }) => url),
                `${photoResourceKey}.*.url`,
                1920,
            );
            const patchLoadingState = this.patch(
                { [photoResourceKey]: updatedPhotos } as Partial<TResource>,
                filesData,
            );
            uploadingState.sync(patchLoadingState);
            patchLoadingState.promise
                ?.then(
                    action(() => {
                        for (const url of urls) {
                            this.deletePhotoUploadingState(url);
                        }
                    }),
                )
                .finally(() => (this.currentPhotosLoadingState = undefined));
        } catch (err) {
            uploadingState.setError(err);
            this.currentPhotosLoadingState = undefined;
        }
    }

    private onNextPhotosLoadingState(cb: () => any) {
        if (!this.currentPhotosLoadingState) cb();
        else {
            const disposer = reaction(
                () => !this.currentPhotosLoadingState,
                () => {
                    if (!this.currentPhotosLoadingState) {
                        disposer();
                        cb();
                    }
                },
            );
        }
    }

    private patch(patch: Partial<TResource & { [key: string]: TImage[] | undefined }>, files?: TFilesData) {
        if (!this.itemSavingState.isLoading) {
            const isCreate = !this.item._id;
            const request = isCreate
                ? this.store.create(patch, files)
                : this.store.patch({ ...patch, _id: this.item._id }, files);
            const promise = request.then(
                action((savedItem: TResource | undefined) => {
                    if (savedItem) {
                        this.item = savedItem;
                        this.itemSavingState.setSuccess(savedItem);
                        eventsStore.send({
                            type: isCreate ? `${this.resource}/created` : `${this.resource}/updated`,
                            payload: { item: this.item },
                        });
                    } else {
                        this.itemSavingState.setError(UNKNOWN_ERROR);
                    }
                    return savedItem;
                }),
                (err) => {
                    this.itemSavingState.setError(err);
                    return undefined;
                },
            );
            this.itemSavingState.startLoading(promise);
        }
        return this.itemSavingState;
    }
}
