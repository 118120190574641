import { TFilter } from "admin/_common/filters/TFilter";
import { PROPERTY_FILTERS } from "properties/searchBar/filters/_utils/filtersUtils";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { getI18nExpByLang } from "_common/_utils/pageUtils";

export function propertyTypesToFilters(propertyTypes: string[]) {
    const filters: TFilter[] = [];
    if (propertyTypes.length) {
        filters.push({
            id: PROPERTY_FILTERS.TYPE,
            type: TFilterType.IN,
            value: propertyTypes,
        });
    }
    return filters;
}

export function propertyTypeKeyFromUrl(propertyType: string, i18n: any): string | undefined {
    const labelKeysValues = (getI18nExpByLang(i18n.language, `property.typesUrl`) as unknown) as {
        [key: string]: string;
    };
    return Object.entries(labelKeysValues).find(([key, value]) => (value === propertyType ? key : undefined))?.[0];
}
