import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { TOpItems } from "admin/_common/filters/OpPicker";
import { TFilter } from "admin/_common/filters/TFilter";

export const BEDROOMS_DATA = {
    min: [
        { value: "", label: "No min" },
        { value: "0", label: "Studio" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
    ],
    max: [
        { value: "", label: "No max" },
        { value: "0", label: "Studio" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
    ],
};

export const BATHROOMS_DATA = {
    min: [
        { value: "", label: "No min" },
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
    ],
    max: [
        { value: "", label: "No max" },
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
    ],
};

export function roomsRangeToFilters(
    payload: {
        filterKey?: string;
        min: string;
        max: string;
    } = {
        filterKey: "price",
        min: "0",
        max: "0",
    },
) {
    const filters: TFilter[] = [];
    const { filterKey, max, min } = payload;
    const reverseBedMaxMin = !isNaN(parseInt(max)) && !isNaN(parseInt(min)) && parseInt(max) < parseInt(min);

    if (!isNaN(parseInt(max))) {
        filters.push({
            id: `${filterKey}.min`,
            type: TFilterType.NUMBER,
            value: reverseBedMaxMin ? parseInt(min) : parseInt(max),
            op: "lte" as TOpItems,
        });
    }

    if (!isNaN(parseInt(min))) {
        filters.push({
            id: `bedrooms.max`,
            type: TFilterType.NUMBER,
            value: reverseBedMaxMin ? parseInt(max) : parseInt(min),
            op: "gte" as TOpItems,
        });
    }
    return filters;
}
