import { PROPERTY_PURPOSE, PROPERTY_TYPE, TPropertyMdl } from "properties/_models/PropertyMdl";
import { PLACEMENT_PROPERTY_TYPE } from "placements/_models/PlacementsMdl";
import { TFilter } from "admin/_common/filters/TFilter";

export function getPlacementPropertyType(purpose: PROPERTY_PURPOSE, type?: PROPERTY_TYPE): PLACEMENT_PROPERTY_TYPE {
    if (purpose === PROPERTY_PURPOSE.RENT) {
        if (type === PROPERTY_TYPE.commercial) {
            return PLACEMENT_PROPERTY_TYPE.COMMERCIAL_RENT;
        } else {
            return PLACEMENT_PROPERTY_TYPE.RESIDENTIAL_RENT;
        }
    }
    return PLACEMENT_PROPERTY_TYPE.RESIDENTIAL_SALE;
}

export function getPlacementPropertyTypeFromProperty(property: Pick<TPropertyMdl, "purpose" | "type">) {
    return getPlacementPropertyType(property.purpose, property.type);
}

export function getPlacementPropertyTypeFromFilters(filters: TFilter[]) {
    const purpose = filters.find((filter) => filter.id === "purpose")?.value as PROPERTY_PURPOSE;
    const type = filters.find((filter) => filter.id === "type")?.value as PROPERTY_TYPE;
    return getPlacementPropertyType(purpose, type);
}

export function getTypeAndPurposeFromPlacementType(placementType: PLACEMENT_PROPERTY_TYPE) {
    const purpose =
        placementType === PLACEMENT_PROPERTY_TYPE.RESIDENTIAL_SALE ? PROPERTY_PURPOSE.BUY : PROPERTY_PURPOSE.RENT;
    const propertyType =
        placementType === PLACEMENT_PROPERTY_TYPE.COMMERCIAL_RENT ? PROPERTY_TYPE.commercial : undefined;
    return { purpose, propertyType };
}
