import React from "react";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { URLS } from "_configs/URLS";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { JsonLdBreadCrumb } from "breadcrumb/JsonLdBreadCrumb";
import { useTranslation } from "react-i18next";
import { Breadcrumb, TBreadcrumbItem } from "breadcrumb/Breadcrumb";
import { IconComponent } from "components/base/icon/IconComponent";

export function PropertiesBreadcrumb() {
    const propertiesStore = usePropertiesStore();
    const { isCityPage, isNeighborhoodPage, propertyTypeKey } = useSearchPageParams();
    const { t } = useTranslation();
    const { geoZone } = geoZonesStore;
    if (!geoZone) return null;
    const provinceName = geoZone.address.provinceLong ?? geoZone.address.province;
    const items: TBreadcrumbItem[] = [
        {
            itemLabel:
                propertiesStore.purpose === PROPERTY_PURPOSE.BUY
                    ? t("propertiesPage.meta.buyGenericMetaTitle")
                    : t("propertiesPage.meta.rentGenericMetaTitle"),
            pageTitle:
                propertiesStore.purpose === PROPERTY_PURPOSE.BUY
                    ? t("propertiesPage.meta.buyGenericMetaTitle")
                    : t("propertiesPage.meta.rentGenericMetaTitle"),
            icon: <IconComponent icon="icon-home" />,
            url: URLS.buy(),
            forceReload: true,
        },
    ];

    let currentPageTitle: string = t(
        `propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.` + (propertyTypeKey ?? "generic"),
        {
            geoZoneName: provinceName,
        },
    );

    if (isCityPage) {
        items.push({
            itemLabel: provinceName,
            url: URLS[propertiesStore.purpose === PROPERTY_PURPOSE.RENT ? "rent" : "buy"](
                undefined,
                reformatStringForUrls(geoZone.address.province),
            ),
            forceReload: true,
            pageTitle: t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.generic`, {
                geoZoneName: provinceName,
            }),
        });
        currentPageTitle = t(
            `propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.` + (propertyTypeKey ?? "generic"),
            {
                geoZoneName: geoZone?.address?.city,
            },
        );
    }

    if (isNeighborhoodPage) {
        items.push({
            itemLabel: geoZone.address.city,
            url: URLS[propertiesStore.purpose === PROPERTY_PURPOSE.RENT ? "rent" : "buy"](
                undefined,
                reformatStringForUrls(geoZone.address.province),
                reformatStringForUrls(geoZone.address.city),
            ),
            forceReload: true,
            pageTitle: t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.generic`, {
                geoZoneName: geoZone?.address?.city,
            }),
        });
        currentPageTitle = t(
            `propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.` + (propertyTypeKey ?? "generic"),
            {
                geoZoneName: geoZone?.address?.neighbourhood,
            },
        );
    }
    if (propertyTypeKey) {
        items.push({
            itemLabel: isNeighborhoodPage
                ? geoZone?.address?.neighbourhood
                : isCityPage
                ? geoZone?.address?.city
                : geoZone?.address?.provinceLong,
            url: URLS[propertiesStore.purpose === PROPERTY_PURPOSE.RENT ? "rent" : "buy"](
                undefined,
                reformatStringForUrls(
                    !isNeighborhoodPage && !isCityPage ? geoZone.address.provinceLong : geoZone.address.province,
                ),
                isCityPage ? reformatStringForUrls(geoZone.address.city) : undefined,
                isNeighborhoodPage ? reformatStringForUrls(geoZone.address.neighbourhood) : undefined,
            ),
            forceReload: true,
            pageTitle: t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.generic`, {
                geoZoneName: isNeighborhoodPage
                    ? geoZone?.address?.neighbourhood
                    : isCityPage
                    ? geoZone?.address?.city
                    : geoZone?.address?.provinceLong,
            }),
        });
    }

    return (
        <>
            <JsonLdBreadCrumb
                items={items}
                currentItem={{
                    itemLabel: currentPageTitle,
                }}
            />
            <Breadcrumb noHome currentItem={{ itemLabel: currentPageTitle }} items={items} />
        </>
    );
}
