import React from "react";
import { VirtualAssistantQuestionLabel } from "virtualAssistant/VirtualAssistantQuestionLabel";
import styles from "virtualAssistant/_css/virtualAssistantStepOneForm.module.css";
import { useTranslation } from "react-i18next";
import { CheckBoxInput } from "_common/ui/forms/CheckBoxInput";
import { Controller, useFormContext } from "react-hook-form";
import { TVirtualAssistantForm } from "virtualAssistant/VirtualAssistantForm";
import { TextArea } from "_common/ui/forms/TextArea";
import { RangeSelect } from "virtualAssistant/RangeSelect";
import { WHEN_DATA } from "virtualAssistant/VIRTUAL_ASSISTANT_DATA";
import { SQUARE_RANGE } from "properties/searchBar/filters/_utils/surfaceUtils";
import { Input } from "_common/ui/forms/Input";
import { InputBlock } from "_common/ui/forms/InputBlock";

export function VirtualAssistantStepThreeForm() {
    const { t } = useTranslation();
    const form = useFormContext<TVirtualAssistantForm>();

    const handleMinChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const [, max] = form.getValues("surface");
        form.setValue("surface", [event.target.value as number, max]);
    };

    const handleMaxChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const [min] = form.getValues("surface");
        form.setValue("surface", [min, event.target.value as number]);
    };

    return (
        <div className={"flex_column gap_50"}>
            <div>
                <VirtualAssistantQuestionLabel label={t("virtualAssistant.stepThreeForm.sixthQuestionLabel")} />
                <div className={styles.title}>{t("virtualAssistant.stepThreeForm.sixthQuestionTitle")}</div>
                <Controller
                    name="surface"
                    control={form.control}
                    defaultValue={["0", "2000"]}
                    render={({ value }) => (
                        <RangeSelect
                            options={{
                                min: SQUARE_RANGE.default.min,
                                max: SQUARE_RANGE.default.max,
                            }}
                            minValue={value[0]}
                            maxValue={value[1]}
                            handleMinChange={handleMinChange}
                            handleMaxChange={handleMaxChange}
                            minLabel={"virtualAssistant.stepThreeForm.minSurface"}
                            maxLabel={"virtualAssistant.stepThreeForm.maxSurface"}
                        />
                    )}
                />
            </div>
            <div>
                <VirtualAssistantQuestionLabel label={t("virtualAssistant.stepThreeForm.seventhQuestionLabel")} />
                <div className={styles.title}>{t("virtualAssistant.stepThreeForm.seventhQuestionTitle")}</div>
                <Controller
                    as={CheckBoxInput}
                    control={form.control}
                    name={"when"}
                    rules={{
                        required: t<string>("errors.forms.required"),
                    }}
                    items={WHEN_DATA}
                    containerClassName={"flex_row flexWrap_wrap gap_20"}
                />
            </div>
            <div>
                <VirtualAssistantQuestionLabel label={t("virtualAssistant.stepThreeForm.eighthQuestionLabel")} />
                <div className={styles.title}>{t("virtualAssistant.stepThreeForm.ninthQuestionTitle")}</div>
                <div className={"flex flexWrap_wrap gap_20 mb_10"}>
                    <InputBlock
                        required
                        label={t("virtualAssistant.stepThreeForm.firstName")}
                        className={styles.inputInformations}
                    >
                        <Controller
                            as={Input}
                            control={form.control}
                            rules={{ required: t<string>("errors.forms.required"), minLength: 1 }}
                            name={"firstName"}
                            error={form.errors.firstName}
                            placeholder={t("virtualAssistant.stepThreeForm.firstNamePlaceholder")}
                        />
                    </InputBlock>
                    <InputBlock
                        required
                        label={t("virtualAssistant.stepThreeForm.lastName")}
                        className={styles.inputInformations}
                    >
                        <Controller
                            as={Input}
                            control={form.control}
                            name={"lastName"}
                            rules={{ required: t<string>("errors.forms.required"), minLength: 1 }}
                            error={form.errors.lastName}
                            placeholder={t("virtualAssistant.stepThreeForm.lastNamePlaceholder")}
                        />
                    </InputBlock>
                </div>
                <div className={"flex flexWrap_wrap gap_20"}>
                    <InputBlock
                        required
                        label={t("virtualAssistant.stepThreeForm.email")}
                        className={styles.inputInformations}
                    >
                        <Controller
                            as={Input}
                            control={form.control}
                            name={"email"}
                            error={form.errors.email}
                            rules={{
                                required: t<string>("errors.forms.required"),
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                                    message: t("errors.forms.emailInvalid"),
                                },
                            }}
                            placeholder={t("virtualAssistant.stepThreeForm.emailPlaceholder")}
                        />
                    </InputBlock>
                    <InputBlock label={t("virtualAssistant.stepThreeForm.phone")} className={styles.inputInformations}>
                        <Controller
                            as={Input}
                            control={form.control}
                            name={"phone"}
                            placeholder={t("virtualAssistant.stepThreeForm.phonePlaceholder")}
                        />
                    </InputBlock>
                </div>
            </div>
            <div>
                <VirtualAssistantQuestionLabel label={t("virtualAssistant.stepThreeForm.tenQuestionLabel")} />
                <div className={styles.title}>{t("virtualAssistant.stepThreeForm.tenQuestionTitle")}</div>
                <Controller
                    as={TextArea}
                    control={form.control}
                    name={"comment"}
                    placeholder={t("virtualAssistant.stepThreeForm.tenQuestionPlaceholder")}
                    className={styles.textArea}
                />
            </div>
        </div>
    );
}
