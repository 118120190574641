import React from "react";
import styles from "./_css/menu.module.css";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { SubMenu } from "main/header/_menu/SubMenu";
import { useTranslation } from "react-i18next";
import { URLS } from "_configs/URLS";
import { LangSelector } from "main/header/_menu/LangSelector";
import { userStore } from "users/_stores/userStore";
import { useMenuContext } from "main/header/_menu/MenuContext";
import { TLang } from "_configs/sharedConfig";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { VirtualAssistantTooltip } from "virtualAssistant/_css/VirtualAssistantTooltip";

export type TMenuItem = {
    key: string;
    itemLabelKey: string;
    itemLink: (
        lang: TLang,
        region?: string,
        city?: string,
        neighborhood?: string,
        propertyType?: PROPERTY_TYPE,
        token?: string | null,
    ) => any;
    routeKey?: string;
    hiddenInMobile?: boolean;
    isSubMenu?: boolean;
};

export function Menu() {
    const { currentMenu, setCurrentMenu, residentialMenu, citiesMenu, userMenu, commercialMenu } = useMenuContext();
    const { t, i18n } = useTranslation();

    const lang = i18n.language;

    return (
        <nav className={styles.container}>
            <div className={styles.menu}>
                <ul>
                    <li
                        className={styles.liMainMenu}
                        onMouseEnter={() => setCurrentMenu("residential")}
                        onMouseLeave={() => setCurrentMenu(undefined)}
                    >
                        <ExternalOrNavLink url={"#"}>{t("menu.residential.title")}</ExternalOrNavLink>
                        <SubMenu columns={residentialMenu} hidden={currentMenu !== "residential"} />
                    </li>
                    <li
                        className={styles.liMainMenu}
                        onMouseEnter={() => setCurrentMenu("commercial")}
                        onMouseLeave={() => setCurrentMenu(undefined)}
                    >
                        <ExternalOrNavLink url={"#"}>{t("menu.commercial.title")}</ExternalOrNavLink>
                        <SubMenu columns={commercialMenu} hidden={currentMenu !== "commercial"} />
                    </li>
                    <li
                        className={styles.liMainMenu}
                        onMouseEnter={() => setCurrentMenu("cities")}
                        onMouseLeave={() => setCurrentMenu(undefined)}
                    >
                        <ExternalOrNavLink url={"#"}>{t("menu.cities")}</ExternalOrNavLink>
                        <SubMenu columns={citiesMenu} hidden={currentMenu !== "cities"} />
                    </li>
                    <li className={styles.liMainMenu}>
                        <ExternalOrNavLink url={URLS.blog()}>{t("menu.blog")}</ExternalOrNavLink>
                    </li>
                    {(lang === "en" || lang === "fr") && (
                        <li
                            className={styles.liMainMenu}
                            style={{ position: "relative" }}
                            onMouseEnter={() => setCurrentMenu("virtualAssistant")}
                            onMouseLeave={() => setCurrentMenu(undefined)}
                        >
                            <ExternalOrNavLink url={URLS.virtualAssistant()}>
                                {t("menu.virtualAssistant.title")}
                            </ExternalOrNavLink>
                            {currentMenu === "virtualAssistant" && <VirtualAssistantTooltip />}
                        </li>
                    )}
                </ul>
                <ul>
                    <li className={styles.liMainMenu}>
                        <ExternalOrNavLink url={URLS.advertise()}>{t("menu.promotion")}</ExternalOrNavLink>
                    </li>
                    {userStore.isLogged ? (
                        <li
                            className={styles.liMainMenu}
                            onMouseEnter={() => setCurrentMenu("user")}
                            onMouseLeave={() => setCurrentMenu(undefined)}
                        >
                            <ExternalOrNavLink url={"#"}>{t("menu.profile")}</ExternalOrNavLink>
                            <SubMenu startingFromRight columns={userMenu} hidden={currentMenu !== "user"} />
                        </li>
                    ) : (
                        <>
                            <li className={styles.liMainMenu}>
                                <ExternalOrNavLink url={URLS.auth.signIn()}>{t("menu.signIn")}</ExternalOrNavLink>
                            </li>
                            <li className={styles.btn}>
                                <ExternalOrNavLink url={URLS.auth.signUp()}>{t("menu.signUp")}</ExternalOrNavLink>
                            </li>
                        </>
                    )}
                </ul>
            </div>
            <ul>
                <LangSelector />
            </ul>
        </nav>
    );
}
