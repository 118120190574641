import React from "react";
import clsx from "clsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useUnitStore } from "units/UnitContext";
import styles from "./_css/unitPlans.module.css";

export function UnitPlans() {
    const unitStore = useUnitStore();
    if (!unitStore.unit.floorPlans) return null;
    return (
        <div className={styles.container}>
            <div className={clsx(styles.gallery, "flex_row flexWrap_wrap justifyContent_center")}>
                {unitStore.unit.floorPlans.map((photo, index) => {
                    return (
                        <div key={index} className={clsx(styles.galleryImage)}>
                            <div>
                                <LazyLoadImage
                                    onClick={() => unitStore.setOpenedImageModal("floorPlans", index)}
                                    className={styles.image}
                                    src={photo.url}
                                    alt={`${unitStore.unit.localized.title}-${index}`}
                                    title={`${unitStore.unit.localized.title} photo ${index}`}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
