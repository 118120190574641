import React from "react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import styles from "./_css/promoteFormModal.module.css";
import { PromoteForm } from "payments/_components/PromoteForm";
import { NOTIFICATION_TYPE } from "components/misc/contactUs/_stores/notifierStore";

type Props = {
    open: boolean;
    onClose: () => void;
};

export function PromoteFormModal(props: Props) {
    return (
        <Dialog
            onClose={() => props.onClose()}
            open={props.open}
            closeCross
            closeSize={"default"}
            className={styles.dialog}
        >
            <div className={"p_20"}>
                <PromoteForm type={NOTIFICATION_TYPE.CONTACT_PROMOTE} onSuccess={() => props.onClose()} />
            </div>
        </Dialog>
    );
}
