import React from "react";
import { TPropertyCardMdl } from "properties/_models/PropertyMdl";
import { Marker } from "@react-google-maps/api";
import dotImg from "../assets/images/dot.svg";
import dotHoverImg from "../assets/images/dotHover.svg";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";

type Props = {
    property: TPropertyCardMdl;
    onPropertySelected: (property: TPropertyCardMdl | undefined) => void;
};

export const MapPropertyMarker = (props: Props) => {
    const propertiesStore = usePropertiesStore();
    const marker = {
        lat: props.property.location?.coordinates[1],
        lng: props.property.location?.coordinates[0],
    };
    return (
        <Marker
            icon={propertiesStore.mapPropertiesSelected?._id === props.property._id ? dotHoverImg : dotImg}
            onClick={() => {
                if (propertiesStore.mapPropertiesSelected?._id === props.property._id) {
                    propertiesStore.setMapPropertiesSelected(undefined);
                } else {
                    props.onPropertySelected(props.property);
                }
            }}
            onMouseOver={() => {
                props.onPropertySelected(props.property);
            }}
            onMouseOut={() => {
                propertiesStore.setMapPropertiesSelected(undefined);
            }}
            position={marker}
            zIndex={propertiesStore.mapPropertiesSelected?._id === props.property._id ? 1000 : undefined}
        />
    );
};
