import dayjs from "dayjs";

export const HOURS_BEFORE_DISPLAY_AGAIN = 2;

export class VirtualAssistantUtils {
    static setLastVisit() {
        localStorage.setItem("virtual-assistant-last-visit", dayjs().format("YYYY-MM-DD HH:mm:ss"));
    }

    static getLastVisit() {
        if (!__BROWSER__) return null;
        const lastVisitStr = localStorage.getItem("virtual-assistant-last-visit");
        if (!lastVisitStr) return null;

        return dayjs(lastVisitStr);
    }
}
