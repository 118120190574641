import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
    typography: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 14,
    },
    palette: {
        primary: {
            main: "#004378",
        },
        secondary: {
            main: "#ffc435",
        },
        error: {
            main: "#FA6F4F",
        },
        background: {
            default: "#fff",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1000,
            lg: 1280,
            xl: 1750,
        },
    },
});
