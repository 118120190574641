import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { TPropertyMdl } from "properties/_models/PropertyMdl";
import clsx from "clsx";
import styles from "projects/_css/projectView.module.css";
import { PricingBreadcrumb } from "payments/PricingBreadcrumb";
import { itemsForBreadcrumb } from "payments/PricingPage";
import { Container } from "@material-ui/core";
import { ButtonNavigation } from "projects/component/ButtonNavigation";
import { URLS } from "_configs/URLS";
import { ordersStore } from "payments/_stores/ordersStore";
import { SelectStepFormProject } from "projects/component/SelectStepFormProject";
import { ProjectForm } from "projects/ProjectForm";
import { ButtonSaveProjectPage } from "projects/ButtonSaveProjectPage";
import { useOrderContext } from "projects/OrderContext";

export type TStepForm = {
    title: string;
    value: number;
};

const STEPS: TStepForm[] = [
    {
        title: "projectPage.step1",
        value: 1,
    },
    {
        title: "projectPage.step2",
        value: 2,
    },
    {
        title: "projectPage.step3",
        value: 3,
    },
    {
        title: "projectPage.step4",
        value: 4,
    },
];

type Props = {
    property: TPropertyMdl;
};

export function ProjectView(props: Props) {
    const { t } = useTranslation();
    const { orderStore } = useOrderContext();
    const [stepSelected, setStepSelected] = useState(1);
    const [property, setProperty] = useState(props.property);

    const form = useForm<TPropertyMdl>({
        mode: "onBlur",
        defaultValues: JSON.parse(JSON.stringify(props.property)),
    });

    return (
        <div className={clsx("flex_column_center", styles.container)}>
            <Container className={"mt_10 mb_20"}>
                <div className={styles.header}>
                    <PricingBreadcrumb items={itemsForBreadcrumb} currentItem={2} />
                    <div className={clsx(styles.title, "mt_30")}>{t("projectPage.projectDetail")}</div>
                    <div className={"flex_row justifyContent_spaceBetween hiddenMobile"}>
                        <ButtonNavigation
                            text={"paymentPage.goBack"}
                            type={"button"}
                            variant={"contained"}
                            link={URLS.resume()}
                            arrowDirection={"left"}
                        />
                        <ButtonNavigation
                            text={"projectPage.skipStep"}
                            type={"button"}
                            variant={"contained"}
                            arrowDirection={"right"}
                            onClick={() => {
                                ordersStore.createCheckoutSession(orderStore.order).then(({ data }) => {
                                    window.location.href = data.url;
                                });
                            }}
                        />
                    </div>
                    <div className={"flex_row justifyContent_spaceBetween hiddenDesktop"}>
                        <ButtonNavigation
                            type={"button"}
                            variant={"contained"}
                            link={URLS.resume()}
                            arrowDirection={"left"}
                        />
                        <ButtonNavigation
                            type={"button"}
                            variant={"contained"}
                            arrowDirection={"right"}
                            onClick={() => {
                                ordersStore.createCheckoutSession(orderStore.order).then(({ data }) => {
                                    window.location.href = data.url;
                                });
                            }}
                        />
                    </div>
                </div>
                <FormProvider {...form}>
                    <div className={clsx("mt_20 flex_row", styles.content)}>
                        <SelectStepFormProject
                            items={STEPS}
                            stepSelected={stepSelected}
                            setStepSelected={setStepSelected}
                        />
                        <ProjectForm stepSelected={stepSelected} property={property} setProperty={setProperty} />
                    </div>
                    <div className={"mb_25"}>
                        <ButtonSaveProjectPage
                            property={property}
                            setProperty={setProperty}
                            stepSelected={stepSelected}
                            setStepSelected={setStepSelected}
                        />
                    </div>
                </FormProvider>
            </Container>
        </div>
    );
}
