import React, { useState } from "react";
import { useCommercialPage, useHomePage, useWindowSize } from "_common/_utils/hookUtils";
import clsx from "clsx";
import { CartIcon } from "../assets/iconComponents/CartIcon";
import styles from "../main/header/_css/cartButton.module.css";
import { observer } from "mobx-react";
import { cartStore } from "Cart/_stores/cartStore";
import { URLS } from "_configs/URLS";
import { useHistory } from "react-router-dom";

export const CartButton = observer(() => {
    const [hover, setHover] = useState(false);

    const isHomePage = useHomePage();
    const isCommercialPage = useCommercialPage();
    const size = useWindowSize();
    const history = useHistory();
    const isMobile = size.width < 980;

    return (
        <div
            className={clsx(styles.container, {
                [styles.hoverHidden]: cartStore.cart.length === 0,
            })}
            onClick={() => {
                if (isMobile) {
                    history.push(URLS.resume());
                }
            }}
            onMouseEnter={() => {
                if (!isMobile) {
                    if (cartStore.cart.length > 0) {
                        setHover(true);
                        cartStore.setIsCartOpenDebounce(true);
                    }
                }
            }}
            onMouseLeave={() => {
                if (!isMobile) {
                    setHover(false);
                    cartStore.setIsCartOpenDebounce(false);
                }
            }}
        >
            <div className={clsx("flex_center_center height_full")}>
                <div className={"position_relative"}>
                    <div className={clsx("flex_center_center", styles.flagMobile)}>
                        <CartIcon color={isHomePage || isCommercialPage || hover ? "white" : "#004378"} size={25} />
                    </div>
                    <div className={clsx("flex_center_center", styles.flagDesktop)}>
                        <CartIcon color={isHomePage || isCommercialPage || hover ? "white" : "#004378"} size={45} />
                    </div>
                    <div className={styles.cartSize}>{cartStore.getNumberTotalProduct()}</div>
                    <div
                        className={clsx(styles.cartMenuContainerArrow, {
                            [styles.cartMenuContainerArrow_initial]: !cartStore.isCartOpen,
                            [styles.cartMenuContainerArrow_animate]: cartStore.isCartOpen,
                        })}
                    />
                </div>
            </div>
        </div>
    );
});
