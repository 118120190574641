import React from "react";
import styles from "../_css/propertiesListLoader.module.css";
import { GridPropertyCard } from "properties/GridPropertyCard";

export function PropertiesListLoader() {
    return (
        <>
            {[...Array(6)].map((_, index) => (
                <GridPropertyCard key={index}>
                    <div className={styles.card}>
                        <div className={styles.img} />
                        <div className={styles.content}>
                            <h2 />
                            <p />
                        </div>
                    </div>
                </GridPropertyCard>
            ))}
        </>
    );
}
