import React from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { useTranslation } from "react-i18next";
import { PROPERTY_STATUS } from "properties/_models/PropertyMdl";
import { getSeasonOrFormatDate } from "_common/_utils/dateUtils";
import dayjs from "dayjs";
import clsx from "clsx";
import styles from "./_css/propertyDetails.module.css";

dayjs.locale("en");

export function PropertyDetails() {
    const { t } = useTranslation();
    const { property } = usePropertyStore();
    const isDisplayLevel = !!property?.nbOfLevels && property.nbOfLevels > 0;
    const isNbOfUnits = !!property?.nbOfUnits && property.nbOfUnits > 0;
    return (
        <div className={clsx("flex_column", styles.text)}>
            <div className={"gap_10"}>
                {isDisplayLevel && (
                    <div className={"mt_10"}>{t("propertyPage.nbOfLevels", { count: property.nbOfLevels })} </div>
                )}
                {!property.source && (
                    <div>
                        {t("propertyPage.status")}
                        {property.status == PROPERTY_STATUS.READY_TO_MOVE_IN ||
                        !property.deliveryDates ||
                        !property.deliveryDates[0]
                            ? t(`admin.property.status.${property.status}`)
                            : t(`admin.property.status.${property.status}`) +
                              " - " +
                              getSeasonOrFormatDate(dayjs(property.deliveryDates[0]), "dates.formats.fullMonthAndYear")}
                    </div>
                )}
                {isNbOfUnits && <div>{t("propertyPage.nbOfUnits", { count: property.nbOfUnits })}</div>}
            </div>
        </div>
    );
}
