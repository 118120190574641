import React, { PropsWithChildren, useRef, useState } from "react";
import styles from "./_css/propertyDescriptionItem.module.css";
import { Collapse } from "@material-ui/core";
import clsx from "clsx";
import { KeyboardArrowDown } from "@material-ui/icons";

type Props = PropsWithChildren<{
    title: string;
    heightOfCollapsable: number;
    titleTag?: "h2" | "h3" | "h4" | "h5" | "h6" | "p";
}>;

export function CollapseSection({ title, children, heightOfCollapsable, titleTag }: Props) {
    const [isCollapse, setIsCollapse] = useState(true);
    const ref = useRef<HTMLDivElement>(null);
    const TitleTag = titleTag ?? "div";
    return (
        <>
            <div
                className={"flex_row_center justifyContent_spaceBetween cursor_pointer"}
                onClick={() => setIsCollapse(!isCollapse)}
            >
                <TitleTag className={styles.titleDescription}>{title}</TitleTag>
                <div
                    className={clsx(styles.arrow, "flex_center_center", {
                        [styles.open]: !isCollapse,
                    })}
                >
                    <KeyboardArrowDown fontSize={"large"} />
                </div>
            </div>
            <Collapse in={!isCollapse} collapsedHeight={heightOfCollapsable}>
                <div ref={ref} className={"pv_10"}>
                    {children}
                </div>
            </Collapse>
        </>
    );
}
