import { action, observable } from "mobx";
import { appConfig } from "_configs/appConfig";
import { fetchUtils } from "shared/_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import jwt from "jsonwebtoken";
import { IUserMdl } from "users/_models/UserMdl";

type TDecodedToken = {
    exp?: number;
    iat?: number;
    data?: IUserMdl;
};

function getTokenExpirationDate(token: string) {
    const decoded = jwt.decode(token) as TDecodedToken;
    const decodedAlt = jwt.decode(token);
    if (decoded.exp === undefined) {
        return undefined;
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
}

function getToken(type?: string) {
    try {
        const token = localStorage.getItem(
            type === "trialToken" ? appConfig.token.trialToken : appConfig.token.storageKey,
        );
        if (!token) {
            return undefined;
        }
        const date = getTokenExpirationDate(token);
        if (date === undefined || date.valueOf() < new Date().valueOf()) {
            return undefined;
        }
        return token;
    } catch (e) {}
    return undefined;
}

class TokenStore {
    @observable token = getToken();
    @observable trialToken = getToken("trialToken");

    setToken = action((token: string | undefined) => {
        this.token = token;
        try {
            if (token) localStorage.setItem(appConfig.token.storageKey, token);
            else localStorage.removeItem(appConfig.token.storageKey);
        } catch (e) {
            //nothing to do
        }
    });

    constructor() {
        this.refreshToken();
    }

    async refreshToken() {
        if (this.token) {
            return fetchUtils.post<string>(sharedConfig.apiUrl + "/auth/token").then(
                ({ data }) => this.setToken(data),
                (error) => {
                    if (error.key && error.key.startsWith("errors.api.")) {
                        this.setToken(undefined);
                    }
                },
            );
        }
    }

    getTokenData(): IUserMdl | undefined {
        return this.token ? (jwt.decode(this.token) as TDecodedToken)?.data : undefined;
    }
}

export const tokenStore = new TokenStore();
