import _ from "lodash";

export function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export function s6() {
    return Math.floor((1 + Math.random()) * 0x1000000)
        .toString(16)
        .substring(1);
}

export function guid() {
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}

export function iterateObject(obj: any, callback: (value: any, path: string[]) => void, path: string[] = []) {
    callback(obj, path);
    if (_.isObject(obj) || _.isArray(obj)) {
        for (const key in obj) {
            iterateObject((obj as any)[key], callback, [...path, key]);
        }
    }
}

export function isPromise<T, U>(value?: T | Promise<U>): value is Promise<U> {
    return typeof (value as Promise<U> | undefined)?.then === "function";
}

export function shuffleArray<T>(array: T[], cb?: (array: T[]) => T[]): T[] {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    if (cb) {
        return cb(array);
    }
    return array;
}
