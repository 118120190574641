import React, { PropsWithChildren, useRef, useState } from "react";
import { Collapse } from "@material-ui/core";
import clsx from "clsx";
import { KeyboardArrowDown } from "@material-ui/icons";
import styles from "./_css/collapsible.module.css";

type Props = PropsWithChildren<{
    moreText?: string;
    heightOfCollapsable: number;
}>;

export function Collapsible({ moreText, children, heightOfCollapsable }: Props) {
    const [isCollapse, setIsCollapse] = useState(true);
    const ref = useRef<HTMLDivElement>(null);

    return (
        <>
            <Collapse in={!isCollapse} collapsedHeight={heightOfCollapsable} className={styles.collapse}>
                <div ref={ref} className={"pv_10"}>
                    {children}
                </div>
            </Collapse>
            <div
                className={clsx(styles.trigger, "flex_row_center justifyContent_center cursor_pointer mt_50")}
                onClick={() => setIsCollapse(!isCollapse)}
            >
                <div className={styles.titleDescription}>{moreText}</div>
                <div
                    className={clsx(styles.arrow, "flex_center_center", {
                        [styles.open]: !isCollapse,
                    })}
                >
                    <KeyboardArrowDown />
                </div>
            </div>
        </>
    );
}
