import React from "react";
import styles from "./_css/textareaFormProject.module.css";

type Props = {
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
};

export function TextareaFormProject(props: Props) {
    return (
        <textarea
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            className={styles.textarea}
            placeholder={props.placeholder}
            cols={8}
            rows={6}
        ></textarea>
    );
}
