import React from "react";
import { Container } from "@material-ui/core";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { ContactFormComponent } from "components/misc/contactUs/ContactFormComponent";

export default function ContactPage() {
    const { t } = useTranslation();
    return (
        <div>
            <Container>
                <Breadcrumb currentItem={{ itemLabelKey: "breadcrumb.contact" }} />
                <div className={clsx("mv_60")}>
                    <h1 className="mb_25">{t("contactPage.contactForm")}</h1>
                    <ContactFormComponent />
                </div>
            </Container>
        </div>
    );
}
