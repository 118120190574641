import Stripe from "stripe";
import { PREMIUM, TPremium } from "properties/_models/PropertyMdl";

export enum PRICING_ZONE {
    CA = "ca",
    QC = "qc",
}

export enum PAYMENT_TYPE {
    RECURRING = "recurring",
    ONE_TIME = "one_time",
}

export enum PRODUCT_NAME {
    BASE = "base",
    PREMIUM = "premium",
    ESSENTIEL = "essentiel",
    ELITE = "elite",
}

export type TStripeProductMdl = {
    id: string;
    active: boolean;
    attributes: [];
    description: string;
    images: string[];
    livemode: boolean;
    metadata: {
        descriptionKey?: string;
    };
    name: string;
    object: string;
    package_dimensions: string;
    shippable: boolean;
    statement_descriptor: string;
    tax_code: string;
    type: string;
    unit_label: string;
    url: string;

    created: number;
    updated: number;
};

export type TStripeProductListingMdl = Stripe.Product & {
    prices: Stripe.Price[];
    optionKeys: string[];
    name: PRODUCT_NAME | string;
    productLabel: string;
};

export type TRecurring = {
    aggregate_usage: null;
    interval: string;
    interval_count: number;
    trial_period_days: null;
    usage_type: string;
};

export const PREMIUM_PLAN_BY_PRODUCT_NAME: { [name: string]: TPremium } = {
    [PRODUCT_NAME.BASE]: {
        premiumPlan: PREMIUM.NONE,
        premiumVedette: false,
        showcase: false,
    },
    [PRODUCT_NAME.ESSENTIEL]: {
        premiumPlan: PREMIUM.SILVER,
        premiumVedette: false,
        showcase: false,
    },
    [PRODUCT_NAME.PREMIUM]: {
        premiumPlan: PREMIUM.GOLD,
        premiumVedette: true,
        showcase: false,
    },
    [PRODUCT_NAME.ELITE]: {
        premiumPlan: PREMIUM.SILVER,
        premiumVedette: true,
        showcase: true,
    },
};
