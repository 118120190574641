import React from "react";
import { Radio } from "@material-ui/core";
import { PROPERTY_STATUS, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { ListStore } from "_common/list/ListStore";
import clsx from "clsx";
import { observer } from "mobx-react";
import styles from "./_css/statusFilter.module.css";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
    onClose?: () => void;
    onChange: (surface: PROPERTY_STATUS | "NONE") => void;
    value: PROPERTY_STATUS | "NONE";
};

const DATAS: PROPERTY_STATUS[] = [
    PROPERTY_STATUS.READY_TO_MOVE_IN,
    PROPERTY_STATUS.UNDER_CONSTRUCTION,
    PROPERTY_STATUS.PROJECT,
];

export const StatusFilter = observer((props: Props) => {
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.container, "p_15")}>
            <div className={"mb_10 text_small"}>{t("searchBar.filter.deliveryType")}</div>
            {DATAS.map((deliveryType) => {
                const isFiltered = props.value.includes(deliveryType);
                return (
                    <div
                        key={deliveryType}
                        className={clsx(styles.checkbox, "flex_row_center cursor_pointer")}
                        onClick={() => props.onChange(deliveryType === props.value ? "NONE" : deliveryType)}
                    >
                        <Radio className={styles.check} checked={isFiltered} />
                        <div>{t(`propertyPage.${deliveryType}`)}</div>
                    </div>
                );
            })}
        </div>
    );
});
