import React from "react";
import { useTranslation } from "react-i18next";
import { DemandSuccessSubscribe } from "properties/propertyPage/demand/DemandSuccessSubscribe";
import styles from "./_css/demandSuccessContact.module.css";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import clsx from "clsx";

type Props = {
    property: TPropertyListingMdl;
};

export function DemandSuccessContact({ property }: Props) {
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.container, "flex_row_center gap_40")}>
            <div className={"flex-1"}>
                <h2 className={styles.title}>{t("demandSuccess.contactTitle")}</h2>
                <div className={styles.message}>{t("demandSuccess.contactMessage")}</div>
            </div>
            <div className={"flex-1 w-full"}>
                <DemandSuccessSubscribe property={property} />
            </div>
        </div>
    );
}
