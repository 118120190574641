import { TFilter } from "admin/_common/filters/TFilter";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";

export function featuresToFilters(features: string[], cb?: () => void): TFilter[] {
    const filters: TFilter[] = [];
    for (const feature of features) {
        filters.push({
            id: feature,
            value: true,
            type: TFilterType.BOOLEAN,
        });
    }
    cb?.();

    return filters;
}
