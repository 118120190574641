import React from "react";
import styles from "./_css/gallery.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { UnitStore } from "units/_stores/UnitStore";
import { PropertyStore } from "properties/_stores/PropertyStore";

type Props = {
    item: TUnitListingMdl | TPropertyListingMdl;
    store: UnitStore | PropertyStore;
};
export function Gallery({ store, item }: Props) {
    if (!item.photos) return null;
    return (
        <div className={styles.container}>
            <div className={"flex_row flexWrap_wrap"}>
                {item.photos.map((photo, index) => {
                    let imageWidth = 100;
                    switch (index % 9) {
                        case 2:
                            imageWidth = 100;
                            break;
                        case 1:
                        case 0:
                        case 6:
                        case 7:
                            imageWidth = 50;
                            break;
                        case 3:
                        case 4:
                        case 5:
                            imageWidth = 33.33;
                            break;
                    }
                    return (
                        <div key={index} className={styles.galleryImage} style={{ width: imageWidth + "%" }}>
                            <LazyLoadImage
                                onClick={() => store.setOpenedImageModal("photos", index)}
                                className={styles.image}
                                src={photo.url}
                                alt={`${item.localized.title}-${index}`}
                                title={`${item.localized.title} photo ${index}`}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
