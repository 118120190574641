import React from "react";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import ShowcasePropertiesFallback from "home/sections/ShowcasePropertiesFallback";
import Suspenser from "_common/loaders/Suspenser";

const LazyShowcaseProperties = React.lazy(() => import("./ShowcaseProperties"));
export function ShowcasePropertiesSection() {
    propertiesStore.fetchShowcaseProperties();
    return (
        <>
            <LoadableFromLoading
                loading={propertiesStore.showcasePropertiesState}
                noSpinner
                noText
                renderer={(_status, _error, properties) => {
                    if (!properties) return null;
                    // return <ShowcaseProperties />;
                    return (
                        <Suspenser fallback={<ShowcasePropertiesFallback />}>
                            <LazyShowcaseProperties />
                        </Suspenser>
                    );
                }}
            />
        </>
    );
}
