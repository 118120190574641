import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./_css/withVistoo.module.css";
import { Container } from "@material-ui/core";
import clsx from "clsx";

export function PromoteWithVistoo() {
    const { t } = useTranslation();
    return (
        <div className={"mt_80"}>
            <Container>
                <div className={styles.content}>
                    <div className={styles.title}>{t("promotePage.withVistoo")}</div>
                    <div className={clsx(styles.text, styles.firstText)}>{t("promotePage.textWithVistoo1")}</div>
                    <div className={styles.text}>{t("promotePage.textWithVistoo2")}</div>
                    <div className={clsx(styles.text, styles.lastText)}>{t("promotePage.textWithVistoo3")}</div>
                </div>
            </Container>
        </div>
    );
}
