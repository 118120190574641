import React from "react";
import { AdvancedResearches } from "advancedResearches/AdvancedResearches";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { getSearchResultPageUrlFromGeoZonePurposeAndType } from "_common/_utils/geoZoneUtils";
import { isBuyingPage } from "_common/_utils/pageUtils";
import { useLocation } from "react-router-dom";
import { useSearchPageParams } from "_common/_utils/hookUtils";

export const AdvancedResearchesSRPsGeozonesPropertyTypes = observer(() => {
    const { t } = useTranslation();
    const location = useLocation();
    const { propertyTypeKey } = useSearchPageParams();

    if (!geoZonesStore.geoZone) return null;

    void geoZonesStore.fetchChildrenGeozone(geoZonesStore.geoZone._id);
    const childrenGeoZone = toJS(geoZonesStore.children);
    const purpose = isBuyingPage(location.pathname) ? PROPERTY_PURPOSE.BUY : PROPERTY_PURPOSE.RENT;

    const links = [];

    if (childrenGeoZone?.[geoZonesStore.geoZone._id]) {
        const translatedType = t(`property.typesUrl.${propertyTypeKey}`);

        links.push(
            ...childrenGeoZone[geoZonesStore.geoZone._id].map((geoZone) => ({
                label: `${t("advancedResearches." + propertyTypeKey)} ${t(
                    "advancedResearches." + purpose.toLowerCase(),
                ).toLowerCase()} ${geoZone.name.split(",")[0]}`,
                to: getSearchResultPageUrlFromGeoZonePurposeAndType(geoZone, purpose, translatedType),
            })),
        );
    }

    return <AdvancedResearches links={links} />;
});
