import { TLang } from "_configs/sharedConfig";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";

export enum USER_EVENT {
    ASSISTANT_VIRTUAL = "users/assistantVirtual",
}

export enum USER_SAVED_SEARCH_FREQUENCY {
    published = "published",
    weekly = "weekly",
    biWeekly = "biWeekly",
    biMonthly = "biMonthly",
}

export enum USER_ROLES {
    ADMIN = "ADMIN",
    DEVELOPER = "DEVELOPER",
}

export enum SIGN_UP_TYPE {
    DEFAULT = "DEFAULT",
    FACEBOOK = "FACEBOOK",
    GOOGLE = "GOOGLE",
    BACK_OFFICE = "BACK_OFFICE",
    SCRIPT = "SCRIPT",
    FORM_CTA = "FORM_CTA",
    FORM_CTA_SUCCESS = "FORM_CTA_SUCCESS",
    ASSISTANT_VIRTUAL = "ASSISTANT_VIRTUAL",
}
export type SIGN_UP_FROM = PROPERTY_PURPOSE | "NONE";

export enum FREE_TRIAL {
    NONE = 0,
    THIRTY = 30,
    SIXTY = 60,
    NINETY = 90,
}

export type IHeardAboutUs = { key: string; other?: string };

export type IAddressMdl = {
    street: string;
    extra: string;
    city: string;
    postalCode: string;
    country: string;
    province: string;
};

export interface IFavoritesSearchMdl {
    filters: string;
    search: string;
    region?: string;
    city?: string;
    neighborhood?: string;
    lastSeenDate: Date;
}

export type IUserMdl = {
    _id: string;
    email: string;
    phone?: string;
    firstName: string;
    lastName: string;
    companyName: string;
    website: string;
    notification: boolean;
    notifications: {
        newsletter: boolean;
        stats: boolean;
    };
    address?: IAddressMdl;
    lang: TLang;
    favorites: string[];
    favoritesSearches: IFavoritesSearchMdl[];
    token: string;
    dashboardToken: string;
    freeTrial: FREE_TRIAL;
    webhook: string;
    webhookOnly: boolean;
    dashboardLinkedUserId: string;
    isAppointingFormDisabled: boolean;
    signUpType: SIGN_UP_TYPE;
    signUpFrom: SIGN_UP_FROM;

    roles: string[];
};

export type TUserSummaryMdl = {
    _id: string;
    phone?: string;
    photo?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    email?: string;
    lang: TLang;
    website?: string;
    isAppointingFormDisabled: boolean;
};
