import React, { PropsWithChildren } from "react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";

type Props = {
    textClassname?: string;
};

const PrimaryButton = (props: PropsWithChildren<Props>) => {
    return (
        <UiButton variant="contained" color={"primary"} size={"medium"}>
            <p className={props.textClassname}>{props.children}</p>
        </UiButton>
    );
};

export default PrimaryButton;
