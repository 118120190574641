import React from "react";
import { placementsStore } from "placements/_store/placementsStore";
import styles from "./_css/placementBanner.module.css";
import { observer } from "mobx-react";
import SwiperCore, { Autoplay } from "swiper";
import clsx from "clsx";
import { shuffleArray } from "_common/_utils/coreUtils";
import { PlacementBannerContent } from "placements/PlacementBannerContent";

SwiperCore.use([Autoplay]);

export const PlacementBanner = observer(() => {
    const placements = placementsStore.placementsBannerProperties;
    const shuffledPlacements = shuffleArray(placements);
    return (
        <div className={clsx(styles.container)}>
            <PlacementBannerContent placements={shuffledPlacements} />
        </div>
    );
});
