import React from "react";
import { ListStore } from "_common/list/ListStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import styles from "./_css/surfaceFilter.module.css";
import { InputFiltersAutocomplete } from "properties/searchBar/filters/InputFiltersAutocomplete";
import { SQUARE_RANGE } from "properties/searchBar/filters/_utils/surfaceUtils";
import { getIsCommercialFilter } from "properties/searchBar/filters/_utils/filtersUtils";

type Props = {
    onClose?: () => void;
    listStore: ListStore<TPropertyListingMdl>;
    onChange: (surface: { min: string; max: string }) => void;
    value: { min: string; max: string };
};

export const SurfaceFilter = observer((props: Props) => {
    const { t } = useTranslation();
    const isCommercial = getIsCommercialFilter(props.listStore.filters);

    return (
        <div className={clsx(styles.container, "position_relative ph_15 pb_0")}>
            <div className={"pb_10 text_small"}>{t("searchBar.filter.surface")}</div>
            <div className={"flex_row_center gap_10"}>
                <InputFiltersAutocomplete
                    value={props.value.min}
                    options={SQUARE_RANGE[isCommercial ? "commercial" : "default"].min.filter(
                        (data) => !data.value || !props.value.max || parseInt(data.value) <= parseInt(props.value.max),
                    )}
                    placeholder={"No min"}
                    onChange={(value) => {
                        props.onChange({ ...props.value, min: value });
                    }}
                />
                <div>-</div>
                <InputFiltersAutocomplete
                    value={props.value.max}
                    options={SQUARE_RANGE[isCommercial ? "commercial" : "default"].max.filter(
                        (data) => !data.value || !props.value.min || parseInt(data.value) >= parseInt(props.value.min),
                    )}
                    placeholder={"No max"}
                    onChange={(value) => {
                        props.onChange({ ...props.value, max: value });
                    }}
                />
            </div>
        </div>
    );
});
