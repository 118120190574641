import React, { useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment-timezone";
import dayjs, { Dayjs } from "dayjs";
import "moment/locale/fr";
import i18next from "i18next";
import styles from "./_css/inputDateFrontProjectForm.module.css";
import ArrowLeft from "projects/component/ArrowDownProjectPage.svg";
import clsx from "clsx";

moment.tz("America/Montreal");

type Props = {
    value?: Dayjs | string;
    onChange?: (newValue: string) => void;
    label: string;
};

export const InputDateFrontProjectForm = React.forwardRef((props: Props, ref) => {
    const [open, setOpen] = useState(false);
    return (
        <div ref={ref} className={styles.input} onClick={() => setOpen(!open)}>
            <div className={clsx("flex_column", styles.content)}>
                <div className={styles.label}>{props.label.toUpperCase()}</div>
                <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    locale={i18next.language}
                    onChange={(newDate) =>
                        props.onChange ? props.onChange(moment(newDate.valueOf()).toDate().toString()) : undefined
                    }
                    initialValue={
                        typeof props.value === "string" ? dayjs(props.value ?? "").toDate() : props.value?.toDate()
                    }
                    open={open}
                    className={styles.datetime}
                    // initialViewMode={"months"}
                />
            </div>
            <div className={styles.arrowContent}>
                <img src={ArrowLeft} className={styles.arrow} />
            </div>
        </div>
    );
});
