import React from "react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { ComparePricesArray } from "payments/_components/ComparePricesArray";
import clsx from "clsx";
import styles from "payments/_css/productSection.module.css";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "_common/_utils/hookUtils";

type Props = {
    open: boolean;
    onClose: () => void;
};
export function ComparePricesModal(props: Props) {
    const isMobile = useWindowSize().width < 950;

    const { t } = useTranslation();
    return (
        <Dialog
            onClose={() => props.onClose()}
            open={props.open}
            closeCross
            closeSize={isMobile ? "small" : "large"}
            maxWidth={isMobile ? "xl" : "lg"}
            fullWidth
            isTransparentBg
            fullScreen={isMobile}
        >
            <div className={clsx("m_20", styles.containerArrayIsMobile)}>
                <ComparePricesArray />
                <div className={clsx(styles.disclaimer, "mt_20")}>{t("paymentPage.disclaimer")}</div>
            </div>
        </Dialog>
    );
}
