import { action, autorun, computed, observable } from "mobx";
import { PAYMENT_TYPE, PRODUCT_NAME } from "payments/_models/paymentStripeMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { userStore } from "users/_stores/userStore";
import _ from "lodash";

export type TLine = {
    productId: string;
    priceId: string;
    type: PAYMENT_TYPE;
    price: number;
    quantity: number;
    name: PRODUCT_NAME;
};

class CartStore {
    @observable cart: TLine[] = [];
    @observable subscriptions: PRODUCT_NAME[] = [];
    @observable isCartOpen = false;

    constructor() {
        autorun(() => {
            if (userStore.isLogged) {
                this.fetchCart().then(({ data }) => {
                    if (data.cart) this.cart = JSON.parse(data.cart);
                    if (!this.cart.length || !data.cart) {
                        this.getLocalCart();
                        if (this.cart.length) this.save();
                    }
                });
            }
        });
        if (__BROWSER__) {
            if (!this.cart.length) {
                const localCart = localStorage.getItem("cart");
                if (localCart) this.getLocalCart();
            }
        }
    }

    @action setIsCartOpenDebounce = _.debounce((value?: boolean) => {
        this.isCartOpen = value ?? false;
    }, 300);

    @action setIsCartOpen = (value: boolean) => {
        this.isCartOpen = value;
    };

    @computed get total() {
        let total = 0;
        this.cart.map((line) => {
            if (line.productId != sharedConfig.stripe.products.INTEGRATION_CRM) {
                total = total + line.price * line.quantity;
            }
        });

        return total;
    }

    @action addCart(line: TLine) {
        const index = this.cart.findIndex((_line) => _line.productId === line.productId && _line.price === line.price);
        if (index >= 0) {
            this.cart[index].quantity = this.cart[index].quantity + line.quantity;
        } else {
            this.cart.push(line);
        }
        this.save();
    }

    @action deleteItemInCart(line: TLine) {
        if (this.cart.find((_line) => _line.productId === line.productId && _line.price === line.price)) {
            const index = this.cart.findIndex(
                (_line) => _line.productId === line.productId && _line.price === line.price,
            );
            if (index >= 0) {
                this.cart[index].quantity = this.cart[index].quantity - 1;
                if (this.cart[index].quantity === 0) this.cart.splice(index, 1);
            }
        }
        this.save();
    }

    @action deleteAllUnitForOneItemInCart(line: TLine) {
        if (this.cart.find((_line) => _line.productId === line.productId && _line.price === line.price)) {
            const index = this.cart.findIndex(
                (_line) => _line.productId === line.productId && _line.price === line.price,
            );
            if (index >= 0) {
                this.cart.splice(index, 1);
            }
        }
        this.save();
    }

    @action deleteCart() {
        this.cart = [];
        this.save();
    }

    getNumberTotalProduct() {
        let total = 0;
        if (this.cart.length > 0) {
            this.cart.map((line) => {
                total = total + line.quantity;
            });
        }
        return total;
    }

    private save() {
        localStorage.setItem("cart", JSON.stringify(this.cart));
        return fetchUtils.post<{ cart: string }>(sharedConfig.apiUrl + "/users/cart", {
            cart: JSON.stringify(this.cart),
        });
    }

    private fetchCart() {
        return fetchUtils.get<{ cart: string }>(sharedConfig.apiUrl + "/users/cart");
    }

    private getLocalCart() {
        if (__BROWSER__) {
            const localCart = localStorage.getItem("cart");
            if (localCart) this.cart = JSON.parse(localCart);
        }
    }
}

export const cartStore = new CartStore();
