import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ProductCard } from "payments/ProductCard";
import clsx from "clsx";
import styles from "./_css/productSection.module.css";
import { cartStore } from "Cart/_stores/cartStore";
import paymentStore from "payments/_stores/paymentStore";

export function ProductsSection() {
    const { t } = useTranslation();
    return (
        <Container style={{ padding: 0, minHeight: 774 }}>
            <h2 className={styles.title}>{t("paymentPage.selectForfeit")}</h2>
            <LoadableFromLoading
                loading={paymentStore.productState}
                renderer={(_status, error) => {
                    if (error) return null;
                    return (
                        <>
                            <div className={clsx(styles.products, "justifyContent_spaceBetween")}>
                                {paymentStore.mainProducts.reverse().map((product) => {
                                    return (
                                        <ProductCard
                                            key={product.id}
                                            product={product}
                                            addCart={(line) => cartStore.addCart(line)}
                                        />
                                    );
                                })}
                            </div>
                            <div className={clsx(styles.disclaimer, "mt_20")}>{t("paymentPage.disclaimer")}</div>
                        </>
                    );
                }}
            />
        </Container>
    );
}
