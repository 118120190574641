import React, { RefObject } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment-timezone";
import styles from "./_css/inputDate.module.css";
import dayjs, { Dayjs } from "dayjs";
import "moment/locale/fr";
import i18next from "i18next";

type Props = {
    value?: Dayjs | string;
    onChange?: (newValue: string) => void;
    disabled?: boolean;
};

export const InputDate = React.forwardRef((props: Props, ref) => {
    return (
        <div ref={ref as RefObject<HTMLDivElement>} className={styles.input}>
            <Datetime
                locale={i18next.language}
                onChange={(newDate) =>
                    props.onChange ? props.onChange(moment(newDate.valueOf())?.toDate()?.toString()) : undefined
                }
                initialValue={dayjs(props.value ?? "")?.toDate()}
                value={dayjs(props.value ?? "")?.toDate()}
                inputProps={{ disabled: props.disabled }}
            />
        </div>
    );
});
