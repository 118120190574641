export enum TFilterType {
    STRING = "string",
    DATE = "date",
    BOOLEAN = "boolean",
    NUMBER = "number",
    COORDINATES = "coordinates",
    ZONE = "intoZone",
    ZONES = "intoZones",
    NEAR_GEOZONE = "nearGeoZone",
    NUMBER_BETWEEN = "numberBetween",
    ENUM = "enum",
    ID = "_id",
    IN = "in",
    NE = "ne",
    EXISTS = "exists",
    NININ = "ninin",
    NOT_IN = "not_in",
    DEVELOPER = "developer",
    PROPERTY_FROM_REGION = "property",
    GROUP_FEATURE = "group_feature",
    INPUT_DATE_NOTIFICATION = "inputDateNotification",
}

export type TResourceFilterConfig = {
    path: string;
    label: string;
    type: TFilterType;
    enum?: { label?: string; value: string }[];
};
