import React, { useState } from "react";
import { TOrderListingMdl } from "admin/orders/_models/OrderMdl";
import { OrderProperties } from "users/orders/OrderProperties";
import { TableCell, TableRow } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { formatPrice } from "_common/_utils/currencyUtils";
import { ordersStore } from "payments/_stores/ordersStore";
import clsx from "clsx";
import { ConfirmationDialog } from "_common/ui/dialogs/ConfirmationDialog";
import Cross from "projects/component/crossFormProjectPage.svg";

type Props = {
    order: TOrderListingMdl;
};

export function OrderLine(props: Props) {
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <>
            <TableRow>
                <TableCell className={"text_bold"}>{props.order._id}</TableCell>
                <TableCell
                    className={clsx("text_bold", { ["cursor_pointer"]: props.order.paymentStatus === "unpaid" })}
                    onClick={() => {
                        if (props.order.paymentStatus === "unpaid") {
                            ordersStore.createCheckoutSession(props.order).then(({ data }) => {
                                window.location.href = data.url;
                            });
                        }
                    }}
                >
                    {t("order.paymentStatus." + props.order.paymentStatus)}
                </TableCell>
                <TableCell className={"text_bold"}>{formatPrice(props.order.totalPrice ?? 0)} + tx</TableCell>
                <TableCell className={"text_bold"}>
                    {dayjs(props.order.createdAt).format(t("dates.formats.dayAndHour"))}
                </TableCell>
                <TableCell align={"right"}>
                    {props.order.paymentStatus === "unpaid" && (
                        <img
                            src={Cross}
                            height={12}
                            width={12}
                            alt={"cross"}
                            className={"cursor_pointer"}
                            onClick={() => setOpenDialog(true)}
                        />
                    )}
                </TableCell>
            </TableRow>
            <OrderProperties orderId={props.order._id} properties={props.order.properties} />
            <ConfirmationDialog
                title={t("order.confirmTitle")}
                open={openDialog}
                message={t("order.confirmMessage")}
                agreeLabel={t("words.confirm")}
                onAgree={() => ordersStore.removeOrder(props.order._id)}
                disagreeLabel={t("words.cancel")}
                onDisagree={() => setOpenDialog(false)}
            />
        </>
    );
}
