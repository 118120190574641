import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import { CollapseSection } from "_common/ui/components/CollapseSection";
import { SeparatorComponent } from "components/layout/separator/SeparatorComponent";
import styles from "./_css/propertiesFaq.module.css";
import { GEO_ZONE_TYPE, TFaq, TGeoZoneMdl } from "geoZones/_models/GeoZoneMdl";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { getFaqJsonLd } from "_common/_utils/pageUtils";
import { Helmet } from "react-helmet-async";
import _ from "lodash";
import { TLang } from "_configs/sharedConfig";
import { zoneName } from "_common/_utils/geoZoneUtils";

type Props = {
    geoZone?: TGeoZoneMdl;
    purpose?: PROPERTY_PURPOSE;
};

export function PropertiesFaq({ geoZone, purpose }: Props) {
    const { t, i18n } = useTranslation();

    const { propertyTypeKey } = useSearchPageParams();
    if (!geoZone) return null;

    const getFaq = (geozoneWithFaq: TGeoZoneMdl): TFaq[] => {
        if (propertyTypeKey) {
            return _.get(geozoneWithFaq, `localized.${i18n.language}.${purpose}.${propertyTypeKey}.faq`);
        }
        return _.get(geozoneWithFaq, `localized.${i18n.language}.${purpose}.faq`);
    };

    const faq = getFaq(geoZone);
    if (!faq?.length) return null;

    const geoZoneName = geoZone.type === GEO_ZONE_TYPE.NEIGHBORHOOD ? geoZone.address.neighbourhood : zoneName(geoZone);
    const propertyPurpose = t("propertiesPage.faq." + purpose);
    const faqTitle = geoZone.localized?.[i18n.language as TLang]?.[purpose as PROPERTY_PURPOSE]?.srps?.faq;

    let propertyType = undefined;
    if (propertyTypeKey) {
        propertyType = t("property.types." + propertyTypeKey);
    }

    return (
        <>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(getFaqJsonLd(faq))}</script>
            </Helmet>
            <Container className={styles.container}>
                <h2 className={styles.title}>
                    {faqTitle || propertyType
                        ? t("propertiesPage.faq.titleWithType", { geoZoneName, propertyType, purpose: propertyPurpose })
                        : t("propertiesPage.faq.title", { geoZoneName, purpose: propertyPurpose })}
                </h2>
                {faq.map((faqItem, index) => (
                    <div key={index}>
                        <CollapseSection title={faqItem.question} titleTag={"h3"} heightOfCollapsable={0}>
                            <div className={styles.answer} dangerouslySetInnerHTML={{ __html: faqItem.answer }} />
                        </CollapseSection>
                        <SeparatorComponent color={"var(--color-primary)"} />
                    </div>
                ))}
            </Container>
        </>
    );
}
