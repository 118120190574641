import React from "react";
import clsx from "clsx";
import styles from "./_css/buttonNavigation.module.css";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ArrowLeft from "./ArrowLeftPricingPage.svg";
import ArrowRight from "./ArrowRightPricingPage.svg";

type Props = {
    type: "button" | "submit";
    variant: "outlined" | "contained";
    text?: string;
    link?: string;
    arrowDirection: "left" | "right";
    onClick?: () => void;
};

export function ButtonNavigation(props: Props) {
    const { t } = useTranslation();
    return (
        <Link to={props.link ?? "#"}>
            <UiButton
                type={props.type}
                variant={props.variant}
                className={clsx(styles.button)}
                onClick={() => {
                    if (props.onClick) {
                        props.onClick();
                    }
                }}
            >
                {props.arrowDirection === "left" && (
                    <div className={styles.arrow}>
                        <img src={ArrowLeft} alt={"Arrow left"} />
                    </div>
                )}
                {props.text && <>{t(`${props.text}`)}</>}
                {props.arrowDirection === "right" && (
                    <div className={styles.arrow}>
                        <img src={ArrowRight} alt={"Arrow right"} />
                    </div>
                )}
            </UiButton>
        </Link>
    );
}
