import React from "react";
import clsx from "clsx";
import { NoSsr } from "@material-ui/core";
import { formatPrice } from "_common/_utils/currencyUtils";
import { PROPERTY_PURPOSE, TPropertyCardMdl } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { getIsLandOrCommercial } from "_common/_utils/propertyUtils";
import styles from "./_css/propertyCardContent.module.css";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";

type Props = {
    property: TPropertyCardMdl;
    isMapCard?: boolean;
    isFeaturedCard?: boolean;
    isPrimaryCard?: boolean;
    isSimilarCard?: boolean;
    isSummaryCard?: boolean;
    isEditable?: boolean;
    onEdit?: () => void;
    onMouseOver?: () => void;
    onMouseLeave?: () => void;
    onClick?: (e: React.MouseEvent) => void;
};

export function PropertyCardContent(props: Props) {
    const { t } = useTranslation();
    const sortAddress = [
        props.property.address.street,
        props.property.address.extra,
        props.property.address.neighbourhood,
        props.property.address.city,
        props.property.address.province,
    ];
    const completeAddress = sortAddress
        .filter((addressPart) => addressPart !== undefined && addressPart !== "")
        .join(", ");
    const isLandOrCommercial = getIsLandOrCommercial(props.property);
    const isCompanyName =
        (props.property.developer && props.property.developer.companyName) || props.property.propertyBuilderName;
    return (
        <Link
            className={clsx("flex_column position_relative", styles.container, {
                [styles.containerMap]: props.isMapCard,
            })}
            to={URLS.property(props.property.localized.urlAlias)}
            onClick={props.onClick}
            aria-label={`Property link for "${props.property.localized.title}"`}
            aria-labelledby={`Property link for "${props.property.localized.title}"`}
        >
            {props.property.priceIsNotDisplayed ? (
                <div className={styles.price}>{t("propertiesPage.card.priceOnRequest")}</div>
            ) : (
                <div className={styles.price}>
                    <NoSsr>
                        {t("propertiesPage.card.from")} {formatPrice(props.property.price.min, false, false)}
                    </NoSsr>
                    {props.property.purpose === PROPERTY_PURPOSE.RENT ? (
                        t("propertiesPage.card.$")
                    ) : (
                        <span className={"ml_5"}>{!props.property.taxIsIncluded ? t("property.taxes") : ""}</span>
                    )}
                </div>
            )}
            <div className={clsx(styles.title, "truncate")}>{props.property.localized.title.toUpperCase()}</div>
            <div
                className={clsx(styles.rooms, {
                    [styles.textCardMap]: props.isMapCard,
                })}
            >
                {!isLandOrCommercial && (
                    <div className={"flex_row_center truncate"}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    props.property.bedrooms.max &&
                                    props.property.bedrooms.max != props.property.bedrooms?.min
                                        ? props.property.bedrooms?.min === 0
                                            ? t("propertiesPage.card.rangeOfRoomsWithStudio", {
                                                  count: props.property.bedrooms.max,
                                              })
                                            : t("propertiesPage.card.rangeOfRooms", {
                                                  nbOfRoomsMin: props.property.bedrooms?.min,
                                                  count: props.property.bedrooms.max,
                                              })
                                        : props.property.bedrooms?.min === 0
                                        ? t("propertiesPage.card.studio")
                                        : t("propertiesPage.card.nbOfRooms", {
                                              count: props.property.bedrooms?.min ?? 0,
                                          }),
                            }}
                        />
                        {!!props.property?.squareSurface?.min && props.property.squareSurface?.min !== 0 && (
                            <>
                                <div className={"mh_5"}>|</div>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            props.property.squareSurface?.max
                                                ? "propertiesPage.card.surface"
                                                : "propertiesPage.card.surfaceMinOnly",
                                            {
                                                min: props.property.squareSurface?.min,
                                                max: props.property.squareSurface?.max,
                                            },
                                        ),
                                    }}
                                ></span>
                            </>
                        )}
                    </div>
                )}
            </div>
            {!props.isMapCard && (
                <>
                    <div
                        className={clsx("truncate", styles.address)}
                        dangerouslySetInnerHTML={{ __html: completeAddress }}
                    />
                    <div
                        className={clsx(styles.user, "truncate flex_row_center", {
                            [styles.textCardMap]: props.isMapCard,
                        })}
                    >
                        {isCompanyName ? (
                            <>
                                {t("words.by")}{" "}
                                {props.property?.propertyBuilderName
                                    ? props.property?.propertyBuilderName
                                    : props.property.developer?.companyName}
                            </>
                        ) : (
                            <div>&nbsp;</div>
                        )}
                    </div>
                </>
            )}
        </Link>
    );
}
