import React, { useState } from "react";
import styles from "./_css/inputNumberFront.module.css";
import clsx from "clsx";
import arrowUp from "../../assets/images/arrowUp.svg";
import arrowDown from "../../assets/images/arrowDown.svg";

type Props = {
    onValueChange?: (value: number) => void;
    onChange?: (value: number) => void;
    defaultValue?: number;
    value?: number;
    max?: number;
};

export function InputNumberFront(props: Props) {
    const [value, setValue] = useState(props.defaultValue ?? 0);
    return (
        <div className={styles.content}>
            <input
                type={"number"}
                className={styles.input}
                value={value}
                max={props.max ?? undefined}
                onChange={(e) => {
                    props.onValueChange ? props.onValueChange(e.target.value) : "";
                    props.onChange ? props.onChange(e.target.value) : "";
                    setValue(e.target.value);
                }}
            />
            <div className={clsx("flex_column", styles.arrows)}>
                <div
                    className={clsx(styles.arrowBlock, "flex_center_center")}
                    onClick={() => {
                        if ((props.max && props.max > value) || !props.max) {
                            props.onValueChange ? props.onValueChange(value + 1) : "";
                            props.onChange ? props.onChange(value + 1) : "";
                            setValue(value + 1);
                        }
                    }}
                >
                    <img src={arrowUp} alt={"arrow up"} />
                </div>
                <div
                    className={clsx(styles.arrowBlock, "flex_center_center")}
                    onClick={() => {
                        if (value > 1) {
                            props.onValueChange ? props.onValueChange(value - 1) : "";
                            props.onChange ? props.onChange(value - 1) : "";
                            setValue(value - 1);
                        }
                    }}
                >
                    <img src={arrowDown} alt={"arrow down"} />
                </div>
            </div>
        </div>
    );
}
