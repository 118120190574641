import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import { ChildrenComponentRenderer } from "components/ChildrenComponentRenderer";
import { Container } from "@material-ui/core";
import clsx from "clsx";
import styles from "./_css/containerComponent.module.css";

type Props = {
    style: CSSProperties;
    children: TComponent[];
    noHorizontalPadding?: boolean;
    $component: TComponent;
};

export function ContainerComponent({ children, noHorizontalPadding, $component, ...props }: Props) {
    return (
        <Container {...props} className={clsx({ [styles.noHorizontalPadding]: noHorizontalPadding })}>
            <ChildrenComponentRenderer propName="children" parent={$component} children={children} />
        </Container>
    );
}
