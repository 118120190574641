import React from "react";
import styles from "placements/_css/placementBanner.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { TPlacementListingLocalizedMdl } from "placements/_models/PlacementsMdl";
import { PlacementBannerCard } from "placements/PlacementBannerCard";

SwiperCore.use([Autoplay, Navigation]);

type Props = {
    placements: TPlacementListingLocalizedMdl[];
};

export function PlacementBannerContent({ placements }: Props) {
    if (!placements.length) return null;
    return (
        <Swiper
            id={"placementBanner"}
            loop
            speed={1200}
            autoplay={{ delay: 3000, disableOnInteraction: true }}
            className={styles.swiper}
        >
            {placements.map((placement) => {
                if (!placement || !placement.property) return;
                return (
                    <SwiperSlide key={placement._id} className={"flex_center_center"}>
                        <PlacementBannerCard placement={placement} />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}
