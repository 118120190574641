import React, { RefObject, useImperativeHandle, useRef, useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { updateSearchFilter } from "properties/searchBar/filters/_utils/filtersUtils";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { ListStore } from "_common/list/ListStore";
import { fetchSearchProperties } from "_common/_utils/searchUtils";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { FilterButtons } from "properties/searchBar/filters/FilterButtons";
import styles from "./_css/roomsFIlter.module.css";
import { ExposedFiltersFunctions } from "properties/searchBar/MobileFilters";
import { useClickOutside } from "_common/_utils/hookUtils";
import { InputFiltersAutocomplete } from "properties/searchBar/filters/InputFiltersAutocomplete";
import { BATHROOMS_DATA, BEDROOMS_DATA, roomsRangeToFilters } from "properties/searchBar/filters/_utils/roomsUtils";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
    filter: {
        key: string;
        labelKey: string;
        mobileData?: number[];
    };
};

export const RoomsNumberFilter = React.forwardRef<ExposedFiltersFunctions, Props>((props: Props, ref) => {
    const listStore = props.listStore;
    const propertiesStore = usePropertiesStore();
    const history = useHistory();
    const defaultBedrooms = {
        min: listStore.getFilterByFilterId(`bedrooms.max`)?.value.toString() ?? "",
        max: listStore.getFilterByFilterId(`bedrooms.min`)?.value.toString() ?? "",
    };
    const defaultBathrooms = {
        min: listStore.getFilterByFilterId(`bathrooms.max`)?.value.toString() ?? "",
        max: listStore.getFilterByFilterId(`bathrooms.min`)?.value.toString() ?? "",
    };
    const [bedrooms, setBedrooms] = useState<{ min: string; max: string }>(defaultBedrooms);
    const [bathrooms, setBathrooms] = useState<{ min: string; max: string }>(defaultBathrooms);
    const { t } = useTranslation();
    function applyFilters() {
        if (
            defaultBedrooms.min === bedrooms.min &&
            defaultBedrooms.max === bedrooms.max &&
            defaultBathrooms.min === bathrooms.min &&
            defaultBedrooms.max === bathrooms.max
        ) {
            return;
        }

        const bedroomFilters = roomsRangeToFilters({ min: bedrooms.min, max: bedrooms.max, filterKey: "bedrooms" });
        const bathroomFilters = roomsRangeToFilters({ min: bathrooms.min, max: bathrooms.max, filterKey: "bathrooms" });

        listStore.removeFilterByFilterId(`bedrooms.min`);
        listStore.removeFilterByFilterId(`bedrooms.max`);
        listStore.removeFilterByFilterId(`bathrooms.min`);
        listStore.removeFilterByFilterId(`bathrooms.max`);

        updateSearchFilter(listStore, [...bathroomFilters, ...bedroomFilters]).then(() => {
            void fetchSearchProperties(propertiesStore, props.listStore, history);
        });
    }

    const resetFilters = () => {
        setBedrooms({ min: "", max: "" });
        setBathrooms({ min: "", max: "" });
        applyFilters();
    };

    useImperativeHandle(
        ref,
        () => ({
            applyFilters,
            resetFilters,
        }),
        [bedrooms, bathrooms],
    );
    const containerRef: RefObject<HTMLDivElement> = useRef(null);
    useClickOutside(
        containerRef,
        () => {
            setTimeout(() => {
                applyFilters();
            }, 100);
        },
        "MuiAutocomplete-option",
    );

    return (
        <div className={clsx(styles.container, "flex_column gap_10 position_relative p_15")} ref={containerRef}>
            <div className={"pb_10"}>{t("searchBar.filter.bedrooms")}</div>
            <div className={"flex_row_center gap_5"}>
                <InputFiltersAutocomplete
                    value={bedrooms.min}
                    options={BEDROOMS_DATA.min.filter(
                        (data) => !data.value || !bedrooms.max || data.value <= bedrooms.max,
                    )}
                    placeholder={"No min"}
                    onChange={(value) => {
                        setBedrooms({ ...bedrooms, min: value ?? "" });
                    }}
                />
                <div>-</div>
                <InputFiltersAutocomplete
                    value={bedrooms.max}
                    options={BEDROOMS_DATA.max.filter(
                        (data) => !data.value || !bedrooms.min || data.value >= bedrooms.min,
                    )}
                    placeholder={"No max"}
                    onChange={(value) => {
                        setBedrooms({ ...bedrooms, max: value ?? "" });
                    }}
                />
            </div>
            <div className={"pv_15"}>{t("searchBar.filter.bathrooms")}</div>
            <div className={"flex_row_center gap_10"}>
                <InputFiltersAutocomplete
                    value={bathrooms.min}
                    options={BATHROOMS_DATA.min.filter(
                        (data) => !data.value || !bathrooms.max || data.value <= bathrooms.max,
                    )}
                    placeholder={"No min"}
                    onChange={(value) => {
                        setBathrooms({ ...bathrooms, min: value });
                    }}
                />
                <div>-</div>
                <InputFiltersAutocomplete
                    value={bathrooms.max}
                    options={BATHROOMS_DATA.max.filter(
                        (data) => !data.value || !bathrooms.min || data.value >= bathrooms.min,
                    )}
                    placeholder={"No max"}
                    onChange={(value) => {
                        setBathrooms({ ...bathrooms, max: value });
                    }}
                />
            </div>
            <FilterButtons className={"mt_20"} onReset={resetFilters} onApply={applyFilters} />
        </div>
    );
});
