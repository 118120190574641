import React from "react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { PROPERTY_SUBTYPE, PROPERTY_TYPE, TPropertyMdl } from "properties/_models/PropertyMdl";
import { reformatLocalizedPropertyToProperty } from "_common/_utils/propertyUtils";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { toast } from "react-toastify";
import { getErrorMessage } from "_common/errors/errorUtils";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { URLS } from "_configs/URLS";
import { useHistory } from "react-router";
import { useOrderContext } from "projects/OrderContext";
import { ordersStore } from "payments/_stores/ordersStore";
import styles from "./_css/buttonSaveProjectPage.module.css";

type Props = {
    property: TPropertyMdl;
    setProperty: (value: TPropertyMdl) => void;
    stepSelected: number;
    setStepSelected: (step: number) => void;
};

export function ButtonSaveProjectPage(props: Props) {
    const form = useFormContext();
    const { t } = useTranslation();
    const history = useHistory();
    const { orderStore } = useOrderContext();
    const [saving, setSaving] = React.useState<boolean>();

    function handleSubmit(item: TPropertyMdl) {
        item = reformatLocalizedPropertyToProperty(item);
        if (!props.property.order?._id) return null;
        item = {
            ...item,
            _id: props.property._id,
            price: {
                min: item.price.min * 100,
                max: item.price?.max ? item.price?.max * 100 : 0,
            },
            type: item?.type ? item?.type : PROPERTY_TYPE.condo,
            subType: item?.subType ? item?.subType : PROPERTY_SUBTYPE.duplex,
            order: { isCompleted: true },
        };
        setSaving(true);
        propertiesStore
            .editProperty(item)
            .then(
                ({ data }) => {
                    toast.success("Successfully saved");
                    orderStore.updatePaymentStatusPropertyInOrder(data);
                    const uncompletedProperties = orderStore.getUncompletedPropertiesInOrder();
                    if (uncompletedProperties.length) {
                        history.push(
                            URLS.project(orderStore.order._id) + `?propertyId=${uncompletedProperties[0]._id}`,
                        );
                    } else if (orderStore.order.paymentStatus === "unpaid") {
                        ordersStore.createCheckoutSession(orderStore.order).then(({ data }) => {
                            window.location.href = data.url;
                        });
                    } else {
                        history.push(URLS.user.orders());
                    }
                },
                (error) => toast.error(getErrorMessage(error)),
            )
            .finally(() => {
                setSaving(undefined);
            });
    }

    return (
        <div className={"flex justifyContent_center mt_30"}>
            <UiButton
                color={"primary"}
                variant={"contained"}
                disabled={saving}
                onClick={form.handleSubmit(async (item: TPropertyMdl) => handleSubmit(item))}
                className={styles.btnSaved}
            >
                {t("projectPage.saveAndConfirm")}
            </UiButton>
        </div>
    );
}
