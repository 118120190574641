import React from "react";
import styles from "./_css/recaptchaDisclaimer.module.css";

export function RecaptchaDisclaimer() {
    return (
        <div className={styles.container}>
            Google's terms of use and privacy policy apply. This site is protected by reCAPTCHA Enterprise and the
            Google
            <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
            <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
        </div>
    );
}
