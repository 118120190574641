import React, { useState } from "react";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { NoSsr } from "@material-ui/core";
import { CookieBanner } from "cookies/CookieBanner";
import { settingsStore } from "settings/_stores/settingsStore";
import { useTrackPage } from "pages/useTrackPage";
import { ADMIN_PATH } from "admin/_configs/ADMIN_URLS";
import clsx from "clsx";
import styles from "./_css/cookiePage.module.css";

export const CookiePage = () => {
    const { url } = useTrackPage();
    const [displayBanner, setDisplayBanner] = useState(false);
    const cookieBannerIsDisplay = !url.startsWith(ADMIN_PATH) && displayBanner;
    return (
        <>
            {!analyticsUtils.hasSetCookiesPolicy && (
                <NoSsr>
                    <div
                        className={clsx(styles.container, {
                            [styles.hide]: !displayBanner,
                            [styles.display]: displayBanner,
                        })}
                        style={{ inset: displayBanner ? "unset" : 0 }}
                        onTouchMove={() => {
                            if (!displayBanner) setDisplayBanner(true);
                        }}
                        onMouseMove={() => {
                            if (!displayBanner) setDisplayBanner(true);
                        }}
                    >
                        {cookieBannerIsDisplay && (
                            <CookieBanner
                                onAccept={() => {
                                    analyticsUtils.acceptCookies();
                                    void settingsStore.incrementSettingsStats("cookies.accept");
                                }}
                                onDecline={() => {
                                    analyticsUtils.declineCookies();
                                    void settingsStore.incrementSettingsStats("cookies.decline");
                                }}
                            />
                        )}
                    </div>
                </NoSsr>
            )}
        </>
    );
};
