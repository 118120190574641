import React, { useContext } from "react";
import { UnitStore } from "units/_stores/UnitStore";

const UnitCtxt = React.createContext<UnitStore>({} as UnitStore);

type Props = {
    unitStore: UnitStore;
};

export function useUnitStore() {
    return useContext(UnitCtxt);
}

export function UnitContext(props: React.PropsWithChildren<Props>) {
    return <UnitCtxt.Provider value={props.unitStore}>{props.children}</UnitCtxt.Provider>;
}
