import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TUnitMdl } from "units/_models/UnitMdl";
import { TLang } from "_configs/sharedConfig";
import { s6 } from "_common/_utils/coreUtils";
import { createFilesData, storeFile } from "_common/_utils/fileUtils";
import { TRangeNumber } from "properties/_models/PropertyMdl";

export class UnitsAdminStore extends BaseResourceStore<TUnitMdl> {
    constructor() {
        super("units");
    }

    async duplicate(itemId: string) {
        let item = await this.getAsync(itemId);
        if (!item) throw { key: "ITEM_NOT_FOUND" };
        for (const lang in item.localized) {
            item.localized[lang as TLang].urlAlias = item.localized[lang as TLang].urlAlias + s6();
        }
        const photos = [];
        const photosForFileData = [];
        for (const photo of item.photos) {
            const blobImg = await fetch(photo.url).then((image) => image.blob());
            const blobUrl = storeFile(blobImg);
            photosForFileData.push(blobUrl);
            photos.push({ url: blobUrl });
        }
        const filesData = await createFilesData(photosForFileData, `photos.*.url`, 1200);
        item = {
            ...item,
            photos,
            floorPlans: [],
            price: { min: item.price.min * 100, max: (item.price?.max ?? 0) * 100 },
        };
        delete item._id;
        return this.duplicateItem(item, filesData);
    }

    protected reformatItem<TUnitMdl>(item: TUnitMdl) {
        const reformattedItem = super.reformatItem(item);
        return {
            ...reformattedItem,
            price: {
                min: (reformattedItem as TUnitMdl & { price: TRangeNumber }).price.min / 100,
                max: (reformattedItem as TUnitMdl & { price: TRangeNumber }).price.max
                    ? (reformattedItem as TUnitMdl & { price: { max: number } }).price.max / 100
                    : 0,
            },
        };
    }
}

const unitsAdminStore = new UnitsAdminStore();
export { unitsAdminStore };
