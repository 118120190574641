import React from "react";
import clsx from "clsx";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import styles from "./_css/propertyBtnSeeMore.module.css";

type Props = {
    setIsCollapse: (bool: boolean) => void;
    isCollapse: boolean;
    notBtn?: boolean;
};

export function PropertyBtnSeeMore(props: Props) {
    const { t } = useTranslation();
    return (
        <div
            className={clsx("flex_row textAlign_center cursor_pointer mt_10", {
                [styles.btnMore]: !props.notBtn,
                [styles.notBtn]: props.notBtn,
            })}
            onClick={() => props.setIsCollapse(!props.isCollapse)}
        >
            {props.isCollapse ? (
                <>
                    {t("propertyPage.seeMore")}
                    <KeyboardArrowDown />
                </>
            ) : (
                <>
                    {t("propertyPage.seeLess")}
                    <KeyboardArrowUp />
                </>
            )}
        </div>
    );
}
