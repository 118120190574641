import React from "react";
import clsx from "clsx";
import { TurnedIn } from "@material-ui/icons";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import styles from "./_css/savedSearchButton.module.css";
import { useTranslation } from "react-i18next";
import { userStore } from "users/_stores/userStore";
import { toast } from "react-toastify";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";

export function SavedSearchButton() {
    const { t } = useTranslation();
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);

    return (
        <UiButton
            id={"savedSearchButton"}
            onClick={() => {
                const filters = JSON.parse(JSON.stringify(listStore.filters));
                userStore.isLogged
                    ? userStore
                          .patchSearchToFavorite(
                              filters,
                              "",
                              propertiesStore.addressParams.region,
                              propertiesStore.addressParams.city,
                              propertiesStore.addressParams?.neighbourhood,
                          )
                          .then(() => toast.success(t("searchBar.savedSearch")))
                          .catch((e) => toast.error(e))
                    : userStore.openSignInWithModal();
            }}
            className={clsx(styles.button, "mh_5")}
            color={"primary"}
            variant={"outlined"}
        >
            <TurnedIn className="mr_5" fontSize={"default"} /> {t("searchBar.saveSearch")}
        </UiButton>
    );
}
