import React from "react";
import { useTranslation } from "react-i18next";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/swiper-bundle.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { PropertyCardSrps } from "properties/searchBar/featured/PropertyCardSrps";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import styles from "./_css/virtualAssistantPropertiesSection.module.css";
import { ListStore } from "_common/list/ListStore";
import { observer } from "mobx-react";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

export const VirtualAssistantPropertiesSection = observer(({ listStore }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <div className={"flex justifyContent_spaceBetween mb_20"}>
                <h3 className={styles.title}>
                    {t("virtualAssistant.finalStepForm.selectedProjects", { count: listStore.count })}
                </h3>
                <div className={"flex gap_10"}>
                    <div className="swiper-button-prev-assistant"></div>
                    <div className="swiper-button-next-assistant"></div>
                </div>
            </div>
            <Swiper
                navigation={{
                    prevEl: `.swiper-button-prev-assistant`,
                    nextEl: `.swiper-button-next-assistant`,
                }}
                slidesPerView={"auto"}
                spaceBetween={10}
                breakpoints={{
                    1400: {
                        slidesPerView: 6,
                        spaceBetween: 20,
                    },
                    1100: {
                        slidesPerView: 4.5,
                        spaceBetween: 20,
                    },
                    960: {
                        slidesPerView: 4,
                    },
                    550: {
                        slidesPerView: 3,
                    },
                    425: {
                        slidesPerView: 2,
                    },
                    375: {
                        slidesPerView: 1.5,
                    },
                    0: {
                        slidesPerView: 1,
                    },
                }}
            >
                {listStore.paginatedItems.map((property) => {
                    if (!property) return;
                    return (
                        <SwiperSlide key={property._id}>
                            <PropertyCardSrps property={property} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </>
    );
});
