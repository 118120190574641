import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { TOpItems } from "admin/_common/filters/OpPicker";

export const SQUARE_RANGE = {
    default: {
        min: [
            { value: "", label: "No min" },
            { value: "200", label: "200 pi²" },
            { value: "500", label: "500 pi²" },
            { value: "750", label: "750 pi²" },
            { value: "1000", label: "1 000 pi²" },
            { value: "1500", label: "1 500 pi²" },
            { value: "2000", label: "2 000 pi²" },
            { value: "3000", label: "3 000 pi²" },
            { value: "5000", label: "5 000 pi²" },
            { value: "10000", label: "10 000 pi²" },
        ],
        max: [
            { value: "", label: "No max" },
            { value: "200", label: "200 pi²" },
            { value: "500", label: "500 pi²" },
            { value: "750", label: "750 pi²" },
            { value: "1000", label: "1 000 pi²" },
            { value: "1500", label: "1 500 pi²" },
            { value: "2000", label: "2 000 pi²" },
            { value: "3000", label: "3 000 pi²" },
            { value: "5000", label: "5 000 pi²" },
            { value: "10000", label: "10 000 pi²" },
        ],
    },
    commercial: {
        min: [
            { value: "", label: "No min" },
            { value: "500", label: "500 pi²" },
            { value: "1000", label: "1 000 pi²" },
            { value: "2500", label: "2 500 pi²" },
            { value: "5000", label: "5 000 pi²" },
            { value: "10000", label: "10 000 pi²" },
            { value: "25000", label: "25 000 pi²" },
            { value: "50000", label: "50 000 pi²" },
            { value: "100000", label: "100 000 pi²" },
        ],
        max: [
            { value: "", label: "No max" },
            { value: "500", label: "500 pi²" },
            { value: "1000", label: "1 000 pi²" },
            { value: "2500", label: "2 500 pi²" },
            { value: "5000", label: "5 000 pi²" },
            { value: "10000", label: "10 000 pi²" },
            { value: "25000", label: "25 000 pi²" },
            { value: "50000", label: "50 000 pi²" },
            { value: "100000", label: "100 000 pi²" },
        ],
    },
};

export function surfaceRangeToFilters(
    payload: {
        max: string;
        min: string;
    } = {
        max: "0",
        min: "0",
    },
) {
    const filters = [];
    const min = parseInt(payload.min);
    const max = parseInt(payload.max);
    const reverseMaxMin = !isNaN(min) && !isNaN(max) && max < min;
    if (!isNaN(min)) {
        filters.push({
            id: `squareSurface.max`,
            type: TFilterType.NUMBER,
            value: reverseMaxMin ? max : min,
            op: "gte" as TOpItems,
        });
    }
    if (!isNaN(max)) {
        filters.push({
            id: `squareSurface.min`,
            type: TFilterType.NUMBER,
            value: reverseMaxMin ? min : max,
            op: "lte" as TOpItems,
        });
    }
    return filters;
}
