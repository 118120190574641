import React from "react";
import simpleVLogo from "../../assets/images/vistoo-logo-simple-v-100x100.png";

type Props = {
    width: number;
};

export function LogoSimple({ width }: Props) {
    return (
        <>
            <img alt="Vistoo logo" width={width} src={simpleVLogo} />
        </>
    );
}
