export const _619578458900b8a7f9fc6df7 = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "ApartmentComplex",
    name: "Condos à Louer (3 ½), (4 ½) et (5 ½) à Wesmount - LABO MTL",
    description:
        "Plongez-vous dans l’élégance d’appartements branchés haut de gamme. Emprunts d’un style industriel urbain chic, ces logements reflètent l’essence de la vie moderne en plein cœur de Montréal. Offrant de vastes espaces baignés de lumière grâce à une grande fenestration, nos appartements locatifs 3 1/2, 4 1/2, et 5 1/2 vous garantissent une atmosphère de calme et de quiétude. Chaque studio neuf, doté d’un balcon privé, est une invitation à profiter du rythme effervescent de la ville.",
    address: {
        "@type": "PostalAddress ",
        addressLocality: "Westmount",
        addressRegion: "Qc",
        postalCode: "H3Z1W7",
        streetAddress: "3555 rue Saint-Antoine O.",
    },
    latitude: 45.48356,
    longitude: -73.58404,
    numberOfAccommodationUnits: 126,
    numberOfBedrooms: [1, 2, 3],
    petsAllowed: "Sous certaines conditions",
    tourBookingPage: "https://vistoo.com/fr/proprietes/qc-h3z1w7-westmount-labo",
    image: [
        "https://vistoo.com/static/uploads/vistoo/image-1jpg-1709137588038.webp",
        "https://vistoo.com/static/uploads/vistoo/image-4jpg-1709137614210.webp",
        "https://vistoo.com/static/uploads/vistoo/image-3jpg-1709137588072.webp",
        "https://vistoo.com/static/uploads/vistoo/image-10jp-1709137606813.webp",
        "https://vistoo.com/static/uploads/vistoo/image-15jp-1709137603370.webp",
        "https://vistoo.com/static/uploads/vistoo/image-11jp-1709137606823.webp",
    ],
    accommodationFloorPlan: {
        "@type": "FloorPlan",
        amenityFeature: [
            "Accès contrôlé",
            "Accès piste cyclable",
            "Aire BBQ",
            "Aire de jeux",
            "Ascenseur",
            "Atelier de vélos",
            "Balcon",
            "Bibliothèque",
            "Caméras de sécurité",
            "Climatiseur central",
            "Coin lecture",
            "Concierge",
            "Électroménagers inclus",
            "Espace barbecue",
            "Espace de rangement pour les bicyclettes",
            "Espace Lounge",
            "Gym",
            "Intercom",
            "Proche des transports en commun",
            "Rangement pour vélo",
            "Salon de Billard",
            "Salon internet",
            "Sécurité 24h/24",
            "Station de recharge pour véhicules electriques",
            "Stationnement couvert",
            "Surveillance vidéo",
            "Terrace",
            "Voiture en autopartage",
        ],
        floorSize: {
            "@type": "QuantitativeValue",
            value: [710, 1839],
            unitCode: "FTK",
        },
        numberOfBathroomsTotal: 1,
        numberOfRooms: [3, 4, 5],
    },
});
