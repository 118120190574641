import React, { ReactNode, RefObject, useRef, useState } from "react";
import { TextField } from "_common/ui/forms/TextField";
import { Autocomplete } from "@material-ui/lab";
import { useClickOutside } from "_common/_utils/hookUtils";
import styles from "./_css/inputFiltersAutocomplete.module.css";
import { KeyboardArrowDown } from "@material-ui/icons";

type Props = {
    options: { value: string; label: string }[];
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    startAdornment?: ReactNode;
};

export function InputFiltersAutocomplete({ options, value, onChange, placeholder, startAdornment }: Props) {
    const [open, setOpen] = useState(false);
    const ref: RefObject<HTMLDivElement> | null = useRef(null);
    const inputRef: RefObject<HTMLDivElement> | null = useRef(null);
    useClickOutside(
        ref,
        () => {
            setOpen(false);
            inputRef?.current?.blur();
        },
        "MuiAutocomplete-option",
    );
    return (
        <div ref={ref} className={"w-full"}>
            <Autocomplete<string, undefined, undefined, true>
                autoComplete
                value={value}
                options={options.map((option) => option.value)}
                fullWidth
                blurOnSelect
                freeSolo
                forcePopupIcon
                open={open}
                renderOption={(option) => options.find((data) => data.value === option)?.label ?? option}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: startAdornment,
                            }}
                            inputRef={inputRef}
                            placeholder={placeholder}
                        />
                    );
                }}
                placeholder={placeholder}
                onInputChange={(_options, value) => {
                    setOpen(false);
                    onChange(value ?? "");
                }}
                popupIcon={
                    <KeyboardArrowDown
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOpen(!open);
                        }}
                    />
                }
                classes={{
                    inputRoot: styles.input,
                    endAdornment: styles.endAdornment,
                    popupIndicator: styles.popup,
                    clearIndicator: styles.popup,
                }}
            />
        </div>
    );
}
