import React, { useState } from "react";
import clsx from "clsx";
import styles from "properties/propertyPage/_css/propertyHeader.module.css";
import { useTranslation } from "react-i18next";
import { IconComponent } from "components/base/icon/IconComponent";
import { convert, UNIT_CONVERSION } from "_common/_utils/unityUtils";
import { useUnitStore } from "units/UnitContext";
import { Collapse } from "@material-ui/core";
import { PropertyBtnSeeMore } from "properties/propertyPage/PropertyBtnSeeMore";
import { UnitFeatures } from "units/UnitFeatures";
import { PropertyDeveloper } from "properties/propertyPage/PropertyDeveloper";
import { PropertyLink } from "properties/propertyPage/PropertyLink";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";

export function UnitDescription() {
    const { t } = useTranslation();
    const unitStore = useUnitStore();
    const propertyStore = usePropertyStore();
    const [isCollapse, setIsCollapse] = useState(true);
    const propertyLink = propertyStore.property.localized.propertyLink;

    return (
        <div className={clsx("mt_50 flex_column", styles.containerDescription)}>
            <h2 className={styles.titleDescription}>{t("unitPage.header.unitDescription")}</h2>
            <div>
                <div className={"flex_row mt_15"}>
                    <IconComponent icon="square_foot" />
                    <div className={"ml_10"}>
                        {unitStore.unit.squareSurface?.min}{" "}
                        {!!unitStore.unit.squareSurface?.max && "- " + unitStore.unit.squareSurface.max}{" "}
                        {t("words.ft2")} / {convert(unitStore.unit.squareSurface?.min, UNIT_CONVERSION.FT2_TO_M2)}
                        {!!unitStore.unit.squareSurface?.max &&
                            "- " + convert(unitStore.unit.squareSurface?.max, UNIT_CONVERSION.FT2_TO_M2)}{" "}
                        {t("words.m2")}
                    </div>
                </div>
                <div className={"flex_row mt_15"}>
                    <IconComponent icon="king_bed" />
                    <div className={"ml_10"}>{t(`unit.bedroom`, { count: unitStore.unit.bedrooms })}</div>
                </div>
                <div className={"flex_row mt_15"}>
                    <IconComponent icon="bathtub" />
                    <div className={"ml_10"}>{t(`unit.bathroom`, { count: unitStore.unit.bathrooms })}</div>
                </div>
                {unitStore.unit.nbOfLevels > 0 && (
                    <div className={"flex_row mt_15"}>
                        <IconComponent icon="bathtub" />
                        <div className={"ml_10"}>{t(`unit.bathroom`, { count: unitStore.unit.nbOfLevels })}</div>
                    </div>
                )}

                {unitStore.unit.parkings &&
                    unitStore.unit.parkings.map((parking, index) => (
                        <div key={index} className={"flex_row mt_15"}>
                            <IconComponent icon="local_parking" />
                            <div className={"ml_10"}>
                                {t(`unitPage.parkingTypes.${parking.type}`, { count: parking.number })}
                            </div>
                        </div>
                    ))}
                {unitStore.unit.nbOfLevels && unitStore.unit.nbOfLevels > 0 ? (
                    <div className={"flex_row mt_15"}>
                        <IconComponent icon="bathtub" />
                        <div className={"ml_10"}>{t(`unit.bathroom`, { count: unitStore.unit.nbOfLevels })}</div>
                    </div>
                ) : (
                    ""
                )}
            </div>
            {unitStore.unit.localized.description && (
                <Collapse in={!isCollapse} collapsedHeight={0} className={styles.collapse}>
                    <div
                        dangerouslySetInnerHTML={{ __html: unitStore.unit.localized.description }}
                        className={clsx("pr_20 mt_15", styles.text)}
                    />
                </Collapse>
            )}
            {unitStore.unit.localized.description && (
                <PropertyBtnSeeMore setIsCollapse={setIsCollapse} isCollapse={isCollapse} />
            )}
            <hr className={clsx("mb_15", styles.line)} />
            <UnitFeatures />
            <PropertyDeveloper />
            {propertyLink && (
                <div className="mt_10">
                    <PropertyLink propertyLink={propertyStore.property.localized.propertyLink} />
                </div>
            )}
        </div>
    );
}
