import React from "react";
import { useTranslation } from "react-i18next";
import avLogo from "../../assets/images/virtual-assistant/avLogo.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "../_css/virtualAssistantTooltip.module.css";

export const VirtualAssistantTooltip = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <LazyLoadImage src={avLogo} alt="Virtual Assistant logo" />
            <div>{t("menu.virtualAssistant.subtitle")}</div>
        </div>
    );
};
