import { i18nextInstance } from "_common/i18n/IntlProvider";
import frImage from "../../../shared/assets/images/fr.png";
import enImage from "../../../shared/assets/images/ca.png";
import esImage from "../../../shared/assets/images/es.svg";
import zhImage from "../../../shared/assets/images/china.svg";
import _ from "lodash";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { URLS } from "_configs/URLS";
import { PARAMS_PAGE_PREFIX } from "_common/_utils/searchUtils";
import { TFaq } from "geoZones/_models/GeoZoneMdl";

export function getUrlWithPagination(url: string, page: number) {
    let newUrl: string;
    if (/\/p_[0-9]+/.test(url)) {
        newUrl = url.replace(/p_[0-9]+/, PARAMS_PAGE_PREFIX + page);
    } else if (/\/z_[0-9]+/.test(url)) {
        const zoom = new RegExp(/z_[0-9]+/).exec(url)?.[0];
        newUrl = url.replace(/z_[0-9]+/, PARAMS_PAGE_PREFIX + page) + "/" + zoom;
    } else {
        newUrl = url + "/" + PARAMS_PAGE_PREFIX + page;
    }
    return newUrl;
}

export function getFlagImg(lang: string) {
    let flag;
    switch (lang) {
        case "fr":
            flag = frImage;
            break;
        case "en":
            flag = enImage;
            break;
        case "es":
            flag = esImage;
            break;
        case "zh":
            flag = zhImage;
            break;
        default:
    }
    return flag;
}

export function getI18nExpByLang(lang: string, key: string, args?: { key: string; value?: string }[]) {
    let exp = _.get(i18nextInstance.getDataByLanguage(lang)?.translation, key);
    if (!exp) _.get(i18nextInstance.getDataByLanguage(sharedConfig.defaultLang)?.translation, key);
    if (!exp) return key;
    if (exp && args) {
        for (const arg of args) {
            if (arg.value) exp = exp.replace("{{" + arg.key + "}}", arg.value);
        }
    }
    return exp;
}

export function refreshPage(url: string) {
    setTimeout(() => {
        window.location.href = url;
    }, 50);
}

export function isBuyingPage(url: string) {
    return url.startsWith(URLS.buy());
}

export function isBuyingOrRentingPage(url: string) {
    return url.startsWith(URLS.buy()) || url.startsWith(URLS.rent());
}

export function getPageNumberFromUrl(url: string): number | undefined {
    const pageIndicator = new RegExp(/p_[0-9]+/).exec(url);
    if (pageIndicator) {
        return parseInt(pageIndicator[0].replace("p_", ""));
    }
}

export function getPageAliasUrlForBlogPage(pathname: string) {
    return (
        "/" +
        i18nextInstance.language +
        pathname.substr(
            (
                sharedConfig.languages[i18nextInstance.language as TLang].basePath +
                "/" +
                getI18nExpByLang(i18nextInstance.language as TLang, "routes.blog")
            ).length,
        )
    );
}

export function getIsHomePage(url: string) {
    const urlSplit = url.split("/");
    return (
        (urlSplit.length <= 3 && Object.keys(sharedConfig.languages).includes(urlSplit[1]) && !urlSplit[2]) ||
        url === "/"
    );
}

export const getFaqJsonLd = (faqs: TFaq[]) => {
    const mainEntity = [];
    for (const faq of faqs) {
        mainEntity.push({
            "@type": "Question",
            name: faq.question,
            acceptedAnswer: {
                "@type": "Answer",
                text: faq.answer,
            },
        });
    }
    return {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: mainEntity,
    };
};
