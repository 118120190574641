import React from "react";
import { Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { comparisonStore } from "compare/_store/ComparisonStore";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import compareIconUnitCard from "../assets/images/compareIconUnitCard.svg";
import styles from "./_css/compareBtnUnitCard.module.css";

type Props = {
    id: string;
    type: MAIN_RESOURCE;
};

export function CompareBtnUnitCard(props: Props) {
    return (
        <Tooltip title={`Compare ${props.type}`}>
            <img
                src={compareIconUnitCard}
                alt={"compareIconUnitCard"}
                className={clsx("p_5", styles.iconCompare)}
                onClick={(e) => {
                    e.preventDefault();
                    comparisonStore.addItem(props.type, props.id);
                    comparisonStore.setOverlayIsDisplayed(true);
                }}
            ></img>
        </Tooltip>
    );
}
