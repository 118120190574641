import React from "react";
import styles from "./_css/CheckboxFeatureAndAmenities.module.css";
import { OPTION_TYPE, PROPERTY_FEATURES_AMENITIES } from "_configs/propertyConfig";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { CheckboxInputFormProject } from "projects/component/CheckboxInputFormProject";

export function CheckboxFeatureAndAmenities() {
    const { t } = useTranslation();
    const form = useFormContext();

    return (
        <div className={styles.container}>
            <div className={styles.titleSectionCheckbox}> {t("projectPage.characteristic")}</div>
            <div className={styles.contentCheckbox}>
                <div className={"flex flexWrap_wrap"}>
                    {PROPERTY_FEATURES_AMENITIES.map((item, index) => (
                        <FormProvider {...form} key={index}>
                            {item.optionType === OPTION_TYPE.FEATURES ? (
                                <Controller
                                    control={form.control}
                                    as={CheckboxInputFormProject}
                                    name={`features.${item.key}`}
                                    value={!!form.watch(`features.${item.key}`)}
                                    onChange={(value: boolean) => form.setValue(`features.${item.key}`, value)}
                                    item={item}
                                />
                            ) : (
                                <Controller
                                    control={form.control}
                                    as={CheckboxInputFormProject}
                                    name={`amenities.${item.key}`}
                                    value={!!form.watch(`amenities.${item.key}`)}
                                    onChange={(value: boolean) => form.setValue(`amenities.${item.key}`, value)}
                                    item={item}
                                />
                            )}
                        </FormProvider>
                    ))}
                </div>
            </div>
        </div>
    );
}
