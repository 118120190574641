import React from "react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { usePropertyOrUnit } from "_common/_utils/hookUtils";
import styles from "./_css/comparedItemPhoto.module.css";
import { IconButton } from "@material-ui/core";
import { comparisonStore } from "compare/_store/ComparisonStore";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";

type Props = {
    itemId: string;
    type: MAIN_RESOURCE;
};

function _ComparedItemPhoto(props: Props) {
    const { itemOrFetch, loading } = usePropertyOrUnit(props.type, props.itemId);

    if ("_id" in itemOrFetch) {
        return (
            <img className={styles.img} src={itemOrFetch?.photos?.[0]?.url ?? ""} alt={itemOrFetch.photos?.[0]?.alt} />
        );
    } else {
        if (!loading) return null;
        return (
            <LoadableFromLoading<TPropertyListingMdl | TUnitListingMdl>
                loading={loading as LoadingStateMdl<TPropertyListingMdl | TUnitListingMdl>}
                renderer={(_status, _error, value: TPropertyListingMdl | TUnitListingMdl | undefined) => {
                    if (!value) return null;
                    return (
                        <img
                            className={styles.img}
                            src={value.photos?.[0]?.url ?? ""}
                            alt={value.photos?.[0]?.alt ?? ""}
                        />
                    );
                }}
            />
        );
    }
}

type WrappedProps = {
    itemId: string;
    type: MAIN_RESOURCE;
};

export function ComparedItemPhoto(props: WrappedProps) {
    return (
        <div className={clsx("position_relative flex_row flex-1 mt_10 pt_10", styles.container)}>
            <IconButton
                aria-label="close"
                size={"small"}
                className={styles.deleteIcon}
                onClick={() => {
                    comparisonStore.deleteItem(props.type, props.itemId);
                }}
            >
                <CloseIcon className={styles.cross} />
            </IconButton>
            <div className={styles.imgContainer}>
                <_ComparedItemPhoto itemId={props.itemId} type={props.type} />
            </div>
        </div>
    );
}
