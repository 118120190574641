import React, { ReactNode } from "react";
import styles from "./_css/inputTextFront.module.css";
import clsx from "clsx";
import { FieldError } from "react-hook-form";

export type TInputProps<T = string> = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & {
    error?: FieldError;
    onValueChange?: (value: T, event?: React.ChangeEvent<HTMLInputElement>) => void;
    endIcon?: ReactNode;
    theme?: "white" | "gray" | "label";
    placeholder?: string;
    disabled?: boolean;
    password?: boolean;
    hidden?: boolean;
};

export const InputTextFront = React.forwardRef<HTMLInputElement, TInputProps>(
    ({ error, className, endIcon, onValueChange, onChange, placeholder, disable, password, hidden, ...props }, ref) => {
        return (
            <div className={clsx(styles.container, { ["hidden"]: hidden })}>
                <div className={styles.content}>
                    <input
                        type={password ? "password" : "text"}
                        {...props}
                        placeholder={placeholder}
                        className={clsx(
                            styles.input,
                            {
                                [styles.input_error]: error,
                            },
                            className,
                        )}
                        ref={ref}
                        onChange={(event) => {
                            if (onChange) onChange(event);
                            if (onValueChange) onValueChange(event.target.value);
                        }}
                        disabled={disable}
                    />
                </div>
                {error && error.message && <div className={styles.error}>{error.message}</div>}
            </div>
        );
    },
);
