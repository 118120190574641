import React from "react";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { AdvancedResearchesSrPsProvinces } from "advancedResearches/AdvancedResearchesSRPsProvinces";
import { AdvancedResearchesSRPsPropertyTypes } from "advancedResearches/AdvancedResearchesSRPsPropertyTypes";
import { AdvancedResearchesSRPsGeozones } from "advancedResearches/AdvancedResearchesSRPsGeoZones";
import { AdvancedResearchesSRPsGeozonesPropertyTypes } from "advancedResearches/AdvancedResearchesSRPsGeoZonesPropertyTypes";

export function AdvancedResearchSrPs() {
    const { isGeoZonePage, isExistingPropertyType } = useSearchPageParams();

    if (!isGeoZonePage && !isExistingPropertyType) {
        return <AdvancedResearchesSrPsProvinces />;
    } else if (!isGeoZonePage && isExistingPropertyType) {
        return <AdvancedResearchesSRPsPropertyTypes />;
    } else if (isGeoZonePage && !isExistingPropertyType) {
        return <AdvancedResearchesSRPsGeozones />;
    } else if (isGeoZonePage && isExistingPropertyType) {
        return <AdvancedResearchesSRPsGeozonesPropertyTypes />;
    }

    return null;
}
