import React from "react";
import { Meta } from "_common/_utils/Meta";
import { useTranslation } from "react-i18next";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { useLocation } from "react-router-dom";
import { PARAMS_NORTH_PREFIX, PARAMS_PAGE_PREFIX, PARAMS_ZOOM_PREFIX } from "_common/_utils/searchUtils";

type Props = {
    purpose?: PROPERTY_PURPOSE;
};

export function PropertiesMeta(props: Props) {
    const { t } = useTranslation();
    const location = useLocation();

    const { isCityPage, isProvincePage, propertyTypeKey } = useSearchPageParams();
    const propertiesStore = usePropertiesStore();
    const title = isProvincePage
        ? t("propertiesPage.meta.title", {
              region: propertiesStore.addressParams.region,
              city: propertiesStore.addressParams.neighbourhood ?? propertiesStore.addressParams.city,
          })
        : undefined;
    const description = isProvincePage
        ? t("propertiesPage.meta.description", {
              region: propertiesStore.addressParams.region,
              city: propertiesStore.addressParams.neighbourhood ?? propertiesStore.addressParams.city,
          }) +
          t(`propertiesPage.meta.purpose.${props.purpose}`, {
              city: propertiesStore.addressParams.neighbourhood ?? propertiesStore.addressParams.city,
          })
        : undefined;
    const alternateUrls = [];
    // alternates : url pour chaque langue, attention à ne pas utiliser les formes localized ou i18next
    for (const lang of Object.keys(sharedConfig.languages)) {
        let route = "";
        const routeLocalized = getI18nExpByLang(
            lang,
            props.purpose === PROPERTY_PURPOSE.BUY ? "routes.buy" : "routes.rent",
        );
        const typeLocalized = getI18nExpByLang(lang, "property.typesUrl." + propertyTypeKey).toLowerCase();

        if (isCityPage) {
            route = `${sharedConfig.appUrl}/${lang}/${routeLocalized}/${reformatStringForUrls(
                propertiesStore.addressParams.region,
            )}/${reformatStringForUrls(propertiesStore.addressParams.city)}${
                propertyTypeKey ? `/${typeLocalized}` : ""
            }`;
        } else if (isProvincePage) {
            route = `${sharedConfig.appUrl}/${lang}/${routeLocalized}/${reformatStringForUrls(
                propertiesStore.addressParams.region,
            )}${propertyTypeKey ? `/${typeLocalized}` : ""}`;
        } else {
            route = `${sharedConfig.appUrl}/${lang}/${routeLocalized}${propertyTypeKey ? `/${typeLocalized}` : ""}`;
        }
        // pour ne pas rank les urls avec sous type dans toutes les langues
        alternateUrls.push({
            url: route,
            lang: lang as TLang,
        });
    }
    const url = location.pathname
        .split("/")
        .filter(
            (x) =>
                !!x &&
                !x.startsWith(PARAMS_NORTH_PREFIX) &&
                !x.startsWith(PARAMS_ZOOM_PREFIX) &&
                !x.startsWith(PARAMS_PAGE_PREFIX),
        )
        .join("/");
    return <Meta title={title} description={description} url={`/${url}`} alternateUrls={alternateUrls} />;
}
