import React from "react";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { Link, useLocation } from "react-router-dom";
import { getUrlWithPagination } from "_common/_utils/searchUtils";

type Props = {
    count?: number;
    selectedPage: number;
    pageSize: number;
    onPageClick: (page: number) => void;
    className?: string;
};

export const Paginator = ({ count, selectedPage, pageSize, onPageClick, className }: Props) => {
    const location = useLocation();

    if (!count) return null;
    const nbPages = Math.ceil(count / pageSize);
    return (
        <Pagination
            id={"paginator"}
            getItemAriaLabel={(type) => type}
            count={nbPages ?? 0}
            page={selectedPage}
            onChange={(event, page) => {
                event.preventDefault();
                onPageClick(page);
            }}
            className={className}
            renderItem={(item) => {
                const noFollow = !item?.page || item.page === 0 || item.page > nbPages;
                if (noFollow) return null;
                const link = getUrlWithPagination(location.pathname, item.page) + location.search;
                return (
                    <Link
                        to={link}
                        onClick={() => onPageClick(item.page)}
                        rel={noFollow ? "nofollow" : item.type.substring(0, 4)}
                    >
                        <PaginationItem rel={item.type.substring(0, 4)} {...item} className={className} />
                    </Link>
                );
            }}
        />
    );
};
