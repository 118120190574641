import React, { PropsWithChildren, Suspense } from "react";
import { Loader } from "_common/loaders/Loader";
import { NoSsr } from "@material-ui/core";

type Props = {
    fallback?: JSX.Element;
    noSpinner?: boolean;
    noText?: boolean;
};

class Suspenser extends React.Component<PropsWithChildren<Props>, any> {
    render() {
        return (
            <>
                {__BROWSER__ ? (
                    <NoSsr>
                        <Suspense
                            fallback={
                                this.props.fallback ?? (
                                    <Loader noSpinner={this.props.noSpinner} noText={this.props.noText} />
                                )
                            }
                        >
                            {this.props.children}
                        </Suspense>
                    </NoSsr>
                ) : (
                    <>{this.props.fallback ?? <Loader noSpinner={this.props.noSpinner} noText={this.props.noText} />}</>
                )}
            </>
        );
    }
}

export default Suspenser;
