import React from "react";
import styles from "./_css/pricingBreadcrumb.module.css";
import clsx from "clsx";

type Props = {
    items: number[];
    currentItem: number;
};

export function PricingBreadcrumb(props: Props) {
    return (
        <div className={clsx(styles.container, "mt_25")}>
            <div className={clsx("flex_row", styles.content)}>
                {props.items.map((_item, i) => {
                    return (
                        <div
                            key={i}
                            className={clsx(styles.bullet, {
                                [styles.bullet_last]: props.items.length - 1 === i,
                            })}
                        >
                            {(i === props.currentItem || i < props.currentItem) && (
                                <div className={styles.currentItem} />
                            )}
                        </div>
                    );
                })}
                <div className={styles.line} />
            </div>
        </div>
    );
}
