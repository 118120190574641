export function reformatUtf8Url(url: string, withAccent = true) {
    return url
        .replace(/%C3%A9/g, withAccent ? "é" : "e")
        .replace(/%C3%A8/g, withAccent ? "è" : "e")
        .replace(/%C3%AB/g, withAccent ? "ë" : "e")
        .replace(/%C3%AA/g, withAccent ? "ê" : "e")
        .replace(/%C3%B9/g, withAccent ? "ù" : "u")
        .replace(/%C3%A0/g, withAccent ? "à" : "a")
        .replace(/%E2%80%94/g, "—");
}

export function removeAccentFromString(text?: string) {
    if (!text) return "";
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function reformatStringForUrls(text?: string) {
    return removeAccentFromString(text)
        .replaceAll("–", "-")
        .replaceAll("+", "-")
        .replaceAll("—", "-")
        .replaceAll(" ", "_")
        .replaceAll("'", "-")
        .replaceAll(".", "")
        .toLowerCase()
        .trim();
}

export function reformatStringForSearch(text?: string) {
    return removeAccentFromString(text)
        .replaceAll("_", "")
        .replaceAll("—", "")
        .replaceAll("–", "")
        .replaceAll("-", "")
        .replaceAll(" ", "-")
        .toLowerCase()
        .trim();
}

export function reformatUrlForSlugSearch(text?: string) {
    return removeAccentFromString(text).replaceAll("_", "-").toLowerCase().trim();
}

export function reformatUrlForOriginalName(text?: string) {
    return removeAccentFromString(text).replaceAll("_", " ").toLowerCase().trim();
}

export function removeInlineCssForMceText(text?: string) {
    if (!text) return "";
    return text.replaceAll('<span style="font-weight: 400;">', "<span>");
}

export function reformatNumber(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function capitalize(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}
