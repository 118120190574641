import React, { lazy, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./_css/headerBlock.module.css";
import clsx from "clsx";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import Suspenser from "_common/loaders/Suspenser";
import { ShowcasePropertiesSection } from "home/sections/ShowcasePropertiesSection";

const LazySearchBar = lazy(() => import("components/sections/searchBlock/SearchBar"));

export function HeaderBlock() {
    const { t } = useTranslation();
    const [purpose, setPurpose] = useState(PROPERTY_PURPOSE.BUY);

    return (
        <div className={clsx("position_relative mb_80", styles.component)}>
            <div className={styles.showcaseContainer}>
                <ShowcasePropertiesSection />
            </div>
            <div className={clsx(styles.container, "flex_column_center")}>
                <div className={styles.titleBlock}>
                    <h1
                        className={styles.title}
                        dangerouslySetInnerHTML={{
                            __html: t("homePage.headerBlock.title"),
                        }}
                    />
                    <div className={styles.subTitle}>{t("homePage.headerBlock.subTitle")}</div>
                </div>
                <div
                    className={clsx("flex_row justifyContent_center mb_20", styles.buttons)}
                    onClick={() => geoZonesStore.open(false)}
                >
                    {Object.keys(PROPERTY_PURPOSE).map((propertyPurpose) => (
                        <div
                            key={propertyPurpose}
                            className={clsx(styles.button, "mr_10", {
                                [styles.isFiltered]: propertyPurpose === purpose,
                            })}
                            onClick={() => setPurpose(propertyPurpose as PROPERTY_PURPOSE)}
                        >
                            {t(`propertyPage.${propertyPurpose}`)}
                        </div>
                    ))}
                </div>
                <div className={styles.searchContainer}>
                    <Suspenser noSpinner noText>
                        <LazySearchBar purpose={purpose} />
                    </Suspenser>
                </div>
            </div>
        </div>
    );
}
