import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/comparePricesHeadCell.module.css";

type Props = {
    name: string;
    price: number;
};

export function ComparePricesHeadCell(props: Props) {
    const { t } = useTranslation();

    return (
        <div className={clsx("flex_column_center", styles.container)}>
            <div className={styles.title}>{t("paymentPage.products.names." + props.name)}</div>
            <div className={styles.lineYellow} />
            <div className={styles.priceSection}>
                <span className={styles.dollar}>$</span>
                <div>
                    <span className={styles.price}>{props.price}</span>
                    <span className={styles.month}>{t("propertiesPage.card.perMonth")}</span>
                </div>
            </div>
        </div>
    );
}
