import React from "react";
import { useTranslation } from "react-i18next";
import sharedConfig from "_configs/sharedConfig";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { Launch } from "@material-ui/icons";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { UNIT_EVENT } from "units/_models/UnitMdl";
import { PROPERTY_EVENT } from "properties/_models/PropertyMdl";
import clsx from "clsx";

type Props = {
    propertyLink: string;
    isUnitPage?: boolean;
    unitId?: string;
};

export function PropertyLink(props: Props) {
    const propertyStore = usePropertyStore();
    const { t } = useTranslation();
    const isAbsolute = props.propertyLink.startsWith("http");
    if (!props.propertyLink) return null;
    if (props.isUnitPage && !props.unitId) return null;
    let utm = "";
    if (!props.propertyLink.includes("utm_source=")) {
        utm = propertyStore.property.source?.platform
            ? sharedConfig.utm.queryStringScrap
            : sharedConfig.utm.queryString;
        utm = props.propertyLink.endsWith("/") ? utm : `/${utm}`;
    }

    return (
        <a
            href={isAbsolute ? props.propertyLink + utm : `https://${props.propertyLink + utm}`}
            target="_blank"
            className={clsx("flex_row_center gap_10 fontWeight_bold")}
            onClick={() =>
                props.isUnitPage
                    ? props.unitId
                        ? analyticsUtils.tracksEventOfItem(UNIT_EVENT.BUILDER_LINK, props.unitId)
                        : ""
                    : analyticsUtils.tracksEventOfItem(PROPERTY_EVENT.BUILDER_LINK, propertyStore.property._id)
            }
            rel="noopener noreferrer nofollow"
        >
            <Launch fontSize={"small"} />
            {t("propertyPage.visitProjectSite")}
        </a>
    );
}
