import React from "react";
import styles from "./_css/helpSection.module.css";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import clsx from "clsx";

type Props = {
    openForm: () => void;
    openCompare: () => void;
};

export function HelpSection(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.container, "flex_column_center")}>
            <div className={styles.text}>{t("paymentPage.help")}</div>
            <div className={clsx(styles.btnContainer, "flex_row justifyContent_center mt_20")}>
                <UiButton
                    variant={"contained"}
                    color={"inherit"}
                    className={styles.button}
                    onClick={() => props.openForm()}
                >
                    {t("paymentPage.clientService")}
                </UiButton>
                <UiButton
                    variant={"contained"}
                    color={"inherit"}
                    className={styles.button}
                    onClick={() => props.openCompare()}
                >
                    {t("paymentPage.comparePlans")}
                </UiButton>
            </div>
        </div>
    );
}
