import React, { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./_css/inputTextFrontDoubleProjectPage.module.css";
import clsx from "clsx";
import { FieldError } from "react-hook-form";

export type TInputProps<T = string> = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & {
    error?: FieldError;
    onValueChange?: (value: T, event?: React.ChangeEvent<HTMLInputElement>) => void;
    endIcon?: ReactNode;
    theme?: "white" | "gray" | "label";
    placeholder?: string;
    disabled?: boolean;
    hidden?: boolean;
    type: "email" | "postalCode" | "phone";
};

export const InputTextFrontDoubleProjectPage = React.forwardRef<HTMLInputElement, TInputProps>(
    ({
        error,
        className,
        endIcon,
        onValueChange,
        onChange,
        placeholder,
        disable,
        hidden,
        type,
        required,
        value,
        ...props
    }) => {
        const [valueInput1, setValueInput1] = useState("");
        const [valueInput2, setValueInput2] = useState("");
        const [valueInput3, setValueInput3] = useState("");

        const isEmail = type === "email";
        const isPhone = type === "phone";
        const isPostalCode = type === "postalCode";

        const maxLength = isPostalCode || isPhone ? 3 : 20;
        const maxPhoneLengthLastField = isPhone ? 4 : 20;

        const ref1 = useRef<HTMLInputElement>(null);
        const ref2 = useRef<HTMLInputElement>(null);
        const ref3 = useRef<HTMLInputElement>(null);

        useEffect(() => {
            if (isPostalCode) {
                setValueInput1(value?.substr(0, maxLength));
                setValueInput2(value?.substr(maxLength, maxLength));
            } else if (isPhone) {
                setValueInput1(value?.substr(0, maxLength));
                setValueInput2(value?.substr(maxLength, maxLength));
                setValueInput3(value?.substr(maxLength * 2, maxLength));
            } else if (isEmail) {
                setValueInput1(value?.split("@")[0]);
                setValueInput2(value?.split("@")[1]);
            }
        }, []);

        return (
            <div className={clsx(styles.container, { ["hidden"]: hidden })}>
                <div className={styles.content}>
                    <label className={styles.placeholder}>
                        {placeholder}
                        {required && <span className={styles.required}>*</span>}
                    </label>
                    <div className={styles.contentInputs}>
                        <input
                            type={isPhone ? "number" : "text"}
                            {...props}
                            className={clsx(
                                styles.input,
                                {
                                    [styles.input_error]: error,
                                    [styles.postalCodeOrPhone]: isPhone || isPostalCode,
                                    [styles.email]: isEmail,
                                },
                                className,
                            )}
                            placeholder={isEmail ? "email" : ""}
                            value={valueInput1}
                            onChange={(event) => {
                                const car = isEmail ? "@" : "";

                                setValueInput1(event.target.value);
                                if (event.target.value.length >= maxLength && ref2.current) {
                                    ref2.current.focus();
                                }
                                if (onChange) {
                                    onChange({
                                        ...event,
                                        target: {
                                            ...event.target,
                                            value: event.target.value + car + valueInput2,
                                        },
                                    });
                                }
                                if (onValueChange) onValueChange(event.target.value + car + valueInput2);
                            }}
                            disabled={disable}
                            maxLength={maxLength}
                            ref={ref1}
                        />

                        {isEmail && <div className={styles.at}> {" @ "} </div>}
                        <input
                            type={isPhone ? "number" : "text"}
                            {...props}
                            className={clsx(
                                styles.input,
                                {
                                    [styles.input_error]: error,
                                    [styles.postalCodeOrPhone]: isPhone || isPostalCode,
                                    [styles.email]: isEmail,
                                },
                                className,
                            )}
                            value={valueInput2}
                            placeholder={isEmail ? "yahoo.com" : ""}
                            ref={ref2}
                            onChange={(event) => {
                                const car = isEmail ? "@" : "";
                                setValueInput2(event.target.value);
                                if (event.target.value.length >= maxLength && ref3.current && isPhone) {
                                    ref3.current.focus();
                                }
                                if (onChange) {
                                    onChange({
                                        ...event,
                                        target: {
                                            ...event.target,
                                            value: valueInput1 + car + event.target.value,
                                        },
                                    });
                                }
                                if (onValueChange) onValueChange(valueInput1 + car + event.target.value);
                            }}
                            disabled={disable}
                            maxLength={maxLength}
                        />
                        {isPhone && (
                            <input
                                type={"number"}
                                {...props}
                                className={clsx(
                                    styles.input,
                                    {
                                        [styles.input_error]: error,
                                        [styles.postalCodeOrPhone]: isPhone,
                                    },
                                    className,
                                )}
                                value={valueInput3}
                                ref={ref3}
                                onChange={(event) => {
                                    setValueInput3(event.target.value);
                                    if (onChange) {
                                        onChange({
                                            ...event,
                                            target: {
                                                ...event.target,
                                                value: valueInput1 + valueInput2 + event.target.value,
                                            },
                                        });
                                    }
                                    if (onValueChange) onValueChange(valueInput1 + event.target.value);
                                }}
                                disabled={disable}
                                maxLength={maxPhoneLengthLastField}
                            />
                        )}
                    </div>
                </div>
                {/* eslint-disable-next-line react/prop-types */}
                {error && error.message && <div className={styles.error}>{error.message}</div>}
            </div>
        );
    },
);
