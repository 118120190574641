import React from "react";
import { observer } from "mobx-react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import clsx from "clsx";
import styles from "properties/_css/propertyToFavorite.module.css";
import { IconComponent } from "components/base/icon/IconComponent";
import { IconButton } from "@material-ui/core";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { useTranslation } from "react-i18next";
import { TPropertyCardMdl, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { useFavoriteToggle } from "users/profile/useFavoriteToggle";

type Props = {
    item: TPropertyListingMdl | TPropertyCardMdl | TUnitListingMdl;
    type: MAIN_RESOURCE;
    isTopBar?: boolean;
    button?: boolean;
    textBtn?: string;
    withoutText?: boolean;
    colors?: "primary";
};

export const ToggleToFavoriteBtn = observer((props: Props) => {
    const { t } = useTranslation();
    const { isFavorite, toggleFavorite } = useFavoriteToggle(props.type, props.item);
    return (
        <>
            {props.button ? (
                <UiButton
                    className={clsx(styles.favoriteButton, "mr_10", {
                        [styles.favoriteTopBar]: props.isTopBar,
                    })}
                    variant={"contained"}
                    onClick={(e) => {
                        e.preventDefault();
                        void toggleFavorite();
                    }}
                    aria-label="Icon favorite"
                    aria-labelledby="Icon favorite"
                >
                    <IconComponent
                        aria-label="Icon favorite"
                        title="Icon favorite"
                        aria-labelledby="Icon favorite"
                        icon={isFavorite ? "favorite" : "favorite_border"}
                    />
                    {!props.withoutText && <span className={"ml_5"}>{props.textBtn ?? t("words.save")}</span>}
                </UiButton>
            ) : (
                <IconButton
                    className={clsx("p_5 favoriteProperty", styles.iconButtonFavorite, {
                        [styles.primaryColors]: !!props.colors,
                    })}
                    onClick={(e) => {
                        e.preventDefault();
                        void toggleFavorite();
                    }}
                    aria-label="Icon favorite"
                    aria-labelledby="Icon favorite"
                >
                    <IconComponent
                        title="Icon favorite"
                        aria-label="Icon favorite"
                        aria-labelledby="Icon favorite"
                        icon={isFavorite ? "favorite" : "favorite_border"}
                    />
                </IconButton>
            )}
        </>
    );
});
