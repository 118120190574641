import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import style from "./_css/budgetSelect.module.css";
import { useTranslation } from "react-i18next";

type Props = {
    options: { min: { value: string; label: string }[]; max: { value: string; label: string }[] };
    handleMinChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    handleMaxChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    minValue: number;
    maxValue: number;
    minLabel: string;
    maxLabel: string;
};

export const RangeSelect = ({
    handleMinChange,
    handleMaxChange,
    minValue,
    maxValue,
    minLabel,
    maxLabel,
    options,
}: Props) => {
    const { t } = useTranslation();

    const filteredMaxOptions = options.max.filter(
        (option) => !minValue || !option.value || parseInt(option.value) >= parseInt(String(minValue))
    );

    const filteredMinOptions = options.min.filter(
        (option) => !maxValue || !option.value || parseInt(option.value) <= parseInt(String(maxValue))
    );

    return (
        <div className={style.container}>
            <FormControl variant="outlined" className={style.budgetSelect}>
                <InputLabel className={style.label} id="min-label">
                    {t(minLabel)}
                </InputLabel>
                <Select
                    labelId="min-label"
                    value={minValue}
                    onChange={handleMinChange}
                    label={`${t(minLabel)}`}
                >
                    {filteredMinOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className={style.budgetDivider}> - </div>
            <FormControl variant="outlined" className={style.budgetSelect}>
                <InputLabel className={style.label} id="max-label">
                    {t(maxLabel)}
                </InputLabel>
                <Select labelId="max-label" value={maxValue} onChange={handleMaxChange} label={`${t(maxLabel)}`}>
                    {filteredMaxOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};
