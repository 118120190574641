import React from "react";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { PropertyCard } from "properties/PropertyCard";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { ListStore } from "_common/list/ListStore";
import clsx from "clsx";
import styles from "properties/_css/propertiesViewList.module.css";
import { Paginator } from "admin/_common/list/Paginator";
import { DEFAULT_PAGE_SIZE } from "_common/resources/BaseResourceStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { GridPropertyCard } from "properties/GridPropertyCard";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
    propertyIdToHide?: string;
};
export const PropertiesList = observer(({ listStore, propertyIdToHide }: Props) => {
    const paginationIsDisplay = !!listStore.count && listStore?.count >= DEFAULT_PAGE_SIZE;
    let paginatedItems = listStore.paginatedItems;

    if (propertyIdToHide) {
        paginatedItems = listStore.paginatedItems.filter((property) => property?._id !== propertyIdToHide);
    }

    return (
        <>
            <Grid alignItems="center" container spacing={4}>
                {paginatedItems.map((property) => {
                    if (!property) return null;
                    return (
                        <>
                            <GridPropertyCard key={property._id}>
                                <PropertyCard
                                    property={property}
                                    onMouseOver={() => {
                                        propertiesStore.setMapPropertiesSelected(property);
                                    }}
                                    onMouseLeave={() => {
                                        propertiesStore.setMapPropertiesSelected.cancel();
                                    }}
                                />
                            </GridPropertyCard>
                        </>
                    );
                })}
            </Grid>
            {paginationIsDisplay && (
                <div className={clsx("flex_center_center pt_40 pb_10 mb_40", styles.paginator)}>
                    <Paginator
                        count={listStore.count}
                        pageSize={listStore.pageSize}
                        selectedPage={listStore.selectedPage}
                        onPageClick={(page) => {
                            listStore.setSelectedPage(page);
                        }}
                        className={styles.listStorePaginator}
                    />
                </div>
            )}
        </>
    );
});
