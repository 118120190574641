import React, { useState } from "react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./_css/propertyOrUnitGalleryImageModal.module.css";
import { observer } from "mobx-react";
import { PropertyStore } from "properties/_stores/PropertyStore";
import { UnitStore } from "units/_stores/UnitStore";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import clsx from "clsx";
import PropertyOrUnitCta from "properties/propertyPage/PropertyOrUnitCta";
import { Container } from "@material-ui/core";

SwiperCore.use([Navigation, Pagination]);

type Props = {
    store: PropertyStore | UnitStore;
    resource: MAIN_RESOURCE;
};

export const PropertyOrUnitGalleryImageModal = observer((props: Props) => {
    const [ctaIsOpened, setCtaIsOpened] = useState(false);
    const { t } = useTranslation();
    const store =
        props.resource === MAIN_RESOURCE.PROPERTIES ? (props.store as PropertyStore) : (props.store as UnitStore);

    const item =
        props.resource === MAIN_RESOURCE.PROPERTIES
            ? (props.store as PropertyStore).property[store.typeOfImage]
            : (props.store as UnitStore).unit[store.typeOfImage];

    return (
        <Dialog
            open={store.imageModalIsOpen > -1}
            onClose={() => store.closeImageModal()}
            fullScreen
            fullWidth
            closeCross
            isTransparentBg
            closeSize={"large"}
            className={styles.dialog}
            id={"propertyImagesId"}
        >
            <Container>
                <UiButton
                    className={clsx(styles.btn, "mb_10")}
                    color={"secondary"}
                    variant={"contained"}
                    onClick={() => setCtaIsOpened(!ctaIsOpened)}
                >
                    <div dangerouslySetInnerHTML={{ __html: t("propertyPage.cta.request") }} />
                </UiButton>
            </Container>
            <div id={"propertyImages"} className={styles.contentSwiper}>
                <Swiper
                    pagination
                    navigation
                    slidesPerView={1}
                    initialSlide={store.imageModalIsOpen}
                    className={styles.swiperContainer}
                    loop
                >
                    {item.map((image) => {
                        return (
                            <SwiperSlide key={image.url}>
                                <LazyLoadImage src={image.url} alt={image.alt} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
            <Dialog id={"ctaGalleryPropertyPage"} open={ctaIsOpened} onClose={() => setCtaIsOpened(false)}>
                <PropertyOrUnitCta />
            </Dialog>
        </Dialog>
    );
});
