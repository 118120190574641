import React, { ReactNode } from "react";
import styles from "./_css/footerLinksSocial.module.css";
import { ISettingsMdl } from "settings/_models/SettingsMdl";
import { IconComponent } from "components/base/icon/IconComponent";
import { settingsStore } from "settings/_stores/settingsStore";
import clsx from "clsx";

const LINKS: { key: keyof ISettingsMdl["links"]; label: string; icon: ReactNode }[] = [
    { key: "fb", label: "Facebook", icon: <IconComponent className={"mr_15"} icon={"icon-facebook1"} /> },
    { key: "tw", label: "Twitter", icon: <IconComponent className={"mr_15"} icon={"icon-twitter1"} /> },
    { key: "ig", label: "Instagram", icon: <IconComponent className={"mr_15"} icon={"icon-instagram1"} /> },
    { key: "yt", label: "YouTube", icon: <IconComponent className={"mr_15"} icon={"icon-youtube1"} /> },
    { key: "li", label: "LinkedIn", icon: <IconComponent className={"mr_15"} icon={"icon-linkedin2"} /> },
];

export function FooterLinksSocial() {
    const settings = settingsStore.getOne();
    if (!settings) return null;
    const links = settings.links;

    return (
        <div className={clsx(styles.container)}>
            {LINKS.map((link) => {
                const settingsLink = links[link.key];
                if (!settingsLink) return null;
                return (
                    <div key={link.label} className={"mt_10"}>
                        <a
                            className={clsx(styles.link, "fontWeight_semi text_small")}
                            key={link.key}
                            href={settingsLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {link.icon}
                            {link.label}
                        </a>
                    </div>
                );
            })}
        </div>
    );
}
