import React from "react";
import { panelStore } from "main/header/panel/_stores/panelStore";
import { IconComponent } from "components/base/icon/IconComponent";
import IconButton from "@material-ui/core/IconButton";
import { useMenuContext } from "main/header/_menu/MenuContext";
import { SubMenuMobile } from "main/header/_menu/SubMenuMobile";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { useTranslation } from "react-i18next";
import styles from "./_css/menuMobile.module.css";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { URLS } from "_configs/URLS";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import clsx from "clsx";
import { userStore } from "users/_stores/userStore";
import { useHistory } from "react-router-dom";

function MenuMobileContainer() {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const lang = i18n.language;

    const {
        currentMenu,
        setCurrentMenu,
        residentialMenu,
        citiesMobileMenu,
        commercialMenu,
        userMobileMenu,
    } = useMenuContext();

    return (
        <nav className={styles.container}>
            <ul>
                <li>
                    <div
                        className={"flex flex_row_center flex-1"}
                        onClick={() => {
                            if (currentMenu === "residential") setCurrentMenu(undefined);
                            else setCurrentMenu("residential");
                        }}
                    >
                        <ExternalOrNavLink url={"#"}>{t("menu.residential.title")}</ExternalOrNavLink>
                        <div className={"flex-1"} />
                        <IconButton aria-label="close" size={"small"}>
                            {currentMenu !== "residential" && <KeyboardArrowDown color={"primary"} />}
                            {currentMenu === "residential" && <KeyboardArrowUp color={"primary"} />}
                        </IconButton>
                    </div>
                    <SubMenuMobile columns={residentialMenu} hidden={currentMenu !== "residential"} />
                </li>
                <li>
                    <div
                        className={"flex flex_row_center flex-1"}
                        onClick={() => {
                            if (currentMenu === "commercial") setCurrentMenu(undefined);
                            else setCurrentMenu("commercial");
                        }}
                    >
                        <ExternalOrNavLink url={"#"}>{t("menu.commercial.title")}</ExternalOrNavLink>
                        <div className={"flex-1"} />
                        <IconButton aria-label="close" size={"small"}>
                            {currentMenu !== "commercial" && <KeyboardArrowDown color={"primary"} />}
                            {currentMenu === "commercial" && <KeyboardArrowUp color={"primary"} />}
                        </IconButton>
                    </div>
                    <SubMenuMobile columns={commercialMenu} hidden={currentMenu !== "commercial"} />
                </li>
                <li>
                    <div
                        className={"flex flex_row_center flex-1"}
                        onClick={() => {
                            if (currentMenu === "cities") setCurrentMenu(undefined);
                            else setCurrentMenu("cities");
                        }}
                    >
                        <ExternalOrNavLink url={"#"}>{t("menu.cities")}</ExternalOrNavLink>
                        <div className={"flex-1"} />
                        <IconButton aria-label="close" size={"small"}>
                            {currentMenu !== "cities" && <KeyboardArrowDown color={"primary"} />}
                            {currentMenu === "cities" && <KeyboardArrowUp color={"primary"} />}
                        </IconButton>
                    </div>
                    <SubMenuMobile columns={citiesMobileMenu} hidden={currentMenu !== "cities"} />
                </li>
                {(lang === "en" || lang === "fr") && (
                    <li>
                        <ExternalOrNavLink url={URLS.virtualAssistant()} onClick={() => panelStore.close()}>
                            {t("menu.virtualAssistant.title")}
                        </ExternalOrNavLink>
                    </li>
                )}
                <li>
                    <ExternalOrNavLink url={URLS.advertise()} onClick={() => panelStore.close()}>
                        {t("menu.promotion")}
                    </ExternalOrNavLink>
                </li>
                <li>
                    <div
                        className={"flex flex_row_center flex-1"}
                        onClick={() => {
                            if (currentMenu === "user") setCurrentMenu(undefined);
                            else setCurrentMenu("user");
                        }}
                    >
                        <ExternalOrNavLink url={"#"}>{t("menu.profile")}</ExternalOrNavLink>
                        <div className={"flex-1"} />
                        <IconButton aria-label="close" size={"small"}>
                            {currentMenu !== "user" && <KeyboardArrowDown color={"primary"} />}
                            {currentMenu === "user" && <KeyboardArrowUp color={"primary"} />}
                        </IconButton>
                    </div>
                    <SubMenuMobile columns={userMobileMenu} hidden={currentMenu !== "user"} />
                </li>
            </ul>
            {!userStore.isLogged && (
                <div className={clsx(styles.buttonsBottom, "flex_row justifyContent_spaceBetween gap_40 ph_20")}>
                    <UiButton
                        className={clsx(styles.button)}
                        onClick={() => {
                            history.push(URLS.auth.signIn());
                            panelStore.close();
                        }}
                        color={"primary"}
                        variant={"outlined"}
                    >
                        {t("menu.signIn")}
                    </UiButton>
                    <UiButton
                        className={styles.button}
                        onClick={() => {
                            history.push(URLS.auth.signUp());
                            panelStore.close();
                        }}
                        color={"primary"}
                        variant={"contained"}
                    >
                        {t("menu.signUp")}
                    </UiButton>
                </div>
            )}
        </nav>
    );
}

export function MenuMobile() {
    const { setCurrentMenu } = useMenuContext();

    return (
        <IconButton
            color={"primary"}
            aria-label="Menu mobile"
            aria-labelledby="Menu mobile"
            title="Menu mobile"
            onClick={() =>
                panelStore.open({
                    logo: true,
                    noPadding: true,
                    children: <MenuMobileContainer />,
                    onClose: () => {
                        panelStore.close();
                        setCurrentMenu(undefined);
                    },
                    withSpinner: false,
                    opened: true,
                })
            }
        >
            <IconComponent icon={"menu"} fontSize={"default"} />
        </IconButton>
    );
}
