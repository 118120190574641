import React, { useEffect } from "react";
import { TPropertyMdl } from "properties/_models/PropertyMdl";
import { FilePicker } from "projects/component/FilePicker";
import { RESOURCE_KEY } from "admin/_common/photos/AdminPhotosStore";
import { FilePreviewFormProjectPage } from "projects/component/FilePreviewFormProjectPage";
import styles from "./_css/filePickerFormProjectPage.module.css";
import { useFormContext } from "react-hook-form";
import { PropertyFileStore } from "properties/_stores/PropertyFilesStore";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

type Props = {
    value: [];
    store: PropertyFileStore;
    resource: RESOURCE_KEY;
    property: TPropertyMdl;
    setProperty: (property: TPropertyMdl) => void;
};

export const ProjectPropertyFilesPicker = observer((props: Props) => {
    const form = useFormContext();
    const { t } = useTranslation();

    // function handleSubmit(item: TPropertyMdl) {
    //     if (!props.property._id) {
    //         item = reformatLocalizedPropertyToProperty(item, props.property);
    //         item = {
    //             ...item,
    //             price: {
    //                 min: item.price.min * 100,
    //                 max: item.price?.max ? item.price?.max * 100 : 0,
    //             },
    //             type: item?.type ? item?.type : PROPERTY_TYPE.condo,
    //             subType: item?.subType ? item?.subType : PROPERTY_SUBTYPE.duplex,
    //         };
    //         propertiesStore.create(item).then((savedItem) => {
    //             savedItem && props.setProperty(savedItem);
    //         });
    //     }
    // }

    useEffect(() => {
        props.setProperty(props.store.item);
    }, [props.store.item]);

    return (
        <div>
            <div className={"flex_row_center"}>
                <div className={styles.textDownloadFiles}>{t("projectPage.textDownloadFileFloorPlan")}</div>
                {/*<div onClick={form.handleSubmit(async (item: TPropertyMdl) => handleSubmit(item))}>*/}
                <FilePicker
                    store={props.store}
                    lastSavedItem={props.property}
                    onChange={(files) => {
                        form.setValue(props.resource, files);
                        props.store.addPlans(files, props.resource);
                    }}
                    value={[]}
                />
                {/*</div>*/}
            </div>
            <div className="flex_row mv_40">
                {props.store.item[props.resource].map((file, index) => {
                    return (
                        <FilePreviewFormProjectPage
                            key={index}
                            url={file.url}
                            file={file}
                            onDelete={() => {
                                props.store.deletePlan(index, props.resource);
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
});
