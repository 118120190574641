import React from "react";
import styles from "main/header/_css/cartContent.module.css";
import PrimaryButton from "_common/ui/mui/buttons/PrimaryButton";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import { cartStore } from "Cart/_stores/cartStore";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { formatPrice } from "_common/_utils/currencyUtils";
import sharedConfig from "_configs/sharedConfig";

export const CartContent = observer(() => {
    const { t } = useTranslation();
    return (
        <div className={styles.carteMenuContent}>
            <div>
                {cartStore.cart.map((line, index) => {
                    return (
                        <div className={styles.cartProductLine} key={line.productId + index}>
                            <div className={styles.cartProductLineText}>
                                <p>{line.name}</p>
                                <p>
                                    {line.productId === sharedConfig.stripe.products.INTEGRATION_CRM ? (
                                        <div>{t("projectPage.product.options.dependOfCrm")}</div>
                                    ) : (
                                        formatPrice(line.price, false, true)
                                    )}
                                </p>
                            </div>
                            <div className={styles.cartProductLineNumber}>{line.quantity.toString()}</div>
                        </div>
                    );
                })}
            </div>
            <div className={styles.separator} />
            <div className={styles.cartTotal}>
                <p>Total</p>
                <p>{formatPrice(cartStore.total, false, true)}</p>
            </div>
            <div className={"flex_center_center"}>
                <Link className={styles.link} to={URLS.resume()}>
                    <PrimaryButton textClassname={styles.button}>{t("paymentPage.seeCart")}</PrimaryButton>
                </Link>
            </div>
        </div>
    );
});
