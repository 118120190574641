import React from "react";
import styles from "./_css/panel.module.css";
import clsx from "clsx";
import { IconButton } from "@material-ui/core";
import { panelStore, TPanelOption } from "main/header/panel/_stores/panelStore";
import { IconComponent } from "components/base/icon/IconComponent";
import { Logo } from "_common/icons/Logo";

type Props = {
    option?: TPanelOption;
};

export function Panel(props: Props) {
    return (
        <>
            {props.option?.opened && <div className={styles.overlay} onClick={() => panelStore.close()} />}
            <div
                {...props}
                className={clsx(styles.container, {
                    [styles.opened]: props.option?.opened,
                    [styles.fullWidthContainer]: props.option?.full,
                })}
            >
                <div className={clsx("flex flex_row_center", styles.header)}>
                    {props.option?.logo ? (
                        <div className={styles.logo}>
                            <Logo width={120} />
                        </div>
                    ) : (
                        <div className={clsx(styles.title, "flex-1")}>{props.option?.title}</div>
                    )}
                    {props.option?.logo && <div className="flex-1" />}
                    <div className={styles.closeIcon}>
                        <IconButton aria-label="close" size={"small"} onClick={() => panelStore.close()}>
                            <IconComponent color={"primary"} icon={"close"} />
                        </IconButton>
                    </div>
                </div>
                <div
                    className={clsx(styles.children, {
                        ["p_0"]: props.option?.noPadding,
                    })}
                >
                    {props.option?.children}
                </div>
            </div>
        </>
    );
}
