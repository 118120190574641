import {
    STRIPE_OPTIONS_PRICES_KEY,
    STRIPE_PRODUCTS_KEY,
    STRIPE_PRODUCTS_PRICES_KEY,
} from "_configs/stripeProductConfig";

const isDev = process.env.NODE_ENV === "development";
const isStaging = process.env.IS_STAGING === "true";
const isProd = process.env.NODE_ENV === "production" && !isStaging;

const languages = {
    en: {
        lang: "en",
        baseUrl: `${process.env.APP_URL}/en`,
        basePath: `/en`,
        title: "English",
        extlang: "en",
    },
    fr: {
        lang: "fr",
        baseUrl: `${process.env.APP_URL}/fr`,
        basePath: `/fr`,
        title: "Français",
        extlang: "fr_FR",
    },
    es: {
        lang: "es",
        baseUrl: `${process.env.APP_URL}/es`,
        basePath: `/es`,
        title: "Español",
        extlang: "es",
    },
    zh: {
        lang: "zh",
        baseUrl: `${process.env.APP_URL}/zh`,
        basePath: `/zh`,
        title: "中文",
        extlang: "zh-CN",
    },
};

export type TLang = keyof typeof languages;

const sharedConfig = {
    env: process.env.NODE_ENV,
    isDev,
    isStaging,
    isProd,

    isInMaintenance: false,

    emails: {
        sales: {
            labelKey: "emails.sales.name",
            valueKey: "emails.sales.email",
        },
        info: {
            labelKey: "emails.info.name",
            valueKey: "emails.info.email",
        },
    },

    adminId: "609abcc5b22c1d79de53944c",

    contact: {
        info: {
            name: "Vistoo Real Estate",
            email: isProd ? "info@vistoo.com" : "contact@lesentrecodeurs.com",
        },
        sales: {
            name: "Vistoo Sales",
            email: isProd ? "sales@vistoo.com" : "contact@lesentrecodeurs.com",
        },
        direction: {
            name: "Vistoo Direction",
            email: isProd ? "sdarras@vistoo.com" : "contact@lesentrecodeurs.com",
        },
        phone: "(438) 389-8981",
    },

    defaultLang: "en" as TLang, // langue par défaut, utilisé si des textes ne sont pas présent dans une autre langue
    languages,

    apiUrl: process.env.API_URL as string,
    appUrl: process.env.APP_URL as string,

    sentry: {
        dsn: "https://1ef730f2356d45214545d6386ddc99f3@sentry.lesentrecodeurs.com/2",
    },

    tinyMce: {
        apiKey: "gn8kbtowzz77r73lsqw4jqgqiiph42qor99w96rwjab4dja1",
    },

    uploadsBaseUrl: "/static/uploads/vistoo",

    localLogic: {
        token: "V3 8xOuzlI0bHDGcvZKnC0iNsLM1My4dX2h.6d84843e-c2ac-47d7-ada4-01499909b08f",
        oldToken: "V3 fJThgHrWKzWZXfqwvpGdlx3d8ujnxmT3.1de51772-d87b-443e-bb8b-e98c3ef155b8",
        tokenDemographic: "8xOuzlI0bHDGcvZKnC0iNsLM1My4dX2h",
        urlDemographic: "https://api.locallogic.co/v2/demographics",
    },

    nesto: {
        apiKey: "m3vmp84wCEGUH39R8xMhKmBEDNBMPuymktMAY3BGw3MT",
        partner: "vistoo",
    },

    stripe: {
        options: isProd ? STRIPE_OPTIONS_PRICES_KEY.prod : STRIPE_OPTIONS_PRICES_KEY.test,
        products: isProd ? STRIPE_PRODUCTS_KEY.prod : STRIPE_PRODUCTS_KEY.test,
        priceOfProducts: isProd ? STRIPE_PRODUCTS_PRICES_KEY.prod : STRIPE_PRODUCTS_PRICES_KEY.test,
    },

    utm: {
        queryString: "?utm_medium=referral&utm_source=Vistoo&utm_campaign=04131000",
        queryStringScrap: "?utm_medium=referral&utm_campaign=05131000",
    },
    blogRedirect: {
        id: "628473abae73a057ff457653",
        url: "https://vistoo.com/zh/first-time-home-buyer-incentive.html",
    },
    planPoint: {
        dataToken:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImFwcGxpZWRUcmlhbCI6ZmFsc2UsInByb2plY3RzIjpbXSwiX2lkIjoiNWY1MDBlNjhlZTRiMDUwMDE3ZTU0YjE2IiwidXNlcm5hbWUiOiJzdXBwb3J0QHBsYW5wb2ludC5pbyIsInBhc3N3b3JkIjoiJDJhJDEwJEE3b05yclhxalp4UmsyeHBUdW16c09PamR2dUdFWXVSVTNxR2h3WWFPSXlIbFJ6TlhSYlB1Iiwic3RyaXBlQ3VzdG9tZXIiOnsiaWQiOiJjdXNfSHd4UEx1YVJFeWJHZjEiLCJvYmplY3QiOiJjdXN0b21lciIsImFkZHJlc3MiOm51bGwsImJhbGFuY2UiOjAsImNyZWF0ZWQiOjE1OTkwODIwODgsImN1cnJlbmN5IjpudWxsLCJkZWZhdWx0X3NvdXJjZSI6bnVsbCwiZGVsaW5xdWVudCI6ZmFsc2UsImRlc2NyaXB0aW9uIjpudWxsLCJkaXNjb3VudCI6bnVsbCwiZW1haWwiOiJzdXBwb3J0QHBsYW5wb2ludC5pbyIsImludm9pY2VfcHJlZml4IjoiOTZBNEYzNzEiLCJpbnZvaWNlX3NldHRpbmdzIjp7ImN1c3RvbV9maWVsZHMiOm51bGwsImRlZmF1bHRfcGF5bWVudF9tZXRob2QiOm51bGwsImZvb3RlciI6bnVsbH0sImxpdmVtb2RlIjp0cnVlLCJuYW1lIjpudWxsLCJuZXh0X2ludm9pY2Vfc2VxdWVuY2UiOjEsInBob25lIjpudWxsLCJwcmVmZXJyZWRfbG9jYWxlcyI6W10sInNoaXBwaW5nIjpudWxsLCJzb3VyY2VzIjp7Im9iamVjdCI6Imxpc3QiLCJkYXRhIjpbXSwiaGFzX21vcmUiOmZhbHNlLCJ0b3RhbF9jb3VudCI6MCwidXJsIjoiL3YxL2N1c3RvbWVycy9jdXNfSHd4UEx1YVJFeWJHZjEvc291cmNlcyJ9LCJzdWJzY3JpcHRpb25zIjp7Im9iamVjdCI6Imxpc3QiLCJkYXRhIjpbXSwiaGFzX21vcmUiOmZhbHNlLCJ0b3RhbF9jb3VudCI6MCwidXJsIjoiL3YxL2N1c3RvbWVycy9jdXNfSHd4UEx1YVJFeWJHZjEvc3Vic2NyaXB0aW9ucyJ9LCJ0YXhfZXhlbXB0Ijoibm9uZSIsInRheF9pZHMiOnsib2JqZWN0IjoibGlzdCIsImRhdGEiOltdLCJoYXNfbW9yZSI6ZmFsc2UsInRvdGFsX2NvdW50IjowLCJ1cmwiOiIvdjEvY3VzdG9tZXJzL2N1c19Id3hQTHVhUkV5YkdmMS90YXhfaWRzIn19LCJjcmVhdGVkQXQiOiIyMDIwLTA5LTAyVDIxOjI4OjA4LjQyNloiLCJ1cGRhdGVkQXQiOiIyMDIwLTA5LTAyVDIxOjI4OjA4LjQyNloiLCJfX3YiOjB9LCJpYXQiOjE1OTkwODIwODh9.zKKD8U-3oZy8GxucWjrW4tOqkRuYeUb9i-_zrW3p8OQ",
    },
    quickBooks: {
        clientId: process.env.QB_CLIENT_ID,
    },
    reCaptcha: {
        url: "https://www.google.com/recaptcha/api.js",
        siteKey: isDev ? "6Lei_6koAAAAABujNy-fNUoQ4bDvQAos5vuaZdE3" : "6LfE3KooAAAAAMLlAFiXG04tJm2tvNFvCORmKP30",
    },
    assistantVirtual: {
        enable: true,
    },
};

export default sharedConfig;
