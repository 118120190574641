import React from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import styles from "./_css/propertyPlans.module.css";
import clsx from "clsx";
import { LazyLoadImage } from "react-lazy-load-image-component";

export function PropertyPlans() {
    const propertyStore = usePropertyStore();
    if (!propertyStore.property.floorPlans) return null;
    return (
        <div className={styles.container}>
            <div className={clsx(styles.gallery, "flex_row flexWrap_wrap justifyContent_center")}>
                {propertyStore.property.floorPlans.map((photo, index) => {
                    return (
                        <div key={index} className={clsx(styles.galleryImage)}>
                            <div>
                                <LazyLoadImage
                                    onClick={() => propertyStore.setOpenedImageModal("floorPlans", index)}
                                    className={styles.image}
                                    src={photo.url}
                                    alt={`${propertyStore.property.localized.title}-${index}`}
                                    title={`${propertyStore.property.localized.title} photo ${index}`}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
