import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "_common/ui/forms/Input";
import styles from "main/footer/_css/footerSubscribe.module.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { notifierStore } from "components/misc/contactUs/_stores/notifierStore";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { observer } from "mobx-react";
import { ButtonNotification } from "components/misc/notifications/ButtonNotificationProps";
import { CheckboxAgreeFooter } from "main/footer/CheckBoxAgreeFooter";
import clsx from "clsx";

export type TContactFooterForm = {
    firstName: string;
    lastname?: string;
    email: string;
};

export const FooterSubscribe = observer(() => {
    const { t } = useTranslation();
    const [hasAgreedNotification, setHasAgreedNotification] = useState(false);
    const form = useForm<TContactFooterForm>();
    const { loading, setPromise } = useLoadingFromPromise();
    const isLoading = loading?.status === "LOADING";

    return (
        <div className={styles.container}>
            <div className={clsx(styles.title, "mb_20")}>{t("footer.subscribeTitle")}</div>
            <div className={"text_xsmall fontWeight_semi"}>{t("footer.subscribeText")}</div>
            <div className={"mv_20"}>
                <FormProvider {...form}>
                    <InputBlock
                        className={"mb_15 fontWeight_semi text_xsmall"}
                        label={t("footer.mailLabel")}
                        labelClassName={"ml_5"}
                    >
                        <Controller
                            className={styles.input}
                            as={Input}
                            name={"email"}
                            control={form.control}
                            defaultValue={""}
                            aria-label={t("footer.mailLabel")}
                        />
                    </InputBlock>
                    <InputBlock
                        className={"mb_15 fontWeight_semi text_xsmall"}
                        label={t("footer.nameLabel")}
                        labelClassName={"ml_5"}
                    >
                        <Controller
                            className={styles.input}
                            as={Input}
                            name={"firstName"}
                            control={form.control}
                            defaultValue={""}
                            aria-label={t("footer.nameLabel")}
                        />
                    </InputBlock>
                    <CheckboxAgreeFooter checked={hasAgreedNotification} onChange={setHasAgreedNotification} />
                    <ButtonNotification
                        variant={"contained"}
                        color={"primary"}
                        className={styles.btn}
                        onClick={form.handleSubmit<TContactFooterForm>((data) => {
                            if (!isLoading) {
                                setPromise(notifierStore.contactFromFooter(data));
                            }
                        })}
                    >
                        {isLoading ? t("loaders.message") : t("footer.subscribe")}
                    </ButtonNotification>
                </FormProvider>
            </div>
        </div>
    );
});
