import React from "react";
import { Loader } from "_common/loaders/Loader";
import { observer } from "mobx-react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { settingsStore } from "settings/_stores/settingsStore";

export const FullPageLoaderObserver = observer(() => {
    return (
        <Dialog
            fullWidth
            fullScreen
            disableEscapeKeyDown
            disableBackdropClick
            open={settingsStore.isFullPageLoaderOpened}
            onClose={() => settingsStore.toggleFullPageLoader()}
        >
            <Loader />
        </Dialog>
    );
});
