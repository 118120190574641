import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import styles from "./_css/productHead.module.css";

type Props = {
    name: string;
    price: number;
    isAnnual?: boolean;
};

export function ProductHead(props: Props) {
    const { t } = useTranslation();

    return (
        <div className={clsx("flex_column_center", styles.container)}>
            <div className={clsx(styles.title, "mb_10")}>{t("paymentPage.products.names." + props.name)}</div>
            <div className={styles.lineYellow} />
            <div className={clsx(styles.priceSection, "flex_row")}>
                <span className={styles.dollar}>$</span>
                {props.isAnnual ? (
                    <div>
                        <div>
                            <span className={styles.price}>{(props.price * 0.9).toFixed(0)}</span>
                            <span className={styles.month}>{t("propertiesPage.card.perMonth")}</span>
                        </div>
                        <div className={clsx(styles.text, "textAlign_center")}>
                            {t("projectPage.product.card.head.billedAt")}
                            <span className={"text_over-lined mh_5"}>${(props.price * 12).toFixed(0)}</span>
                            <span className={"mr_5"}>${(props.price * 12 * 0.9).toFixed(0)}</span>
                            <span>{t("propertiesPage.card.perYear")}</span>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div>
                            <span className={styles.price}>{props.price}</span>
                            <span className={styles.month}>{t("propertiesPage.card.perMonth")}</span>
                        </div>

                        <div className={clsx("textAlign_center", styles.text)}>
                            {t("projectPage.product.card.head.monthly")}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
