import React from "react";
import { useTranslation } from "react-i18next";
import { settingsStore } from "settings/_stores/settingsStore";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import styles from "./_css/filterButtons.module.css";
import clsx from "clsx";

interface Props {
    onReset: () => void;
    onApply: () => void;
    resetLabel?: string;
    applyLabel?: string;
    className?: string;
}

export function FilterButtons(props: Props) {
    const { t } = useTranslation();

    return (
        <div className={clsx("flex_row_center justifyContent_spaceBetween", props.className, styles.container)}>
            <div className={"text_small cursor_pointer"} onClick={() => props.onReset()}>
                {props.resetLabel ?? t("searchBar.reset")}
            </div>
            <UiButton
                variant={"contained"}
                color={"primary"}
                className={styles.button}
                onClick={(e) => {
                    e.stopPropagation();
                    props.onApply();
                    settingsStore.close();
                }}
            >
                {props.applyLabel ?? t("searchBar.seeResults")}
            </UiButton>
        </div>
    );
}
