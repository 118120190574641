import React from "react";
import { SelectInputFront } from "payments/_components/SelectInputFront";
import styles from "./_css/optionsSection.module.css";
import clsx from "clsx";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import imgBackground from "../assets/images/promotePage/commercialPictureCompressed.webp";
import { useTranslation } from "react-i18next";
import paymentStore from "payments/_stores/paymentStore";

export function OptionsSection() {
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.container, "position_relative")}>
            <div className={clsx(styles.selectContainer)}>
                <LoadableFromLoading
                    loading={paymentStore.productState}
                    renderer={(_status, error) => {
                        if (error) return null;
                        return (
                            <SelectInputFront
                                label={t("paymentPage.products.increaseVisibility")}
                                choices={paymentStore.options}
                            />
                        );
                    }}
                />
            </div>
            <div className={styles.contentImg}>
                <img src={imgBackground} alt={"Image illustration"} className={styles.imgMobile} />
            </div>
        </div>
    );
}
