import sharedConfig from "_configs/sharedConfig";

export const appConfig = {
    token: {
        storageKey: "TOKEN",
        trialToken: "TrialToken",
    },

    square: {
        appId: process.env.SQUARE_APP_ID as string,
        locationId: process.env.SQUARE_LOCATION_ID as string,
    },
    hotjar: {
        id: "2673144",
    },
    googleGtm: {
        gtmId: "GTM-KZBKKVT",
        dataLayerName: "PageDataLayer",
    },
    google: {
        analytics: "271450311",
        apiKey: "AIzaSyCzusE0LFaOjCE_dEka-z1ZVzoIEu3Nf-k",
    },
    googleAuth: {
        apiKey:
            sharedConfig.isProd && process.env.GOOGLE_AUTH_KEY
                ? process.env.GOOGLE_AUTH_KEY
                : "344950018713-100cgq2oqonon4gvtoi6162sj90g3v0j.apps.googleusercontent.com",
    },
    facebook: {
        appId: (process.env.FACEBOOK_ID as string) ?? "518688512502557",
    },
};
