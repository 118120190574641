import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import { CookiePage } from "pages/CookiePage";

type Props = {
    children: ReactNode;
};

export const PageTracker = observer((props: Props) => {
    return (
        <>
            {props.children}
            <CookiePage />
        </>
    );
});
