import React from "react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import clsx from "clsx";
import styles from "properties/searchBar/_css/savedSearchButton.module.css";
import { List, MapOutlined } from "@material-ui/icons";
import { MAP_OR_LIST_SELECTED, usePropertiesPageContext } from "properties/PropertiesPageContext";
import { useTranslation } from "react-i18next";

export const PropertiesViewButtonMapList = () => {
    const { mapOrListSelected, setMapOrListSelected } = usePropertiesPageContext();
    const { t } = useTranslation();

    return (
        <UiButton
            onClick={() =>
                setMapOrListSelected(
                    mapOrListSelected === MAP_OR_LIST_SELECTED.MAP
                        ? MAP_OR_LIST_SELECTED.LIST
                        : MAP_OR_LIST_SELECTED.MAP,
                )
            }
            className={clsx(styles.button, styles.resetButton, "mh_5")}
            color={"primary"}
            variant={"outlined"}
        >
            <>
                {mapOrListSelected === MAP_OR_LIST_SELECTED.MAP ? (
                    <List className="mr_5" fontSize={"default"} />
                ) : (
                    <MapOutlined className="mr_5" fontSize={"default"} />
                )}
            </>
            {mapOrListSelected === MAP_OR_LIST_SELECTED.MAP ? t("propertiesPage.seeList") : t("propertiesPage.seeMap")}
        </UiButton>
    );
};
