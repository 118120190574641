import React from "react";
import clsx from "clsx";
import styles from "./_css/selectInputFront.module.css";
import { MenuItemFront } from "payments/_components/MenuItemFront";
import { TStripeProductListingMdl } from "payments/_models/paymentStripeMdl";

type Props = {
    label?: string;
    choices: TStripeProductListingMdl[];
};

export function SelectInputFront(props: Props) {
    return (
        <div className={clsx(styles.container)}>
            <div className={clsx("flex_row", styles.dropButton)}>
                <div className={clsx(styles.label, "flex_row justifyContent_spaceBetween flex-1")}>
                    <div>{props.label}</div>
                </div>
            </div>
            <div className={clsx("flex_column", styles.dropDown)}>
                {props.choices.map((product) => {
                    return <MenuItemFront key={product.id} item={product} />;
                })}
            </div>
        </div>
    );
}
