import React from "react";
import styles from "./_css/footer.module.css";
import { Container, Grid } from "@material-ui/core";
import { Logo } from "_common/icons/Logo";
import { FooterMail } from "main/footer/FooterMail";
import { FooterLinkList } from "main/footer/FooterLinkList";
import { LINKS } from "main/footer/links";
import { FooterSubscribe } from "main/footer/FooterSubscribe";
import { FooterLinksSocial } from "main/footer/FooterLinksSocial";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { PopularLinks } from "popular/popularLinks";

export function Footer() {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <Container>
                <Grid container className={"mb_20"}>
                    <Grid item xs={12} sm={4} md={3} className={"space-y-20"}>
                        <div className={"flex mb_20"}>
                            <Logo width={150} height={50} />
                        </div>
                        <FooterLinkList titleLabel={t("footer.officialLinks")} list={LINKS(i18next.language)} />
                        <div hidden>
                            <Link target={"_blank"} rel="noopener noreferrer" to={URLS.sitePlan()}>
                                {t("footer.links.sitePlan")}
                            </Link>
                        </div>
                        <FooterMail />
                        <FooterLinksSocial />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <FooterSubscribe />
                    </Grid>
                    <Grid item xs={12} sm={4} md={6}>
                        <PopularLinks type={"location"} />
                        <div className={styles.separator} />
                        <PopularLinks type={"search"} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
