import React, { createRef, RefObject, useRef } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { PropertiesPageContext, usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { FILTERS } from "properties/searchBar/Filters";
import { PropertiesStore } from "properties/_stores/propertiesStore";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { ListStore } from "_common/list/ListStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { panelStore } from "main/header/panel/_stores/panelStore";
import { Replay } from "@material-ui/icons";
import styles from "./_css/mobileFilters.module.css";
import { getUrlWithoutPropertyType } from "_common/_utils/filterUtils";
import { fetchSearchProperties } from "_common/_utils/searchUtils";
import { useHistory, useLocation } from "react-router";

type Props = {
    className?: string;
    propertiesStore: PropertiesStore;
    listStore: ListStore<TPropertyListingMdl>;
};

export interface ExposedFiltersFunctions {
    applyFilters: () => void;
    resetFilters?: () => void;
}

export const MobileFilters = (props: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const propertiesStore = usePropertiesStore();
    const filtersRef = useRef<RefObject<ExposedFiltersFunctions>[]>([]);
    filtersRef.current = FILTERS.map((_, idx) => filtersRef.current[idx] ?? createRef());

    return (
        <PropertiesPageContext store={props.propertiesStore}>
            <div id={"mobileFilters"} className={clsx(props.className, "flex_column position_relative mt_10")}>
                {FILTERS.map((filter, idx) => {
                    const ComponentTag = filter.filterComponent;
                    return (
                        <div key={filter.key} className={clsx(styles.line, "pb_20 mb_20")}>
                            <ComponentTag ref={filtersRef.current[idx]} listStore={props.listStore} filter={filter} />
                        </div>
                    );
                })}
                <div className={"mt_10 flex_row justifyContent_spaceBetween gap_40"}>
                    <UiButton
                        onClick={() => {
                            filtersRef.current.forEach((_ref) => {
                                if (_ref.current?.resetFilters) _ref.current?.resetFilters();
                            });
                            props.listStore.removeFilters();
                            history.push(getUrlWithoutPropertyType(location.pathname));
                            fetchSearchProperties(propertiesStore, props.listStore, history, true);
                        }}
                        className={clsx(styles.button, styles.reset)}
                        color={"primary"}
                        variant={"outlined"}
                    >
                        {t("searchBar.resetSearch")} <Replay className="ml_5" />
                    </UiButton>
                    <UiButton
                        onClick={() => {
                            filtersRef.current.forEach((_ref) => {
                                _ref.current?.applyFilters();
                            });
                            panelStore.close();
                        }}
                        className={styles.button}
                        color={"primary"}
                        variant={"contained"}
                    >
                        {t("searchBar.filter.search")}
                    </UiButton>
                </div>
            </div>
        </PropertiesPageContext>
    );
};
