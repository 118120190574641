import dayjs, { Dayjs } from "dayjs";
import { SEASONS_DATES } from "admin/units/input/DeliveryDatesInput";
import i18next from "i18next";

export function getSeasonOrFormatDate(date: Dayjs, format: string) {
    for (const season of SEASONS_DATES) {
        if (season.date == dayjs(date).get("date") && season.month === dayjs(date).get("month")) {
            return i18next.t(`propertyPage.seasons.${season.key}`) + " " + dayjs(date).year();
        }
    }
    const localizedMonth = i18next.t(`dates.monthOfYear.${dayjs(date).format("M")}`);
    return localizedMonth + " " + dayjs(date).format("YYYY");
}

export function getNumberOfOutdatedDay(date: Dayjs) {
    return dayjs().diff(date, "day");
}

export function isOutdatedDate(date: Dayjs) {
    return getNumberOfOutdatedDay(date) > 0;
}
export function isNearOutdatedDate(date: Dayjs) {
    return getNumberOfOutdatedDay(date) > -15;
}

export function getDateByWeek(week: number, year: number) {
    const d = new Date(year, 0);
    const dayNum = d.getDay();
    let requiredDate = --week * 7;
    if (dayNum != 0 || dayNum > 4) {
        requiredDate += 7;
    }
    d.setDate(1 - d.getDay() + ++requiredDate);
    return d;
}
