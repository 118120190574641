import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import clsx from "clsx";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Input } from "_common/ui/forms/Input";
import { Controller, FormProvider, useForm } from "react-hook-form";
import styles from "./_css/contactFormComponent.module.css";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import i18next from "i18next";
import { Subject } from "components/misc/contactUs/Subject";
import { TextArea } from "_common/ui/forms/TextArea";
import { MenuItem } from "@material-ui/core";
import { ContactFormBtn } from "components/misc/contactUs/ContactFormBtn";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { RecaptchaDisclaimer } from "components/misc/contactUs/recaptchaDisclaimer";
import { SIGN_UP_FROM, SIGN_UP_TYPE } from "users/_models/UserMdl";

type Props = {
    style?: CSSProperties;
    theme?: "white" | "gray";
    $component?: TComponent;
};

export type TContactForm = {
    name: string;
    email: string;
    phone: string;
    subject: string;
    text: string;
    company?: string;
    notification?: boolean;
    signUpType?: SIGN_UP_TYPE;
    signUpFrom?: SIGN_UP_FROM;
    propertyId?: string;
};

export function ContactFormComponent(props: Props) {
    const form = useForm<TContactForm>({ mode: "onBlur" });
    const { t } = useTranslation();
    return (
        <FormProvider {...form}>
            <div style={{ ...props.style }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <InputBlock label={t("words.name")} required>
                            <Controller as={Input} name="name" control={form.control} theme={props.theme} />
                        </InputBlock>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputBlock label={t("words.email")} required>
                            <Controller as={Input} name="email" control={form.control} theme={props.theme} />
                        </InputBlock>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputBlock label={t("words.phone")} required>
                            <Controller as={Input} name="phone" control={form.control} theme={props.theme} />
                        </InputBlock>
                    </Grid>
                </Grid>

                <div className={clsx(styles.line, "flex_row flex-1 pb_20")}>
                    <div className="flex-1">
                        <InputBlock label={t("words.companyName")}>
                            <Controller as={Input} name="company" control={form.control} theme={props.theme} />
                        </InputBlock>
                    </div>
                </div>

                <div className={clsx(styles.line, "flex_row flex-1 pb_20")}>
                    <div className="flex-1">
                        <InputBlock label={t("contactPage.subject")} required>
                            <Controller
                                as={
                                    <UiSelect>
                                        {Subject[i18next.language].map((object, key) => (
                                            <MenuItem value={object} key={key}>
                                                {object}
                                            </MenuItem>
                                        ))}
                                    </UiSelect>
                                }
                                defaultValue={Subject[i18next.language][0]}
                                name="subject"
                                control={form.control}
                            />
                        </InputBlock>
                    </div>
                </div>
                <div className={clsx(styles.line, "flex_row flex-1 pb_20")}>
                    <div className="flex-1">
                        <InputBlock label={t("contactPage.message")} required>
                            <Controller as={TextArea} name="text" control={form.control} theme={props.theme} rows={5} />
                        </InputBlock>
                    </div>
                </div>
                <ContactFormBtn successMessage />
                <div className={"mb_20"}>
                    <RecaptchaDisclaimer />
                </div>
                <div className={"mb_10"}>
                    {t("contactPage.customerSupport")} : <a href={"tel:(438) 389-8981"}>(438) 389-8981</a>
                </div>
                <div>
                    {t("contactPage.sales")} : <a href={"tel: (438) 951-6414"}>(438) 951-6414</a>
                </div>
            </div>
        </FormProvider>
    );
}
