import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import styles from "./_css/imageComponent.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

export type ImageComponentProps = {
    style: CSSProperties;
    src: string;
    alt: string;
    $component: TComponent;
};

export function ImageComponent({ $component, ...props }: ImageComponentProps) {
    return <LazyLoadImage {...props} className={styles.image} />;
}
