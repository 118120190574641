import React from "react";
import { Grid } from "@material-ui/core";
import { GridPropertyCard } from "properties/GridPropertyCard";
import { PropertyCard } from "properties/PropertyCard";
import { useTranslation } from "react-i18next";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { observer } from "mobx-react";

export const PropertiesNearListProperties = observer(() => {
    const propertiesStore = usePropertiesStore();
    const { t } = useTranslation();

    return (
        <div className={"mt_20"}>
            <h3>{t("propertiesPage.similarResults")}</h3>
            {propertiesStore.nearProperties.length === 0 && (
                <span>
                    {propertiesStore.nearProperties.length} {t("words.results")} &nbsp;
                </span>
            )}
            <Grid container spacing={4}>
                {propertiesStore.nearProperties.map((property) => {
                    if (!property) return null;
                    return (
                        <GridPropertyCard key={property._id}>
                            <PropertyCard
                                property={property}
                                onMouseOver={() => {
                                    propertiesStore.setMapPropertiesSelected(property);
                                }}
                                onMouseLeave={() => {
                                    propertiesStore.setMapPropertiesSelected.cancel();
                                }}
                            />
                        </GridPropertyCard>
                    );
                })}
            </Grid>
        </div>
    );
});
