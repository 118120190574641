import React from "react";

export function ArrowCtaIcon() {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.25 0.75L15 7.5L8.25 14.25" stroke="white" />
            <path d="M14.25 7.5H0" stroke="white" />
        </svg>
    );
}
