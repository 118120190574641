import React, { useEffect, useState } from "react";
import { Map } from "maps/Map";
import clsx from "clsx";
import styles from "properties/_css/propertiesView.module.css";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { MAP_REF } from "properties/listing/PropertiesView";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { useHistory } from "react-router";
import {
    DEFAULT_LOCATION,
    DEFAULT_MAP_COORDINATES,
    DEFAULT_ZOOM,
    fetchSearchProperties,
} from "_common/_utils/searchUtils";
import { observer } from "mobx-react";
import { getResourceInitialStateValue } from "_common/_utils/initialStateUtils";

export const getMapCoordinates = () => {
    if ((MAP_REF as any)?.current?.state?.map.getBounds()) {
        const bounds = (MAP_REF as any).current.state.map.getBounds();
        if (bounds) {
            return {
                n: bounds.getNorthEast().lat() as number,
                s: bounds.getSouthWest().lat() as number,
                e: bounds.getNorthEast().lng() as number,
                w: bounds.getSouthWest().lng() as number,
            };
        } else return DEFAULT_MAP_COORDINATES;
    } else return DEFAULT_MAP_COORDINATES;
};

const MapRender = observer(() => {
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);
    const history = useHistory();

    const [loaded, setLoaded] = useState({
        useEffect: false,
        onCenterChanged: false,
        onZoomChanged: false,
    });

    const isInitialGeozoneNotEqualsToStoreGeozone =
        getResourceInitialStateValue("geoZone")?.items?.[0]?.name !== geoZonesStore.geoZone?.name ||
        !geoZonesStore.geoZone;
    useEffect(() => {
        if (!(MAP_REF as any).current?.state?.map?.center?.lat()) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            fetchSearchProperties(
                propertiesStore,
                listStore,
                history,
                false,
                (!geoZonesStore.geoZone && !loaded.useEffect && !loaded.onZoomChanged && !loaded.onCenterChanged) ||
                    !isInitialGeozoneNotEqualsToStoreGeozone,
            );
            if (!loaded.useEffect) setLoaded({ ...loaded, useEffect: true });
        }
    }, [geoZonesStore.geoZone]);

    return (
        <Map
            id="googleMap"
            areMarkersVisible
            isOnSearchingPage
            ref={(ref) => {
                MAP_REF.current = ref;
            }}
            mapRef={MAP_REF}
            mapContainerStyle={{ top: 0, bottom: 0 }}
            mapContainerClassName={clsx("flex-1", styles.map)}
            properties={propertiesStore.items}
            propertySelected={propertiesStore.mapPropertiesSelected}
            onPropertySelected={(property) => propertiesStore.setMapPropertiesSelected(property)}
            center={propertiesStore.searchParams.location ?? DEFAULT_LOCATION}
            zoom={propertiesStore.searchParams.zoom ?? DEFAULT_ZOOM}
            onCenterChanged={() => {
                if ((MAP_REF as any)?.current?.state?.map) {
                    if (
                        propertiesStore?.searchParams?.location?.lat !==
                            (MAP_REF as any).current.state.map?.center?.lat() &&
                        propertiesStore?.searchParams?.location?.lng !==
                            (MAP_REF as any).current.state.map?.center?.lng() &&
                        isInitialGeozoneNotEqualsToStoreGeozone
                    ) {
                        propertiesStore.setLocation({
                            lng: (MAP_REF as any).current.state.map.center.lng(),
                            lat: (MAP_REF as any).current.state.map.center.lat(),
                        });
                        propertiesStore.setMapPropertiesSelected(undefined);
                        fetchSearchProperties(propertiesStore, listStore, history);
                    }
                }
                if (!loaded.onCenterChanged) setLoaded({ ...loaded, onCenterChanged: true });
            }}
            onZoomChanged={() => {
                if ((MAP_REF as any)?.current?.state?.map) {
                    if (
                        propertiesStore?.searchParams?.zoom !== (MAP_REF as any).current.state.map?.zoom &&
                        isInitialGeozoneNotEqualsToStoreGeozone
                    ) {
                        propertiesStore.setSearchParams({ zoom: MAP_REF.current.state.map.zoom ?? DEFAULT_ZOOM });
                        propertiesStore.setLocation({
                            lng: (MAP_REF as any).current.state.map.center.lng(),
                            lat: (MAP_REF as any).current.state.map.center.lat(),
                        });
                        propertiesStore.setMapPropertiesSelected(undefined);
                        fetchSearchProperties(propertiesStore, listStore, history);
                    }
                }
                if (!loaded.onZoomChanged) setLoaded({ ...loaded, onZoomChanged: true });
            }}
            polygon={geoZonesStore.geoZone?.zoneLocations}
        />
    );
});

export default MapRender;
