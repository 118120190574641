import React, { useEffect } from "react";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { TFilter } from "admin/_common/filters/TFilter";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { VirtualAssistantPropertiesSection } from "virtualAssistant/VirtualAssistantPropertiesSection";
import { getPageNumberFromUrl } from "_common/_utils/pageUtils";
import { observer } from "mobx-react";

type Props = {
    filters: TFilter[];
};
export const VirtualAssistantPropertiesLoader = observer(({ filters }: Props) => {
    const listStore = propertiesStore.getListStore(
        "virtual-assistant-summary",
        undefined,
        filters,
        true,
        true,
        undefined,
        getPageNumberFromUrl(location.pathname) ?? 1,
    );
    useEffect(() => {
        listStore.load();
    }, []);

    return (
        <>
            {listStore.currentLoadingState ? (
                <LoadableFromLoading
                    loading={listStore.currentLoadingState}
                    renderer={() => {
                        return <VirtualAssistantPropertiesSection listStore={listStore} />;
                    }}
                />
            ) : (
                <VirtualAssistantPropertiesSection listStore={listStore} />
            )}
        </>
    );
});
