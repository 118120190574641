import React, { forwardRef, RefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./_css/selectEnumFormProject.module.css";
import clsx from "clsx";
import arrowDown from "../../assets/images/arrowDown.svg";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

type Props = {
    onValueChange?: (value: string) => void;
    onChange?: (value: string) => void;
    value?: string;
    enum: string[] | { label: string; value: string }[];
    label: string;
    labelKey: string;
    defaultLabelValue?: string;
    color?: string;
    dontSplit?: boolean;
};

export const SelectEnumFormProject = forwardRef((props: Props, ref) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(props.value);
    const [labelValue, setLabelValue] = useState<string | undefined>(props.defaultLabelValue);
    const label = labelValue
        ? props.dontSplit
            ? labelValue
            : labelValue?.split(" ")[0] + " " + labelValue?.split(" ")[1]
        : value
        ? t(`${props.labelKey}.${value}`)
        : t(`${props.label}`);

    return (
        <div
            ref={ref as RefObject<HTMLDivElement>}
            id={props.labelKey}
            className={clsx(styles.container, {
                [styles.secondary]: props.color === "secondary",
            })}
            onClick={() => setOpen(!open)}
        >
            <div className={clsx("flex_row justifyContent_spaceBetween", styles.content)}>
                <div
                    className={clsx(styles.label, {
                        [styles.labelSecondary]: props.color === "secondary",
                    })}
                    style={{ color: t(`${props.label}`) !== label ? "#004378" : "#7a7e8d" }}
                >
                    {label}
                </div>
                <div
                    className={clsx(styles.arrowContent, {
                        [styles.arrowContentSecondary]: props.color === "secondary",
                    })}
                >
                    {!props.color ? (
                        <img
                            src={arrowDown}
                            className={clsx(styles.arrow, {
                                [styles.arrowDown]: open,
                                [styles.arrowUp]: !open,
                            })}
                            alt={"arrow left"}
                        />
                    ) : !open ? (
                        <KeyboardArrowDown color={"primary"} />
                    ) : (
                        <KeyboardArrowUp color={"primary"} />
                    )}
                </div>
            </div>
            {open && (
                <div className={styles.contentItems}>
                    {typeof props.enum[0] !== "string" ? (
                        <>
                            {(props.enum as { label: string; value: string }[]).map((item, index) => {
                                if (!item) return null;
                                return (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            if (props.onValueChange && item.value !== value) {
                                                props.onValueChange(item.value);
                                            }
                                            if (props.onChange) {
                                                props.onChange(item.value);
                                            }
                                            setValue(item.value);
                                            setLabelValue(item.label);
                                        }}
                                        className={styles.item}
                                    >
                                        {!props.dontSplit &&
                                            item.label?.split(" ")[0] + " " + (item.label?.split(" ")[1] ?? "")}
                                        {props.dontSplit && item.label}
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <>
                            {(props.enum as string[]).map((item, index) => {
                                if (!item) return null;
                                return (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            if (props.onValueChange) {
                                                props.onValueChange(item);
                                            }
                                            if (props.onChange) {
                                                props.onChange(item);
                                            }
                                            setValue(item);
                                        }}
                                        className={styles.item}
                                    >
                                        {t(`${props.labelKey}.${item}`)}
                                    </div>
                                );
                            })}
                        </>
                    )}
                </div>
            )}
        </div>
    );
});
