import React from "react";
import { IPageMdl, PAGE_TYPE } from "pages/_models/PageMdl";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { Meta } from "_common/_utils/Meta";

type Props = {
    page: IPageMdl;
};

export function PageMeta(props: Props) {
    const currentLang = i18nextInstance.language as TLang;
    const localizedPage = props.page?.localized?.[currentLang];
    if (!localizedPage) return null;
    const isBlogPage = props.page.type === PAGE_TYPE.ARTICLE;
    const title = localizedPage.meta?.title ?? localizedPage.title;
    const description = localizedPage.meta?.description ?? localizedPage.description;
    const image = localizedPage.meta?.image ?? localizedPage.image;
    const url = sharedConfig.languages[currentLang].baseUrl + (isBlogPage ? "/blog" : "") + localizedPage.url;
    const alternateUrls = Object.entries(props.page.localized).reduce((acc, [lang, localized]) => {
        if (localized?.published) {
            const url =
                localizedPage.url === "/" && lang === "fr"
                    ? sharedConfig.appUrl
                    : sharedConfig.languages[lang as TLang].baseUrl + (isBlogPage ? "/blog" : "") + localized.url;
            acc.push({
                lang: lang as TLang,
                url: url,
            });
        }
        return acc;
    }, [] as { lang: TLang; url: string }[]);
    return (
        <Meta
            title={title}
            description={description}
            image={image}
            url={url}
            alternateUrls={alternateUrls}
            noIndex={!localizedPage.published}
        />
    );
}
