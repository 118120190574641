import React from "react";
import styles from "./_css/propertyGalleryCard.module.css";
import { TPropertyCardMdl } from "properties/_models/PropertyMdl";
import "swiper/swiper-bundle.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import SwiperCore, { Navigation } from "swiper";
import { ErrorEventWithRetry } from "_common/types/specificsTypes";
import { getWebpFile } from "_common/_utils/fileUtils";

SwiperCore.use([Navigation]);

type Props = {
    property: TPropertyCardMdl;
    isMapCard?: boolean;
    isPrimaryCard?: boolean;
    isSimilarCard?: boolean;
};

export function PropertyGalleryCardFallback(props: Props) {
    return (
        <img
            className={styles.image}
            alt={`${props.property.localized.title}-1`}
            title={`${props.property.localized.title} photo 1`}
            src={getWebpFile(props.property.photos?.[0]?.thumbnail) ?? props.property.photos?.[0]?.url}
            onError={(event: ErrorEventWithRetry) => {
                const { currentTarget } = event;
                if (currentTarget.retryAttempt) currentTarget.retryAttempt++;
                if (!currentTarget.retryAttempt) {
                    currentTarget.retryAttempt = 1;
                }
                currentTarget.onerror = null; // prevents looping
                currentTarget.src =
                    currentTarget?.retryAttempt > 6
                        ? "https://via.placeholder.com/250?text=Vistoo.com"
                        : props.property.photos?.[0]?.url;
            }}
        />
    );
}
