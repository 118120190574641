import React, { useEffect, useRef } from "react";
import styles from "placements/_css/placementBanner.module.css";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { PLACEMENT_EVENT, TPlacementListingLocalizedMdl } from "placements/_models/PlacementsMdl";
import { useTranslation } from "react-i18next";
import { refreshPage } from "_common/_utils/pageUtils";
import i18next from "i18next";
import { TLang } from "_configs/sharedConfig";
import { useLocation } from "react-router";
import { useOnScreen } from "_common/_utils/useOnScreen";
import { analyticsUtils } from "_common/_utils/analyticsUtils";

type Props = {
    placement: TPlacementListingLocalizedMdl;
};

export function PlacementBannerCard({ placement }: Props) {
    const { t } = useTranslation();
    const url = useLocation().pathname;
    const ref = useRef<HTMLDivElement>(null);
    const { isIntersecting } = useOnScreen(ref, 0.5);
    useEffect(() => {
        if (isIntersecting) {
            void analyticsUtils.tracksEventOfItem(PLACEMENT_EVENT.PRINTED_STICKY, placement._id, url);
        }
    }, [isIntersecting]);
    if (!placement.property) return null;
    const displayText =
        placement.localized?.text ??
        placement.localized?.[i18next.language as TLang]?.text ??
        placement.localized?.en?.text;
    return (
        <>
            <div className={"swiper-button-prev-banner"} />
            <div className={clsx(styles.textAndCta, "flex_row_center justifyContent_center gap_20")} ref={ref}>
                {displayText ? (
                    <div className={styles.text}>{displayText}</div>
                ) : (
                    <div className={styles.text}>{placement.property.localized.title}</div>
                )}
                <Link
                    className={styles.cta}
                    to={URLS.property(placement.property.localized.urlAlias)}
                    onClick={(e) => {
                        e.preventDefault();
                        void analyticsUtils.tracksEventOfItem(PLACEMENT_EVENT.VISITED_STICKY, placement._id, url);
                        if (placement.property?.localized?.urlAlias) {
                            refreshPage(URLS.property(placement.property.localized.urlAlias));
                        }
                    }}
                >
                    {t("placements.banner.seeProject")}
                </Link>
            </div>
            <div className={"swiper-button-next-banner"}></div>
        </>
    );
}
