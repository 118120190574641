import React from "react";
import clsx from "clsx";
import { settingsStore } from "settings/_stores/settingsStore";
import { Email } from "@material-ui/icons";

export function FooterMail() {
    const settings = settingsStore.getOne();
    if (!settings) return null;
    return (
        <a
            href={`mailto:${settings.contacts?.emailContact}`}
            className={clsx("flex_row_center gap_10 text_small fontWeight_semi")}
        >
            <Email fontSize={"small"} />
            {settings.contacts?.emailContact}
        </a>
    );
}
