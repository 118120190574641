import dayjs from "dayjs";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { TMongooseId } from "api/_common/_types/MongooseTypes";
import { IPlacementWithPropertyModel } from "api/placements/_models/PlacementModel";

export enum PLACEMENT_PRINTED_EVENT {
    PRINTED_SRPS = "placements/srps/printed",
    PRINTED_LISTING = "placements/listing/printed",
    PRINTED_SHOWCASE = "placements/showcase/printed",
    PRINTED_FEATURED = "placements/featured/printed",
    PRINTED_STICKY = "placements/sticky/printed",
    PRINTED_CTA = "placements/cta/printed",
    PRINTED_PREMIUM = "placements/premium/printed",
}

export enum PLACEMENT_VISITED_EVENT {
    VISITED_SRPS = "placements/srps/visited",
    VISITED_LISTING = "placements/listing/visited",
    VISITED_SHOWCASE = "placements/showcase/visited",
    VISITED_FEATURED = "placements/featured/visited",
    VISITED_STICKY = "placements/sticky/visited",
    VISITED_CTA = "placements/cta/visited",
    VISITED_PREMIUM = "placements/premium/visited",
}

export enum PLACEMENT_STAT_EVENT {
    PRINTED_SRPS = "placements/srps",
    PRINTED_LISTING = "placements/listing",
    PRINTED_SHOWCASE = "placements/showcase",
    PRINTED_FEATURED = "placements/featured",
    PRINTED_STICKY = "placements/sticky",
    PRINTED_CTA = "placements/cta",
    PRINTED_PREMIUM = "placements/premium",
    VISITED_SRPS = "placements/srps",
    VISITED_LISTING = "placements/listing",
    VISITED_SHOWCASE = "placements/showcase",
    VISITED_FEATURED = "placements/featured",
    VISITED_STICKY = "placements/sticky",
    VISITED_CTA = "placements/cta",
    VISITED_PREMIUM = "placements/premium",
}

export const PLACEMENT_EVENT = { ...PLACEMENT_PRINTED_EVENT, ...PLACEMENT_VISITED_EVENT };

export const PLACEMENT_PRINTED_EVENTS = Object.values(PLACEMENT_PRINTED_EVENT);

export enum PLACEMENT_TYPE {
    SRPS = "SRPS", // Brand
    MEDIA = "MEDIA", // Listing Page // Brand // SUR 1 PAGE PROPERTY
    SLIDER = "SLIDER", // Sticky CTA // SUR 1 PAGE PROPERTY
    SHOWCASE = "SHOWCASE",
    FEATURED = "FEATURED",
    PREMIUM = "PREMIUM",
    BANNER = "BANNER", // Sticky Banner
}

// rename used in dashboard
export enum PLACEMENT_TYPES {
    SRPS = "placements/srps",
    LISTING = "placements/listing",
    SHOWCASE = "placements/showcase",
    FEATURED = "placements/featured",
    STICKY = "placements/sticky",
    CTA = "placements/cta",
    PREMIUM = "placements/premium",
}

export enum PLACEMENT_PROPERTY_TYPE {
    RESIDENTIAL_SALE = "residentialSale",
    RESIDENTIAL_RENT = "residentialRent",
    COMMERCIAL_RENT = "commercialRent",
}

export type TPlacementLocalized = {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
    image: string;
    text: string;
    userId?: string;
    url: string;
    gifUrl: string;
};

export type TPlacementBaseMdl = TPlacementLocalized & {
    _id: string;
    type: PLACEMENT_TYPE;
    propertyId: string;
    userId: string;
    geoZoneId: string;
    propertyType: PLACEMENT_PROPERTY_TYPE;
    isAutomatedAdding?: boolean;
    localized: {
        en: TPlacementLocalized;
        fr: TPlacementLocalized;
        es: TPlacementLocalized;
        zh: TPlacementLocalized;
    };
};

export type TPlacementMdl = TPlacementBaseMdl & {
    propertyId: string;
};

export type TPlacementListingMdl = TPlacementMdl & {
    property?: TPropertyListingMdl;
};

export type TPlacementListingLocalizedMdl = TPlacementListingMdl & {
    localized: TPlacementLocalized;
};

export type TPlacementStatEventModel = {
    _id: TMongooseId;
    count: number;
    type: PLACEMENT_VISITED_EVENT | PLACEMENT_PRINTED_EVENT;
    placement: IPlacementWithPropertyModel;
    users: string[];
};

export const defaultPlacement: (
    type: PLACEMENT_TYPE,
    startDate?: dayjs.Dayjs,
    enDate?: dayjs.Dayjs,
    propertyType?: PLACEMENT_PROPERTY_TYPE,
    geoZoneId?: string,
    propertyId?: string,
) => Omit<TPlacementMdl, "_id"> = (
    type: PLACEMENT_TYPE,
    startDate?: dayjs.Dayjs,
    enDate?: dayjs.Dayjs,
    propertyType = PLACEMENT_PROPERTY_TYPE.RESIDENTIAL_SALE,
    geoZoneId?: string,
    propertyId?: string,
) => {
    return {
        type: type,
        propertyId: propertyId ?? "",
        geoZoneId: geoZoneId ?? "",
        propertyType: propertyType,
        startDate: startDate ?? dayjs().startOf("week"),
        endDate: enDate ?? dayjs().endOf("week"),
        image: "",
        userId: "",
        text: "",
        url: "",
        gifUrl: "",
        isAutomatedAdding: false,
        localized: {
            en: {
                startDate: startDate ?? dayjs().startOf("week"),
                endDate: enDate ?? dayjs().endOf("week"),
                image: "",
                text: "",
                url: "",
                gifUrl: "",
            },
            fr: {
                startDate: startDate ?? dayjs().startOf("week"),
                endDate: enDate ?? dayjs().endOf("week"),
                image: "",
                text: "",
                url: "",
                gifUrl: "",
            },
            es: {
                startDate: startDate ?? dayjs().startOf("week"),
                endDate: enDate ?? dayjs().endOf("week"),
                image: "",
                text: "",
                url: "",
                gifUrl: "",
            },
            zh: {
                startDate: startDate ?? dayjs().startOf("week"),
                endDate: enDate ?? dayjs().endOf("week"),
                image: "",
                text: "",
                url: "",
                gifUrl: "",
            },
        },
    };
};
