import React from "react";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import clsx from "clsx";
import styles from "./_css/propertyAddress.module.css";
import { Room } from "@material-ui/icons";

type Props = {
    property: TPropertyListingMdl;
    className?: string;
};

export function PropertyAddress(props: Props) {
    const sortAddress = [
        props.property.address.street,
        props.property.address.neighbourhood,
        props.property.address.city,
        props.property.address.province,
        props.property.address.country,
        props.property.address.postalCode
            ? props.property.address.postalCode.substring(0, 3) + " " + props.property.address.postalCode.substring(3)
            : "",
    ];
    const completeAddress = sortAddress
        .filter((addressPart) => addressPart !== undefined && addressPart !== "")
        .join(", ");
    return (
        <div className={clsx(props.className, "flex_row_center gap_10")}>
            <Room fontSize={"small"} color={"disabled"} className={styles.text} />
            <div className={clsx("flex-1 flexWrap_wrap", styles.text)}>
                <div>{completeAddress}</div>
            </div>
        </div>
    );
}
