import React from "react";
import styles from "./_css/propertyCardLoader.module.css";

export const PropertyCardLoader = () => {
    return (
        <div className={styles.container}>
            <div className={styles.img}></div>
            <div className={styles.content}>
                <h2></h2>
                <p></p>
            </div>
        </div>
    );
};
