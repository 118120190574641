import React from "react";
import clsx from "clsx";
import styles from "./_css/tour3D.module.css";

type Props = {
    className?: string;
    embedSrc: string;
    thumbnail?: boolean;
};

export function Tour3D(props: Props) {
    return (
        <div
            className={clsx(styles.container, props.className, {
                [styles.thumbnail]: props.thumbnail,
            })}
        >
            <iframe
                src={props.embedSrc}
                width={"100%"}
                height={"100%"}
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </div>
    );
}
