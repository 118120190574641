import React from "react";
import { AdvancedResearches } from "advancedResearches/AdvancedResearches";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { observer } from "mobx-react";
import { URLS } from "_configs/URLS";
import { isBuyingPage } from "_common/_utils/pageUtils";
import { useLocation } from "react-router-dom";

export const AdvancedResearchesSrPsProvinces = observer(() => {
    const location = useLocation();
    void geoZonesStore.fetchProvinces();

    if (!geoZonesStore.provinces) return null;

    const purpose = isBuyingPage(location.pathname) ? "buy" : "rent";

    return (
        <AdvancedResearches
            links={geoZonesStore.provinces.map((province) => ({
                to: URLS[purpose](undefined, province.address.province.toLowerCase()),
                label: province.name.split(",")[0],
            }))}
        />
    );
});
