import React from "react";
import { ITEM_MODEL, ItemsListComponent } from "components/base/itemsList/itemsListComponent";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { Container } from "@material-ui/core";
import { PageTitle } from "_common/ui/pages/PageTitle";
import { useParams } from "react-router";
import { PARAMS_PAGE_PREFIX } from "_common/_utils/searchUtils";

export function BlogsPage() {
    const params = useParams<{ page: string }>();
    let page;
    if (params.page && params.page.startsWith(PARAMS_PAGE_PREFIX)) page = parseInt(params.page.slice(2));
    return (
        <Container className={"mt_10"}>
            <PageTitle style={{ display: "none" }}>Blog</PageTitle>
            <Breadcrumb currentItem={{ itemLabel: "Blog" }} />
            <ItemsListComponent itemModel={ITEM_MODEL.ARTICLE} pageSize={20} page={page} />
        </Container>
    );
}
