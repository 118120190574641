import React, { useContext } from "react";
import { OrderStore } from "payments/_stores/orderStore";

const OrderCtxt = React.createContext<{ orderStore: OrderStore; selectedPropertyId?: string }>(
    {} as { orderStore: OrderStore; selectedPropertyId?: string },
);

type Props = {
    value: { orderStore: OrderStore; selectedPropertyId?: string };
};

export function useOrderContext() {
    return useContext(OrderCtxt);
}

export function OrderContext(props: React.PropsWithChildren<Props>) {
    return <OrderCtxt.Provider value={props.value}>{props.children}</OrderCtxt.Provider>;
}
