import React from "react";
import clsx from "clsx";
import { PropsTextarea } from "_common/ui/forms/TextArea";
import styles from "./_css/textareaCta.module.css";

export const TextAreaCta = React.forwardRef<HTMLTextAreaElement, PropsTextarea>(
    ({ error, className, onValueChange, onChange, style, ...props }, ref) => {
        return (
            <>
                <textarea
                    {...props}
                    className={clsx(
                        styles.input,
                        styles.input_default,
                        {
                            [styles.input_label]: props.theme === "label",
                            [styles.input_white]: props.theme === "white",
                            [styles.input_gray]: props.theme === "gray" || props.disabled,
                            [styles.input_error]: error,
                        },
                        className,
                    )}
                    style={style}
                    ref={ref}
                    onChange={(event) => {
                        if (onChange) onChange(event);
                        if (onValueChange) onValueChange(event.target.value, event);
                    }}
                    rows={2}
                />
                {error && <div className={styles.error}>{error.message}</div>}
            </>
        );
    },
);
