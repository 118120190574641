import React from "react";
import styles from "./_css/inputTextCta.module.css";
import clsx from "clsx";
import { TInputProps } from "payments/_components/InputTextFront";

export const InputTextCta = React.forwardRef<HTMLInputElement, TInputProps>(
    (
        { error, className, endIcon, onValueChange, onChange, placeholder, disable, password, hidden, style, ...props },
        ref,
    ) => {
        return (
            <div className={clsx({ ["hidden"]: hidden })}>
                <div>
                    <input
                        type={password ? "password" : "text"}
                        {...props}
                        style={style}
                        placeholder={placeholder}
                        className={clsx(styles.input, className)}
                        ref={ref}
                        onChange={(event) => {
                            if (onChange) onChange(event);
                            if (onValueChange) onValueChange(event.target.value);
                        }}
                        disabled={disable}
                    />
                </div>
                {error && error.message && <div>{error.message}</div>}
            </div>
        );
    },
);
