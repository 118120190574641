import React from "react";
import styles from "./_css/formProject.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { TextareaFormProject } from "projects/component/TextareaFormProject";
import { CheckboxFeatureAndAmenities } from "projects/component/CheckboxFeatureAndAmenities";

type Props = {
    stepSelected: number;
};

export function ProjectThirdStepForm(props: Props) {
    const { t } = useTranslation();
    const form = useFormContext();

    return (
        <div
            className={clsx(styles.container, "p_40", {
                [styles.open]: props.stepSelected === 3,
                [styles.close]: props.stepSelected !== 3,
            })}
        >
            <div className={"flex_row justifyContent_spaceBetween"}>
                <div className={styles.title}> {t("projectPage.descriptions")}</div>
            </div>
            <div className={styles.contentForm}>
                <div className={styles.contentTextarea}>
                    <div className={styles.label}> {t("projectPage.descriptionProperty")}</div>
                    <Controller
                        as={TextareaFormProject}
                        name={"localized[en].description"}
                        placeholder={t("projectPage.placeholderDescription")}
                        value={form.watch("localized.en.description") ?? ""}
                        onChange={(e: string) => form.setValue("localized.en.description", e)}
                    />
                </div>
                <div className={styles.contentCheckbox}>
                    <CheckboxFeatureAndAmenities />
                </div>
            </div>
        </div>
    );
}
