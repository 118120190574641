import { TPropertyListingMdl, TPropertyMdl } from "properties/_models/PropertyMdl";
import { RESOURCE_KEY } from "admin/_common/photos/AdminPhotosStore";
import _ from "lodash";
import { action, observable, reaction } from "mobx";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { createFilesData } from "_common/_utils/fileUtils";
import { TFilesData } from "_common/_utils/fetchUtils";
import { UNKNOWN_ERROR } from "_common/errors/errorUtils";
import { TImage } from "_common/types/baseTypes";
import { propertiesStore } from "properties/_stores/propertiesStore";

const PLANS_UPLOAD_BATCH_SIZE = 3;

export class PropertyFileStore {
    @observable plansUploadingState: {
        url: string;
        loadingState: LoadingStateMdl<TPropertyMdl | undefined>;
    }[] = [];
    @observable currentPlansLoadingState: LoadingStateMdl<TPropertyMdl | undefined> | undefined = undefined;

    @observable item: TPropertyMdl;
    readonly itemSavingState = new LoadingStateMdl<TPropertyMdl | TPropertyListingMdl | undefined>();
    constructor(item: TPropertyMdl) {
        this.item = item;
    }

    addPlans(urls: string[], resourceKey: RESOURCE_KEY) {
        const urlsBatchs = _.chunk(urls, PLANS_UPLOAD_BATCH_SIZE);
        return urlsBatchs.map((urlsBatch: string[]) => this.uploadPlansBatchs(urlsBatch, resourceKey));
    }

    @action deletePlanUploadingState(url: string) {
        return this.plansUploadingState.splice(
            this.plansUploadingState.findIndex((uploadingState) => uploadingState.url === url),
            1,
        );
    }

    deletePlan(planIndex: number, resourceKey: RESOURCE_KEY) {
        const loadingState = new LoadingStateMdl<TPropertyMdl | undefined>();
        const deletePromise = new Promise<TPropertyMdl | undefined>((resolve) => {
            this.onNextPlansLoadingState(() => {
                const updatedPhotos = [...(this.item[resourceKey] ?? [])];
                updatedPhotos.splice(planIndex, 1);
                const patchLoadingState = this.patch({ [resourceKey]: updatedPhotos } as Partial<TPropertyMdl>);
                loadingState.sync(patchLoadingState);
                resolve(patchLoadingState.promise);
            });
        });
        loadingState.startLoading(deletePromise);
        return loadingState;
    }

    @action
    private uploadPlansBatchs(urls: string[], resourceKey: RESOURCE_KEY) {
        const uploadingState = new LoadingStateMdl<TPropertyMdl | undefined>("LOADING");
        for (let i = 0; i < urls.length; i++) {
            const url = urls[i];
            this.plansUploadingState.push({ url, loadingState: uploadingState });
        }
        this.onNextPlansLoadingState(() => this.executePlansUpload(uploadingState, urls, resourceKey));
        return uploadingState;
    }

    private onNextPlansLoadingState(cb: () => any) {
        if (!this.currentPlansLoadingState) cb();
        else {
            const disposer = reaction(
                () => !this.currentPlansLoadingState,
                () => {
                    if (!this.currentPlansLoadingState) {
                        disposer();
                        cb();
                    }
                },
            );
        }
    }

    @action
    private async executePlansUpload(
        uploadingState: LoadingStateMdl<TPropertyMdl | undefined>,
        urls: string[],
        resourceKey: RESOURCE_KEY,
    ) {
        try {
            this.currentPlansLoadingState = uploadingState;
            const updatedPlans = [...(this.item[resourceKey] ?? []), ...urls.map((url) => ({ url }))];
            const filesData = await createFilesData(
                updatedPlans.map(({ url }) => url),
                `${resourceKey}.*.url`,
                1920,
            );
            const patchLoadingState = this.patch({ [resourceKey]: updatedPlans }, filesData);
            uploadingState.sync(patchLoadingState);
            patchLoadingState.promise
                ?.then(
                    action(() => {
                        for (const url of urls) {
                            this.deletePlanUploadingState(url);
                        }
                    }),
                )
                .finally(() => (this.currentPlansLoadingState = undefined));
        } catch (err) {
            uploadingState.setError(err);
            this.currentPlansLoadingState = undefined;
        }
    }

    private patch(patch: Partial<TPropertyMdl & { [key: string]: TImage[] | undefined }>, files?: TFilesData) {
        if (!this.itemSavingState.isLoading) {
            const isCreate = !this.item._id;
            if (isCreate) return;
            const request = propertiesStore.editProperty({ ...patch, _id: this.item._id }, files);
            const promise = request.then(
                action(({ data }) => {
                    if (data) {
                        this.item = { ...data, localized: this.item.localized } as TPropertyMdl;
                        this.itemSavingState.setSuccess({ ...data, localized: this.item.localized } as TPropertyMdl);
                    } else {
                        this.itemSavingState.setError(UNKNOWN_ERROR);
                    }
                    return data;
                }),
                (err) => {
                    this.itemSavingState.setError(err);
                    return undefined;
                },
            );
            this.itemSavingState.startLoading(promise);
        }
        return this.itemSavingState;
    }
}
