import React, { PropsWithChildren } from "react";
import styles from "./_css/buttonV2.module.css";
import clsx from "clsx";

type Props = PropsWithChildren<{
    onClick?: () => void;
    variant: "outlined" | "contained";
    size?: "small" | "medium" | "large" | "fit";
    color?: "primary" | "secondary";
    className?: string;
}>;

export function ButtonV2({ children, onClick, variant, size, color, className }: Props) {
    return (
        <div
            onClick={onClick}
            className={clsx(
                styles.container,
                "cursor_pointer pv_5 ph_10",
                {
                    [styles.outlined]: variant === "outlined",
                    [styles.contained]: variant === "contained",
                    [styles.large]: !size || size === "large",
                    [styles.medium]: size === "medium",
                    [styles.small]: size === "small",
                    [styles.fit]: size === "fit",
                    [styles.primaryContained]: color === "primary" && variant === "contained",
                    [styles.primaryOutlined]: color === "primary" && variant === "outlined",
                    [styles.secondaryContained]: color === "secondary" && variant === "contained",
                    [styles.secondaryOutlined]: color === "secondary" && variant === "outlined",
                },
                className,
            )}
        >
            {children}
        </div>
    );
}
