import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { useTranslation } from "react-i18next";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ButtonV2 } from "_common/ui/mui/buttons/ButtonV2";
import styles from "./_css/propertiesNoResultsButton.module.css";
import React from "react";
import { useHistory } from "react-router-dom";
import { fetchSearchProperties } from "_common/_utils/searchUtils";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";

interface ExploreMoreProps {
    geoZoneId: string;
}

export function PropertiesNoResultsExploreMoreButton({ geoZoneId }: ExploreMoreProps) {
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);
    const promise = geoZonesStore.listing(undefined, 1, undefined, undefined, [
        {
            id: "_id",
            type: TFilterType.ID,
            value: geoZoneId,
        },
    ]);

    const history = useHistory();

    const { loading } = useLoadingFromPromise(promise);
    const { t } = useTranslation();

    if (!loading) return null;

    return (
        <LoadableFromLoading
            loading={loading}
            noSpinner
            noText
            renderer={(_status, error, data) => {
                if (error || !data || data.items.length <= 0) return null;
                const geoZone = data.items[0];
                return (
                    <ButtonV2
                        onClick={() => {
                            geoZonesStore.setGeoZone(geoZone);
                            propertiesStore.setLocationFromSearch(geoZone);
                            fetchSearchProperties(propertiesStore, listStore, history);
                        }}
                        size={"medium"}
                        className={styles.btn}
                        variant={"outlined"}
                        color={"primary"}
                    >
                        {t("propertiesPage.noResults.cta", { geoZoneName: geoZone.name.split(",")[0] })}
                    </ButtonV2>
                );
            }}
        />
    );
}
