import { URLS } from "_configs/URLS";

export const LINKS = (lang: string) => {
    return [
        {
            labelKey: "footer.searches.news",
            url: URLS.blog(lang),
        },
        {
            labelKey: "footer.links.advertise",
            url: URLS.advertise(lang),
        },
        {
            labelKey: "footer.links.policy",
            url: URLS.policy(lang),
        },
        {
            labelKey: "footer.links.terms",
            url: URLS.terms(lang),
        },
        {
            labelKey: "footer.links.contactUs",
            url: URLS.contact(lang),
        },
        {
            labelKey: "footer.links.faq",
            url: URLS.faq(lang),
        },
        {
            labelKey: "footer.links.aboutUs",
            url: URLS.about(lang),
        },
    ];
};
