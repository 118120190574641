import React, { useState } from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import styles from "./_css/propertyGallery.module.css";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import clsx from "clsx";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { userStore } from "users/_stores/userStore";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { observer } from "mobx-react";
import { PropertyOrUnitGallerySecondary } from "properties/gallery/PropertyOrUnitGallerySecondary";
import { PropertyOrUnitGalleryThumbnails } from "properties/gallery/PropertyOrUnitGalleryThumbnails";
import { PropertyGalleryLabels } from "properties/gallery/PropertyGalleryLabels";
import { PropertyOrUnitGallerySwiper } from "properties/gallery/PropertyOrUnitGallerySwiper";

SwiperCore.use([Navigation, Thumbs]);

export const PropertyGallery = observer(() => {
    const [indexGallery, setIndexGallery] = useState(0);
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>();
    const propertyStore = usePropertyStore();
    if (!propertyStore.property || !propertyStore.property.photos) return null;
    if (propertyStore.property.photos.length === 0) return null;
    if (!userStore.favoritesPropertiesStates.isSucceeded) userStore.fetchFavoritesProperties();

    let lengthImgAndVideo = propertyStore.property.photos.length;
    const indexForVideoOrVisit3D = [];
    if (propertyStore.property.video) {
        indexForVideoOrVisit3D.push(lengthImgAndVideo + 1);
        lengthImgAndVideo += 1;
    }
    if (propertyStore.property.video2) {
        indexForVideoOrVisit3D.push(lengthImgAndVideo + 1);
        lengthImgAndVideo += 1;
    }
    if (propertyStore.property.visit3D) {
        indexForVideoOrVisit3D.push(lengthImgAndVideo + 1);
        lengthImgAndVideo += 1;
    }
    const isLand = propertyStore.property.type === PROPERTY_TYPE.land;
    return (
        <div id={"propertyGallery"} className={clsx(styles.container, "flex_row")}>
            <div
                className={clsx("flex_column position_relative", styles.contentSliders, {
                    [styles.contentSliderLand]: isLand,
                })}
            >
                <PropertyGalleryLabels indexForVideoOrVisit3D={indexForVideoOrVisit3D} indexGallery={indexGallery} />
                <PropertyOrUnitGallerySwiper
                    indexGallery={indexGallery}
                    thumbsSwiper={thumbsSwiper}
                    setIndexGallery={setIndexGallery}
                    lengthImgAndVideo={lengthImgAndVideo}
                    indexForVideoOrVisit3D={indexForVideoOrVisit3D}
                    resource={MAIN_RESOURCE.PROPERTIES}
                />
                {!isLand && (
                    <PropertyOrUnitGalleryThumbnails
                        indexGallery={indexGallery}
                        setThumbsSwiper={(swipe) => setThumbsSwiper(swipe)}
                        resource={MAIN_RESOURCE.PROPERTIES}
                    />
                )}
            </div>
            {!isLand && <PropertyOrUnitGallerySecondary resource={MAIN_RESOURCE.PROPERTIES} />}
        </div>
    );
});
