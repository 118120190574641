import React, { useEffect } from "react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { placementsStore } from "placements/_store/placementsStore";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { getPlacementTypeOfSrps } from "_common/_utils/searchUtils";
import { ListStore } from "_common/list/ListStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { PlacementSrpsCard } from "placements/PlacmentSrpsCard";
import { observer } from "mobx-react";
import i18next from "i18next";
import sharedConfig from "_configs/sharedConfig";
import { GridPropertyCard } from "properties/GridPropertyCard";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
    srpIndex: number;
};
const PLACEMENT_POSITIONS = [1, 5, 9, 13];

type PropsPlacementSrps = { position: number };

const _PlacementSrps = observer(({ position }: PropsPlacementSrps) => {
    const { placementsSrps } = placementsStore;
    const positionIndex = PLACEMENT_POSITIONS.indexOf(position);
    const placementIndex = positionIndex < placementsSrps.length ? positionIndex : 0;
    if (!placementsSrps[placementIndex]) return null;
    return (
        <GridPropertyCard>
            <PlacementSrpsCard placement={placementsSrps[placementIndex]} />
        </GridPropertyCard>
    );
});

export const PlacementSrps = ({ srpIndex, listStore }: Props) => {
    useEffect(() => {
        placementsStore.fetchSrpsPlacements(getPlacementTypeOfSrps(listStore), geoZonesStore.geoZone?._id);
    }, [geoZonesStore.geoZone?._id]);

    if (
        !PLACEMENT_POSITIONS.includes(srpIndex) ||
        i18next.language === sharedConfig.languages.zh.lang ||
        i18next.language === sharedConfig.languages.es.lang
    ) {
        return null;
    }
    return (
        <LoadableFromLoading
            noSpinner
            noText
            loading={placementsStore.placementsSrpsState}
            renderer={(_status, error) => {
                if (error) return null;
                return <_PlacementSrps position={srpIndex} />;
            }}
        />
    );
};
