import React from "react";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { userStore } from "users/_stores/userStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "users/profile/_css/favoritesList.module.css";
import { UnitCard } from "units/UnitCard";
import { PropertyCard } from "properties/PropertyCard";
import { observer } from "mobx-react";

type Props = {
    resourceType: MAIN_RESOURCE;
};

export const FavoriteList = observer((props: Props) => {
    const { t } = useTranslation();
    const isProperty = props.resourceType === MAIN_RESOURCE.PROPERTIES;

    return (
        <LoadableFromLoading
            loading={userStore[isProperty ? "favoritesPropertiesStates" : "favoritesUnitsStates"]}
            renderer={(_status, error) => {
                if (error) return null;
                if (userStore[isProperty ? "favoritesProperties" : "favoritesUnits"].length === 0) return null;
                return (
                    <div>
                        <h2 className={"mt_20"}>
                            {t(`favoritesPage.${isProperty ? "favoritesProperties" : "favoritesUnits"}`)}
                        </h2>
                        <div className={clsx("flex_row flexWrap_wrap", styles.cards)}>
                            {userStore[isProperty ? "favoritesProperties" : "favoritesUnits"].map((item) => {
                                return (
                                    <div key={item._id} className={clsx("mh_10 mb_20", styles.card)}>
                                        {isProperty ? <PropertyCard property={item} /> : <UnitCard unit={item} />}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            }}
        />
    );
});
