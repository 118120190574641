import React from "react";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { InputDate } from "_common/ui/forms/InputDate";
import { IconButton } from "@material-ui/core";
import { IconComponent } from "components/base/icon/IconComponent";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { SEASONS } from "properties/_models/PropertyMdl";
import { Input } from "_common/ui/forms/Input";

export const SEASONS_DATES = [
    {
        key: SEASONS.SPRING,
        date: 21,
        month: 2,
    },
    {
        key: SEASONS.SUMMER,
        date: 21,
        month: 5,
    },
    {
        key: SEASONS.AUTUMN,
        date: 21,
        month: 8,
    },
    {
        key: SEASONS.WINTER,
        date: 21,
        month: 11,
    },
];

export function DeliveryDatesInput() {
    const form = useFormContext();
    const { t } = useTranslation();
    const { fields, append, remove } = useFieldArray({ control: form.control, name: "deliveryDates" });
    return (
        <div className={"mb_20"}>
            {fields.map((field, index) => {
                return (
                    <div className={"flex_row_center"} key={field.id}>
                        <InputBlock className="mr_20" label={t("admin.property.phase") + " " + (index + 1)}>
                            <Controller control={form.control} as={InputDate} name={`deliveryDates.${index}`} />
                        </InputBlock>
                        <InputBlock label={"Year for seasons"}>
                            <Controller
                                name={"seasonYear"}
                                type={"number"}
                                as={Input}
                                defaultValue={dayjs(form.watch(`deliveryDates.${index}`)).year() ?? dayjs().year()}
                                min={dayjs().year()}
                            />
                        </InputBlock>
                        <div className={"flex_row ml_10"}>
                            {SEASONS_DATES.map((season) => {
                                return (
                                    <UiButton
                                        key={season.key}
                                        onClick={() => {
                                            form.setValue(
                                                `deliveryDates.${index}`,
                                                dayjs()
                                                    .set("date", season.date)
                                                    .set("month", season.month)
                                                    .set("year", form.watch("seasonYear")),
                                            );
                                        }}
                                        variant={
                                            dayjs()
                                                .set("date", season.date)
                                                .set("month", season.month)
                                                .set("year", form.watch("seasonYear"))
                                                .isSame(form.watch(`deliveryDates.${index}`), "day")
                                                ? "contained"
                                                : "text"
                                        }
                                    >
                                        {t(`admin.property.seasons.${season.key}`)}
                                    </UiButton>
                                );
                            })}
                        </div>
                        <IconButton
                            onClick={() => {
                                remove(index);
                            }}
                            aria-label="close"
                        >
                            <IconComponent
                                className={"flex_row justifyContent_flexEnd"}
                                style={{ cursor: "pointer" }}
                                icon={"close"}
                            />
                        </IconButton>
                    </div>
                );
            })}
            <div>
                <UiButton
                    variant={"outlined"}
                    color={"primary"}
                    className="flex_row_center"
                    onClick={() => append(dayjs())}
                >
                    <IconComponent style={{ cursor: "pointer" }} icon="icon-plus" className="mr_10" />
                    {t("words.add") + " phase"}
                </UiButton>
            </div>
        </div>
    );
}
