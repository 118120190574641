export enum CA_PROVINCES {
    AB = "AB",
    BC = "BC",
    MB = "MB",
    NB = "NB",
    NL = "NL",
    NS = "NS",
    ON = "ON",
    PE = "PE",
    QC = "QC",
    SK = "SK",
    NT = "NT",
    NU = "NU",
    YT = "YT",
}

export enum CA_PROVINCES_URL {
    QC = "province_quebec",
    ON = "ontario",
}

export type TCity = {
    name: string;
    region: CA_PROVINCES | string;
    lat: number;
    lng: number;
    img?: string;
    isHomeCard?: boolean;
    nbOfProperties?: number;
};

export const CA_CITIES: TCity[] = [
    { name: "Toronto", region: "ON", lat: 43.7417, lng: -79.3733 },
    { name: "Montreal", region: "QC", lat: 45.5089, lng: -73.5617 },
    { name: "Vancouver", region: "BC", lat: 49.25, lng: -123.1 },
    { name: "Calgary", region: "AB", lat: 51.05, lng: -114.0667 },
    { name: "Edmonton", region: "AB", lat: 53.5344, lng: -113.4903 },
    { name: "Ottawa", region: "ON", lat: 45.4247, lng: -75.695 },
    { name: "Mississauga", region: "ON", lat: 43.6, lng: -79.65 },
    { name: "Winnipeg", region: "MB", lat: 49.8844, lng: -97.1464 },
    { name: "Quebec", region: "QC", lat: 46.8139, lng: -71.2081 },
    { name: "Hamilton", region: "ON", lat: 43.2567, lng: -79.8692 },
    { name: "Brampton", region: "ON", lat: 43.6833, lng: -79.7667 },
    { name: "Surrey", region: "BC", lat: 49.19, lng: -122.8489 },
    { name: "Kitchener", region: "ON", lat: 43.4186, lng: -80.4728 },
    { name: "Laval", region: "QC", lat: 45.5833, lng: -73.75 },
    { name: "Halifax", region: "NS", lat: 44.6475, lng: -63.5906 },
    { name: "London", region: "ON", lat: 42.9836, lng: -81.2497 },
    { name: "Victoria", region: "BC", lat: 48.4283, lng: -123.3647 },
    { name: "Markham", region: "ON", lat: 43.8767, lng: -79.2633 },
    { name: "St. Catharines", region: "ON", lat: 43.1833, lng: -79.2333 },
    { name: "Niagara Falls", region: "ON", lat: 43.06, lng: -79.1067 },
    { name: "Vaughan", region: "ON", lat: 43.8333, lng: -79.5 },
    { name: "Gatineau", region: "QC", lat: 45.4833, lng: -75.65 },
    { name: "Windsor", region: "ON", lat: 42.2833, lng: -83 },
    { name: "Saskatoon", region: "SK", lat: 52.1333, lng: -106.6833 },
    { name: "Longueuil", region: "QC", lat: 45.5333, lng: -73.5167 },
    { name: "Burnaby", region: "BC", lat: 49.2667, lng: -122.9667 },
    { name: "Regina", region: "SK", lat: 50.4547, lng: -104.6067 },
    { name: "Richmond", region: "BC", lat: 49.1667, lng: -123.1333 },
    { name: "Richmond Hill", region: "ON", lat: 43.8667, lng: -79.4333 },
    { name: "Oakville", region: "ON", lat: 43.45, lng: -79.6833 },
    { name: "Burlington", region: "ON", lat: 43.3167, lng: -79.8 },
    { name: "Barrie", region: "ON", lat: 44.3711, lng: -79.6769 },
    { name: "Oshawa", region: "ON", lat: 43.9, lng: -78.85 },
    { name: "Sherbrooke", region: "QC", lat: 45.4, lng: -71.9 },
    { name: "Saguenay", region: "QC", lat: 48.4167, lng: -71.0667 },
    { name: "Levis", region: "QC", lat: 46.8, lng: -71.1833 },
    { name: "Kelowna", region: "BC", lat: 49.8881, lng: -119.4956 },
    { name: "Abbotsford", region: "BC", lat: 49.05, lng: -122.3167 },
    { name: "Coquitlam", region: "BC", lat: 49.2839, lng: -122.7919 },
    { name: "Trois-Rivieres", region: "QC", lat: 46.35, lng: -72.55 },
    { name: "Guelph", region: "ON", lat: 43.55, lng: -80.25 },
    { name: "Cambridge", region: "ON", lat: 43.3972, lng: -80.3114 },
    { name: "Whitby", region: "ON", lat: 43.8833, lng: -78.9417 },
    { name: "Ajax", region: "ON", lat: 43.8583, lng: -79.0364 },
    { name: "Langley", region: "BC", lat: 49.1044, lng: -122.5827 },
    { name: "Saanich", region: "BC", lat: 48.484, lng: -123.381 },
    { name: "Terrebonne", region: "QC", lat: 45.7, lng: -73.6333 },
    { name: "Milton", region: "ON", lat: 43.5083, lng: -79.8833 },
    { name: "St. John's", region: "NL", lat: 47.4817, lng: -52.7971 },
    { name: "Moncton", region: "NB", lat: 46.1328, lng: -64.7714 },
    { name: "Thunder Bay", region: "ON", lat: 48.3822, lng: -89.2461 },
    { name: "Dieppe", region: "NB", lat: 46.0989, lng: -64.7242 },
    { name: "Waterloo", region: "ON", lat: 43.4667, lng: -80.5167 },
    { name: "Delta", region: "BC", lat: 49.0847, lng: -123.0586 },
    { name: "Chatham", region: "ON", lat: 42.4229, lng: -82.1324 },
    { name: "Red Deer", region: "AB", lat: 52.2681, lng: -113.8111 },
    { name: "Kamloops", region: "BC", lat: 50.6761, lng: -120.3408 },
    { name: "Brantford", region: "ON", lat: 43.1667, lng: -80.25 },
    { name: "Cape Breton", region: "NS", lat: 46.1389, lng: -60.1931 },
    { name: "Lethbridge", region: "AB", lat: 49.6942, lng: -112.8328 },
    { name: "Saint-Jean-sur-Richelieu", region: "QC", lat: 45.3167, lng: -73.2667 },
    { name: "Clarington", region: "ON", lat: 43.935, lng: -78.6083 },
    { name: "Pickering", region: "ON", lat: 43.8354, lng: -79.089 },
    { name: "Nanaimo", region: "BC", lat: 49.1642, lng: -123.9364 },
    { name: "Sudbury", region: "ON", lat: 46.49, lng: -81.01 },
    { name: "North Vancouver", region: "BC", lat: 49.3641, lng: -123.0066 },
    { name: "Brossard", region: "QC", lat: 45.4667, lng: -73.45 },
    { name: "Repentigny", region: "QC", lat: 45.7333, lng: -73.4667 },
    { name: "Newmarket", region: "ON", lat: 44.05, lng: -79.4667 },
    { name: "Chilliwack", region: "BC", lat: 49.1577, lng: -121.9509 },
    { name: "White Rock", region: "BC", lat: 49.025, lng: -122.8028 },
    { name: "Maple Ridge", region: "BC", lat: 49.2167, lng: -122.6 },
    { name: "Peterborough", region: "ON", lat: 44.3, lng: -78.3167 },
    { name: "Kawartha Lakes", region: "ON", lat: 44.35, lng: -78.75 },
    { name: "Prince George", region: "BC", lat: 53.9169, lng: -122.7494 },
    { name: "Sault Ste.Marie", region: "ON", lat: 46.5333, lng: -84.35 },
    { name: "Sarnia", region: "ON", lat: 42.9994, lng: -82.3089 },
    { name: "Wood Buffalo", region: "AB", lat: 57.6042, lng: -111.3284 },
    { name: "New Westminster", region: "BC", lat: 49.2069, lng: -122.9111 },
    { name: "Chateauguay", region: "QC", lat: 45.38, lng: -73.75 },
    { name: "Saint-Jerome", region: "QC", lat: 45.7833, lng: -74 },
    { name: "Drummondville", region: "QC", lat: 45.8833, lng: -72.4833 },
    { name: "Saint John", region: "NB", lat: 45.2806, lng: -66.0761 },
    { name: "Caledon", region: "ON", lat: 43.8667, lng: -79.8667 },
    { name: "St. Albert", region: "AB", lat: 53.6303, lng: -113.6258 },
    { name: "Granby", region: "QC", lat: 45.4, lng: -72.7333 },
    { name: "Medicine Hat", region: "AB", lat: 50.0417, lng: -110.6775 },
    { name: "Grande Prairie", region: "AB", lat: 55.1708, lng: -118.7947 },
    { name: "St. Thomas", region: "ON", lat: 42.775, lng: -81.1833 },
    { name: "Airdrie", region: "AB", lat: 51.2917, lng: -114.0144 },
    { name: "Halton Hills", region: "ON", lat: 43.63, lng: -79.95 },
    { name: "Saint-Hyacinthe", region: "QC", lat: 45.6167, lng: -72.95 },
    { name: "Lac-Brome", region: "QC", lat: 45.2167, lng: -72.5167 },
    { name: "Port Coquitlam", region: "BC", lat: 49.2625, lng: -122.7811 },
    { name: "Fredericton", region: "NB", lat: 45.9636, lng: -66.6431 },
    { name: "Blainville", region: "QC", lat: 45.67, lng: -73.88 },
    { name: "Aurora", region: "ON", lat: 44, lng: -79.4667 },
    { name: "Welland", region: "ON", lat: 42.9833, lng: -79.2333 },
    { name: "North Bay", region: "ON", lat: 46.3, lng: -79.45 },
    { name: "Beloeil", region: "QC", lat: 45.5667, lng: -73.2 },
    { name: "Belleville", region: "ON", lat: 44.1667, lng: -77.3833 },
    { name: "Mirabel", region: "QC", lat: 45.65, lng: -74.0833 },
    { name: "Shawinigan", region: "QC", lat: 46.5667, lng: -72.75 },
    { name: "Dollard-des-Ormeaux", region: "QC", lat: 45.4833, lng: -73.8167 },
    { name: "Brandon", region: "MB", lat: 49.8483, lng: -99.95 },
    { name: "Rimouski", region: "QC", lat: 48.45, lng: -68.53 },
    { name: "Cornwall", region: "ON", lat: 45.0275, lng: -74.74 },
    { name: "Stouffville", region: "ON", lat: 43.9667, lng: -79.25 },
    { name: "Georgina", region: "ON", lat: 44.3, lng: -79.4333 },
    { name: "Victoriaville", region: "QC", lat: 46.05, lng: -71.9667 },
    { name: "Vernon", region: "BC", lat: 50.267, lng: -119.272 },
    { name: "Duncan", region: "BC", lat: 48.7787, lng: -123.7079 },
    { name: "Saint-Eustache", region: "QC", lat: 45.57, lng: -73.9 },
    { name: "Quinte West", region: "ON", lat: 44.1833, lng: -77.5667 },
    { name: "Charlottetown", region: "PE", lat: 46.2403, lng: -63.1347 },
    { name: "Mascouche", region: "QC", lat: 45.75, lng: -73.6 },
    { name: "West Vancouver", region: "BC", lat: 49.3667, lng: -123.1667 },
    { name: "Salaberry-de-Valleyfield", region: "QC", lat: 45.25, lng: -74.13 },
    { name: "Rouyn-Noranda", region: "QC", lat: 48.2333, lng: -79.0167 },
    { name: "Timmins", region: "ON", lat: 48.4667, lng: -81.3333 },
    { name: "Sorel-Tracy", region: "QC", lat: 46.0333, lng: -73.1167 },
    { name: "New Tecumseth", region: "ON", lat: 44.0833, lng: -79.75 },
    { name: "Woodstock", region: "ON", lat: 43.1306, lng: -80.7467 },
    { name: "Boucherville", region: "QC", lat: 45.6, lng: -73.45 },
    { name: "Mission", region: "BC", lat: 49.1337, lng: -122.3112 },
    { name: "Vaudreuil-Dorion", region: "QC", lat: 45.4, lng: -74.0333 },
    { name: "Brant", region: "ON", lat: 43.1167, lng: -80.3667 },
    { name: "Lakeshore", region: "ON", lat: 42.2399, lng: -82.6511 },
    { name: "Innisfil", region: "ON", lat: 44.3, lng: -79.5833 },
    { name: "Prince Albert", region: "SK", lat: 53.2, lng: -105.75 },
    { name: "Langford Station", region: "BC", lat: 48.4506, lng: -123.5058 },
    { name: "Bradford West Gwillimbury", region: "ON", lat: 44.1333, lng: -79.6333 },
    { name: "Campbell River", region: "BC", lat: 50.0244, lng: -125.2475 },
    { name: "Spruce Grove", region: "AB", lat: 53.545, lng: -113.9008 },
    { name: "Moose Jaw", region: "SK", lat: 50.3933, lng: -105.5519 },
    { name: "Penticton", region: "BC", lat: 49.4911, lng: -119.5886 },
    { name: "Port Moody", region: "BC", lat: 49.2831, lng: -122.8317 },
    { name: "Leamington", region: "ON", lat: 42.0667, lng: -82.5833 },
    { name: "East Kelowna", region: "BC", lat: 49.8625, lng: -119.5833 },
    { name: "Cote-Saint-Luc", region: "QC", lat: 45.4687, lng: -73.6673 },
    { name: "Val-d'Or", region: "QC", lat: 48.1, lng: -77.7833 },
    { name: "Owen Sound", region: "ON", lat: 44.5667, lng: -80.9333 },
    { name: "Stratford", region: "ON", lat: 43.3708, lng: -80.9819 },
    { name: "Lloydminster", region: "SK", lat: 53.2783, lng: -110.005 },
    { name: "Pointe-Claire", region: "QC", lat: 45.45, lng: -73.8167 },
    { name: "Orillia", region: "ON", lat: 44.6, lng: -79.4167 },
    { name: "Alma", region: "QC", lat: 48.55, lng: -71.65 },
    { name: "Orangeville", region: "ON", lat: 43.9167, lng: -80.1167 },
    { name: "Fort Erie", region: "ON", lat: 42.9167, lng: -79.0167 },
    { name: "LaSalle", region: "ON", lat: 42.2167, lng: -83.0667 },
    { name: "Sainte-Julie", region: "QC", lat: 45.5833, lng: -73.3333 },
    { name: "Leduc", region: "AB", lat: 53.2594, lng: -113.5492 },
    { name: "North Cowichan", region: "BC", lat: 48.8236, lng: -123.7192 },
    { name: "Chambly", region: "QC", lat: 45.4311, lng: -73.2873 },
    { name: "Okotoks", region: "AB", lat: 50.725, lng: -113.975 },
    { name: "Sept-Iles", region: "QC", lat: 50.2167, lng: -66.3833 },
    { name: "Centre Wellington", region: "ON", lat: 43.7, lng: -80.3667 },
    { name: "Saint-Constant", region: "QC", lat: 45.37, lng: -73.57 },
    { name: "Grimsby", region: "ON", lat: 43.2, lng: -79.55 },
    { name: "Boisbriand", region: "QC", lat: 45.62, lng: -73.83 },
    { name: "Conception Bay South", region: "NL", lat: 47.5167, lng: -52.9833 },
    { name: "Saint-Bruno-de-Montarville", region: "QC", lat: 45.5333, lng: -73.35 },
    { name: "Sainte-Therese", region: "QC", lat: 45.6333, lng: -73.85 },
    { name: "Cochrane", region: "AB", lat: 51.189, lng: -114.467 },
    { name: "Thetford Mines", region: "QC", lat: 46.1, lng: -71.3 },
    { name: "Courtenay", region: "BC", lat: 49.6878, lng: -124.9944 },
    { name: "Magog", region: "QC", lat: 45.2667, lng: -72.15 },
    { name: "Whitehorse", region: "YT", lat: 60.7029, lng: -135.0691 },
    { name: "Woolwich", region: "ON", lat: 43.5667, lng: -80.4833 },
    { name: "Clarence-Rockland", region: "ON", lat: 45.4833, lng: -75.2 },
    { name: "Fort Saskatchewan", region: "AB", lat: 53.7128, lng: -113.2133 },
    { name: "East Gwillimbury", region: "ON", lat: 44.1333, lng: -79.4167 },
    { name: "Lincoln", region: "ON", lat: 43.13, lng: -79.43 },
    { name: "La Prairie", region: "QC", lat: 45.42, lng: -73.5 },
    { name: "Tecumseh", region: "ON", lat: 42.2431, lng: -82.9256 },
    { name: "Mount Pearl Park", region: "NL", lat: 47.5189, lng: -52.8058 },
    { name: "Amherstburg", region: "ON", lat: 42.1, lng: -83.0833 },
    { name: "Saint-Lambert", region: "QC", lat: 45.5, lng: -73.5167 },
    { name: "Brockville", region: "ON", lat: 44.5833, lng: -75.6833 },
    { name: "Collingwood", region: "ON", lat: 44.5, lng: -80.2167 },
    { name: "Scugog", region: "ON", lat: 44.09, lng: -78.936 },
    { name: "Kingsville", region: "ON", lat: 42.1, lng: -82.7167 },
    { name: "Baie-Comeau", region: "QC", lat: 49.2167, lng: -68.15 },
    { name: "Paradise", region: "NL", lat: 47.5333, lng: -52.8667 },
    { name: "Uxbridge", region: "ON", lat: 44.1167, lng: -79.1333 },
    { name: "Essa", region: "ON", lat: 44.25, lng: -79.7833 },
    { name: "Candiac", region: "QC", lat: 45.38, lng: -73.52 },
    { name: "Oro-Medonte", region: "ON", lat: 44.5667, lng: -79.5833 },
    { name: "Varennes", region: "QC", lat: 45.6833, lng: -73.4333 },
    { name: "Strathroy-Caradoc", region: "ON", lat: 42.9575, lng: -81.6167 },
    { name: "Wasaga Beach", region: "ON", lat: 44.5206, lng: -80.0167 },
    { name: "New Glasgow", region: "NS", lat: 45.5926, lng: -62.6455 },
    { name: "Wilmot", region: "ON", lat: 43.4, lng: -80.65 },
    { name: "Essex", region: "ON", lat: 42.0833, lng: -82.9 },
    { name: "Fort St.John", region: "BC", lat: 56.2465, lng: -120.8476 },
    { name: "Kirkland", region: "QC", lat: 45.45, lng: -73.8667 },
    { name: "L'Assomption", region: "QC", lat: 45.8333, lng: -73.4167 },
    { name: "Westmount", region: "QC", lat: 45.4833, lng: -73.6 },
    { name: "Saint-Lazare", region: "QC", lat: 45.4, lng: -74.1333 },
    { name: "Chestermere", region: "AB", lat: 51.05, lng: -113.8225 },
    { name: "Huntsville", region: "ON", lat: 45.3333, lng: -79.2167 },
    { name: "Corner Brook", region: "NL", lat: 48.9287, lng: -57.926 },
    { name: "Riverview", region: "NB", lat: 46.0613, lng: -64.8052 },
    { name: "Lloydminster", region: "AB", lat: 53.2807, lng: -110.035 },
    { name: "Joliette", region: "QC", lat: 46.0167, lng: -73.45 },
    { name: "Yellowknife", region: "NT", lat: 62.4709, lng: -114.4053 },
    { name: "Squamish", region: "BC", lat: 49.7017, lng: -123.1589 },
    { name: "Mont-Royal", region: "QC", lat: 45.5161, lng: -73.6431 },
    { name: "Riviere-du-Loup", region: "QC", lat: 47.8333, lng: -69.5333 },
    { name: "Cobourg", region: "ON", lat: 43.9667, lng: -78.1667 },
    { name: "Cranbrook", region: "BC", lat: 49.5097, lng: -115.7667 },
    { name: "Beaconsfield", region: "QC", lat: 45.4333, lng: -73.8667 },
    { name: "Springwater", region: "ON", lat: 44.4333, lng: -79.7333 },
    { name: "Dorval", region: "QC", lat: 45.45, lng: -73.75 },
    { name: "Thorold", region: "ON", lat: 43.1167, lng: -79.2 },
    { name: "Camrose", region: "AB", lat: 53.0167, lng: -112.8333 },
    { name: "South Frontenac", region: "ON", lat: 44.5081, lng: -76.4939 },
    { name: "Pitt Meadows", region: "BC", lat: 49.2333, lng: -122.6833 },
    { name: "Port Colborne", region: "ON", lat: 42.8833, lng: -79.25 },
    { name: "Quispamsis", region: "NB", lat: 45.4322, lng: -65.9462 },
    { name: "Mont-Saint-Hilaire", region: "QC", lat: 45.5622, lng: -73.1917 },
    { name: "Bathurst", region: "NB", lat: 47.62, lng: -65.65 },
    { name: "Saint-Augustin-de-Desmaures", region: "QC", lat: 46.7333, lng: -71.4667 },
    { name: "Oak Bay", region: "BC", lat: 48.4264, lng: -123.3228 },
    { name: "Sainte-Marthe-sur-le-Lac", region: "QC", lat: 45.53, lng: -73.93 },
    { name: "Salmon Arm", region: "BC", lat: 50.7022, lng: -119.2722 },
    { name: "Port Alberni", region: "BC", lat: 49.2339, lng: -124.805 },
    { name: "Esquimalt", region: "BC", lat: 48.4306, lng: -123.4147 },
    { name: "Deux-Montagnes", region: "QC", lat: 45.5333, lng: -73.8833 },
    { name: "Miramichi", region: "NB", lat: 47.0196, lng: -65.5072 },
    { name: "Niagara-on-the-Lake", region: "ON", lat: 43.2553, lng: -79.0717 },
    { name: "Saint-Lin--Laurentides", region: "QC", lat: 45.85, lng: -73.7667 },
    { name: "Beaumont", region: "AB", lat: 53.3572, lng: -113.4147 },
    { name: "Middlesex Centre", region: "ON", lat: 43.05, lng: -81.45 },
    { name: "Inverness", region: "NS", lat: 46.2, lng: -61.1 },
    { name: "Stony Plain", region: "AB", lat: 53.5264, lng: -114.0069 },
    { name: "Petawawa", region: "ON", lat: 45.9, lng: -77.2833 },
    { name: "Pelham", region: "ON", lat: 43.0333, lng: -79.3333 },
    { name: "Selwyn", region: "ON", lat: 44.4167, lng: -78.3333 },
    { name: "Loyalist", region: "ON", lat: 44.25, lng: -76.75 },
    { name: "Midland", region: "ON", lat: 44.75, lng: -79.8833 },
    { name: "Colwood", region: "BC", lat: 48.4236, lng: -123.4958 },
    { name: "Central Saanich", region: "BC", lat: 48.5142, lng: -123.3839 },
    { name: "Sainte-Catherine", region: "QC", lat: 45.4, lng: -73.58 },
    { name: "Port Hope", region: "ON", lat: 43.95, lng: -78.3 },
    { name: "L'Ancienne-Lorette", region: "QC", lat: 46.8, lng: -71.35 },
    { name: "Saint-Basile-le-Grand", region: "QC", lat: 45.5333, lng: -73.2833 },
    { name: "Swift Current", region: "SK", lat: 50.2881, lng: -107.7939 },
    { name: "Edmundston", region: "NB", lat: 47.3765, lng: -68.3253 },
    { name: "Russell", region: "ON", lat: 45.2569, lng: -75.3583 },
    { name: "North Grenville", region: "ON", lat: 44.9667, lng: -75.65 },
    { name: "Yorkton", region: "SK", lat: 51.2139, lng: -102.4628 },
    { name: "Tracadie", region: "NB", lat: 47.5124, lng: -64.9101 },
    { name: "Bracebridge", region: "ON", lat: 45.0333, lng: -79.3 },
    { name: "Greater Napanee", region: "ON", lat: 44.25, lng: -76.95 },
    { name: "Tillsonburg", region: "ON", lat: 42.8667, lng: -80.7333 },
    { name: "Steinbach", region: "MB", lat: 49.5258, lng: -96.6839 },
    { name: "Hanover", region: "MB", lat: 49.4433, lng: -96.8492 },
    { name: "Terrace", region: "BC", lat: 54.5164, lng: -128.5997 },
    { name: "Springfield", region: "MB", lat: 49.9292, lng: -96.6939 },
    { name: "Gaspe", region: "QC", lat: 48.8333, lng: -64.4833 },
    { name: "Kenora", region: "ON", lat: 49.7667, lng: -94.4833 },
    { name: "Cold Lake", region: "AB", lat: 54.4642, lng: -110.1825 },
    { name: "Summerside", region: "PE", lat: 46.4, lng: -63.7833 },
    { name: "Comox", region: "BC", lat: 49.6733, lng: -124.9022 },
    { name: "Sylvan Lake", region: "AB", lat: 52.3083, lng: -114.0964 },
    { name: "Pincourt", region: "QC", lat: 45.3833, lng: -73.9833 },
    { name: "West Lincoln", region: "ON", lat: 43.0667, lng: -79.5667 },
    { name: "Matane", region: "QC", lat: 48.85, lng: -67.5333 },
    { name: "Brooks", region: "AB", lat: 50.5642, lng: -111.8989 },
    { name: "Sainte-Anne-des-Plaines", region: "QC", lat: 45.7617, lng: -73.8204 },
    { name: "West Nipissing / Nipissing Ouest", region: "ON", lat: 46.3667, lng: -79.9167 },
    { name: "Rosemere", region: "QC", lat: 45.6369, lng: -73.8 },
    { name: "Mistassini", region: "QC", lat: 48.8229, lng: -72.2154 },
    { name: "Grand Falls", region: "NL", lat: 48.9578, lng: -55.6633 },
    { name: "Clearview", region: "ON", lat: 44.3981, lng: -80.0742 },
    { name: "St. Clair", region: "ON", lat: 42.7833, lng: -82.35 },
    { name: "Canmore", region: "AB", lat: 51.089, lng: -115.359 },
    { name: "North Battleford", region: "SK", lat: 52.7575, lng: -108.2861 },
    { name: "Pembroke", region: "ON", lat: 45.8167, lng: -77.1 },
    { name: "Mont-Laurier", region: "QC", lat: 46.55, lng: -75.5 },
    { name: "Strathmore", region: "AB", lat: 51.0378, lng: -113.4003 },
    { name: "Saugeen Shores", region: "ON", lat: 44.4333, lng: -81.3667 },
    { name: "Thompson", region: "MB", lat: 55.7433, lng: -97.8553 },
    { name: "Lavaltrie", region: "QC", lat: 45.8833, lng: -73.2833 },
    { name: "High River", region: "AB", lat: 50.5808, lng: -113.8744 },
    { name: "Severn", region: "ON", lat: 44.75, lng: -79.5167 },
    { name: "Sainte-Sophie", region: "QC", lat: 45.82, lng: -73.9 },
    { name: "Saint-Charles-Borromee", region: "QC", lat: 46.05, lng: -73.4667 },
    { name: "Portage La Prairie", region: "MB", lat: 49.9728, lng: -98.2919 },
    { name: "Thames Centre", region: "ON", lat: 43.03, lng: -81.08 },
    { name: "Mississippi Mills", region: "ON", lat: 45.2167, lng: -76.2 },
    { name: "Powell River", region: "BC", lat: 49.8353, lng: -124.5247 },
    { name: "South Glengarry", region: "ON", lat: 45.2, lng: -74.5833 },
    { name: "North Perth", region: "ON", lat: 43.73, lng: -80.95 },
    { name: "Mercier", region: "QC", lat: 45.32, lng: -73.75 },
    { name: "South Stormont", region: "ON", lat: 45.0833, lng: -74.9667 },
    { name: "Saint-Colomban", region: "QC", lat: 45.73, lng: -74.13 },
    { name: "Lacombe", region: "AB", lat: 52.4683, lng: -113.7369 },
    { name: "Sooke", region: "BC", lat: 48.3761, lng: -123.7378 },
    { name: "Dawson Creek", region: "BC", lat: 55.7606, lng: -120.2356 },
    { name: "Lake Country", region: "BC", lat: 50.0833, lng: -119.4142 },
    { name: "Trent Hills", region: "ON", lat: 44.3142, lng: -77.8514 },
    { name: "Sainte-Marie", region: "QC", lat: 46.45, lng: -71.0333 },
    { name: "Guelph / Eramos", region: "ON", lat: 43.63, lng: -80.22 },
    { name: "Truro", region: "NS", lat: 45.3647, lng: -63.28 },
    { name: "Amos", region: "QC", lat: 48.5667, lng: -78.1167 },
    { name: "The Nation / La Nation", region: "ON", lat: 45.35, lng: -75.0333 },
    { name: "Ingersoll", region: "ON", lat: 43.0392, lng: -80.8836 },
    { name: "Winkler", region: "MB", lat: 49.1817, lng: -97.9397 },
    { name: "Wetaskiwin", region: "AB", lat: 52.9694, lng: -113.3769 },
    { name: "Central Elgin", region: "ON", lat: 42.7667, lng: -81.1 },
    { name: "Lachute", region: "QC", lat: 45.65, lng: -74.3333 },
    { name: "West Grey", region: "ON", lat: 44.1833, lng: -80.8167 },
    { name: "Parksville", region: "BC", lat: 49.315, lng: -124.312 },
    { name: "Cowansville", region: "QC", lat: 45.2, lng: -72.75 },
    { name: "Becancour", region: "QC", lat: 46.3333, lng: -72.4333 },
    { name: "Gravenhurst", region: "ON", lat: 44.9167, lng: -79.3667 },
    { name: "Perth East", region: "ON", lat: 43.47, lng: -80.95 },
    { name: "Prince Rupert", region: "BC", lat: 54.3122, lng: -130.3271 },
    { name: "Prevost", region: "QC", lat: 45.87, lng: -74.08 },
    { name: "Sainte-Adele", region: "QC", lat: 45.95, lng: -74.13 },
    { name: "Kentville", region: "NS", lat: 45.0775, lng: -64.4958 },
    { name: "Beauharnois", region: "QC", lat: 45.32, lng: -73.87 },
    { name: "Les Iles-de-la-Madeleine", region: "QC", lat: 47.3833, lng: -61.8667 },
    { name: "Wellington North", region: "ON", lat: 43.9, lng: -80.57 },
    { name: "St. Andrews", region: "MB", lat: 50.27, lng: -96.9747 },
    { name: "Carleton Place", region: "ON", lat: 45.1333, lng: -76.1333 },
    { name: "Whistler", region: "BC", lat: 50.1208, lng: -122.9544 },
    { name: "Brighton", region: "ON", lat: 44.1222, lng: -77.7642 },
    { name: "Tiny", region: "ON", lat: 44.6833, lng: -79.95 },
    { name: "Gander", region: "NL", lat: 48.9569, lng: -54.6089 },
    { name: "Sidney", region: "BC", lat: 48.6506, lng: -123.3986 },
    { name: "Rothesay", region: "NB", lat: 45.3831, lng: -65.9969 },
    { name: "Brock", region: "ON", lat: 44.3167, lng: -79.0833 },
    { name: "Summerland", region: "BC", lat: 49.6006, lng: -119.6778 },
    { name: "Val-des-Monts", region: "QC", lat: 45.65, lng: -75.6667 },
    { name: "Tache", region: "MB", lat: 49.7081, lng: -96.6736 },
    { name: "Montmagny", region: "QC", lat: 46.9833, lng: -70.55 },
    { name: "Erin", region: "ON", lat: 43.7667, lng: -80.0667 },
    { name: "Kincardine", region: "ON", lat: 44.1667, lng: -81.6333 },
    { name: "North Dundas", region: "ON", lat: 45.0833, lng: -75.35 },
    { name: "Wellesley", region: "ON", lat: 43.55, lng: -80.7167 },
    { name: "Estevan", region: "SK", lat: 49.1392, lng: -102.9861 },
    { name: "North Saanich", region: "BC", lat: 48.6142, lng: -123.42 },
    { name: "Warman", region: "SK", lat: 52.3219, lng: -106.5842 },
    { name: "La Tuque", region: "QC", lat: 48.0652, lng: -74.0528 },
    { name: "Norwich", region: "ON", lat: 42.9833, lng: -80.6 },
    { name: "Meaford", region: "ON", lat: 44.58, lng: -80.73 },
    { name: "Adjala-Tosorontio", region: "ON", lat: 44.1333, lng: -79.9333 },
    { name: "Hamilton Township", region: "ON", lat: 44.054, lng: -78.2164 },
    { name: "St. Clements", region: "MB", lat: 50.2689, lng: -96.6742 },
    { name: "Saint-Amable", region: "QC", lat: 45.65, lng: -73.3 },
    { name: "Weyburn", region: "SK", lat: 49.6611, lng: -103.8525 },
    { name: "South Dundas", region: "ON", lat: 44.9167, lng: -75.2667 },
    { name: "L'Ile-Perrot", region: "QC", lat: 45.3833, lng: -73.95 },
    { name: "Notre-Dame-de-l'Ile-Perrot", region: "QC", lat: 45.3667, lng: -73.9333 },
    { name: "Williams Lake", region: "BC", lat: 52.1294, lng: -122.1383 },
    { name: "Elliot Lake", region: "ON", lat: 46.3833, lng: -82.65 },
    { name: "Cantley", region: "QC", lat: 45.5667, lng: -75.7833 },
    { name: "Nelson", region: "BC", lat: 49.5, lng: -117.2833 },
    { name: "Lambton Shores", region: "ON", lat: 43.1833, lng: -81.9 },
    { name: "Mapleton", region: "ON", lat: 43.7358, lng: -80.6681 },
    { name: "Georgian Bluffs", region: "ON", lat: 44.65, lng: -81.0333 },
    { name: "Rawdon", region: "QC", lat: 46.05, lng: -73.7167 },
    { name: "Campbellton", region: "NB", lat: 48.005, lng: -66.6731 },
    { name: "View Royal", region: "BC", lat: 48.4517, lng: -123.4339 },
    { name: "Coldstream", region: "BC", lat: 50.22, lng: -119.2481 },
    { name: "Chester", region: "NS", lat: 44.65, lng: -64.3 },
    { name: "Queens", region: "NS", lat: 44.0333, lng: -64.7167 },
    { name: "Selkirk", region: "MB", lat: 50.1436, lng: -96.8839 },
    { name: "Saint-Felicien", region: "QC", lat: 48.65, lng: -72.45 },
    { name: "Hawkesbury", region: "ON", lat: 45.6, lng: -74.6 },
    { name: "Roberval", region: "QC", lat: 48.52, lng: -72.23 },
    { name: "Sainte-Agathe-des-Monts", region: "QC", lat: 46.05, lng: -74.28 },
    { name: "North Dumfries", region: "ON", lat: 43.32, lng: -80.38 },
    { name: "Rideau Lakes", region: "ON", lat: 44.6667, lng: -76.2167 },
    { name: "Sechelt", region: "BC", lat: 49.4742, lng: -123.7542 },
    { name: "North Glengarry", region: "ON", lat: 45.3333, lng: -74.7333 },
    { name: "South Huron", region: "ON", lat: 43.32, lng: -81.5 },
    { name: "Marieville", region: "QC", lat: 45.4333, lng: -73.1667 },
    { name: "Tay", region: "ON", lat: 44.7167, lng: -79.7667 },
    { name: "Temiskaming Shores", region: "ON", lat: 47.5167, lng: -79.6833 },
    { name: "Hinton", region: "AB", lat: 53.4114, lng: -117.5639 },
    { name: "Saint-Sauveur", region: "QC", lat: 45.9, lng: -74.17 },
    { name: "Quesnel", region: "BC", lat: 52.9784, lng: -122.4927 },
    { name: "Elizabethtown-Kitley", region: "ON", lat: 44.7, lng: -75.8833 },
    { name: "Morinville", region: "AB", lat: 53.8022, lng: -113.6497 },
    { name: "Grey Highlands", region: "ON", lat: 44.3333, lng: -80.5 },
    { name: "Stratford", region: "PE", lat: 46.2167, lng: -63.0893 },
    { name: "Alfred and Plantagenet", region: "ON", lat: 45.5667, lng: -74.9167 },
    { name: "Mont-Tremblant", region: "QC", lat: 46.1167, lng: -74.6 },
    { name: "Martensville", region: "SK", lat: 52.2897, lng: -106.6667 },
    { name: "Saint-Raymond", region: "QC", lat: 46.9, lng: -71.8333 },
    { name: "Amherst", region: "NS", lat: 45.8167, lng: -64.2167 },
    { name: "Ramara", region: "ON", lat: 44.6333, lng: -79.2167 },
    { name: "Bois-des-Filion", region: "QC", lat: 45.6667, lng: -73.75 },
    { name: "Leeds and the Thousand Islands", region: "ON", lat: 44.45, lng: -76.08 },
    { name: "Carignan", region: "QC", lat: 45.45, lng: -73.3 },
    { name: "Brockton", region: "ON", lat: 44.1667, lng: -81.2167 },
    { name: "Laurentian Valley", region: "ON", lat: 45.7681, lng: -77.2239 },
    { name: "East St. Paul", region: "MB", lat: 49.9772, lng: -97.0103 },
    { name: "Lorraine", region: "QC", lat: 45.6833, lng: -73.7833 },
    { name: "Sainte-Julienne", region: "QC", lat: 45.97, lng: -73.72 },
    { name: "Blackfalds", region: "AB", lat: 52.3833, lng: -113.8 },
    { name: "Malahide", region: "ON", lat: 42.7928, lng: -80.9361 },
    { name: "Oromocto", region: "NB", lat: 45.8488, lng: -66.4788 },
    { name: "Olds", region: "AB", lat: 51.7928, lng: -114.1067 },
    { name: "Huron East", region: "ON", lat: 43.63, lng: -81.28 },
    { name: "Stanley", region: "MB", lat: 49.1331, lng: -98.0656 },
    { name: "Penetanguishene", region: "ON", lat: 44.7667, lng: -79.9333 },
    { name: "Qualicum Beach", region: "BC", lat: 49.35, lng: -124.4333 },
    { name: "Notre-Dame-des-Prairies", region: "QC", lat: 46.05, lng: -73.4333 },
    { name: "West Perth", region: "ON", lat: 43.47, lng: -81.2 },
    { name: "Cavan Monaghan", region: "ON", lat: 44.2, lng: -78.4667 },
    { name: "Arnprior", region: "ON", lat: 45.4333, lng: -76.35 },
    { name: "Smiths Falls", region: "ON", lat: 44.9, lng: -76.0167 },
    { name: "Pont-Rouge", region: "QC", lat: 46.75, lng: -71.7 },
    { name: "Champlain", region: "ON", lat: 45.5333, lng: -74.65 },
    { name: "Coaticook", region: "QC", lat: 45.1333, lng: -71.8 },
    { name: "Minto", region: "ON", lat: 43.9167, lng: -80.8667 },
    { name: "Morden", region: "MB", lat: 49.1919, lng: -98.1006 },
    { name: "Mono", region: "ON", lat: 44.0167, lng: -80.0667 },
    { name: "Corman Park No. 344", region: "SK", lat: 52.2291, lng: -106.8002 },
    { name: "Ladysmith", region: "BC", lat: 48.9975, lng: -123.8203 },
    { name: "Bridgewater", region: "NS", lat: 44.37, lng: -64.52 },
    { name: "Dauphin", region: "MB", lat: 51.1992, lng: -100.0633 },
    { name: "Otterburn Park", region: "QC", lat: 45.5333, lng: -73.2167 },
    { name: "Taber", region: "AB", lat: 49.7847, lng: -112.1508 },
    { name: "South Bruce Peninsula", region: "ON", lat: 44.7333, lng: -81.2 },
    { name: "Edson", region: "AB", lat: 53.5817, lng: -116.4344 },
    { name: "Farnham", region: "QC", lat: 45.2833, lng: -72.9833 },
    { name: "Kapuskasing", region: "ON", lat: 49.4167, lng: -82.4333 },
    { name: "La Malbaie", region: "QC", lat: 47.65, lng: -70.15 },
    { name: "Renfrew", region: "ON", lat: 45.4717, lng: -76.6831 },
    { name: "Coaldale", region: "AB", lat: 49.7333, lng: -112.6167 },
    { name: "Portugal Cove-St. Philip's", region: "NL", lat: 47.6272, lng: -52.8506 },
    { name: "Zorra", region: "ON", lat: 43.15, lng: -80.95 },
    { name: "Kitimat", region: "BC", lat: 54, lng: -128.7 },
    { name: "Shelburne", region: "ON", lat: 44.0833, lng: -80.2 },
    { name: "Happy Valley", region: "NL", lat: 53.3396, lng: -60.4467 },
    { name: "Saint-Hippolyte", region: "QC", lat: 45.93, lng: -74.02 },
    { name: "Castlegar", region: "BC", lat: 49.3256, lng: -117.6661 },
    { name: "Church Point", region: "NS", lat: 44.3333, lng: -66.1167 },
    { name: "Drumheller", region: "AB", lat: 51.4636, lng: -112.7194 },
    { name: "Kirkland Lake", region: "ON", lat: 48.15, lng: -80.0333 },
    { name: "Argyle", region: "NS", lat: 43.8, lng: -65.85 },
    { name: "Torbay", region: "NL", lat: 47.65, lng: -52.7333 },
    { name: "La Peche", region: "QC", lat: 45.6833, lng: -75.9833 },
    { name: "Banff", region: "AB", lat: 51.1781, lng: -115.5719 },
    { name: "Innisfail", region: "AB", lat: 52.0333, lng: -113.95 },
    { name: "Nicolet", region: "QC", lat: 46.2167, lng: -72.6167 },
    { name: "Rockwood", region: "MB", lat: 50.2856, lng: -97.2869 },
    { name: "Drummond/North Elmsley", region: "ON", lat: 44.9667, lng: -76.2 },
    { name: "Dryden", region: "ON", lat: 49.7833, lng: -92.8333 },
    { name: "Iqaluit", region: "NU", lat: 63.7598, lng: -68.5107 },
    { name: "Fort Frances", region: "ON", lat: 48.6167, lng: -93.4 },
    { name: "La Sarre", region: "QC", lat: 48.8, lng: -79.2 },
    { name: "Trail", region: "BC", lat: 49.095, lng: -117.71 },
    { name: "Chandler", region: "QC", lat: 48.35, lng: -64.6833 },
    { name: "Stone Mills", region: "ON", lat: 44.45, lng: -76.9167 },
    { name: "Hanover", region: "ON", lat: 44.15, lng: -81.0333 },
    { name: "South-West Oxford", region: "ON", lat: 42.95, lng: -80.8 },
    { name: "Acton Vale", region: "QC", lat: 45.65, lng: -72.5667 },
    { name: "Bromont", region: "QC", lat: 45.3167, lng: -72.65 },
    { name: "Beckwith", region: "ON", lat: 45.0833, lng: -76.0667 },
    { name: "Goderich", region: "ON", lat: 43.7333, lng: -81.7 },
    { name: "Plympton-Wyoming", region: "ON", lat: 43.0167, lng: -82.0833 },
    { name: "Central Huron", region: "ON", lat: 43.63, lng: -81.57 },
    { name: "Rigaud", region: "QC", lat: 45.4833, lng: -74.3 },
    { name: "Louiseville", region: "QC", lat: 46.25, lng: -72.95 },
    { name: "Chibougamau", region: "QC", lat: 49.9167, lng: -74.3667 },
    { name: "Aylmer", region: "ON", lat: 42.7667, lng: -80.9833 },
    { name: "Delson", region: "QC", lat: 45.37, lng: -73.55 },
    { name: "Kimberley", region: "BC", lat: 49.6697, lng: -115.9775 },
    { name: "Blandford-Blenheim", region: "ON", lat: 43.2333, lng: -80.6 },
    { name: "Bayham", region: "ON", lat: 42.7333, lng: -80.7833 },
    { name: "Augusta", region: "ON", lat: 44.7511, lng: -75.6003 },
    { name: "Puslinch", region: "ON", lat: 43.45, lng: -80.1667 },
    { name: "Beauport", region: "QC", lat: 46.9667, lng: -71.3 },
    { name: "Saint-Remi", region: "QC", lat: 45.2667, lng: -73.6167 },
    { name: "St. Marys", region: "ON", lat: 43.2583, lng: -81.1333 },
    { name: "Drayton Valley", region: "AB", lat: 53.2222, lng: -114.9769 },
    { name: "Ponoka", region: "AB", lat: 52.6833, lng: -113.5667 },
    { name: "Labrador City", region: "NL", lat: 52.95, lng: -66.9167 },
    { name: "Donnacona", region: "QC", lat: 46.6747, lng: -71.7294 },
    { name: "Southgate", region: "ON", lat: 44.1, lng: -80.5833 },
    { name: "McNab/Braeside", region: "ON", lat: 45.45, lng: -76.5 },
    { name: "Macdonald", region: "MB", lat: 49.6725, lng: -97.4472 },
    { name: "Hampstead", region: "QC", lat: 45.4833, lng: -73.6333 },
    { name: "Baie-Saint-Paul", region: "QC", lat: 47.45, lng: -70.5 },
    { name: "Merritt", region: "BC", lat: 50.1128, lng: -120.7897 },
    { name: "Bluewater", region: "ON", lat: 43.45, lng: -81.6 },
    { name: "East Zorra-Tavistock", region: "ON", lat: 43.2333, lng: -80.7833 },
    { name: "Brownsburg", region: "QC", lat: 45.6703, lng: -74.4467 },
    { name: "Stoneham-et-Tewkesbury", region: "QC", lat: 47.1667, lng: -71.4333 },
    { name: "Asbestos", region: "QC", lat: 45.7667, lng: -71.9333 },
    { name: "Huron-Kinloss", region: "ON", lat: 44.05, lng: -81.5333 },
    { name: "Coteau-du-Lac", region: "QC", lat: 45.3, lng: -74.18 },
    { name: "The Blue Mountains", region: "ON", lat: 44.4833, lng: -80.3833 },
    { name: "Whitewater Region", region: "ON", lat: 45.7167, lng: -76.8333 },
    { name: "Edwardsburgh/Cardinal", region: "ON", lat: 44.8333, lng: -75.5 },
    { name: "Sainte-Anne-des-Monts", region: "QC", lat: 49.1333, lng: -66.5 },
    { name: "Old Chelsea", region: "QC", lat: 45.5, lng: -75.7833 },
    { name: "North Stormont", region: "ON", lat: 45.2167, lng: -75 },
    { name: "Alnwick/Haldimand", region: "ON", lat: 44.0833, lng: -78.0333 },
    { name: "Peace River", region: "AB", lat: 56.2339, lng: -117.2897 },
    { name: "Arran-Elderslie", region: "ON", lat: 44.4, lng: -81.2 },
    { name: "Saint-Zotique", region: "QC", lat: 45.25, lng: -74.25 },
    { name: "Val-Shefford", region: "QC", lat: 45.35, lng: -72.5667 },
    { name: "Douro-Dummer", region: "ON", lat: 44.45, lng: -78.1 },
    { name: "Plessisville", region: "QC", lat: 46.2167, lng: -71.7833 },
    { name: "Ritchot", region: "MB", lat: 49.6647, lng: -97.1167 },
    { name: "Otonabee-South Monaghan", region: "ON", lat: 44.2333, lng: -78.2333 },
    { name: "Shediac", region: "NB", lat: 46.2167, lng: -64.5333 },
    { name: "Slave Lake", region: "AB", lat: 55.2853, lng: -114.7706 },
    { name: "Port-Cartier", region: "QC", lat: 50.0333, lng: -66.8667 },
    { name: "Saint-Lambert-de-Lauzon", region: "QC", lat: 46.5865, lng: -71.2271 },
    { name: "Barrington", region: "NS", lat: 43.5646, lng: -65.5639 },
    { name: "Rocky Mountain House", region: "AB", lat: 52.3753, lng: -114.9217 },
    { name: "Chatsworth", region: "ON", lat: 44.38, lng: -80.87 },
    { name: "Stephenville", region: "NL", lat: 48.55, lng: -58.5667 },
    { name: "Muskoka Falls", region: "ON", lat: 45.1264, lng: -79.558 },
    { name: "Devon", region: "AB", lat: 53.3633, lng: -113.7322 },
    { name: "Yarmouth", region: "NS", lat: 43.8361, lng: -66.1175 },
    { name: "Boischatel", region: "QC", lat: 46.9, lng: -71.15 },
    { name: "Parry Sound", region: "ON", lat: 45.3333, lng: -80.0333 },
    { name: "Pointe-Calumet", region: "QC", lat: 45.5, lng: -73.97 },
    { name: "Beaubassin East / Beaubassin-est", region: "NB", lat: 46.1726, lng: -64.3122 },
    { name: "Wainfleet", region: "ON", lat: 42.925, lng: -79.375 },
    { name: "Cramahe", region: "ON", lat: 44.0833, lng: -77.8833 },
    { name: "Beauceville", region: "QC", lat: 46.2, lng: -70.7833 },
    { name: "North Middlesex", region: "ON", lat: 43.15, lng: -81.6333 },
    { name: "Amqui", region: "QC", lat: 48.4667, lng: -67.4333 },
    { name: "Sainte-Catherine-de-la-Jacques-Cartier", region: "QC", lat: 46.85, lng: -71.6167 },
    { name: "Clarenville", region: "NL", lat: 48.1566, lng: -53.965 },
    { name: "Mont-Joli", region: "QC", lat: 48.58, lng: -68.18 },
    { name: "Dysart et al", region: "ON", lat: 45.2042, lng: -78.4047 },
    { name: "Wainwright", region: "AB", lat: 52.8333, lng: -110.8667 },
    { name: "Contrecoeur", region: "QC", lat: 45.85, lng: -73.2333 },
    { name: "Beresford", region: "NB", lat: 47.7181, lng: -65.8794 },
    { name: "Saint-Joseph-du-Lac", region: "QC", lat: 45.5333, lng: -74 },
    { name: "Hope", region: "BC", lat: 49.3858, lng: -121.4419 },
    { name: "Gimli", region: "MB", lat: 50.6619, lng: -97.0297 },
    { name: "Douglas", region: "NB", lat: 46.2819, lng: -66.942 },
    { name: "Saint-Apollinaire", region: "QC", lat: 46.6167, lng: -71.5167 },
    { name: "Hindon Hill", region: "ON", lat: 44.9333, lng: -78.7333 },
    { name: "Les Cedres", region: "QC", lat: 45.3, lng: -74.05 },
    { name: "La Broquerie", region: "MB", lat: 49.3994, lng: -96.5103 },
    { name: "Kent", region: "BC", lat: 49.2833, lng: -121.75 },
    { name: "Tweed", region: "ON", lat: 44.6, lng: -77.3333 },
    { name: "Saint-Felix-de-Valois", region: "QC", lat: 46.17, lng: -73.43 },
    { name: "Bay Roberts", region: "NL", lat: 47.5847, lng: -53.2783 },
    { name: "Melfort", region: "SK", lat: 52.8564, lng: -104.61 },
    { name: "Bonnyville", region: "AB", lat: 54.2667, lng: -110.75 },
    { name: "Stettler", region: "AB", lat: 52.3236, lng: -112.7192 },
    { name: "Saint-Calixte", region: "QC", lat: 45.95, lng: -73.85 },
    { name: "Lac-Megantic", region: "QC", lat: 45.5833, lng: -70.8833 },
    { name: "Perth", region: "ON", lat: 44.9, lng: -76.25 },
    { name: "Oliver Paipoonge", region: "ON", lat: 48.39, lng: -89.52 },
    { name: "Humboldt", region: "SK", lat: 52.2019, lng: -105.1231 },
    { name: "Charlemagne", region: "QC", lat: 45.7167, lng: -73.4833 },
    { name: "Pontiac", region: "QC", lat: 45.5833, lng: -76.1333 },
    { name: "St. Paul", region: "AB", lat: 53.9928, lng: -111.2972 },
    { name: "Petrolia", region: "ON", lat: 42.8833, lng: -82.1417 },
    { name: "Southwest Middlesex", region: "ON", lat: 42.75, lng: -81.7 },
    { name: "Front of Yonge", region: "ON", lat: 44.5333, lng: -75.8667 },
    { name: "Vegreville", region: "AB", lat: 53.4928, lng: -112.0522 },
    { name: "Sainte-Brigitte-de-Laval", region: "QC", lat: 47, lng: -71.2 },
    { name: "Princeville", region: "QC", lat: 46.1667, lng: -71.8833 },
    { name: "Vercheres", region: "QC", lat: 45.7833, lng: -73.35 },
    { name: "The Pas", region: "MB", lat: 53.825, lng: -101.2533 },
    { name: "Saint-Cesaire", region: "QC", lat: 45.4167, lng: -73 },
    { name: "La Ronge", region: "SK", lat: 55.1, lng: -105.3 },
    { name: "Tay Valley", region: "ON", lat: 44.8667, lng: -76.3833 },
    { name: "South Bruce", region: "ON", lat: 44.0333, lng: -81.2 },
    { name: "McMasterville", region: "QC", lat: 45.55, lng: -73.2333 },
    { name: "Redcliff", region: "AB", lat: 50.0792, lng: -110.7783 },
    { name: "Crowsnest Pass", region: "AB", lat: 49.5955, lng: -114.5136 },
    { name: "Saint-Philippe", region: "QC", lat: 45.35, lng: -73.47 },
    { name: "Richelieu", region: "QC", lat: 45.45, lng: -73.25 },
    { name: "Notre-Dame-du-Mont-Carmel", region: "QC", lat: 46.4833, lng: -72.65 },
    { name: "L'Ange-Gardien", region: "QC", lat: 45.5833, lng: -75.45 },
    { name: "Sainte-Martine", region: "QC", lat: 45.25, lng: -73.8 },
    { name: "Saint-Pie", region: "QC", lat: 45.5, lng: -72.9 },
    { name: "Peachland", region: "BC", lat: 49.7736, lng: -119.7369 },
    { name: "Ashfield-Colborne-Wawanosh", region: "ON", lat: 43.8667, lng: -81.6 },
    { name: "Trent Lakes", region: "ON", lat: 44.6667, lng: -78.4333 },
    { name: "Northern Rockies", region: "BC", lat: 59, lng: -123.75 },
    { name: "Cookshire", region: "QC", lat: 45.3729, lng: -71.672 },
    { name: "West St. Paul", region: "MB", lat: 50.0119, lng: -97.115 },
    { name: "Windsor", region: "QC", lat: 45.5667, lng: -72 },
    { name: "L'Epiphanie", region: "QC", lat: 45.85, lng: -73.4833 },
    { name: "Creston", region: "BC", lat: 49.09, lng: -116.51 },
    { name: "Smithers", region: "BC", lat: 54.7819, lng: -127.1681 },
    { name: "Cornwall", region: "PE", lat: 46.2407, lng: -63.2009 },
    { name: "Meadow Lake", region: "SK", lat: 54.1242, lng: -108.4358 },
    { name: "Lanark Highlands", region: "ON", lat: 45.088, lng: -76.517 },
    { name: "Sackville", region: "NB", lat: 45.9, lng: -64.3667 },
    { name: "Grand Falls", region: "NB", lat: 47.0344, lng: -67.7394 },
    { name: "Cochrane", region: "ON", lat: 49.0667, lng: -81.0167 },
    { name: "Marystown", region: "NL", lat: 47.1667, lng: -55.1667 },
    { name: "Sioux Lookout", region: "ON", lat: 50.1, lng: -91.9167 },
    { name: "Didsbury", region: "AB", lat: 51.6658, lng: -114.1311 },
    { name: "Saint-Honore", region: "QC", lat: 48.5333, lng: -71.0833 },
    { name: "Fernie", region: "BC", lat: 49.5042, lng: -115.0628 },
    { name: "Deer Lake", region: "NL", lat: 49.1744, lng: -57.4269 },
    { name: "Woodstock", region: "NB", lat: 46.1522, lng: -67.5983 },
    { name: "Val-David", region: "QC", lat: 46.03, lng: -74.22 },
    { name: "Flin Flon", region: "SK", lat: 54.7667, lng: -101.8778 },
    { name: "Hudson", region: "QC", lat: 45.45, lng: -74.15 },
    { name: "Gananoque", region: "ON", lat: 44.33, lng: -76.17 },
    { name: "Brokenhead", region: "MB", lat: 50.1428, lng: -96.5319 },
    { name: "Saint-Paul", region: "QC", lat: 45.9833, lng: -73.45 },
    { name: "Burton", region: "NB", lat: 45.8009, lng: -66.4066 },
    { name: "Spallumcheen", region: "BC", lat: 50.4462, lng: -119.2121 },
    { name: "Westlock", region: "AB", lat: 54.1522, lng: -113.8511 },
    { name: "Temiscouata-sur-le-Lac", region: "QC", lat: 47.68, lng: -68.88 },
    { name: "Shannon", region: "QC", lat: 46.8833, lng: -71.5167 },
    { name: "Osoyoos", region: "BC", lat: 49.0325, lng: -119.4661 },
    { name: "Montreal-Ouest", region: "QC", lat: 45.4536, lng: -73.6472 },
    { name: "Hearst", region: "ON", lat: 49.6869, lng: -83.6544 },
    { name: "Saint-Henri", region: "QC", lat: 46.7, lng: -71.0667 },
    { name: "Ste. Anne", region: "MB", lat: 49.6186, lng: -96.5708 },
    { name: "Antigonish", region: "NS", lat: 45.6167, lng: -61.9833 },
    { name: "Espanola", region: "ON", lat: 46.25, lng: -81.7667 },
    { name: "West Elgin", region: "ON", lat: 42.5833, lng: -81.6667 },
    { name: "Flin Flon (Part)", region: "MB", lat: 54.7712, lng: -101.8419 },
    { name: "Grand Bay-Westfield", region: "NB", lat: 45.3608, lng: -66.2415 },
    { name: "Sainte-Anne-de-Bellevue", region: "QC", lat: 45.4039, lng: -73.9525 },
    { name: "North Huron", region: "ON", lat: 43.83, lng: -81.42 },
    { name: "Oliver", region: "BC", lat: 49.1844, lng: -119.55 },
    { name: "Saint-Roch-de-l'Achigan", region: "QC", lat: 45.85, lng: -73.6 },
    { name: "Stirling-Rawdon", region: "ON", lat: 44.3667, lng: -77.5917 },
    { name: "Chisasibi", region: "QC", lat: 53.6645, lng: -78.7938 },
    { name: "Carbonear", region: "NL", lat: 47.7375, lng: -53.2294 },
    { name: "Saint Marys", region: "NB", lat: 46.1748, lng: -66.4897 },
    { name: "Chertsey", region: "QC", lat: 46.17, lng: -73.92 },
    { name: "Armstrong", region: "BC", lat: 50.4483, lng: -119.1961 },
    { name: "Stonewall", region: "MB", lat: 50.1344, lng: -97.3261 },
    { name: "Shippagan", region: "NB", lat: 47.8557, lng: -64.6012 },
    { name: "Lanoraie", region: "QC", lat: 45.9667, lng: -73.2167 },
    { name: "Memramcook", region: "NB", lat: 46, lng: -64.55 },
    { name: "Centre Hastings", region: "ON", lat: 44.4167, lng: -77.4417 },
    { name: "Warwick", region: "QC", lat: 45.95, lng: -71.9833 },
    { name: "East Ferris", region: "ON", lat: 46.2667, lng: -79.3 },
    { name: "Hanwell", region: "NB", lat: 45.8681, lng: -66.7947 },
    { name: "Saint-Joseph-de-Beauce", region: "QC", lat: 46.3, lng: -70.8833 },
    { name: "Metchosin", region: "BC", lat: 48.3819, lng: -123.5378 },
    { name: "Lucan Biddulph", region: "ON", lat: 43.2, lng: -81.3833 },
    { name: "Riviere-Rouge", region: "QC", lat: 46.4167, lng: -74.8667 },
    { name: "Greenstone", region: "ON", lat: 50, lng: -86.7333 },
    { name: "Saint-Mathias-sur-Richelieu", region: "QC", lat: 45.4667, lng: -73.2667 },
    { name: "Neepawa", region: "MB", lat: 50.2289, lng: -99.4656 },
    { name: "Gibsons", region: "BC", lat: 49.4028, lng: -123.5036 },
    { name: "Kindersley", region: "SK", lat: 51.4678, lng: -109.1567 },
    { name: "Jasper", region: "AB", lat: 52.9013, lng: -118.1312 },
    { name: "Barrhead", region: "AB", lat: 54.1167, lng: -114.4 },
    { name: "Les Coteaux", region: "QC", lat: 45.28, lng: -74.23 },
    { name: "Melville", region: "SK", lat: 50.9306, lng: -102.8078 },
    { name: "Saint-Germain-de-Grantham", region: "QC", lat: 45.8333, lng: -72.5667 },
    { name: "Iroquois Falls", region: "ON", lat: 48.7667, lng: -80.6667 },
    { name: "Havelock-Belmont-Methuen", region: "ON", lat: 44.5667, lng: -77.9 },
    { name: "Cornwallis", region: "MB", lat: 49.7981, lng: -99.8481 },
    { name: "Saint-Boniface", region: "QC", lat: 46.5, lng: -72.8167 },
    { name: "Edenwold No. 158", region: "SK", lat: 50.5166, lng: -104.3451 },
    { name: "Coverdale", region: "NB", lat: 46.0003, lng: -64.8859 },
    { name: "Vanderhoof", region: "BC", lat: 54.0143, lng: -124.0089 },
    { name: "Southwold", region: "ON", lat: 42.75, lng: -81.3167 },
    { name: "Goulds", region: "NL", lat: 47.4517, lng: -52.7647 },
    { name: "Saint Stephen", region: "NB", lat: 45.2, lng: -67.2833 },
    { name: "Waterloo", region: "QC", lat: 45.35, lng: -72.5167 },
    { name: "Nipawin", region: "SK", lat: 53.3572, lng: -104.0192 },
    { name: "Neuville", region: "QC", lat: 46.7, lng: -71.5833 },
    { name: "Saint-Cyrille-de-Wendover", region: "QC", lat: 45.9333, lng: -72.4333 },
    { name: "Central Frontenac", region: "ON", lat: 44.7167, lng: -76.8 },
    { name: "Mont-Orford", region: "QC", lat: 45.3661, lng: -72.1838 },
    { name: "Saint-Jean-de-Matha", region: "QC", lat: 46.23, lng: -73.53 },
    { name: "Seguin", region: "ON", lat: 45.2882, lng: -79.8116 },
    { name: "Tyendinaga", region: "ON", lat: 44.3, lng: -77.2 },
    { name: "Hampton", region: "NB", lat: 45.533, lng: -65.833 },
    { name: "Sussex", region: "NB", lat: 45.7167, lng: -65.5167 },
    { name: "Grand Forks", region: "BC", lat: 49.0333, lng: -118.44 },
    { name: "La Pocatiere", region: "QC", lat: 47.3667, lng: -70.0333 },
    { name: "Caraquet", region: "NB", lat: 47.7853, lng: -64.9592 },
    { name: "Saint-Etienne-des-Gres", region: "QC", lat: 46.4333, lng: -72.7667 },
    { name: "Altona", region: "MB", lat: 49.1044, lng: -97.5625 },
    { name: "Stellarton", region: "NS", lat: 45.5567, lng: -62.66 },
    { name: "Wolfville", region: "NS", lat: 45.0833, lng: -64.3667 },
    { name: "New Maryland", region: "NB", lat: 45.8911, lng: -66.6847 },
    { name: "Port Hardy", region: "BC", lat: 50.7225, lng: -127.4928 },
    { name: "Saint-Donat", region: "QC", lat: 46.3167, lng: -74.2167 },
    { name: "Chateau-Richer", region: "QC", lat: 46.9667, lng: -71.0167 },
    { name: "Madawaska Valley", region: "ON", lat: 45.5, lng: -77.6667 },
    { name: "Deep River", region: "ON", lat: 46.1, lng: -77.4917 },
    { name: "Asphodel-Norwood", region: "ON", lat: 44.3531, lng: -78.0183 },
    { name: "Red Lake", region: "ON", lat: 51.0333, lng: -93.8333 },
    { name: "Metabetchouan-Lac-a-la-Croix", region: "QC", lat: 48.4333, lng: -71.8667 },
    { name: "Berthierville", region: "QC", lat: 46.0833, lng: -73.1833 },
    { name: "Vermilion", region: "AB", lat: 53.3542, lng: -110.8528 },
    { name: "Niverville", region: "MB", lat: 49.6056, lng: -97.0417 },
    { name: "Hastings Highlands", region: "ON", lat: 45.2333, lng: -77.9333 },
    { name: "Carstairs", region: "AB", lat: 51.5619, lng: -114.0953 },
    { name: "Danville", region: "QC", lat: 45.7833, lng: -72.0167 },
    { name: "Channel-Port aux Basques", region: "NL", lat: 47.5694, lng: -59.1361 },
    { name: "Battleford", region: "SK", lat: 52.7383, lng: -108.3153 },
    { name: "Lac-Etchemin", region: "QC", lat: 46.4, lng: -70.5 },
    { name: "Saint-Antonin", region: "QC", lat: 47.7667, lng: -69.4833 },
    { name: "Saint-Jacques", region: "QC", lat: 45.95, lng: -73.5667 },
    { name: "Swan River", region: "MB", lat: 52.1058, lng: -101.2667 },
    { name: "Sutton", region: "QC", lat: 45.091, lng: -72.5792 },
    { name: "Northern Bruce Peninsula", region: "ON", lat: 45.08, lng: -81.38 },
    { name: "L'Islet-sur-Mer", region: "QC", lat: 47.1, lng: -70.35 },
    { name: "Carleton-sur-Mer", region: "QC", lat: 48.1, lng: -66.1333 },
    { name: "Oka", region: "QC", lat: 45.47, lng: -74.08 },
    { name: "Prescott", region: "ON", lat: 44.7167, lng: -75.5167 },
    { name: "Amaranth", region: "ON", lat: 43.9833, lng: -80.2333 },
    { name: "Marmora and Lake", region: "ON", lat: 44.6425, lng: -77.7372 },
    { name: "Maniwaki", region: "QC", lat: 46.375, lng: -75.9667 },
    { name: "Morin-Heights", region: "QC", lat: 45.9, lng: -74.25 },
    { name: "Dundas", region: "NB", lat: 46.3155, lng: -64.6947 },
    { name: "Napierville", region: "QC", lat: 45.1833, lng: -73.4 },
    { name: "Crabtree", region: "QC", lat: 45.9667, lng: -73.4667 },
    { name: "Bancroft", region: "ON", lat: 45.05, lng: -77.85 },
    { name: "Saint-Tite", region: "QC", lat: 46.7333, lng: -72.5667 },
    { name: "Howick", region: "ON", lat: 43.9, lng: -81.07 },
    { name: "Dutton/Dunwich", region: "ON", lat: 42.6667, lng: -81.5 },
    { name: "Callander", region: "ON", lat: 46.1781, lng: -79.4125 },
    { name: "Simonds", region: "NB", lat: 45.3145, lng: -65.803 },
    { name: "Baie-d'Urfe", region: "QC", lat: 45.4167, lng: -73.9167 },
    { name: "New Richmond", region: "QC", lat: 48.1667, lng: -65.8667 },
    { name: "Perth South", region: "ON", lat: 43.3, lng: -81.15 },
    { name: "Roxton Pond", region: "QC", lat: 45.4833, lng: -72.6667 },
    { name: "Sparwood", region: "BC", lat: 49.7331, lng: -114.8853 },
    { name: "Claresholm", region: "AB", lat: 50.0194, lng: -113.5783 },
    { name: "Breslau", region: "ON", lat: 43.4816, lng: -80.408 },
    { name: "Montague", region: "ON", lat: 44.9667, lng: -75.9667 },
    { name: "Cumberland", region: "BC", lat: 49.6206, lng: -125.0261 },
    { name: "Beaupre", region: "QC", lat: 47.05, lng: -70.9 },
    { name: "Saint-Andre-Avellin", region: "QC", lat: 45.7167, lng: -75.0667 },
    { name: "Saint-Ambroise-de-Kildare", region: "QC", lat: 46.0833, lng: -73.55 },
    { name: "East Angus", region: "QC", lat: 45.4833, lng: -71.6667 },
    { name: "Rossland", region: "BC", lat: 49.0786, lng: -117.7992 },
    { name: "Mackenzie", region: "BC", lat: 55.3381, lng: -123.0944 },
    { name: "Golden", region: "BC", lat: 51.3019, lng: -116.9667 },
    { name: "Raymond", region: "AB", lat: 49.4658, lng: -112.6508 },
    { name: "Saint-Adolphe-d'Howard", region: "QC", lat: 45.97, lng: -74.33 },
    { name: "Warwick", region: "ON", lat: 43, lng: -81.8917 },
    { name: "Bowen Island", region: "BC", lat: 49.3833, lng: -123.3833 },
    { name: "Bonnechere Valley", region: "ON", lat: 45.5333, lng: -77.1 },
    { name: "Windsor", region: "NS", lat: 44.9803, lng: -64.1292 },
    { name: "Pincher Creek", region: "AB", lat: 49.4861, lng: -113.95 },
    { name: "Alnwick", region: "NB", lat: 47.2656, lng: -65.2292 },
    { name: "Westville", region: "NS", lat: 45.55, lng: -62.7 },
    { name: "Fruitvale", region: "BC", lat: 49.1161, lng: -117.5414 },
    { name: "Pasadena", region: "NL", lat: 49.0161, lng: -57.605 },
    { name: "Saint-Prosper", region: "QC", lat: 46.2167, lng: -70.4833 },
    { name: "Ormstown", region: "QC", lat: 45.13, lng: -74 },
    { name: "Cardston", region: "AB", lat: 49.2025, lng: -113.3019 },
    { name: "Westbank", region: "BC", lat: 49.8423, lng: -119.6743 },
    { name: "De Salaberry", region: "MB", lat: 49.4403, lng: -96.9844 },
    { name: "Headingley", region: "MB", lat: 49.8681, lng: -97.3908 },
    { name: "Grande Cache", region: "AB", lat: 53.8773, lng: -119.1199 },
    { name: "Atholville", region: "NB", lat: 47.9894, lng: -66.7125 },
    { name: "Saint-Agapit", region: "QC", lat: 46.5667, lng: -71.4333 },
    { name: "Prince Albert No. 461", region: "SK", lat: 53.1089, lng: -105.6574 },
    { name: "Casselman", region: "ON", lat: 45.3, lng: -75.0833 },
    { name: "Saint-Ambroise", region: "QC", lat: 48.55, lng: -71.3333 },
    { name: "Hay River", region: "NT", lat: 60.7531, lng: -115.9004 },
    { name: "Mistissini", region: "QC", lat: 50.5707, lng: -73.6829 },
    { name: "Studholm", region: "NB", lat: 45.8133, lng: -65.5747 },
    { name: "Lumby", region: "BC", lat: 50.2494, lng: -118.9656 },
    { name: "Saint-Faustin--Lac-Carre", region: "QC", lat: 46.0813, lng: -74.4668 },
    { name: "Morris-Turnberry", region: "ON", lat: 43.85, lng: -81.25 },
    { name: "Placentia", region: "NL", lat: 47.2458, lng: -53.9611 },
    { name: "Saint-Pascal", region: "QC", lat: 47.5333, lng: -69.8 },
    { name: "Mulmur", region: "ON", lat: 44.1917, lng: -80.1083 },
    { name: "Blind River", region: "ON", lat: 46.1833, lng: -82.95 },
    { name: "Dunham", region: "QC", lat: 45.1333, lng: -72.8 },
    { name: "Havre-Saint-Pierre", region: "QC", lat: 50.2333, lng: -63.6 },
    { name: "Saint-Anselme", region: "QC", lat: 46.6333, lng: -70.9667 },
    { name: "Trois-Pistoles", region: "QC", lat: 48.12, lng: -69.18 },
    { name: "Grande-Riviere", region: "QC", lat: 48.4, lng: -64.5 },
    { name: "Powassan", region: "ON", lat: 46.0825, lng: -79.3619 },
    { name: "Malartic", region: "QC", lat: 48.1333, lng: -78.1333 },
    { name: "Bonavista", region: "NL", lat: 48.6597, lng: -53.1208 },
    { name: "Killarney-Turtle Mountain", region: "MB", lat: 49.1775, lng: -99.6906 },
    { name: "Woodlands", region: "MB", lat: 50.2408, lng: -97.7358 },
    { name: "Lewisporte", region: "NL", lat: 49.23, lng: -55.07 },
    { name: "Saint-Denis-de-Brompton", region: "QC", lat: 45.45, lng: -72.0833 },
    { name: "Invermere", region: "BC", lat: 50.5083, lng: -116.0303 },
    { name: "Salisbury", region: "NB", lat: 46.0776, lng: -65.1996 },
    { name: "Bifrost-Riverton", region: "MB", lat: 51.0603, lng: -97.1436 },
    { name: "Buckland No. 491", region: "SK", lat: 53.3276, lng: -105.7804 },
    { name: "Cartier", region: "MB", lat: 49.9161, lng: -97.7 },
    { name: "Sainte-Anne-des-Lacs", region: "QC", lat: 45.85, lng: -74.1333 },
    { name: "Highlands East", region: "ON", lat: 44.9667, lng: -78.25 },
    { name: "Alexander", region: "MB", lat: 50.4222, lng: -96.075 },
    { name: "Sainte-Claire", region: "QC", lat: 46.6, lng: -70.8667 },
    { name: "Perce", region: "QC", lat: 48.5333, lng: -64.2167 },
    { name: "Saint-Jean-Port-Joli", region: "QC", lat: 47.2167, lng: -70.2667 },
    { name: "East Hawkesbury", region: "ON", lat: 45.5167, lng: -74.4667 },
    { name: "Bright", region: "NB", lat: 46.1205, lng: -67.0545 },
    { name: "Penhold", region: "AB", lat: 52.1333, lng: -113.8667 },
    { name: "Saint-Andre-d'Argenteuil", region: "QC", lat: 45.5667, lng: -74.3333 },
    { name: "Saint-Come--Liniere", region: "QC", lat: 46.0667, lng: -70.5167 },
    { name: "Saint-Sulpice", region: "QC", lat: 45.8333, lng: -73.35 },
    { name: "Marathon", region: "ON", lat: 48.75, lng: -86.3667 },
    { name: "Forestville", region: "QC", lat: 48.7333, lng: -69.0833 },
    { name: "Inuvik", region: "NT", lat: 68.3407, lng: -133.6096 },
    { name: "Richmond", region: "QC", lat: 45.6667, lng: -72.15 },
    { name: "Lake Cowichan", region: "BC", lat: 48.8258, lng: -124.0542 },
    { name: "Sables-Spanish Rivers", region: "ON", lat: 46.2333, lng: -82 },
    { name: "Hillsburg-Roblin-Shell River", region: "MB", lat: 51.3343, lng: -101.2929 },
    { name: "Port Hawkesbury", region: "NS", lat: 45.6153, lng: -61.3642 },
    { name: "Three Hills", region: "AB", lat: 51.7072, lng: -113.2647 },
    { name: "Lorette", region: "MB", lat: 49.7414, lng: -96.8761 },
    { name: "Paspebiac", region: "QC", lat: 48.0333, lng: -65.25 },
    { name: "Saint-Thomas", region: "QC", lat: 46.0167, lng: -73.35 },
    { name: "Saint-Jean-Baptiste", region: "QC", lat: 45.5167, lng: -73.1167 },
    { name: "Portneuf", region: "QC", lat: 46.7, lng: -71.8833 },
    { name: "Pictou", region: "NS", lat: 45.6814, lng: -62.7119 },
    { name: "Tisdale", region: "SK", lat: 52.85, lng: -104.05 },
    { name: "Lake of Bays", region: "ON", lat: 45.3043, lng: -79.018 },
    { name: "High Level", region: "AB", lat: 58.5169, lng: -117.1361 },
    { name: "Gibbons", region: "AB", lat: 53.8278, lng: -113.3228 },
    { name: "Bishops Falls", region: "NL", lat: 49.0167, lng: -55.5167 },
    { name: "WestLake-Gladstone", region: "MB", lat: 50.2862, lng: -98.8415 },
    { name: "Normandin", region: "QC", lat: 48.8333, lng: -72.5333 },
    { name: "Saint-Alphonse-Rodriguez", region: "QC", lat: 46.1833, lng: -73.7 },
    { name: "Beausejour", region: "MB", lat: 50.0622, lng: -96.5161 },
    { name: "Dalhousie", region: "NB", lat: 48.1, lng: -66.6167 },
    { name: "Saint-Alphonse-de-Granby", region: "QC", lat: 45.3333, lng: -72.8167 },
    { name: "Lac du Bonnet", region: "MB", lat: 50.2577, lng: -96.1209 },
    { name: "Clermont", region: "QC", lat: 47.6833, lng: -70.2333 },
    { name: "Virden", region: "MB", lat: 49.8508, lng: -100.9317 },
    { name: "Compton", region: "QC", lat: 45.2333, lng: -71.8167 },
    { name: "White City", region: "SK", lat: 50.4353, lng: -104.3572 },
    { name: "Ellison", region: "BC", lat: 49.9646, lng: -119.3178 },
    { name: "Mont-Saint-Gregoire", region: "QC", lat: 45.3333, lng: -73.1667 },
    { name: "Wellington", region: "NB", lat: 46.4768, lng: -64.7478 },
    { name: "Merrickville", region: "ON", lat: 44.8539, lng: -75.8269 },
    { name: "Saint-Liboire", region: "QC", lat: 45.65, lng: -72.7667 },
    { name: "Degelis", region: "QC", lat: 47.55, lng: -68.65 },
    { name: "Morris", region: "MB", lat: 49.3986, lng: -97.4592 },
    { name: "Saint-Alexis-des-Monts", region: "QC", lat: 46.4667, lng: -73.1333 },
    { name: "Cap-Saint-Ignace", region: "QC", lat: 47.0333, lng: -70.4667 },
    { name: "Saint-Anaclet-de-Lessard", region: "QC", lat: 48.48, lng: -68.42 },
    { name: "Carman", region: "MB", lat: 49.4992, lng: -98.0008 },
    { name: "Athens", region: "ON", lat: 44.625, lng: -75.95 },
    { name: "Melancthon", region: "ON", lat: 44.15, lng: -80.2667 },
    { name: "Cap Sante", region: "QC", lat: 46.6667, lng: -71.7833 },
    { name: "Harbour Grace", region: "NL", lat: 47.6917, lng: -53.2167 },
    { name: "Houston", region: "BC", lat: 54.3975, lng: -126.6419 },
    { name: "Adelaide-Metcalfe", region: "ON", lat: 42.95, lng: -81.7 },
    { name: "Crossfield", region: "AB", lat: 51.4333, lng: -114.0333 },
    { name: "Springdale", region: "NL", lat: 49.4974, lng: -56.0727 },
    { name: "Fort Macleod", region: "AB", lat: 49.7256, lng: -113.3975 },
    { name: "Athabasca", region: "AB", lat: 54.7197, lng: -113.2856 },
    { name: "Enderby", region: "BC", lat: 50.5508, lng: -119.1397 },
    { name: "Saint-Ferreol-les-Neiges", region: "QC", lat: 47.1167, lng: -70.85 },
    { name: "Laurentian Hills", region: "ON", lat: 46.1333, lng: -77.55 },
    { name: "Grand Valley", region: "ON", lat: 43.95, lng: -80.3667 },
    { name: "Senneterre", region: "QC", lat: 48.3833, lng: -77.2333 },
    { name: "Sainte-Marie-Madeleine", region: "QC", lat: 45.6, lng: -73.1 },
    { name: "Admaston/Bromley", region: "ON", lat: 45.5292, lng: -76.8969 },
    { name: "Saint-Gabriel-de-Valcartier", region: "QC", lat: 46.9333, lng: -71.4667 },
    { name: "North Algona Wilberforce", region: "ON", lat: 45.6167, lng: -77.2 },
    { name: "Kingston", region: "NB", lat: 45.4663, lng: -66.0217 },
    { name: "Wawa", region: "ON", lat: 47.9931, lng: -84.7736 },
    { name: "Saint-Christophe-d'Arthabaska", region: "QC", lat: 46.0333, lng: -71.8833 },
    { name: "Sainte-Melanie", region: "QC", lat: 46.1333, lng: -73.5167 },
    { name: "Ascot Corner", region: "QC", lat: 45.45, lng: -71.7667 },
    { name: "Horton", region: "ON", lat: 45.5, lng: -76.6667 },
    { name: "Saint-Michel", region: "QC", lat: 45.2333, lng: -73.5667 },
    { name: "Botwood", region: "NL", lat: 49.15, lng: -55.3667 },
    { name: "Saint-Paul-d'Abbotsford", region: "QC", lat: 45.4333, lng: -72.8833 },
    { name: "Saint-Marc-des-Carrieres", region: "QC", lat: 46.6833, lng: -72.05 },
    { name: "Stanstead", region: "QC", lat: 45.0167, lng: -72.1 },
    { name: "Sainte-Anne-de-Beaupre", region: "QC", lat: 47.0167, lng: -70.9333 },
    { name: "Sainte-Luce", region: "QC", lat: 48.55, lng: -68.38 },
    { name: "Saint-Gabriel", region: "QC", lat: 46.3, lng: -73.3833 },
    { name: "Rankin Inlet", region: "NU", lat: 62.83, lng: -92.1321 },
    { name: "Vanscoy No. 345", region: "SK", lat: 52.0073, lng: -107.0552 },
    { name: "Cedar", region: "BC", lat: 49.0853, lng: -123.8259 },
    { name: "Princeton", region: "BC", lat: 49.4589, lng: -120.506 },
    { name: "La Loche", region: "SK", lat: 56.4833, lng: -109.4333 },
    { name: "Kingsclear", region: "NB", lat: 45.8796, lng: -66.8695 },
    { name: "Ferme-Neuve", region: "QC", lat: 46.7, lng: -75.45 },
    { name: "Thurso", region: "QC", lat: 45.5969, lng: -75.2433 },
    { name: "Adstock", region: "QC", lat: 46.05, lng: -71.08 },
    { name: "Shuniah", region: "ON", lat: 48.5833, lng: -88.8333 },
    { name: "Enniskillen", region: "ON", lat: 42.8167, lng: -82.125 },
    { name: "Yamachiche", region: "QC", lat: 46.2667, lng: -72.8333 },
    { name: "Saint-Maurice", region: "QC", lat: 46.4667, lng: -72.5333 },
    { name: "Bonaventure", region: "QC", lat: 48.05, lng: -65.4833 },
    { name: "Val-Morin", region: "QC", lat: 46, lng: -74.18 },
    { name: "Pohenegamook", region: "QC", lat: 47.4667, lng: -69.2167 },
    { name: "Wakefield", region: "NB", lat: 46.2406, lng: -67.6248 },
    { name: "Stoke", region: "QC", lat: 45.5333, lng: -71.8 },
    { name: "Sainte-Marguerite-du-Lac-Masson", region: "QC", lat: 46.056, lng: -74.0723 },
    { name: "Saint-Prime", region: "QC", lat: 48.58, lng: -72.33 },
    { name: "Kuujjuaq", region: "QC", lat: 58.1429, lng: -68.3742 },
    { name: "Atikokan", region: "ON", lat: 48.75, lng: -91.6167 },
    { name: "Grenville-sur-la-Rouge", region: "QC", lat: 45.65, lng: -74.6333 },
    { name: "North Cypress-Langford", region: "MB", lat: 49.9969, lng: -99.3982 },
    { name: "Sainte-Anne-de-Sorel", region: "QC", lat: 46.05, lng: -73.0667 },
    { name: "Macamic", region: "QC", lat: 48.75, lng: -79 },
    { name: "Sundre", region: "AB", lat: 51.7972, lng: -114.6406 },
    { name: "Rougemont", region: "QC", lat: 45.4333, lng: -73.05 },
    { name: "Piedmont", region: "QC", lat: 45.9, lng: -74.13 },
    { name: "Grimshaw", region: "AB", lat: 56.1908, lng: -117.6117 },
    { name: "Lac-des-Ecorces", region: "QC", lat: 46.55, lng: -75.35 },
    { name: "Northeastern Manitoulin and the Islands", region: "ON", lat: 45.9667, lng: -81.9333 },
    { name: "Pelican Narrows", region: "SK", lat: 55.1883, lng: -102.9342 },
    { name: "McDougall", region: "ON", lat: 45.45, lng: -80.0167 },
    { name: "Black Diamond", region: "AB", lat: 50.6881, lng: -114.2333 },
    { name: "Saint-Pamphile", region: "QC", lat: 46.9667, lng: -69.7833 },
    { name: "Bedford", region: "QC", lat: 45.1167, lng: -72.9833 },
    { name: "Weedon-Centre", region: "QC", lat: 45.7, lng: -71.4667 },
    { name: "Lacolle", region: "QC", lat: 45.0833, lng: -73.3667 },
    { name: "Saint-Gabriel-de-Brandon", region: "QC", lat: 46.2667, lng: -73.3833 },
    { name: "Errington", region: "BC", lat: 49.2874, lng: -124.3433 },
    { name: "Coalhurst", region: "AB", lat: 49.7457, lng: -112.9319 },
    { name: "French River / Riviere des Francais", region: "ON", lat: 46.1667, lng: -80.5 },
    { name: "Arviat", region: "NU", lat: 61.0996, lng: -94.1688 },
    { name: "Saint-David-de-Falardeau", region: "QC", lat: 48.6167, lng: -71.1167 },
    { name: "Markstay", region: "ON", lat: 46.4912, lng: -80.4717 },
    { name: "Spaniards Bay", region: "NL", lat: 47.6181, lng: -53.3369 },
    { name: "Cocagne", region: "NB", lat: 46.3406, lng: -64.62 },
    { name: "Saint-Bruno", region: "QC", lat: 48.4667, lng: -71.65 },
    { name: "Chetwynd", region: "BC", lat: 55.6972, lng: -121.6333 },
    { name: "Laurier-Station", region: "QC", lat: 46.5333, lng: -71.6333 },
    { name: "Saint-Anicet", region: "QC", lat: 45.12, lng: -74.35 },
    { name: "Saint-Mathieu-de-Beloeil", region: "QC", lat: 45.5667, lng: -73.2 },
    { name: "Cap-Chat", region: "QC", lat: 49.1, lng: -66.6833 },
    { name: "Sexsmith", region: "AB", lat: 55.3508, lng: -118.7825 },
    { name: "Notre-Dame-de-Lourdes", region: "QC", lat: 46.1, lng: -73.4333 },
    { name: "Ville-Marie", region: "QC", lat: 47.3333, lng: -79.4333 },
    { name: "Saint-Isidore", region: "QC", lat: 45.3, lng: -73.68 },
    { name: "Shippegan", region: "NB", lat: 47.7439, lng: -64.7178 },
    { name: "East Garafraxa", region: "ON", lat: 43.85, lng: -80.25 },
    { name: "Pemberton", region: "BC", lat: 50.3203, lng: -122.8053 },
    { name: "Unity", region: "SK", lat: 52.4333, lng: -109.1667 },
    { name: "Rimbey", region: "AB", lat: 52.6333, lng: -114.2167 },
    { name: "High Prairie", region: "AB", lat: 55.4325, lng: -116.4861 },
    { name: "Turner Valley", region: "AB", lat: 50.6739, lng: -114.2786 },
    { name: "Hanna", region: "AB", lat: 51.6383, lng: -111.9419 },
    { name: "Fort Smith", region: "NT", lat: 60.026, lng: -112.0821 },
    { name: "Maria", region: "QC", lat: 48.1667, lng: -65.9833 },
    { name: "Saint-Chrysostome", region: "QC", lat: 45.1, lng: -73.7667 },
    { name: "Greater Madawaska", region: "ON", lat: 45.2722, lng: -76.8589 },
    { name: "Berwick", region: "NS", lat: 45.0475, lng: -64.736 },
    { name: "Saint-Damase", region: "QC", lat: 45.5333, lng: -73 },
    { name: "Lincoln", region: "NB", lat: 45.8716, lng: -66.5437 },
    { name: "Disraeli", region: "QC", lat: 45.9, lng: -71.35 },
    { name: "Sainte-Victoire-de-Sorel", region: "QC", lat: 45.95, lng: -73.0833 },
    { name: "Meadow Lake No. 588", region: "SK", lat: 54.1213, lng: -108.2837 },
    { name: "Elkford", region: "BC", lat: 50.0214, lng: -114.9158 },
    { name: "Georgian Bay", region: "ON", lat: 44.9833, lng: -79.8167 },
    { name: "Saint-Alexandre", region: "QC", lat: 45.2333, lng: -73.1167 },
    { name: "Herbertville", region: "QC", lat: 48.3473, lng: -71.6784 },
    { name: "Moosomin", region: "SK", lat: 50.142, lng: -101.67 },
    { name: "North Kawartha", region: "ON", lat: 44.75, lng: -78.1 },
    { name: "Sainte-Thecle", region: "QC", lat: 46.8167, lng: -72.5 },
    { name: "Trenton", region: "NS", lat: 45.6193, lng: -62.6332 },
    { name: "Fermont", region: "QC", lat: 52.7833, lng: -67.0833 },
    { name: "Esterhazy", region: "SK", lat: 50.65, lng: -102.0667 },
    { name: "Wickham", region: "QC", lat: 45.75, lng: -72.5 },
    { name: "La Presentation", region: "QC", lat: 45.6667, lng: -73.05 },
    { name: "Beaverlodge", region: "AB", lat: 55.2094, lng: -119.4292 },
    { name: "Sainte-Catherine-de-Hatley", region: "QC", lat: 45.25, lng: -72.05 },
    { name: "Saint-Basile", region: "QC", lat: 46.75, lng: -71.8167 },
    { name: "Saint-Raphael", region: "QC", lat: 46.8, lng: -70.75 },
    { name: "Holyrood", region: "NL", lat: 47.3833, lng: -53.1333 },
    { name: "Gracefield", region: "QC", lat: 46.0926, lng: -75.9574 },
    { name: "Saint-Martin", region: "QC", lat: 45.9667, lng: -70.65 },
    { name: "Causapscal", region: "QC", lat: 48.3667, lng: -67.2333 },
    { name: "Brigham", region: "QC", lat: 45.25, lng: -72.85 },
    { name: "Perry", region: "ON", lat: 45.5, lng: -79.2833 },
    { name: "Port-Daniel--Gascons", region: "QC", lat: 48.1833, lng: -64.9667 },
    { name: "Rosetown", region: "SK", lat: 51.55, lng: -107.9833 },
    { name: "Minnedosa", region: "MB", lat: 50.2453, lng: -99.8428 },
    { name: "Labelle", region: "QC", lat: 46.2833, lng: -74.7333 },
    { name: "Huntingdon", region: "QC", lat: 45.08, lng: -74.17 },
    { name: "Hebertville", region: "QC", lat: 48.4, lng: -71.6833 },
    { name: "Black River-Matheson", region: "ON", lat: 48.5333, lng: -80.4667 },
    { name: "Saint-Michel-des-Saints", region: "QC", lat: 46.6833, lng: -73.9167 },
    { name: "Dufferin", region: "MB", lat: 49.5319, lng: -98.07 },
    { name: "Saint-Victor", region: "QC", lat: 46.15, lng: -70.9 },
    { name: "Sicamous", region: "BC", lat: 50.8378, lng: -118.9703 },
    { name: "Cap Pele", region: "NB", lat: 46.2172, lng: -64.2822 },
    { name: "Kelsey", region: "MB", lat: 53.7356, lng: -101.395 },
    { name: "Killaloe, Hagarty and Richards", region: "ON", lat: 45.6, lng: -77.5 },
    { name: "Alvinston", region: "ON", lat: 42.8489, lng: -81.9049 },
    { name: "Dundurn No. 314", region: "SK", lat: 51.8261, lng: -106.5416 },
    { name: "Saint-Ephrem-de-Beauce", region: "QC", lat: 46.0516, lng: -70.9374 },
    { name: "Assiniboia", region: "SK", lat: 49.6167, lng: -105.9833 },
    { name: "Temiscaming", region: "QC", lat: 46.7167, lng: -79.1 },
    { name: "Magrath", region: "AB", lat: 49.4239, lng: -112.8683 },
    { name: "Sainte-Genevieve-de-Berthier", region: "QC", lat: 46.0833, lng: -73.2167 },
    { name: "Buctouche", region: "NB", lat: 46.4719, lng: -64.7249 },
    { name: "Grand Manan", region: "NB", lat: 44.69, lng: -66.82 },
    { name: "Sainte-Madeleine", region: "QC", lat: 45.6, lng: -73.1 },
    { name: "Boissevain", region: "MB", lat: 49.1779, lng: -100.0955 },
    { name: "Scott", region: "QC", lat: 46.512, lng: -71.077 },
    { name: "Sainte-Croix", region: "QC", lat: 46.62, lng: -71.73 },
    { name: "Algonquin Highlands", region: "ON", lat: 45.4, lng: -78.75 },
    { name: "Valcourt", region: "QC", lat: 45.5, lng: -72.3167 },
    { name: "Saint George", region: "NB", lat: 45.2916, lng: -66.8501 },
    { name: "Paquetville", region: "NB", lat: 47.6334, lng: -65.1803 },
    { name: "Saint-Dominique", region: "QC", lat: 45.5667, lng: -72.85 },
    { name: "Clearwater", region: "BC", lat: 51.65, lng: -120.0333 },
    { name: "Addington Highlands", region: "ON", lat: 45, lng: -77.25 },
    { name: "Lillooet", region: "BC", lat: 50.6864, lng: -121.9364 },
    { name: "Burin", region: "NL", lat: 47.05, lng: -55.18 },
    { name: "Grand Bank", region: "NL", lat: 47.1, lng: -55.7833 },
    { name: "Lery", region: "QC", lat: 45.35, lng: -73.8 },
    { name: "Minto", region: "NB", lat: 46.1497, lng: -66.1067 },
    { name: "Rosthern No. 403", region: "SK", lat: 52.6206, lng: -106.3967 },
    { name: "Chase", region: "BC", lat: 50.8189, lng: -119.6844 },
    { name: "Mansfield-et-Pontefract", region: "QC", lat: 45.8611, lng: -76.7392 },
    { name: "Saint-Denis", region: "QC", lat: 45.7833, lng: -73.15 },
    { name: "Outlook", region: "SK", lat: 51.5, lng: -107.05 },
    { name: "Mitchell", region: "MB", lat: 49.5363, lng: -96.7634 },
    { name: "Saint-Gedeon-de-Beauce", region: "QC", lat: 45.85, lng: -70.6333 },
    { name: "Saint-Leonard-d'Aston", region: "QC", lat: 46.1, lng: -72.3667 },
    { name: "Lunenburg", region: "NS", lat: 44.3833, lng: -64.3167 },
    { name: "Northesk", region: "NB", lat: 47.2569, lng: -66.2613 },
    { name: "Albanel", region: "QC", lat: 48.8833, lng: -72.45 },
    { name: "St. Anthony", region: "NL", lat: 51.3725, lng: -55.5947 },
    { name: "Pessamit", region: "QC", lat: 49.0485, lng: -68.6814 },
    { name: "Maskinonge", region: "QC", lat: 46.2333, lng: -73.0167 },
    { name: "Saint-Charles-de-Bellechasse", region: "QC", lat: 46.7667, lng: -70.95 },
    { name: "Fogo Island", region: "NL", lat: 49.6667, lng: -54.1833 },
    { name: "East Broughton", region: "QC", lat: 46.2167, lng: -71.0667 },
    { name: "Lantz", region: "NS", lat: 44.9894, lng: -63.4736 },
    { name: "Calmar", region: "AB", lat: 53.25, lng: -113.7833 },
    { name: "Highlands", region: "BC", lat: 48.52, lng: -123.5 },
    { name: "Saint-Polycarpe", region: "QC", lat: 45.3, lng: -74.3 },
    { name: "Logy Bay-Middle Cove-Outer Cove", region: "NL", lat: 47.63, lng: -52.68 },
    { name: "Deschambault", region: "QC", lat: 46.6436, lng: -72.0236 },
    { name: "Canora", region: "SK", lat: 51.6339, lng: -102.4369 },
    { name: "Upper Miramichi", region: "NB", lat: 46.5254, lng: -66.2085 },
    { name: "Anmore", region: "BC", lat: 49.3144, lng: -122.8564 },
    { name: "Hardwicke", region: "NB", lat: 47.0208, lng: -65.0302 },
    { name: "Saint-Come", region: "QC", lat: 46.27, lng: -73.78 },
    { name: "Waskaganish", region: "QC", lat: 51.3674, lng: -78.7069 },
    { name: "Twillingate", region: "NL", lat: 49.6444, lng: -54.7436 },
    { name: "Saint-Quentin", region: "NB", lat: 47.5135, lng: -67.3921 },
    { name: "Lebel-sur-Quevillon", region: "QC", lat: 49.05, lng: -76.9833 },
    { name: "Pilot Butte", region: "SK", lat: 50.4667, lng: -104.4167 },
    { name: "Nanton", region: "AB", lat: 50.3494, lng: -113.7717 },
    { name: "Pierreville", region: "QC", lat: 46.0667, lng: -72.8167 },
    { name: "New-Wes-Valley", region: "NL", lat: 49.15, lng: -53.5833 },
    { name: "Pennfield Ridge", region: "NB", lat: 45.1924, lng: -66.6858 },
    { name: "West Interlake", region: "MB", lat: 50.9837, lng: -98.3572 },
    { name: "Biggar", region: "SK", lat: 52.059, lng: -107.979 },
    { name: "Britannia No. 502", region: "SK", lat: 53.4236, lng: -109.7772 },
    { name: "Kent", region: "NB", lat: 46.6221, lng: -67.2953 },
    { name: "Wabana", region: "NL", lat: 47.65, lng: -52.9333 },
    { name: "Saint-Gilles", region: "QC", lat: 46.5167, lng: -71.3667 },
    { name: "Wendake", region: "QC", lat: 46.8693, lng: -71.3628 },
    { name: "Saint-Bernard", region: "QC", lat: 46.5, lng: -71.1333 },
    { name: "Sainte-Cecile-de-Milton", region: "QC", lat: 45.4833, lng: -72.75 },
    { name: "Saint-Roch-de-Richelieu", region: "QC", lat: 45.8833, lng: -73.1667 },
    { name: "Saint-Nazaire", region: "QC", lat: 48.5833, lng: -71.5333 },
    { name: "Saint-Elzear", region: "QC", lat: 46.4, lng: -71.0667 },
    { name: "Hinchinbrooke", region: "QC", lat: 45.05, lng: -74.1 },
    { name: "Saint-Francois-Xavier-de-Brompton", region: "QC", lat: 45.5333, lng: -72.05 },
    { name: "Papineauville", region: "QC", lat: 45.6167, lng: -75.0167 },
    { name: "Prairie View", region: "MB", lat: 50.3304, lng: -100.9803 },
    { name: "Cowichan Bay", region: "BC", lat: 48.7666, lng: -123.6743 },
    { name: "Saint-Ignace-de-Loyola", region: "QC", lat: 46.0667, lng: -73.1333 },
    { name: "Central Manitoulin", region: "ON", lat: 45.7167, lng: -82.2 },
    { name: "Maple Creek", region: "SK", lat: 49.9167, lng: -109.4667 },
    { name: "Glovertown", region: "NL", lat: 48.6667, lng: -54.05 },
    { name: "Tofield", region: "AB", lat: 53.3703, lng: -112.6667 },
    { name: "Madoc", region: "ON", lat: 44.5833, lng: -77.5167 },
    { name: "Upton", region: "QC", lat: 45.65, lng: -72.6833 },
    { name: "Sainte-Anne-de-Sabrevois", region: "QC", lat: 45.2, lng: -73.2167 },
    { name: "Logan Lake", region: "BC", lat: 50.4911, lng: -120.8153 },
    { name: "Sainte-Anne-de-la-Perade", region: "QC", lat: 46.5833, lng: -72.2 },
    { name: "Saint-Damien-de-Buckland", region: "QC", lat: 46.6333, lng: -70.6667 },
    { name: "Baker Lake", region: "NU", lat: 64.3287, lng: -96.0308 },
    { name: "Saltair", region: "BC", lat: 48.9504, lng: -123.7637 },
    { name: "Pouch Cove", region: "NL", lat: 47.767, lng: -52.767 },
    { name: "Saint-Ferdinand", region: "QC", lat: 46.1, lng: -71.5667 },
    { name: "Port McNeill", region: "BC", lat: 50.5903, lng: -127.0847 },
    { name: "Digby", region: "NS", lat: 44.6222, lng: -65.7606 },
    { name: "Manouane", region: "QC", lat: 47.2091, lng: -74.3833 },
    { name: "Saint-Gervais", region: "QC", lat: 46.7167, lng: -70.8833 },
    { name: "Neebing", region: "ON", lat: 48.1833, lng: -89.4667 },
    { name: "Redwater", region: "AB", lat: 53.9489, lng: -113.1067 },
    { name: "Saint-Alexandre-de-Kamouraska", region: "QC", lat: 47.6817, lng: -69.625 },
    { name: "Saint-Marc-sur-Richelieu", region: "QC", lat: 45.6833, lng: -73.2 },
    { name: "Mandeville", region: "QC", lat: 46.3667, lng: -73.35 },
    { name: "Caplan", region: "QC", lat: 48.1, lng: -65.6833 },
    { name: "Point Edward", region: "ON", lat: 42.9931, lng: -82.4083 },
    { name: "Allardville", region: "NB", lat: 47.4321, lng: -65.4383 },
    { name: "Waterville", region: "QC", lat: 45.2667, lng: -71.9 },
    { name: "Saint-Damien", region: "QC", lat: 46.33, lng: -73.48 },
    { name: "Lac-Nominingue", region: "QC", lat: 46.4, lng: -75.0333 },
    { name: "Obedjiwan", region: "QC", lat: 48.6686, lng: -74.9289 },
    { name: "Rama", region: "SK", lat: 51.7578, lng: -103.0008 },
    { name: "McCreary", region: "MB", lat: 50.7494, lng: -99.485 },
    { name: "Deloraine-Winchester", region: "MB", lat: 49.1775, lng: -100.4322 },
    { name: "Oakland-Wawanesa", region: "MB", lat: 49.6208, lng: -99.8481 },
    { name: "Brenda-Waskada", region: "MB", lat: 49.1775, lng: -100.7019 },
    { name: "Russell-Binscarth", region: "MB", lat: 50.7272, lng: -101.3689 },
    { name: "Ellice-Archie", region: "MB", lat: 50.3239, lng: -101.2729 },
    { name: "Souris-Glenwood", region: "MB", lat: 49.6208, lng: -100.2581 },
    { name: "Riverdale", region: "MB", lat: 49.975, lng: -100.2789 },
    { name: "Pembina", region: "MB", lat: 49.1775, lng: -98.5408 },
    { name: "Wallace-Woodworth", region: "MB", lat: 49.9156, lng: -100.9389 },
    { name: "Lorne", region: "MB", lat: 49.4436, lng: -98.7494 },
    { name: "Ethelbert", region: "MB", lat: 51.5364, lng: -100.4981 },
    { name: "Yellowhead", region: "MB", lat: 50.4847, lng: -100.4828 },
    { name: "Swan Valley West", region: "MB", lat: 51.9978, lng: -101.3944 },
    { name: "Grey", region: "MB", lat: 49.7094, lng: -98.0736 },
    { name: "Gilbert Plains", region: "MB", lat: 51.1547, lng: -100.4381 },
    { name: "Norfolk-Treherne", region: "MB", lat: 49.6653, lng: -98.5967 },
    { name: "Hamiota", region: "MB", lat: 50.1964, lng: -100.6342 },
    { name: "Emerson-Franklin", region: "MB", lat: 49.1333, lng: -97.0331 },
    { name: "Sifton", region: "MB", lat: 49.6653, lng: -100.6678 },
    { name: "Rossburn", region: "MB", lat: 50.7272, lng: -100.7408 },
    { name: "Grand View", region: "MB", lat: 51.155, lng: -100.7892 },
    { name: "Grassland", region: "MB", lat: 49.4306, lng: -100.3103 },
    { name: "Louise", region: "MB", lat: 49.1772, lng: -98.8794 },
    { name: "Ste. Rose", region: "MB", lat: 51.0222, lng: -99.4306 },
    { name: "Cartwright-Roblin", region: "MB", lat: 49.1331, lng: -99.2797 },
    { name: "Mossey River", region: "MB", lat: 51.755, lng: -99.9664 },
    { name: "Lakeshore", region: "MB", lat: 51.244, lng: -99.6562 },
    { name: "Riding Mountain West", region: "MB", lat: 50.8347, lng: -101.0961 },
    { name: "Clanwilliam-Erickson", region: "MB", lat: 50.5061, lng: -99.8156 },
    { name: "Glenboro-South Cypress", region: "MB", lat: 49.665, lng: -99.3708 },
    { name: "North Norfolk", region: "MB", lat: 49.9308, lng: -98.8356 },
    { name: "Reinland", region: "MB", lat: 49.1331, lng: -97.5942 },
    { name: "Minitonas-Bowsman", region: "MB", lat: 52.1433, lng: -100.9772 },
    { name: "Kippens", region: "NL", lat: 48.5492, lng: -58.6236 },
    { name: "Blucher", region: "SK", lat: 52.0134, lng: -106.2176 },
    { name: "Hatley", region: "QC", lat: 45.27, lng: -71.95 },
    { name: "Saint-Gedeon", region: "QC", lat: 48.5, lng: -71.7667 },
    { name: "Kingsey Falls", region: "QC", lat: 45.85, lng: -72.0667 },
    { name: "Provost", region: "AB", lat: 52.3539, lng: -110.2686 },
    { name: "Saint-Charles", region: "NB", lat: 46.6692, lng: -65.0184 },
    { name: "Mattawa", region: "ON", lat: 46.3167, lng: -78.7 },
    { name: "Tumbler Ridge", region: "BC", lat: 55.1333, lng: -121 },
    { name: "Terrasse-Vaudreuil", region: "QC", lat: 45.4, lng: -73.9833 },
    { name: "L'Ascension-de-Notre-Seigneur", region: "QC", lat: 48.7, lng: -71.6833 },
    { name: "Bow Island", region: "AB", lat: 49.8667, lng: -111.3667 },
    { name: "Barraute", region: "QC", lat: 48.4333, lng: -77.6333 },
    { name: "One Hundred Mile House", region: "BC", lat: 51.6413, lng: -121.3127 },
    { name: "Kedgwick", region: "NB", lat: 47.6456, lng: -67.3431 },
    { name: "Gambo", region: "NL", lat: 48.7833, lng: -54.2167 },
    { name: "Saint-Liguori", region: "QC", lat: 46.0167, lng: -73.5667 },
    { name: "Bonfield", region: "ON", lat: 46.2167, lng: -79.1333 },
    { name: "Pointe-Lebel", region: "QC", lat: 49.1667, lng: -68.2 },
    { name: "Saint Mary", region: "NB", lat: 46.3987, lng: -64.8681 },
    { name: "Saint-Patrice-de-Sherrington", region: "QC", lat: 45.1667, lng: -73.5167 },
    { name: "Fox Creek", region: "AB", lat: 54.395, lng: -116.8092 },
    { name: "Dawn-Euphemia", region: "ON", lat: 42.7, lng: -82.0167 },
    { name: "Chapleau", region: "ON", lat: 47.8333, lng: -83.4 },
    { name: "Saint-Esprit", region: "QC", lat: 45.9, lng: -73.6667 },
    { name: "Westfield Beach", region: "NB", lat: 45.3432, lng: -66.2868 },
    { name: "Montague", region: "PE", lat: 46.1652, lng: -62.65 },
    { name: "Mashteuiatsh", region: "QC", lat: 48.569, lng: -72.2495 },
    { name: "Saint-Francois-du-Lac", region: "QC", lat: 46.0667, lng: -72.8333 },
    { name: "Eel River Crossing", region: "NB", lat: 48.0125, lng: -66.4208 },
    { name: "Saint-Fulgence", region: "QC", lat: 48.45, lng: -70.9 },
    { name: "Millet", region: "AB", lat: 53.0978, lng: -113.4728 },
    { name: "Vallee-Jonction", region: "QC", lat: 46.3667, lng: -70.9167 },
    { name: "Saint-Georges-de-Cacouna", region: "QC", lat: 47.9167, lng: -69.5 },
    { name: "Lumsden No. 189", region: "SK", lat: 50.6734, lng: -104.7686 },
    { name: "Manitouwadge", region: "ON", lat: 49.1333, lng: -85.8333 },
    { name: "Wellington", region: "ON", lat: 43.9579, lng: -77.3534 },
    { name: "Swift Current No. 137", region: "SK", lat: 50.2211, lng: -107.8559 },
    { name: "Tofino", region: "BC", lat: 49.1275, lng: -125.8852 },
    { name: "Fort Qu'Appelle", region: "SK", lat: 50.7667, lng: -103.7833 },
    { name: "Vulcan", region: "AB", lat: 50.4, lng: -113.25 },
    { name: "Indian Head", region: "SK", lat: 50.5333, lng: -103.6667 },
    { name: "Petit Rocher", region: "NB", lat: 47.7839, lng: -65.7159 },
    { name: "Wabush", region: "NL", lat: 52.9081, lng: -66.869 },
    { name: "Saint-Fabien", region: "QC", lat: 48.3, lng: -68.87 },
    { name: "Watrous", region: "SK", lat: 51.6841, lng: -105.4661 },
    { name: "North Frontenac", region: "ON", lat: 44.95, lng: -76.9 },
    { name: "Lac-Superieur", region: "QC", lat: 46.2, lng: -74.4667 },
    { name: "Les Escoumins", region: "QC", lat: 48.35, lng: -69.4 },
    { name: "Richibucto", region: "NB", lat: 46.6189, lng: -64.8385 },
    { name: "Riviere-Beaudette", region: "QC", lat: 45.2333, lng: -74.3333 },
    { name: "Saint-Barthelemy", region: "QC", lat: 46.1833, lng: -73.1333 },
    { name: "Nisga'a", region: "BC", lat: 55.1078, lng: -129.4293 },
    { name: "Austin", region: "QC", lat: 45.1833, lng: -72.2833 },
    { name: "Saint-Mathieu", region: "QC", lat: 45.3167, lng: -73.5164 },
    { name: "Saint-Paul-de-l'Ile-aux-Noix", region: "QC", lat: 45.1333, lng: -73.2833 },
    { name: "Orkney No. 244", region: "SK", lat: 51.2557, lng: -102.6469 },
    { name: "Behchoko", region: "NT", lat: 62.8184, lng: -115.9933 },
    { name: "Saint-Joseph-de-Coleraine", region: "QC", lat: 45.97, lng: -71.37 },
    { name: "Saint-Cyprien-de-Napierville", region: "QC", lat: 45.1833, lng: -73.4167 },
    { name: "Sayabec", region: "QC", lat: 48.5667, lng: -67.6833 },
    { name: "Valleyview", region: "AB", lat: 55.0686, lng: -117.2683 },
    { name: "Deleage", region: "QC", lat: 46.3833, lng: -75.9167 },
    { name: "Potton", region: "QC", lat: 45.0833, lng: -72.3667 },
    { name: "Sainte-Beatrix", region: "QC", lat: 46.2, lng: -73.6167 },
    { name: "Sainte-Justine", region: "QC", lat: 46.4, lng: -70.35 },
    { name: "Eastman", region: "QC", lat: 45.3341, lng: -72.3041 },
    { name: "Saint-Valerien-de-Milton", region: "QC", lat: 45.5667, lng: -72.7167 },
    { name: "Saint-Cuthbert", region: "QC", lat: 46.15, lng: -73.2333 },
    { name: "Saint-Blaise-sur-Richelieu", region: "QC", lat: 45.2167, lng: -73.2833 },
    { name: "Middleton", region: "NS", lat: 44.9418, lng: -65.0686 },
    { name: "Maugerville", region: "NB", lat: 46.1301, lng: -66.2859 },
    { name: "Dalmeny", region: "SK", lat: 52.3411, lng: -106.7733 },
    { name: "Kamsack", region: "SK", lat: 51.565, lng: -101.8947 },
    { name: "Lumsden", region: "SK", lat: 50.6463, lng: -104.8676 },
    { name: "Trinity Bay North", region: "NL", lat: 48.4978, lng: -53.086 },
    { name: "Saint-Michel-de-Bellechasse", region: "QC", lat: 46.8667, lng: -70.9167 },
    { name: "Sainte-Angele-de-Monnoir", region: "QC", lat: 45.3833, lng: -73.1 },
    { name: "Picture Butte", region: "AB", lat: 49.8731, lng: -112.78 },
    { name: "Sacre-Coeur-Saguenay", region: "QC", lat: 48.2479, lng: -69.854 },
    { name: "Saint-Louis", region: "NB", lat: 46.7048, lng: -65.1046 },
    { name: "Victoria", region: "NL", lat: 47.7675, lng: -53.2411 },
    { name: "Saint-Robert", region: "QC", lat: 45.9667, lng: -73 },
    { name: "Armstrong", region: "MB", lat: 50.64, lng: -97.495 },
    { name: "Saint-Pierre-de-l'Ile-d'Orleans", region: "QC", lat: 46.8833, lng: -71.0667 },
    { name: "La Guadeloupe", region: "QC", lat: 45.95, lng: -70.93 },
    { name: "Saint Andrews", region: "NB", lat: 45.074, lng: -67.0521 },
    { name: "Burns Lake", region: "BC", lat: 54.2292, lng: -125.7625 },
    { name: "Povungnituk", region: "QC", lat: 60.0477, lng: -77.2751 },
    { name: "Manners Sutton", region: "NB", lat: 45.6417, lng: -67.0609 },
    { name: "Gore", region: "QC", lat: 45.77, lng: -74.25 },
    { name: "Deseronto", region: "ON", lat: 44.2, lng: -77.05 },
    { name: "Lamont", region: "AB", lat: 53.7603, lng: -112.7778 },
    { name: "Chambord", region: "QC", lat: 48.4333, lng: -72.0667 },
    { name: "Dudswell", region: "QC", lat: 45.5833, lng: -71.5833 },
    { name: "Wynyard", region: "SK", lat: 51.7667, lng: -104.1833 },
    { name: "Cambridge Bay", region: "NU", lat: 69.1528, lng: -105.1707 },
    { name: "Saint-Narcisse", region: "QC", lat: 46.5667, lng: -72.4667 },
    { name: "Frontenac Islands", region: "ON", lat: 44.2, lng: -76.3833 },
    { name: "Waswanipi", region: "QC", lat: 49.7883, lng: -75.9544 },
    { name: "Inukjuak", region: "QC", lat: 58.4824, lng: -78.1309 },
    { name: "Piney", region: "MB", lat: 49.2069, lng: -95.8333 },
    { name: "Komoka", region: "ON", lat: 42.958, lng: -81.4001 },
    { name: "Saint-Zacharie", region: "QC", lat: 46.1333, lng: -70.3667 },
    { name: "Hemmingford", region: "QC", lat: 45.0833, lng: -73.5833 },
    { name: "Shelburne", region: "NS", lat: 43.7633, lng: -65.3236 },
    { name: "Saint-Clet", region: "QC", lat: 45.35, lng: -74.22 },
    { name: "Carberry", region: "MB", lat: 49.8689, lng: -99.3594 },
    { name: "Brighton", region: "NB", lat: 46.3316, lng: -67.3585 },
    { name: "Saint-Antoine", region: "NB", lat: 46.3629, lng: -64.753 },
    { name: "Warfield", region: "BC", lat: 49.0953, lng: -117.7344 },
    { name: "Northampton", region: "NB", lat: 46.1313, lng: -67.4713 },
    { name: "Saint-Ours", region: "QC", lat: 45.8833, lng: -73.15 },
    { name: "Stephenville Crossing", region: "NL", lat: 48.5167, lng: -58.4167 },
    { name: "Sainte-Anne-de-la-Pocatiere", region: "QC", lat: 47.35, lng: -70 },
    { name: "Ucluelet", region: "BC", lat: 48.9358, lng: -125.5433 },
    { name: "Saint-Placide", region: "QC", lat: 45.5333, lng: -74.2 },
    { name: "Barriere", region: "BC", lat: 51.1803, lng: -120.1261 },
    { name: "Fisher", region: "MB", lat: 51.0825, lng: -97.6611 },
    { name: "Nipissing", region: "ON", lat: 46.05, lng: -79.55 },
    { name: "Sainte-Clotilde", region: "QC", lat: 45.15, lng: -73.6833 },
    { name: "Shaunavon", region: "SK", lat: 49.651, lng: -108.412 },
    { name: "Wicklow", region: "NB", lat: 46.5017, lng: -67.7067 },
    { name: "Southesk", region: "NB", lat: 46.9901, lng: -66.4336 },
    { name: "Nouvelle", region: "QC", lat: 48.1333, lng: -66.3167 },
    { name: "Rosthern", region: "SK", lat: 52.65, lng: -106.3333 },
    { name: "Yamaska", region: "QC", lat: 46.0236, lng: -72.9391 },
    { name: "Neguac", region: "NB", lat: 47.2333, lng: -65.05 },
    { name: "Flat Rock", region: "NL", lat: 47.7086, lng: -52.7144 },
    { name: "Igloolik", region: "NU", lat: 69.3817, lng: -81.6811 },
    { name: "Grunthal", region: "MB", lat: 49.4065, lng: -96.8603 },
    { name: "Naramata", region: "BC", lat: 49.5886, lng: -119.5838 },
    { name: "Saint-Elie-de-Caxton", region: "QC", lat: 46.4833, lng: -72.9667 },
    { name: "Blumenort", region: "MB", lat: 49.6033, lng: -96.7006 },
    { name: "Balmoral", region: "NB", lat: 47.9667, lng: -66.45 },
    { name: "Price", region: "QC", lat: 48.6017, lng: -68.1227 },
    { name: "Rosedale", region: "MB", lat: 50.4397, lng: -99.5389 },
    { name: "Saint-Jacques-le-Mineur", region: "QC", lat: 45.2833, lng: -73.4167 },
    { name: "Huron Shores", region: "ON", lat: 46.2833, lng: -83.2 },
    { name: "Champlain", region: "QC", lat: 46.45, lng: -72.35 },
    { name: "Whitehead", region: "MB", lat: 49.7981, lng: -100.2575 },
    { name: "Saint-Antoine-sur-Richelieu", region: "QC", lat: 45.7833, lng: -73.1833 },
    { name: "Saint-Pacome", region: "QC", lat: 47.4, lng: -69.95 },
    { name: "Saint-Stanislas-de-Kostka", region: "QC", lat: 45.18, lng: -74.13 },
    { name: "Frontenac", region: "QC", lat: 45.58, lng: -70.83 },
    { name: "Stuartburn", region: "MB", lat: 49.1331, lng: -96.5158 },
    { name: "Yamaska-Est", region: "QC", lat: 46, lng: -72.92 },
    { name: "Sainte-Emelie-de-l'Energie", region: "QC", lat: 46.3167, lng: -73.65 },
    { name: "Saint-Charles-sur-Richelieu", region: "QC", lat: 45.6833, lng: -73.1833 },
    { name: "Saint-Joseph-de-Sorel", region: "QC", lat: 46.0446, lng: -73.1308 },
    { name: "Nipigon", region: "ON", lat: 49.0153, lng: -88.2683 },
    { name: "Riviere-Blanche", region: "QC", lat: 48.7833, lng: -67.7 },
    { name: "Sainte-Helene-de-Bagot", region: "QC", lat: 45.7333, lng: -72.7333 },
    { name: "Franklin Centre", region: "QC", lat: 45.0467, lng: -73.9005 },
    { name: "Harbour Breton", region: "NL", lat: 47.4833, lng: -55.8333 },
    { name: "Massey Drive", region: "NL", lat: 48.9372, lng: -57.9 },
    { name: "Mille-Isles", region: "QC", lat: 45.82, lng: -74.22 },
    { name: "Wilton No. 472", region: "SK", lat: 53.124, lng: -109.7885 },
    { name: "Lyster", region: "QC", lat: 46.3667, lng: -71.6167 },
    { name: "Oakview", region: "MB", lat: 50.1964, lng: -100.2167 },
    { name: "Balgonie", region: "SK", lat: 50.488, lng: -104.269 },
    { name: "Harrison Park", region: "MB", lat: 50.5563, lng: -100.1674 },
    { name: "Kensington", region: "PE", lat: 46.4333, lng: -63.65 },
    { name: "Witless Bay", region: "NL", lat: 47.28, lng: -52.83 },
    { name: "Pond Inlet", region: "NU", lat: 72.6808, lng: -77.7503 },
    { name: "Royston", region: "BC", lat: 49.6405, lng: -124.9406 },
    { name: "Sainte-Clotilde-de-Horton", region: "QC", lat: 45.9833, lng: -72.2333 },
    { name: "Burford", region: "ON", lat: 43.1036, lng: -80.424 },
    { name: "Fossambault-sur-le-Lac", region: "QC", lat: 46.8667, lng: -71.6167 },
    { name: "Saint-Benoit-Labre", region: "QC", lat: 46.0667, lng: -70.8 },
    { name: "Coombs", region: "BC", lat: 49.3008, lng: -124.4049 },
    { name: "Terrace Bay", region: "ON", lat: 48.8, lng: -87.1 },
    { name: "Chapais", region: "QC", lat: 49.7819, lng: -74.8544 },
    { name: "Saint-Honore-de-Shenley", region: "QC", lat: 45.9667, lng: -70.8333 },
    { name: "Cleveland", region: "QC", lat: 45.67, lng: -72.08 },
    { name: "Macdonald, Meredith and Aberdeen Additional", region: "ON", lat: 46.4833, lng: -84.0667 },
    { name: "Messines", region: "QC", lat: 46.2333, lng: -76.0167 },
    { name: "Saint-Jean-de-Dieu", region: "QC", lat: 48, lng: -69.05 },
    { name: "Nakusp", region: "BC", lat: 50.2434, lng: -117.8002 },
    { name: "Florenceville", region: "NB", lat: 46.4435, lng: -67.6152 },
    { name: "Saint-Antoine-de-Tilly", region: "QC", lat: 46.6667, lng: -71.5833 },
    { name: "Lakeview", region: "BC", lat: 49.9026, lng: -119.5699 },
    { name: "Humbermouth", region: "NL", lat: 49.0156, lng: -58.1678 },
    { name: "Fort St. James", region: "BC", lat: 54.4431, lng: -124.2542 },
    { name: "Saint-Francois-de-la-Riviere-du-Sud", region: "QC", lat: 46.8833, lng: -70.7167 },
    { name: "Saint-Jacques", region: "NB", lat: 47.5634, lng: -68.3693 },
    { name: "Uashat", region: "QC", lat: 50.233, lng: -66.3947 },
    { name: "Perth", region: "NB", lat: 46.7393, lng: -67.6984 },
    { name: "Eeyou Istchee Baie-James", region: "QC", lat: 52.3382, lng: -75.1977 },
    { name: "Shellbrook No. 493", region: "SK", lat: 53.3545, lng: -106.2553 },
    { name: "Shawville", region: "QC", lat: 45.6, lng: -76.4833 },
    { name: "Saint-Lucien", region: "QC", lat: 45.8667, lng: -72.2667 },
    { name: "Lambton", region: "QC", lat: 45.83, lng: -71.08 },
    { name: "Saint-Laurent-de-l'Ile-d'Orleans", region: "QC", lat: 46.8667, lng: -71.0167 },
    { name: "Saint-Flavien", region: "QC", lat: 46.5167, lng: -71.6 },
    { name: "Grenville", region: "QC", lat: 45.6333, lng: -74.6 },
    { name: "Chute-aux-Outardes", region: "QC", lat: 49.1167, lng: -68.4 },
    { name: "Sainte-Marcelline-de-Kildare", region: "QC", lat: 46.1167, lng: -73.6 },
    { name: "Saint-Felix-de-Kingsey", region: "QC", lat: 45.8, lng: -72.1833 },
    { name: "Upper Island Cove", region: "NL", lat: 47.6472, lng: -53.2233 },
    { name: "Glenelg", region: "NB", lat: 46.9455, lng: -65.2893 },
    { name: "Sainte-Elisabeth", region: "QC", lat: 46.0833, lng: -73.35 },
    { name: "Ashcroft", region: "BC", lat: 50.7256, lng: -121.2806 },
    { name: "Clarkes Beach", region: "NL", lat: 47.5447, lng: -53.2824 },
    { name: "Saint-Bernard-de-Lacolle", region: "QC", lat: 45.0833, lng: -73.4167 },
    { name: "Belledune", region: "NB", lat: 47.9, lng: -65.8167 },
    { name: "Saint-Guillaume", region: "QC", lat: 45.8833, lng: -72.7667 },
    { name: "Venise-en-Quebec", region: "QC", lat: 45.0833, lng: -73.1333 },
    { name: "Maliotenam", region: "QC", lat: 50.2114, lng: -66.1911 },
    { name: "Ripon", region: "QC", lat: 45.7833, lng: -75.1 },
    { name: "Hilliers", region: "BC", lat: 49.3022, lng: -124.4727 },
    { name: "Saint-Joseph", region: "NB", lat: 47.558, lng: -68.3082 },
    { name: "Saint-Paulin", region: "QC", lat: 46.4167, lng: -73.0333 },
    { name: "Bon Accord", region: "AB", lat: 53.8328, lng: -113.4189 },
    { name: "Saint David", region: "NB", lat: 45.2918, lng: -67.1983 },
    { name: "Saint-Albert", region: "QC", lat: 46, lng: -72.0833 },
    { name: "Matagami", region: "QC", lat: 49.75, lng: -77.6333 },
    { name: "Springfield", region: "NB", lat: 45.7005, lng: -65.8079 },
    { name: "Amherst", region: "QC", lat: 46.05, lng: -74.7667 },
    { name: "Notre-Dame-du-Laus", region: "QC", lat: 46.0833, lng: -75.6167 },
    { name: "St. George", region: "NB", lat: 45.1333, lng: -66.8167 },
    { name: "Wembley", region: "AB", lat: 55.1572, lng: -119.1392 },
    { name: "Victoria", region: "MB", lat: 49.6644, lng: -98.9153 },
    { name: "Springbrook", region: "AB", lat: 52.1796, lng: -113.885 },
    { name: "Saint-Tite-des-Caps", region: "QC", lat: 47.1333, lng: -70.7667 },
    { name: "Hudson Bay", region: "SK", lat: 52.851, lng: -102.392 },
    { name: "Pinawa", region: "MB", lat: 50.1707, lng: -95.9547 },
    { name: "Brudenell, Lyndoch and Raglan", region: "ON", lat: 45.3167, lng: -77.4 },
    { name: "Carlyle", region: "SK", lat: 49.6333, lng: -102.2667 },
    { name: "Keremeos", region: "BC", lat: 49.2025, lng: -119.8294 },
    { name: "Val-Joli", region: "QC", lat: 45.6, lng: -71.97 },
    { name: "Gold River", region: "BC", lat: 49.7769, lng: -126.0514 },
    { name: "Saint-Casimir", region: "QC", lat: 46.65, lng: -72.1333 },
    { name: "Bay Bulls", region: "NL", lat: 47.3158, lng: -52.8103 },
    { name: "Langham", region: "SK", lat: 52.3667, lng: -106.9667 },
    { name: "Frenchman Butte", region: "SK", lat: 53.6052, lng: -109.4298 },
    { name: "Gordon", region: "NB", lat: 46.8363, lng: -67.1913 },
    { name: "Kugluktuk", region: "NU", lat: 67.8055, lng: -115.3223 },
    { name: "Saint-Malachie", region: "QC", lat: 46.5333, lng: -70.7667 },
    { name: "Southampton", region: "NB", lat: 46.0789, lng: -67.3124 },
    { name: "Salluit", region: "QC", lat: 62.2013, lng: -75.6337 },
    { name: "Pangnirtung", region: "NU", lat: 66.1436, lng: -65.6829 },
    { name: "Saint-Louis-de-Gonzague", region: "QC", lat: 45.2, lng: -73.98 },
    { name: "Moosonee", region: "ON", lat: 51.2722, lng: -80.6431 },
    { name: "Englehart", region: "ON", lat: 47.8167, lng: -79.8667 },
    { name: "Saint-Urbain", region: "QC", lat: 47.55, lng: -70.5333 },
    { name: "Tring-Jonction", region: "QC", lat: 46.2667, lng: -70.9833 },
    { name: "Nauwigewauk", region: "NB", lat: 45.4812, lng: -65.8738 },
    { name: "Pointe-a-la-Croix", region: "QC", lat: 48.0167, lng: -66.6833 },
    { name: "Denmark", region: "NB", lat: 47.1155, lng: -67.4771 },
    { name: "Saint-Joachim", region: "QC", lat: 47.05, lng: -70.85 },
    { name: "Torch River No. 488", region: "SK", lat: 53.5445, lng: -104.4619 },
    { name: "Saint-Theodore-d'Acton", region: "QC", lat: 45.6833, lng: -72.5833 },
    { name: "Grindrod", region: "BC", lat: 50.63, lng: -119.1314 },
    { name: "L' Isle-Verte", region: "QC", lat: 48.0167, lng: -69.3333 },
    { name: "Harrison Hot Springs", region: "BC", lat: 49.3, lng: -121.7819 },
    { name: "Palmarolle", region: "QC", lat: 48.6667, lng: -79.2 },
    { name: "Henryville", region: "QC", lat: 45.1333, lng: -73.1833 },
    { name: "Sussex Corner", region: "NB", lat: 45.7122, lng: -65.4719 },
    { name: "Saint-Odilon-de-Cranbourne", region: "QC", lat: 46.3667, lng: -70.6833 },
    { name: "Pipestone", region: "MB", lat: 49.6653, lng: -101.1444 },
    { name: "Laurierville", region: "QC", lat: 46.3, lng: -71.65 },
    { name: "La Dore", region: "QC", lat: 48.72, lng: -72.65 },
    { name: "Lac-au-Saumon", region: "QC", lat: 48.4167, lng: -67.35 },
    { name: "Wotton", region: "QC", lat: 45.7333, lng: -71.8 },
    { name: "Prairie Lakes", region: "MB", lat: 49.4034, lng: -99.6298 },
    { name: "Elk Point", region: "AB", lat: 53.8967, lng: -110.8972 },
    { name: "Shellbrook", region: "SK", lat: 53.2167, lng: -106.4 },
    { name: "Wemindji", region: "QC", lat: 53.044, lng: -78.7384 },
    { name: "Cape Dorset", region: "NU", lat: 64.2237, lng: -76.5405 },
    { name: "Strong", region: "ON", lat: 45.75, lng: -79.4 },
    { name: "Lappe", region: "ON", lat: 48.5693, lng: -89.3573 },
    { name: "Riviere-Heva", region: "QC", lat: 48.2333, lng: -78.2167 },
    { name: "Fort-Coulonge", region: "QC", lat: 45.85, lng: -76.7333 },
    { name: "Irishtown-Summerside", region: "NL", lat: 48.9833, lng: -57.95 },
    { name: "Godmanchester", region: "QC", lat: 45.08, lng: -74.25 },
    { name: "Macklin", region: "SK", lat: 52.33, lng: -109.94 },
    { name: "Armour", region: "ON", lat: 45.6289, lng: -79.3436 },
    { name: "Saint-Simon", region: "QC", lat: 45.719, lng: -72.8463 },
    { name: "St. Francois Xavier", region: "MB", lat: 49.9903, lng: -97.6722 },
    { name: "Tingwick", region: "QC", lat: 45.8873, lng: -71.9244 },
    { name: "Saint-Aubert", region: "QC", lat: 47.1833, lng: -70.2167 },
    { name: "Saint-Mathieu-du-Parc", region: "QC", lat: 46.5667, lng: -72.9167 },
    { name: "Wabasca", region: "AB", lat: 55.9855, lng: -113.8566 },
    { name: "Ragueneau", region: "QC", lat: 49.0667, lng: -68.5333 },
    { name: "Notre-Dame-du-Bon-Conseil", region: "QC", lat: 46, lng: -72.35 },
    { name: "Wasagamack", region: "MB", lat: 53.9056, lng: -94.9412 },
    { name: "Saint-Ubalde", region: "QC", lat: 46.75, lng: -72.2667 },
    { name: "Creighton", region: "SK", lat: 54.7561, lng: -101.8973 },
    { name: "Fortune", region: "NL", lat: 47.0733, lng: -55.8217 },
    { name: "Faraday", region: "ON", lat: 45, lng: -77.9167 },
    { name: "Berthier-sur-Mer", region: "QC", lat: 46.9167, lng: -70.7333 },
    { name: "Frampton", region: "QC", lat: 46.4667, lng: -70.8 },
    { name: "Magnetawan", region: "ON", lat: 45.6667, lng: -79.6333 },
    { name: "New Carlisle", region: "QC", lat: 48.0167, lng: -65.3333 },
    { name: "Laird No. 404", region: "SK", lat: 52.5696, lng: -106.7312 },
    { name: "Petitcodiac", region: "NB", lat: 45.9333, lng: -65.1667 },
    { name: "Popkum", region: "BC", lat: 49.1911, lng: -121.7553 },
    { name: "Norton", region: "NB", lat: 45.6387, lng: -65.6955 },
    { name: "Canwood No. 494", region: "SK", lat: 53.4574, lng: -106.7768 },
    { name: "Wentworth-Nord", region: "QC", lat: 45.85, lng: -74.45 },
    { name: "Bas Caraquet", region: "NB", lat: 47.8, lng: -64.8333 },
    { name: "Sainte-Ursule", region: "QC", lat: 46.2833, lng: -73.0333 },
    { name: "Dawson", region: "YT", lat: 64.0464, lng: -139.3893 },
    { name: "Nantes", region: "QC", lat: 45.6333, lng: -71.0333 },
    { name: "Lac-aux-Sables", region: "QC", lat: 46.8667, lng: -72.4 },
    { name: "Stewiacke", region: "NS", lat: 45.1422, lng: -63.3483 },
    { name: "Taylor", region: "BC", lat: 56.159, lng: -120.6878 },
    { name: "Rosser", region: "MB", lat: 49.99, lng: -97.4592 },
    { name: "Estevan No. 5", region: "SK", lat: 49.1308, lng: -103.0126 },
    { name: "Falmouth", region: "NS", lat: 44.9967, lng: -64.1634 },
    { name: "Vaudreuil-sur-le-Lac", region: "QC", lat: 45.4, lng: -74.0333 },
    { name: "Grahamdale", region: "MB", lat: 51.42, lng: -98.3733 },
    { name: "Cardwell", region: "NB", lat: 45.7848, lng: -65.3037 },
    { name: "Two Hills", region: "AB", lat: 53.715, lng: -111.7461 },
    { name: "Spiritwood No. 496", region: "SK", lat: 53.4435, lng: -107.4495 },
    { name: "Legal", region: "AB", lat: 53.9492, lng: -113.595 },
    { name: "Amulet", region: "QC", lat: 48.2938, lng: -79.0274 },
    { name: "Herouxville", region: "QC", lat: 46.6667, lng: -72.6167 },
    { name: "Pointe-des-Cascades", region: "QC", lat: 45.3333, lng: -73.9667 },
    { name: "Weldford", region: "NB", lat: 46.5221, lng: -65.1114 },
    { name: "Reynolds", region: "MB", lat: 49.7678, lng: -95.8842 },
    { name: "St. Laurent", region: "MB", lat: 50.43, lng: -97.7933 },
    { name: "Lions Bay", region: "BC", lat: 49.4581, lng: -123.2369 },
    { name: "L'Isle-aux-Allumettes", region: "QC", lat: 45.8667, lng: -77.0667 },
    { name: "Emo", region: "ON", lat: 48.6333, lng: -93.8333 },
    { name: "Sainte-Brigide-d'Iberville", region: "QC", lat: 45.3167, lng: -73.0667 },
    { name: "Les Eboulements", region: "QC", lat: 47.4833, lng: -70.3167 },
    { name: "Dunsmuir", region: "BC", lat: 49.3696, lng: -124.5772 },
    { name: "Pointe-aux-Outardes", region: "QC", lat: 49.05, lng: -68.4333 },
    { name: "Smooth Rock Falls", region: "ON", lat: 49.2833, lng: -81.6333 },
    { name: "Oxbow", region: "SK", lat: 49.2333, lng: -102.1667 },
    { name: "Telkwa", region: "BC", lat: 54.6972, lng: -127.05 },
    { name: "Gjoa Haven", region: "NU", lat: 68.6448, lng: -95.8912 },
    { name: "Sainte-Barbe", region: "QC", lat: 45.1667, lng: -74.2 },
    { name: "Mayerthorpe", region: "AB", lat: 53.9503, lng: -115.1336 },
    { name: "Saint-Louis-du-Ha! Ha!", region: "QC", lat: 47.67, lng: -68.98 },
    { name: "Powerview-Pine Falls", region: "MB", lat: 50.5661, lng: -96.1981 },
    { name: "Baie Verte", region: "NL", lat: 49.9167, lng: -56.1833 },
    { name: "Saint-Edouard", region: "QC", lat: 45.2333, lng: -73.5167 },
    { name: "Charlo", region: "NB", lat: 48, lng: -66.32 },
    { name: "Hillsborough", region: "NB", lat: 45.9052, lng: -64.7652 },
    { name: "Bruederheim", region: "AB", lat: 53.8042, lng: -112.9278 },
    { name: "Burgeo", region: "NL", lat: 47.6, lng: -57.6333 },
    { name: "Wadena", region: "SK", lat: 51.9458, lng: -103.8014 },
    { name: "Richmond", region: "NB", lat: 46.0776, lng: -67.7248 },
    { name: "Swan Hills", region: "AB", lat: 54.7106, lng: -115.4133 },
    { name: "Wilkie", region: "SK", lat: 52.4167, lng: -108.7 },
    { name: "Saint-Leonard", region: "NB", lat: 47.1625, lng: -67.925 },
    { name: "Riviere-Bleue", region: "QC", lat: 47.4333, lng: -69.05 },
    { name: "Noyan", region: "QC", lat: 45.0667, lng: -73.3 },
    { name: "Ile-a-la-Crosse", region: "SK", lat: 55.45, lng: -107.8833 },
    { name: "Landmark", region: "MB", lat: 49.6711, lng: -96.8179 },
    { name: "Saint-Hugues", region: "QC", lat: 45.8, lng: -72.8667 },
    { name: "Chisholm", region: "ON", lat: 46.1, lng: -79.2333 },
    { name: "Sainte-Anne-du-Sault", region: "QC", lat: 46.1733, lng: -72.1415 },
    { name: "La Conception", region: "QC", lat: 46.15, lng: -74.7 },
    { name: "Saint-Valere", region: "QC", lat: 46.0667, lng: -72.1 },
    { name: "Sorrento", region: "BC", lat: 50.8832, lng: -119.4782 },
    { name: "Lameque", region: "NB", lat: 47.7925, lng: -64.6532 },
    { name: "Thessalon", region: "ON", lat: 46.25, lng: -83.55 },
    { name: "L'Isle-aux-Coudres", region: "QC", lat: 47.4, lng: -70.3833 },
    { name: "Nobleford", region: "AB", lat: 49.8822, lng: -113.0531 },
    { name: "Larouche", region: "QC", lat: 48.45, lng: -71.5167 },
    { name: "South Qu'Appelle No. 157", region: "SK", lat: 50.5389, lng: -104.0141 },
    { name: "Elton", region: "MB", lat: 49.975, lng: -99.8658 },
    { name: "Lorrainville", region: "QC", lat: 47.3613, lng: -79.3382 },
    { name: "Conestogo", region: "ON", lat: 43.5441, lng: -80.4997 },
    { name: "Upham", region: "NB", lat: 45.5083, lng: -65.6618 },
    { name: "St.-Charles", region: "ON", lat: 46.3422, lng: -80.4497 },
    { name: "Sainte-Lucie-des-Laurentides", region: "QC", lat: 46.13, lng: -74.18 },
    { name: "Saint-Alexis", region: "QC", lat: 45.9333, lng: -73.6167 },
    { name: "Gillam", region: "MB", lat: 56.3472, lng: -94.7078 },
    { name: "Roxton Falls", region: "QC", lat: 45.5667, lng: -72.5167 },
    { name: "Montcalm", region: "MB", lat: 49.1775, lng: -97.3247 },
    { name: "Clarendon", region: "QC", lat: 45.65, lng: -76.5167 },
    { name: "Mervin No. 499", region: "SK", lat: 53.5455, lng: -108.8762 },
    { name: "Saint-Ludger", region: "QC", lat: 45.75, lng: -70.7 },
    { name: "Coldwell", region: "MB", lat: 50.6389, lng: -98.0417 },
    { name: "Saint-Arsene", region: "QC", lat: 47.9167, lng: -69.4333 },
    { name: "Racine", region: "QC", lat: 45.5, lng: -72.25 },
    { name: "Saint-Majorique-de-Grantham", region: "QC", lat: 45.9333, lng: -72.5833 },
    { name: "Saint-Zenon", region: "QC", lat: 46.55, lng: -73.8167 },
    { name: "Saint-Armand", region: "QC", lat: 45.0333, lng: -73.05 },
    { name: "Saint-Edouard-de-Lotbiniere", region: "QC", lat: 46.5667, lng: -71.8333 },
    { name: "Alonsa", region: "MB", lat: 50.9794, lng: -99.0796 },
    { name: "Listuguj", region: "QC", lat: 48.0609, lng: -66.7491 },
    { name: "Bowden", region: "AB", lat: 51.9306, lng: -114.0256 },
    { name: "St. Joseph", region: "ON", lat: 46.2667, lng: -84 },
    { name: "Osler", region: "SK", lat: 52.37, lng: -106.54 },
    { name: "Saint-Hubert-de-Riviere-du-Loup", region: "QC", lat: 47.8167, lng: -69.15 },
    { name: "Saint-Jude", region: "QC", lat: 45.7667, lng: -72.9833 },
    { name: "Dildo", region: "NL", lat: 47.5685, lng: -53.5471 },
    { name: "La Minerve", region: "QC", lat: 46.25, lng: -74.9333 },
    { name: "Lanigan", region: "SK", lat: 51.85, lng: -105.0333 },
    { name: "Lajord No. 128", region: "SK", lat: 50.1965, lng: -104.2507 },
    { name: "Moonbeam", region: "ON", lat: 49.35, lng: -82.15 },
    { name: "Notre-Dame-des-Pins", region: "QC", lat: 46.1833, lng: -70.7167 },
    { name: "Saint-Alban", region: "QC", lat: 46.7167, lng: -72.0833 },
    { name: "Saint-Pierre-les-Becquets", region: "QC", lat: 46.5, lng: -72.2 },
    { name: "Arborg", region: "MB", lat: 50.9075, lng: -97.2182 },
    { name: "Vauxhall", region: "AB", lat: 50.0689, lng: -112.0975 },
    { name: "Bayfield", region: "ON", lat: 43.5615, lng: -81.6983 },
    { name: "Beaver River", region: "SK", lat: 54.3531, lng: -109.5575 },
    { name: "Irricana", region: "AB", lat: 51.3189, lng: -113.6106 },
    { name: "Labrecque", region: "QC", lat: 48.6667, lng: -71.5333 },
    { name: "New Bandon", region: "NB", lat: 47.6912, lng: -65.29 },
    { name: "Wemotaci", region: "QC", lat: 47.9219, lng: -73.7872 },
    { name: "Sainte-Henedine", region: "QC", lat: 46.55, lng: -70.9833 },
    { name: "L'Anse-Saint-Jean", region: "QC", lat: 48.2333, lng: -70.2 },
    { name: "Bassano", region: "AB", lat: 50.7833, lng: -112.4667 },
    { name: "Parrsboro", region: "NS", lat: 45.3998, lng: -64.3312 },
    { name: "Kaleden", region: "BC", lat: 49.3926, lng: -119.5955 },
    { name: "St. George's", region: "NL", lat: 48.4275, lng: -58.4778 },
    { name: "Fort Simpson", region: "NT", lat: 61.8082, lng: -121.3199 },
    { name: "Akwesasne", region: "QC", lat: 45.0155, lng: -74.5769 },
    { name: "L'Avenir", region: "QC", lat: 45.7667, lng: -72.3 },
    { name: "Ignace", region: "ON", lat: 49.4167, lng: -91.6667 },
    { name: "Claremont", region: "ON", lat: 43.9741, lng: -79.1316 },
    { name: "Teulon", region: "MB", lat: 50.3858, lng: -97.2611 },
    { name: "Peel", region: "NB", lat: 46.4058, lng: -67.5278 },
    { name: "Musquash", region: "NB", lat: 45.1836, lng: -66.3514 },
    { name: "Notre-Dame-du-Portage", region: "QC", lat: 47.7667, lng: -69.6167 },
    { name: "St. Lawrence", region: "NL", lat: 46.9244, lng: -55.3928 },
    { name: "Oxford", region: "NS", lat: 45.7306, lng: -63.8733 },
    { name: "Minto-Odanah", region: "MB", lat: 50.2406, lng: -99.8056 },
    { name: "St. Alban's", region: "NL", lat: 47.8753, lng: -55.8414 },
    { name: "Saint James", region: "NB", lat: 45.3822, lng: -67.3427 },
    { name: "Saint-Norbert-d'Arthabaska", region: "QC", lat: 46.1, lng: -71.8167 },
    { name: "Manning", region: "AB", lat: 56.9142, lng: -117.6272 },
    { name: "Glenella-Lansdowne", region: "MB", lat: 50.4163, lng: -99.2097 },
    { name: "Saint-Hilarion", region: "QC", lat: 47.5667, lng: -70.4 },
    { name: "Saint-Simeon", region: "QC", lat: 48.0667, lng: -65.5667 },
    { name: "Saint-Barnabe", region: "QC", lat: 46.4, lng: -72.8833 },
    { name: "Sainte-Felicite", region: "QC", lat: 48.9, lng: -67.3333 },
    { name: "Two Borders", region: "MB", lat: 49.2668, lng: -101.1124 },
    { name: "Queensbury", region: "NB", lat: 45.9918, lng: -67.0632 },
    { name: "Bury", region: "QC", lat: 45.4667, lng: -71.5 },
    { name: "Lac-Bouchette", region: "QC", lat: 48.25, lng: -72.18 },
    { name: "Saint-Lazare-de-Bellechasse", region: "QC", lat: 46.65, lng: -70.8 },
    { name: "Saint-Michel-du-Squatec", region: "QC", lat: 47.88, lng: -68.72 },
    { name: "Saint-Joachim-de-Shefford", region: "QC", lat: 45.45, lng: -72.5333 },
    { name: "St-Pierre-Jolys", region: "MB", lat: 49.4403, lng: -96.9844 },
    { name: "Grand-Remous", region: "QC", lat: 46.6167, lng: -75.9 },
    { name: "Saint-Gabriel-de-Rimouski", region: "QC", lat: 48.4209, lng: -68.1791 },
    { name: "Armstrong", region: "ON", lat: 47.7083, lng: -79.825 },
    { name: "Rogersville", region: "NB", lat: 46.7167, lng: -65.4167 },
    { name: "Langenburg", region: "SK", lat: 50.8333, lng: -101.7 },
    { name: "Sainte-Marie-Salome", region: "QC", lat: 45.9333, lng: -73.5 },
    { name: "Moose Jaw No. 161", region: "SK", lat: 50.4433, lng: -105.5091 },
    { name: "Saint-Cyprien", region: "QC", lat: 47.9, lng: -69.0167 },
    { name: "Maidstone", region: "SK", lat: 53.092, lng: -109.294 },
    { name: "Tres-Saint-Sacrement", region: "QC", lat: 45.1833, lng: -73.85 },
    { name: "Battle River No. 438", region: "SK", lat: 52.7343, lng: -108.4452 },
    { name: "Miltonvale Park", region: "PE", lat: 46.318, lng: -63.237 },
    { name: "McAdam", region: "NB", lat: 45.5944, lng: -67.3258 },
    { name: "Saints-Anges", region: "QC", lat: 46.4167, lng: -70.8833 },
    { name: "Saint-Urbain-Premier", region: "QC", lat: 45.22, lng: -73.73 },
    { name: "Centreville-Wareham-Trinity", region: "NL", lat: 48.9879, lng: -53.9069 },
    { name: "Alberton", region: "PE", lat: 46.8167, lng: -64.0667 },
    { name: "Winnipeg Beach", region: "MB", lat: 50.5058, lng: -96.9742 },
    { name: "Sainte-Agathe-de-Lotbiniere", region: "QC", lat: 46.3833, lng: -71.4167 },
    { name: "Salmo", region: "BC", lat: 49.1942, lng: -117.2778 },
    { name: "Kipling", region: "SK", lat: 50.1015, lng: -102.6324 },
    { name: "Sagamok", region: "ON", lat: 46.1529, lng: -82.2072 },
    { name: "Trecesson", region: "QC", lat: 48.65, lng: -78.3167 },
    { name: "Tara", region: "ON", lat: 44.4793, lng: -81.1445 },
    { name: "Grande-Vallee", region: "QC", lat: 49.2167, lng: -65.1333 },
    { name: "Bertrand", region: "NB", lat: 47.7622, lng: -65.0686 },
    { name: "Newcastle", region: "NB", lat: 47.1725, lng: -65.5551 },
    { name: "Mont-Carmel", region: "QC", lat: 47.4397, lng: -69.8586 },
    { name: "Saint Martins", region: "NB", lat: 45.4563, lng: -65.4395 },
    { name: "Saint-Eugene", region: "QC", lat: 45.8, lng: -72.7 },
    { name: "Notre-Dame-des-Neiges", region: "QC", lat: 48.1167, lng: -69.1667 },
    { name: "Saint-Andre", region: "NB", lat: 47.1392, lng: -67.7444 },
    { name: "Centreville", region: "NS", lat: 45.13, lng: -64.5224 },
    { name: "Roland", region: "MB", lat: 49.3547, lng: -97.8997 },
    { name: "Saint-Leon-de-Standon", region: "QC", lat: 46.4833, lng: -70.6167 },
    { name: "Saint-Modeste", region: "QC", lat: 47.8333, lng: -69.4 },
    { name: "Carnduff", region: "SK", lat: 49.167, lng: -101.783 },
    { name: "Carling", region: "ON", lat: 45.4333, lng: -80.2167 },
    { name: "Eckville", region: "AB", lat: 52.3622, lng: -114.3614 },
    { name: "Nain", region: "NL", lat: 56.5422, lng: -61.6928 },
    { name: "Hillsburgh", region: "ON", lat: 43.7914, lng: -80.1354 },
    { name: "Foam Lake", region: "SK", lat: 51.65, lng: -103.5333 },
    { name: "Sainte-Sabine", region: "QC", lat: 45.2333, lng: -73.0167 },
    { name: "Saint-Maxime-du-Mont-Louis", region: "QC", lat: 49.2333, lng: -65.7333 },
    { name: "Blanc-Sablon", region: "QC", lat: 51.4167, lng: -57.1333 },
    { name: "Cobalt", region: "ON", lat: 47.4, lng: -79.6833 },
    { name: "Gravelbourg", region: "SK", lat: 49.874, lng: -106.555 },
    { name: "South River", region: "ON", lat: 45.8417, lng: -79.375 },
    { name: "Hudson Bay No. 394", region: "SK", lat: 53.0295, lng: -102.3122 },
    { name: "McKellar", region: "ON", lat: 45.4833, lng: -79.85 },
    { name: "Frelighsburg", region: "QC", lat: 45.0461, lng: -72.8106 },
    { name: "Buffalo Narrows", region: "SK", lat: 55.8769, lng: -108.5244 },
    { name: "Ayer's Cliff", region: "QC", lat: 45.1667, lng: -72.05 },
    { name: "Les Mechins", region: "QC", lat: 49, lng: -66.9833 },
    { name: "Sainte-Marguerite", region: "QC", lat: 46.5167, lng: -70.9333 },
    { name: "Saint-Claude", region: "QC", lat: 45.6667, lng: -71.9833 },
    { name: "Air Ronge", region: "SK", lat: 55.0872, lng: -105.3318 },
    { name: "Chipman", region: "NB", lat: 46.171, lng: -65.884 },
    { name: "Girardville", region: "QC", lat: 49, lng: -72.55 },
    { name: "Saint-Bruno-de-Guigues", region: "QC", lat: 47.4667, lng: -79.4333 },
    { name: "Grenfell", region: "SK", lat: 50.4167, lng: -102.9167 },
    { name: "Dorchester", region: "NB", lat: 45.9016, lng: -64.5161 },
    { name: "South Algonquin", region: "ON", lat: 45.4967, lng: -78.0239 },
    { name: "Windermere", region: "BC", lat: 50.4856, lng: -115.9948 },
    { name: "Saint-Narcisse-de-Beaurivage", region: "QC", lat: 46.4833, lng: -71.2333 },
    { name: "Saint-Rene-de-Matane", region: "QC", lat: 48.7, lng: -67.3833 },
    { name: "Sainte-Jeanne-d'Arc", region: "QC", lat: 48.8575, lng: -72.0939 },
    { name: "Plaisance", region: "QC", lat: 45.6167, lng: -75.1167 },
    { name: "Roxton-Sud", region: "QC", lat: 45.5521, lng: -72.5265 },
    { name: "St. Louis No. 431", region: "SK", lat: 52.8277, lng: -105.7873 },
    { name: "Youbou", region: "BC", lat: 48.8562, lng: -124.1731 },
    { name: "Duchess", region: "AB", lat: 50.7333, lng: -111.9 },
    { name: "Saint-Frederic", region: "QC", lat: 46.3, lng: -70.9667 },
    { name: "Viking", region: "AB", lat: 53.0953, lng: -111.7769 },
    { name: "Sioux Narrows-Nestor Falls", region: "ON", lat: 49.4, lng: -94.08 },
    { name: "Whitecourt", region: "AB", lat: 54.1417, lng: -115.6833 },
    { name: "Repulse Bay", region: "NU", lat: 66.5628, lng: -86.3186 },
    { name: "Montreal-Est", region: "QC", lat: 45.63, lng: -73.52 },
    { name: "King", region: "ON", lat: 44.0463, lng: -79.6044 },
    { name: "Regina Beach", region: "SK", lat: 50.79, lng: -104.99 },
    { name: "Saint-Patrice-de-Beaurivage", region: "QC", lat: 46.4167, lng: -71.2333 },
    { name: "Ootischenia", region: "BC", lat: 49.2916, lng: -117.6323 },
    { name: "Hensall", region: "ON", lat: 43.4345, lng: -81.504 },
    { name: "Bentley", region: "AB", lat: 52.4667, lng: -114.05 },
    { name: "Durham", region: "NB", lat: 47.7631, lng: -66.0849 },
    { name: "Sainte-Marthe", region: "QC", lat: 45.4, lng: -74.3 },
    { name: "Notre-Dame-du-Nord", region: "QC", lat: 47.6, lng: -79.4833 },
    { name: "Pinehouse", region: "SK", lat: 55.5136, lng: -106.5986 },
    { name: "Saint-Aime-des-Lacs", region: "QC", lat: 47.6833, lng: -70.3 },
    { name: "Lac-Drolet", region: "QC", lat: 45.72, lng: -70.85 },
    { name: "Preeceville", region: "SK", lat: 51.958, lng: -102.6673 },
    { name: "Maple Creek No. 111", region: "SK", lat: 49.8044, lng: -109.6508 },
    { name: "Harbour Main-Chapel's Cove-Lakeview", region: "NL", lat: 47.4337, lng: -53.1458 },
    { name: "Saint-Wenceslas", region: "QC", lat: 46.1667, lng: -72.3333 },
    { name: "Weyburn No. 67", region: "SK", lat: 49.6535, lng: -103.8348 },
    { name: "Birch Hills", region: "SK", lat: 52.9833, lng: -105.4333 },
    { name: "Wedgeport", region: "NS", lat: 43.7323, lng: -65.9797 },
    { name: "Kerrobert", region: "SK", lat: 51.92, lng: -109.1272 },
    { name: "Havelock", region: "NB", lat: 45.9523, lng: -65.3885 },
    { name: "Eston", region: "SK", lat: 51.15, lng: -108.75 },
    { name: "Sainte-Genevieve-de-Batiscan", region: "QC", lat: 46.5333, lng: -72.3333 },
    { name: "Saint-Justin", region: "QC", lat: 46.25, lng: -73.0833 },
    { name: "Saint-Norbert", region: "QC", lat: 46.1667, lng: -73.3167 },
    { name: "Schreiber", region: "ON", lat: 48.8167, lng: -87.2667 },
    { name: "Trochu", region: "AB", lat: 51.8236, lng: -113.2328 },
    { name: "Botsford", region: "NB", lat: 46.1145, lng: -63.9804 },
    { name: "Riviere-Ouelle", region: "QC", lat: 47.4333, lng: -70.0167 },
    { name: "Greenwich", region: "NB", lat: 45.5112, lng: -66.1229 },
    { name: "Stukely-Sud", region: "QC", lat: 45.3167, lng: -72.4167 },
    { name: "Saint-Georges-de-Clarenceville", region: "QC", lat: 45.0667, lng: -73.25 },
    { name: "Sainte-Therese-de-Gaspe", region: "QC", lat: 48.4167, lng: -64.4167 },
    { name: "Beachburg", region: "ON", lat: 45.7303, lng: -76.8559 },
    { name: "Desbiens", region: "QC", lat: 48.4167, lng: -71.95 },
    { name: "Clyde River", region: "NU", lat: 70.4632, lng: -68.4822 },
    { name: "La Macaza", region: "QC", lat: 46.3667, lng: -74.7667 },
    { name: "Souris", region: "PE", lat: 46.3554, lng: -62.2542 },
    { name: "Kindersley No. 290", region: "SK", lat: 51.4894, lng: -109.0979 },
    { name: "Laird", region: "ON", lat: 46.3833, lng: -84.0667 },
    { name: "Falher", region: "AB", lat: 55.7372, lng: -117.2014 },
    { name: "Saint-Vallier", region: "QC", lat: 46.8833, lng: -70.8167 },
    { name: "Coleraine", region: "QC", lat: 45.9649, lng: -71.3734 },
    { name: "Melita", region: "MB", lat: 49.2681, lng: -100.9958 },
    { name: "Noonan", region: "NB", lat: 45.9544, lng: -66.4868 },
    { name: "Sainte-Petronille", region: "QC", lat: 46.85, lng: -71.1333 },
    { name: "Delisle", region: "SK", lat: 51.9254, lng: -107.1333 },
    { name: "Bristol", region: "QC", lat: 45.5333, lng: -76.4667 },
    { name: "Mahone Bay", region: "NS", lat: 44.4489, lng: -64.3819 },
    { name: "Waldheim", region: "SK", lat: 52.65, lng: -106.6167 },
    { name: "Saint-Sylvestre", region: "QC", lat: 46.3667, lng: -71.2333 },
    { name: "Taloyoak", region: "NU", lat: 69.5554, lng: -93.4972 },
    { name: "Onoway", region: "AB", lat: 53.7011, lng: -114.1981 },
    { name: "Saint-Stanislas", region: "QC", lat: 46.6167, lng: -72.4 },
    { name: "Malpeque", region: "PE", lat: 46.5, lng: -63.6667 },
    { name: "Plantagenet", region: "ON", lat: 45.5321, lng: -74.9956 },
    { name: "Longue-Rive", region: "QC", lat: 48.55, lng: -69.25 },
    { name: "Argyle", region: "MB", lat: 49.3697, lng: -99.1506 },
    { name: "Davidson", region: "SK", lat: 51.2667, lng: -105.9667 },
    { name: "Plaster Rock", region: "NB", lat: 46.8833, lng: -67.3833 },
    { name: "Wilmot", region: "NB", lat: 46.3463, lng: -67.7099 },
    { name: "Valemount", region: "BC", lat: 52.8284, lng: -119.2659 },
    { name: "Saint-Leonard-de-Portneuf", region: "QC", lat: 46.8833, lng: -71.9167 },
    { name: "Alberta Beach", region: "AB", lat: 53.6767, lng: -114.35 },
    { name: "Saint-Narcisse-de-Rimouski", region: "QC", lat: 48.28, lng: -68.43 },
    { name: "Saint-Bonaventure", region: "QC", lat: 45.9667, lng: -72.6833 },
    { name: "Longlaketon No. 219", region: "SK", lat: 50.9386, lng: -104.6913 },
    { name: "Papineau-Cameron", region: "ON", lat: 46.3, lng: -78.7333 },
    { name: "Assiginack", region: "ON", lat: 45.7333, lng: -81.8 },
    { name: "Brebeuf", region: "QC", lat: 46.0667, lng: -74.6667 },
    { name: "Hudson Hope", region: "BC", lat: 56.0316, lng: -121.9057 },
    { name: "Prince", region: "ON", lat: 46.5333, lng: -84.5 },
    { name: "Baie-du-Febvre", region: "QC", lat: 46.13, lng: -72.72 },
    { name: "Durham-Sud", region: "QC", lat: 45.6667, lng: -72.3333 },
    { name: "Melbourne", region: "QC", lat: 45.58, lng: -72.17 },
    { name: "Nipawin No. 487", region: "SK", lat: 53.2881, lng: -104.0544 },
    { name: "Duck Lake No. 463", region: "SK", lat: 52.9596, lng: -106.2089 },
    { name: "Oyen", region: "AB", lat: 51.3522, lng: -110.4739 },
];
