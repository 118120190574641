import { getI18nExpByLang } from "_common/_utils/pageUtils";

export const URLS_ALTERNATE = {
    unit: (propertyAlias: string, unitAlias: string, lang: string) =>
        `/${lang}/${getI18nExpByLang(lang, "routes.properties")}/${propertyAlias}/${getI18nExpByLang(
            lang,
            "routes.units",
        )}/${unitAlias}`,

    property: (propertyAlias: string, lang: string) =>
        `/${lang}/${getI18nExpByLang(lang, "routes.properties")}/${propertyAlias}`,
};
