import React from "react";
import { VirtualAssistantForm } from "virtualAssistant/VirtualAssistantForm";
import { PageTitle } from "_common/ui/pages/PageTitle";
import styles from "./_css/virtualAssistantPage.module.css";
import sharedConfig from "_configs/sharedConfig";
import { PageNotFound } from "pages/PageNotFound";
import { userStore } from "users/_stores/userStore";
import { useTranslation } from "react-i18next";
import { Meta } from "_common/_utils/Meta";

export function VirtualAssistantPage() {
    const { t } = useTranslation();

    if (!sharedConfig.assistantVirtual.enable && !userStore.isAdmin) return <PageNotFound />;
    return (
        <div className={styles.container}>
            <Meta title={t("virtualAssistant.meta.title")} description={t("virtualAssistant.meta.description")} />
            <PageTitle style={{ display: "none" }}>Virtual Assistant</PageTitle>
            <VirtualAssistantForm />
        </div>
    );
}
