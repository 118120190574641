import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { PROPERTY_PURPOSE, PROPERTY_TYPE, PROPERTY_TYPE_BY_PURPOSE } from "properties/_models/PropertyMdl";
import { getSearchResultPageUrlFromGeoZonePurposeAndType } from "_common/_utils/geoZoneUtils";
import { URLS } from "_configs/URLS";
import { POPULAR_CITIES_MENU } from "_configs/popularCitiesList";
import i18next from "i18next";
import { TLang } from "_configs/sharedConfig";
import { userStore } from "users/_stores/userStore";
import { USER_ROLES } from "users/_models/UserMdl";
import { DASHBOARD_URLS } from "users/dashboard/_configs/DASHBOARD_URLS";

export type TMenuColumn = {
    title?: string;
    items: {
        label: string;
        to: string;
    }[];
};

const MenuContext = React.createContext(
    {} as {
        currentMenu: string | undefined;
        setCurrentMenu: (v?: string) => void;
        residentialMenu: TMenuColumn[];
        commercialMenu: TMenuColumn[];
        citiesMenu: TMenuColumn[];
        citiesMobileMenu: TMenuColumn[];
        virtualAssistantMenu: TMenuColumn[];
        userMenu: TMenuColumn[];
        userMobileMenu: TMenuColumn[];
    },
);

export function useMenuContext() {
    return useContext(MenuContext);
}

export function MenuProvider(props: React.PropsWithChildren<any>) {
    const [currentMenu, setCurrentMenu] = useState<string>();
    const { t } = useTranslation();

    const residentialMenu: TMenuColumn[] = [
        {
            title: t("menu.buy"),
            items: [
                ...PROPERTY_TYPE_BY_PURPOSE[PROPERTY_PURPOSE.BUY]
                    .filter((type) => type !== PROPERTY_TYPE.commercial)
                    .map((type) => ({
                        label: t("menu.residential." + PROPERTY_PURPOSE.BUY.toLowerCase() + "." + type),
                        to: getSearchResultPageUrlFromGeoZonePurposeAndType(
                            undefined,
                            PROPERTY_PURPOSE.BUY,
                            t(`property.typesUrl.${type}`),
                        ),
                    })),
                {
                    label: t(`menu.seeAllBuy`),
                    to: URLS.buy(),
                },
            ],
        },
        {
            title: t("menu.rent"),
            items: [
                ...PROPERTY_TYPE_BY_PURPOSE[PROPERTY_PURPOSE.RENT]
                    .filter((type) => type !== PROPERTY_TYPE.commercial)
                    .map((type) => ({
                        label: t("menu.residential." + PROPERTY_PURPOSE.RENT.toLowerCase() + "." + type),
                        to: getSearchResultPageUrlFromGeoZonePurposeAndType(
                            undefined,
                            PROPERTY_PURPOSE.RENT,
                            t(`property.typesUrl.${type}`),
                        ),
                    })),
                {
                    label: t(`menu.seeAllRent`),
                    to: URLS.rent(),
                },
            ],
        },
    ];

    const commercialMenu: TMenuColumn[] = [
        {
            title: t("menu.buy"),
            items: [
                {
                    label: t("menu.commercial." + PROPERTY_PURPOSE.BUY.toLowerCase() + "." + PROPERTY_TYPE.commercial),
                    to: getSearchResultPageUrlFromGeoZonePurposeAndType(
                        undefined,
                        PROPERTY_PURPOSE.BUY,
                        t(`property.typesUrl.commercial`),
                    ),
                },
            ],
        },
        {
            title: t("menu.rent"),
            items: [
                {
                    label: t("menu.commercial." + PROPERTY_PURPOSE.RENT.toLowerCase() + "." + PROPERTY_TYPE.commercial),
                    to: getSearchResultPageUrlFromGeoZonePurposeAndType(
                        undefined,
                        PROPERTY_PURPOSE.RENT,
                        t(`property.typesUrl.commercial`),
                    ),
                },
            ],
        },
    ];

    const citiesMobileMenu: TMenuColumn[] = [
        {
            title: t("menu.buy"),
            items: POPULAR_CITIES_MENU.map((city) => ({
                label: city.name,
                to: city.urlSale(i18next.language as TLang),
            })),
        },
        {
            title: t("menu.rent"),
            items: POPULAR_CITIES_MENU.map((city) => ({
                label: city.name,
                to: city.urlRent(i18next.language as TLang),
            })),
        },
    ];

    const citiesMenu: TMenuColumn[] = [
        {
            title: t("menu.buy"),
            items: POPULAR_CITIES_MENU.slice(0, POPULAR_CITIES_MENU.length / 2).map((city) => ({
                label: city.name,
                to: city.urlSale(i18next.language as TLang),
            })),
        },
        {
            items: POPULAR_CITIES_MENU.slice(POPULAR_CITIES_MENU.length / 2).map((city) => ({
                label: city.name,
                to: city.urlSale(i18next.language as TLang),
            })),
        },
        {
            title: t("menu.rent"),
            items: POPULAR_CITIES_MENU.slice(0, POPULAR_CITIES_MENU.length / 2).map((city) => ({
                label: city.name,
                to: city.urlRent(i18next.language as TLang),
            })),
        },
        {
            items: POPULAR_CITIES_MENU.slice(POPULAR_CITIES_MENU.length / 2).map((city) => ({
                label: city.name,
                to: city.urlRent(i18next.language as TLang),
            })),
        },
    ];

    const userMenu: TMenuColumn[] = [
        {
            title: t("menu.profile"),
            items: [
                {
                    label: t("menu.profile"),
                    to: URLS.user.profile(),
                },
                // {
                //     label: t("menu.orders"),
                //     to: URLS.user.orders(),
                // },
                {
                    label: t("menu.favorites"),
                    to: URLS.user.favorites(),
                },
                {
                    label: t("menu.savedSearch"),
                    to: URLS.user.savedSearch(),
                },
            ],
        },
    ];

    if (userStore.hasRole(USER_ROLES.DEVELOPER)) {
        userMenu[0].items.push({
            label: t("menu.dashboard"),
            to: DASHBOARD_URLS.home(),
        });
    }

    const userMobileMenu = userMenu.map((column) => ({
        title: undefined,
        items: column.items.map((item) => ({
            label: item.label,
            to: item.to,
        })),
    }));

    return (
        <MenuContext.Provider
            value={{
                currentMenu,
                setCurrentMenu,
                residentialMenu,
                commercialMenu,
                citiesMenu,
                citiesMobileMenu,
                userMenu,
                userMobileMenu,
            }}
        >
            {props.children}
        </MenuContext.Provider>
    );
}
