import React from "react";
import { ButtonV2 } from "_common/ui/mui/buttons/ButtonV2";
import styles from "./_css/propertiesNoResultsButton.module.css";
import { useTranslation } from "react-i18next";

export function PropertiesNoResultsEditSearch({ onClick }: { onClick: () => void }) {
    const { t } = useTranslation();
    return (
        <ButtonV2 onClick={onClick} size={"medium"} className={styles.btn} variant={"outlined"} color={"primary"}>
            {t("propertiesPage.noResults.tryToEditCta")}
        </ButtonV2>
    );
}
