import React from "react";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import clsx from "clsx";
import styles from "./_css/price.module.css";
import { useTranslation } from "react-i18next";
import { formatPrice } from "_common/_utils/currencyUtils";

type Props = {
    price: number;
    purpose: PROPERTY_PURPOSE;
    priceSmall?: boolean;
    fromSmall?: boolean;
    className?: string;
    withTaxes?: boolean;
    capitalize?: boolean;
    color?: "blue" | "white";
};

export function Price(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={clsx(props.className)}>
            <div
                className={clsx(styles.from, {
                    [styles.fromSmall]: props.fromSmall,
                    [styles.textBlue]: props.color === "blue",
                    [styles.textWhite]: props.color === "white",
                    ["text_capitalize"]: props.capitalize,
                })}
            >
                {t("propertyPage.from")}
            </div>
            <div
                className={clsx(styles.price, {
                    [styles.priceSmall]: props.priceSmall,
                    [styles.textBlue]: props.color === "blue",
                    [styles.textWhite]: props.color === "white",
                })}
            >
                {formatPrice(props.price, false)}
                {props.purpose === PROPERTY_PURPOSE.BUY && (
                    <span
                        className={clsx(styles.text, {
                            [styles.priceSmall]: props.priceSmall,
                            [styles.textBlue]: props.color === "blue",
                            [styles.textWhite]: props.color === "white",
                        })}
                    >
                        {props.withTaxes ? t("property.includedTaxes") : t("property.taxes")}
                    </span>
                )}
                {props.purpose === PROPERTY_PURPOSE.RENT && t("propertiesPage.card.perMonth")}
            </div>
        </div>
    );
}
