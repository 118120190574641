import React, { PropsWithChildren, useRef, useState } from "react";
import styles from "./_css/propertyDescriptionItem.module.css";
import { Collapse } from "@material-ui/core";
import clsx from "clsx";
import { KeyboardArrowDown } from "@material-ui/icons";

type Props = PropsWithChildren<{
    title: string;
    heightOfCollapsable: number;
    isLastItem?: boolean;
}>;

export function PropertyDescriptionItem({ title, children, isLastItem, heightOfCollapsable }: Props) {
    const [isCollapse, setIsCollapse] = useState(true);
    const ref = useRef<HTMLDivElement>(null);

    return (
        <div className={styles.container}>
            <div
                className={"flex_row_center justifyContent_spaceBetween cursor_pointer"}
                onClick={() => setIsCollapse(!isCollapse)}
            >
                <h2 className={styles.titleDescription}>{title}</h2>
                <div
                    className={clsx(styles.arrow, "flex_center_center", {
                        [styles.open]: !isCollapse,
                    })}
                    onClick={() => setIsCollapse(!isCollapse)}
                >
                    <KeyboardArrowDown fontSize={"large"} color={"primary"} />
                </div>
            </div>
            <Collapse in={!isCollapse} collapsedHeight={heightOfCollapsable} className={styles.collapse}>
                <div ref={ref} className={"p_8"}>
                    {children}
                </div>
            </Collapse>
            {!isLastItem && <hr className={styles.line} />}
        </div>
    );
}
