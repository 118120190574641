import React from "react";
import styles from "./_css/virtualAssistantQuestionLabel.module.css";

type Props = {
    label: string;
};

export function VirtualAssistantQuestionLabel({ label }: Props) {
    return <div className={styles.container}>{label}</div>;
}
