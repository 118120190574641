import React from "react";
import { IFavoritesSearchMdl } from "users/_models/UserMdl";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { SavedSearchesCard } from "users/profile/SavedSearchesCard";

type Props = {
    favorites: IFavoritesSearchMdl[];
    isNotDeletable?: boolean;
};

export const SavedSearchesList = observer((props: Props) => {
    const { t } = useTranslation();
    return (
        <div>
            {props.favorites.length > 0 && (
                <>
                    <h2>{t("menu.savedSearch")}</h2>
                    <div className={"flex_columnReverse"}>
                        {props.favorites.map((favorite, key) => {
                            return (
                                <SavedSearchesCard
                                    favorite={favorite}
                                    key={key}
                                    isNotDeletable={props.isNotDeletable}
                                />
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
});
