import React, { useEffect } from "react";
import { useParams } from "react-router";
import { ordersStore } from "payments/_stores/ordersStore";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import styles from "./_css/paymentThanksPage.module.css";
import clsx from "clsx";
import { Container } from "@material-ui/core";

export function PaymentThanksPage() {
    const { sessionId } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        ordersStore.updateCustomerFromSessionId(sessionId);
        toast.success(t("promotePage.successfullyPaid"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <div className={clsx(styles.container, "flex_center_center")}>
                <div className={"flex_column_center"}>
                    <h1 className={clsx(styles.title, "mb_40")}>{t("order.thankYouTitle")}</h1>
                    <div className={clsx(styles.description, "mb_40")}>{t("order.thankYouDescription")}</div>
                    <Link to={URLS.home()}>{t("order.goBackHome")}</Link>
                </div>
            </div>
        </Container>
    );
}
