import React from "react";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import clsx from "clsx";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import styles from "../_css/propertiesViewList.module.css";
import { DEFAULT_PAGE_SIZE } from "_common/resources/BaseResourceStore";
import { PropertiesPaginator } from "properties/listing/PropertiesPaginator";
import { PropertiesNearList } from "properties/listing/PropertiesNearList";
import { MAP_OR_LIST_SELECTED, usePropertiesPageContext } from "properties/PropertiesPageContext";
import { AdvancedResearchSrPs } from "advancedResearches/AdvancedResearchSRPs";
import { Footer } from "main/footer/Footer";
import { PropertiesFaq } from "properties/PropertiesFaq";
import { useTranslation } from "react-i18next";
import { PropertiesBreadcrumb } from "properties/listing/PropertiesBreadcrumb";
import { Grid } from "@material-ui/core";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { PropertiesListLoader } from "properties/listing/PropertiesListLoader";
import { PropertiesViewListProperties } from "properties/listing/PropertiesViewListProperties";

export const PropertiesViewList = () => {
    const { t } = useTranslation();
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);
    const { mapOrListSelected } = usePropertiesPageContext();

    const paginationIsDisplay = !!listStore.count && listStore?.count >= DEFAULT_PAGE_SIZE;

    const displayNearProperties = !!(
        geoZonesStore.geoZone &&
        geoZonesStore.geoZone?._id &&
        (!listStore.count || listStore.count <= 6)
    );

    return (
        <>
            <div className={clsx("p_20 pt_0", styles.container)} id={"searchPage"}>
                <Grid alignItems="center" container spacing={4}>
                    <LoadableFromLoading
                        loading={listStore.currentLoadingState}
                        fallback={<PropertiesListLoader />}
                        renderer={() => {
                            return <PropertiesViewListProperties />;
                        }}
                    />
                </Grid>
                <div className={clsx("flex_column_center gap_15 pt_40 pb_10 mb_40", styles.paginator)}>
                    {paginationIsDisplay && <PropertiesPaginator className={styles.listStorePaginator} />}
                    <PropertiesBreadcrumb />
                </div>
                {displayNearProperties && <div className={styles.separator}>{t("propertiesPage.endOfResults")}</div>}
                {displayNearProperties && <PropertiesNearList />}
                <div className={clsx(styles.advancedResearches)}>
                    <AdvancedResearchSrPs />
                </div>
                {listStore.selectedPage === 1 && (
                    <PropertiesFaq geoZone={geoZonesStore.geoZone} purpose={propertiesStore.purpose} />
                )}
            </div>
            {mapOrListSelected === MAP_OR_LIST_SELECTED.LIST && <Footer />}
        </>
    );
};
