import React from "react";
import GoogleLogin from "react-google-login";
import { authStore } from "users/auth/_stores/authStore";
import { toast } from "react-toastify";
import { URLS } from "_configs/URLS";
import { getErrorMessage } from "_common/errors/errorUtils";
import { useHistory } from "react-router";
import { observer } from "mobx-react";
import { appConfig } from "_configs/appConfig";
import { useTranslation } from "react-i18next";

type Props = {
    backUrl?: string;
    onSuccess?: (success: boolean) => void;
};

export const GoogleAuth = observer((props: Props) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div className="flex_row_center">
            <GoogleLogin
                autoLoad={false}
                className="mb_20"
                clientId={appConfig.googleAuth.apiKey}
                onSuccess={(response) => {
                    const promise = authStore.googleAuth(response);
                    if (promise) {
                        promise
                            .then(
                                () => {
                                    if (props.onSuccess) props.onSuccess(true);
                                    history.push(props.backUrl ?? URLS.home());
                                    if (props.backUrl) history.go(0);
                                },
                                (e) => {
                                    toast.error(getErrorMessage(e));
                                },
                            )
                            .catch((e) => {
                                console.error(e);
                            });
                    } else toast.error(t("errors.global.unknownLight"));
                }}
                onFailure={(e) => toast.error(e)}
            />
        </div>
    );
});
