import { fetchUtils } from "_common/_utils/fetchUtils";
import { action, observable } from "mobx";
import sharedConfig from "_configs/sharedConfig";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { PAYMENT_TYPE } from "payments/_models/paymentStripeMdl";
import { Stripe } from "stripe";
import { TOrderListingMdl } from "admin/orders/_models/OrderMdl";
import { STRIPE_OPTIONS_PRICES_KEY } from "_configs/stripeProductConfig";
import i18next from "i18next";

class OrdersStore {
    apiUrl = sharedConfig.apiUrl + "/orders";

    @observable subscriptionPrices: Stripe.Price[] = [];

    @observable selectedPricesIds: string[] = [];
    @observable selectedPrice: Stripe.Price | undefined;
    @observable orders: TOrderListingMdl[] = [];
    @observable ordersState = new LoadingStateMdl<TOrderListingMdl[]>();

    updateCustomerFromSessionId(sessionId: string) {
        return fetchUtils.get<{ url: string }>(this.apiUrl + "/updateCustomerFromSession?id=" + sessionId);
    }

    reformatOrderToObjectStripe(order: TOrderListingMdl) {
        return order.lineItems.map((line) => ({ priceId: line.price.priceId, quantity: line.quantity }));
    }

    createCheckoutSession(order: TOrderListingMdl) {
        let typeOfPayment: "payment" | "setup" | "subscription" = "payment";
        let crmOption = false;
        if (order?.lineItems.filter((line) => line.price.type === PAYMENT_TYPE.RECURRING).length) {
            typeOfPayment = "subscription";
        }

        if (order.lineItems.filter((line) => line.id === sharedConfig.stripe.products.INTEGRATION_CRM)) {
            const env = sharedConfig.isProd ? "prod" : "test";
            order.lineItems = order.lineItems.filter(
                (line) => line.price.priceId !== STRIPE_OPTIONS_PRICES_KEY[env].INTEGRATION_CRM,
            );
            crmOption = true;
        }

        const priceIdAndQuantities = this.reformatOrderToObjectStripe(order);

        return fetchUtils.post<{ url: string }>(sharedConfig.apiUrl + "/stripes/createCheckoutSession", {
            priceIdAndQuantities,
            lang: i18next.language,
            typeOfPayment,
            metadata: {
                orderId: order._id,
            },
            crmOption,
        });
    }

    createOrder() {
        return fetchUtils.post<TOrderListingMdl>(this.apiUrl + "/createOrder");
    }

    removeOrder(orderId: string) {
        return fetchUtils.delete<TOrderListingMdl>(this.apiUrl + "/removeOrder/" + orderId).then(() => {
            this.orders.splice(
                this.orders.findIndex((order) => order._id === orderId),
                1,
            );
        });
    }

    fetchOrders() {
        if (!this.ordersState.isLoading) {
            this.ordersState.startLoading();
            const promise = fetchUtils.get<TOrderListingMdl[]>(sharedConfig.apiUrl + "/users/orders");
            promise
                .then(
                    action(({ data }) => {
                        this.orders = data;
                        this.ordersState.setSuccess(data);
                    }),
                )
                .catch(() => this.ordersState.setError);
        }
    }
}

export const ordersStore = new OrdersStore();
