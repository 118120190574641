import React, { useEffect } from "react";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import styles from "./_css/localLogic.module.css";
import i18next from "i18next";

type Props = {
    property: TPropertyListingMdl;
};

let MAP_WIDGET: { destroy: () => void } | undefined = undefined;

export function LocalLogic(props: Props) {
    const sortAddress = [
        props.property.address?.street,
        props.property.address?.extra,
        props.property.address?.neighbourhood,
        props.property.address?.city,
        props.property.address?.province,
    ];
    const completeAddress = sortAddress
        .filter((addressPart) => addressPart !== undefined && addressPart !== "")
        .join(", ");

    useEffect(() => {
        if (document.querySelector("#LOCLA")) {
            setTimeout(() => {
                MAP_WIDGET = (window as any).ll.create(
                    "local-content",
                    document.querySelector("#LOCLA"),
                    {
                        lat: props.property.location.coordinates[1],
                        lng: props.property.location.coordinates[0],
                        locale: i18next.language ?? "en",
                        marker: {
                            lat: props.property.location.coordinates[1],
                            lng: props.property.location.coordinates[0],
                            addressLabel: completeAddress,
                        },
                    },
                    { lazy: true },
                );
            }, 10);
        }

        return () => {
            if (MAP_WIDGET) MAP_WIDGET.destroy();
        };
    }, []);

    return <div id={"LOCLA"} className={styles.container}></div>;
}
