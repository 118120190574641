import React from "react";
import clsx from "clsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Swiper, SwiperSlide } from "swiper/react";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/swiper-bundle.min.css";
import { PlayArrow } from "@material-ui/icons";
import { VideoComponent } from "components/base/video/VideoComponent";
import { Tour3D } from "components/base/tour3D/Tour3D";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import styles from "./_css/propertyGalleryThumbnails.module.css";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { useUnitStore } from "units/UnitContext";

interface Props {
    indexGallery: number;
    setThumbsSwiper: (swipe: any) => void;
    resource: MAIN_RESOURCE;
}

export function PropertyOrUnitGalleryThumbnails({ indexGallery, setThumbsSwiper, resource }: Props) {
    const propertyStore = usePropertyStore();
    const unitStore = useUnitStore();
    const item = resource === MAIN_RESOURCE.PROPERTIES ? propertyStore.property : unitStore.unit;

    return (
        <Swiper
            id={"propertyGalleryThumbs"}
            onSwiper={(swipe) => setThumbsSwiper(swipe)}
            loop
            spaceBetween={10}
            slidesPerView={8}
            initialSlide={0}
            className={clsx("mt_10", styles.sliderThumbnail)}
        >
            {item.photos.map((image, index) => {
                return (
                    <SwiperSlide key={index + "thumbs"} className={styles.slideThumbnail}>
                        <LazyLoadImage
                            alt={`${item.localized.title}-${index}`}
                            title={`${item.localized.title} photo ${index}`}
                            effect={"blur"}
                            className={clsx(styles.thumbImg, {
                                [styles.notCurrentPhoto]: index + 1 !== indexGallery,
                            })}
                            src={image.url}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "https://via.placeholder.com/250?text=Vistoo.com";
                            }}
                        />
                    </SwiperSlide>
                );
            })}
            {item.video && (
                <SwiperSlide className={styles.slideThumbnail}>
                    {item.video.includes("youtu") ? (
                        <div
                            className={clsx("position_relative", styles.contentThumbnailVideo, {
                                [styles.notCurrentPhoto]: item.photos.length + 1 !== indexGallery,
                            })}
                        >
                            <img
                                className={styles.thumbnailVideo}
                                src={"https://img.youtube.com/vi/" + item.video.split("/")[3] + "/default.jpg"}
                                alt={"video img"}
                            />
                            <PlayArrow color={"primary"} className={styles.iconPlay} />
                        </div>
                    ) : (
                        <VideoComponent size={"100%"} url={item.video} disabled />
                    )}
                </SwiperSlide>
            )}
            {item.video2 && (
                <SwiperSlide className={styles.slideThumbnail}>
                    {item.video2.includes("youtu") ? (
                        <div
                            className={clsx("position_relative", styles.contentThumbnailVideo, {
                                [styles.notCurrentPhoto]: item.photos.length + 1 !== indexGallery,
                            })}
                        >
                            <img
                                className={styles.thumbnailVideo}
                                src={"https://img.youtube.com/vi/" + item.video2.split("/")[3] + "/default.jpg"}
                                alt={"video img"}
                            />
                            <PlayArrow color={"primary"} className={styles.iconPlay} />
                        </div>
                    ) : (
                        <VideoComponent size={"100%"} url={item.video2} disabled />
                    )}
                </SwiperSlide>
            )}
            {item.visit3D && (
                <SwiperSlide className={styles.slideThumbnail}>
                    <Tour3D embedSrc={item.visit3D} thumbnail />
                </SwiperSlide>
            )}
        </Swiper>
    );
}
