import React from "react";
import { Container } from "@material-ui/core";
import { KeyboardArrowLeft } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { TPropertyCardMdl, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import styles from "./_css/propertyPageHeader.module.css";
import clsx from "clsx";
import { IconComponent } from "components/base/icon/IconComponent";
import { useFavoriteToggle } from "users/profile/useFavoriteToggle";
import { useHistory } from "react-router";
import { PROPERTY_CTA_CONTAINER } from "properties/propertyPage/PropertyHeader";
import { UNIT_CTA_CONTAINER } from "units/UnitHeader";
import { useScrollPositionAlt } from "_common/_utils/hookUtils";

type Props = {
    type: MAIN_RESOURCE;
    item: TPropertyListingMdl | TPropertyCardMdl | TUnitListingMdl;
};

export function PropertyOrUnitPageHeaderTop({ type, item }: Props) {
    const { t } = useTranslation();
    const { isFavorite, toggleFavorite } = useFavoriteToggle(type, item);
    const history = useHistory();

    const scrollPosition = useScrollPositionAlt();
    return (
        <div className={clsx(styles.container, { [styles.display]: scrollPosition > 0 }, "p_10")}>
            <Container>
                <div className={"flex_row_center justifyContent_spaceBetween gap_10"}>
                    <div className={"flex_row_center cursor_pointer"} onClick={() => history.goBack()}>
                        <KeyboardArrowLeft />
                        <div className={styles.text}>{t("words.back")}</div>
                    </div>
                    <div className={"flex_row_center gap_10"}>
                        <UiButton
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => {
                                const heightToScroll =
                                    ((type === MAIN_RESOURCE.PROPERTIES
                                        ? PROPERTY_CTA_CONTAINER?.current?.offsetTop
                                        : UNIT_CTA_CONTAINER?.current?.offsetTop) ?? 0) - 80;
                                window.scrollTo({
                                    top: heightToScroll,
                                    behavior: "smooth",
                                });
                            }}
                        >
                            {t("propertyPage.headerTop.requestInfo")}
                        </UiButton>
                        <UiButton
                            className={clsx(styles.favoriteButton, "mr_10")}
                            variant={"outlined"}
                            onClick={(e) => {
                                e.preventDefault();
                                void toggleFavorite();
                            }}
                            aria-label="Icon favorite"
                            aria-labelledby="Icon favorite"
                        >
                            <IconComponent
                                aria-label="Icon favorite"
                                title="Icon favorite"
                                aria-labelledby="Icon favorite"
                                icon={isFavorite ? "favorite" : "favorite_border"}
                            />
                            <span className={"ml_5"}>{t("words.save")}</span>
                        </UiButton>
                    </div>
                </div>
            </Container>
        </div>
    );
}
