import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/swiper-bundle.min.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "./_css/propertyFloorPlans.module.css";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { isImage } from "_common/_utils/fileUtils";
import { GALLERY_TABS } from "properties/gallery/PropertyOrUnitGalleryPage";

export function PropertyFloorPlans() {
    const propertyStore = usePropertyStore();
    const { t } = useTranslation();
    if (propertyStore.property.floorPlans.length === 0) return null;
    return (
        <div id="propertyFloorPlans" className={clsx("mt_20")}>
            <h2 className={styles.title}>{t("propertyPage.floorPlan")}</h2>
            <div className={clsx("position_relative", styles.container)}>
                <Swiper navigation slidesPerView={1} loop>
                    {propertyStore.property.floorPlans.map((image, index) => {
                        return (
                            <SwiperSlide key={index} className={"flex_row justifyContent_center"}>
                                {isImage(image.url) ? (
                                    <LazyLoadImage
                                        onClick={() => propertyStore.openGallery(0, GALLERY_TABS.LEVEL_PLAN)}
                                        alt={`${propertyStore.property.localized.title}-level-plan-${index}`}
                                        title={`${propertyStore.property.localized.title} photo level plan ${index}`}
                                        effect={"blur"}
                                        className={styles.photo}
                                        src={image.url}
                                    />
                                ) : (
                                    <embed src={image.url} width="600" height="500" />
                                )}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
}
