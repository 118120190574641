import React from "react";
import { TBreadcrumbItem } from "breadcrumb/Breadcrumb";
import { Helmet } from "react-helmet-async";
import sharedConfig from "_configs/sharedConfig";

type jsonLdItem = {
    "@type": string;
    position: number;
    name: string;
    item?: string;
};
type Props = {
    currentItem: TBreadcrumbItem;
    items: TBreadcrumbItem[];
};

export function JsonLdBreadCrumb(props: Props) {
    const itemsOfJsonLd: jsonLdItem[] = props.items.map((item, idx) => {
        return {
            "@type": "ListItem",
            position: idx + 1,
            name: item.pageTitle ?? "",
            item: sharedConfig.appUrl + item.url,
        };
    });
    itemsOfJsonLd.push({
        "@type": "ListItem",
        position: itemsOfJsonLd.length + 1,
        name: props.currentItem.itemLabel ?? "",
    });
    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: itemsOfJsonLd,
    };
    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
        </Helmet>
    );
}
