export const WHEN_DATA = [
    {
        value: "now",
        label: "virtualAssistant.stepThreeForm.oneMonth",
    },
    {
        value: "2-6m",
        label: "virtualAssistant.stepThreeForm.twoSixMonths",
    },
    {
        value: "6m-1y",
        label: "virtualAssistant.stepThreeForm.sixMonthsOneYear",
    },
    {
        value: "1y+",
        label: "virtualAssistant.stepThreeForm.oneYearOrMore",
    },
];
