import React, { useState } from "react";
import styles from "./_css/cookieBanner.module.css";
import { LogoSimple } from "_common/icons/LogoSimple";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { URLS } from "_configs/URLS";
import { ButtonV2 } from "_common/ui/mui/buttons/ButtonV2";
import { observer } from "mobx-react";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { CookieModal } from "cookies/CookieModal";

type Props = {
    onAccept: () => void;
    onDecline: () => void;
};

export const CookieBanner = observer(({ onAccept, onDecline }: Props) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    if (analyticsUtils.hasSetCookiesPolicy || !__BROWSER__) return null;

    if (isOpen) {
        return (
            <CookieModal isOpen={isOpen} onClose={() => setIsOpen(false)} onAccept={onAccept} onDecline={onDecline} />
        );
    }

    return (
        <>
            <div className={clsx(styles.container, "flex_row_center justifyContent_spaceBetween gap_100")}>
                <div className={styles.logo}>
                    <LogoSimple width={100} />
                </div>
                <div className={clsx(styles.content, "flex_column")}>
                    <div className={styles.title}>{t("cookieBanner.title")}</div>
                    <div
                        className={styles.text}
                        dangerouslySetInnerHTML={{ __html: t("cookieBanner.content", { link: URLS.policy() }) }}
                    />
                </div>
                <div className={clsx(styles.actions, "flex_column_center gap_15")}>
                    <div className={clsx(styles.actions, "flex_column_center gap_40")}>
                        <div>
                            <ButtonV2 onClick={onAccept} variant={"contained"}>
                                {t("cookieBanner.accept")}
                            </ButtonV2>
                        </div>
                        <div>
                            <ButtonV2 onClick={() => setIsOpen(true)} variant={"outlined"}>
                                {t("cookieBanner.more")}
                            </ButtonV2>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});
