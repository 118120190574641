import { useEffect } from "react";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { useLocation } from "react-router";

export function useTrackPage() {
    const location = useLocation();
    const url = location.pathname + location.search + location.hash;
    useEffect(() => {
        analyticsUtils.trackPage(url);
        analyticsUtils.tracksPropertiesAndUnitsPage(location.pathname);
    }, [url]);

    return { url };
}
