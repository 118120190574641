import React, { lazy } from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import styles from "./_css/propertyHeader.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { PropertyAddress } from "properties/propertyPage/PropertyAddress";
import { Price } from "components/propertyOrUnit/Price";
import { PropertyUnitsList } from "properties/propertyPage/PropertyUnitsList";
import { PropertyDescription } from "properties/propertyPage/PropertyDescription";
import Suspenser from "_common/loaders/Suspenser";
import { observer } from "mobx-react";

const LazyPropertyHeaderCta = lazy(() => import("properties/propertyPage/PropertyOrUnitCta"));

export const PROPERTY_CTA_CONTAINER: { current: HTMLDivElement | null } = { current: null };

export const PropertyHeader = observer(() => {
    const propertyStore = usePropertyStore();
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.container, "flex")}>
            <div className={styles.details}>
                <h1 className={styles.title}>{propertyStore.property.localized.title}</h1>
                <PropertyAddress property={propertyStore.property} />
                <div className={clsx("mt_20")}>
                    {propertyStore.property.priceIsNotDisplayed ? (
                        <div className={styles.price}>{t("propertiesPage.card.priceOnRequest")}</div>
                    ) : (
                        <Price
                            purpose={propertyStore.property.purpose}
                            price={propertyStore.property.price.min}
                            withTaxes={propertyStore.property.taxIsIncluded}
                        />
                    )}
                </div>
                {!propertyStore.property.planPoint && <PropertyUnitsList />}
                <PropertyDescription />
            </div>
            <div className={clsx(styles.CTA, "flex_column")} ref={(ref) => (PROPERTY_CTA_CONTAINER.current = ref)}>
                {propertyStore.property?.developer && (
                    <div className={clsx("flex_column")}>
                        <Suspenser noText noSpinner fallback={<div style={{ width: 327, height: 510 }} />}>
                            <LazyPropertyHeaderCta />
                        </Suspenser>
                    </div>
                )}
            </div>
        </div>
    );
});
