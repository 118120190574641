import React from "react";
import styles from "./_css/checkBox.module.css";
import clsx from "clsx";

type Props = { label: string; onCheck: (value: string) => void; checked: boolean; value: string };

export function CheckBox({ label, onCheck, checked, value }: Props) {
    return (
        <div
            className={clsx(styles.container, "flex_center_center", {
                [styles.activeContainer]: checked,
            })}
            onClick={() => onCheck(value)}
        >
            <>
                {checked ? (
                    <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect
                            x="1"
                            y="1"
                            width="25.3491"
                            height="24"
                            rx="3"
                            fill="#004378"
                            stroke="#004378"
                            strokeWidth="2"
                        />
                        <path d="M5 14L10.5 19.5L23 7" stroke="white" strokeWidth="2" />
                    </svg>
                ) : (
                    <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="25.3491" height="24" rx="3" stroke="#004378" strokeWidth="2" />
                    </svg>
                )}
            </>
            <div className={styles.label}>{label}</div>
        </div>
    );
}
