import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";

export function valueToLowerCase(value: any) {
    return typeof value === "string" ? value.toLowerCase() : value;
}

export function getUrlWithoutPropertyType(url: string) {
    const tmpUrl = url.split("/");
    const propertyType = tmpUrl.find(
        (str: string) =>
            str === PROPERTY_TYPE.condo ||
            str === PROPERTY_TYPE.house ||
            str === PROPERTY_TYPE.multiplex ||
            str === PROPERTY_TYPE.commercial,
    );

    if (propertyType) {
        const index = tmpUrl.findIndex((str: string) => str === propertyType);
        tmpUrl.splice(index, 1);
    }
    return tmpUrl.join("/");
}
