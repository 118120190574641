import React from "react";
import logo from "../../assets/images/vistoo-logo.png";
import whiteLogo from "../../assets/images/vistoo-logo-white.png";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import clsx from "clsx";

type Props = {
    width?: number | string;
    height?: number | string;
    containerClassName?: string;
    className?: string;
    white?: boolean;
    isMobile?: boolean;
};

export function Logo(props: Props) {
    const style = {
        width: props.width ?? undefined,
        height: props.height ?? undefined,
    };

    return (
        <Link to={URLS.home()} className={clsx("flex_center_center", props.containerClassName)}>
            {props.white ? (
                <img alt="Vistoo logo" style={style} className={props.className} src={whiteLogo} />
            ) : (
                <img alt="Vistoo logo" style={style} className={props.className} src={logo} />
            )}
        </Link>
    );
}
