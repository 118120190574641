import { useEffect, useState } from "react";

export const isIos = __BROWSER__
    ? /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    : undefined;

export function measureHeight(): number | null {
    if (!__BROWSER__) return null;
    return window.innerHeight;
}

function useWasRenderedOnClientAtLeastOnce() {
    const [wasRenderedOnClientAtLeastOnce, setWasRenderedOnClientAtLeastOnce] = useState(false);

    useEffect(() => {
        if (__BROWSER__) {
            setWasRenderedOnClientAtLeastOnce(true);
        }
    }, []);
    return wasRenderedOnClientAtLeastOnce;
}

export function use100vh(): number | null {
    const [height, setHeight] = useState<number | null>(measureHeight);

    const wasRenderedOnClientAtLeastOnce = useWasRenderedOnClientAtLeastOnce();

    useEffect(() => {
        if (!wasRenderedOnClientAtLeastOnce) return;

        function setMeasuredHeight() {
            const measuredHeight = measureHeight();
            setHeight(measuredHeight);
        }

        window.addEventListener("resize", setMeasuredHeight);
        return () => window.removeEventListener("resize", setMeasuredHeight);
    }, [wasRenderedOnClientAtLeastOnce]);
    return wasRenderedOnClientAtLeastOnce ? height : null;
}
