import React, { useContext } from "react";
import { PropertyStore } from "properties/_stores/PropertyStore";

const PropertyCtxt = React.createContext<PropertyStore>({} as PropertyStore);

type Props = {
    propertyStore: PropertyStore;
};

export function usePropertyStore() {
    return useContext(PropertyCtxt);
}

export function PropertyContext(props: React.PropsWithChildren<Props>) {
    return <PropertyCtxt.Provider value={props.propertyStore}>{props.children}</PropertyCtxt.Provider>;
}
