import React, { PropsWithChildren } from "react";
import clsx from "clsx";
import styles from "../main/header/_css/cartMenu.module.css";
import { observer } from "mobx-react";
import { cartStore } from "Cart/_stores/cartStore";

type Props = {};

export const CartMenu = observer((props: PropsWithChildren<Props>) => {
    return (
        <div
            onMouseEnter={() => cartStore.setIsCartOpenDebounce(true)}
            onMouseLeave={() => cartStore.setIsCartOpenDebounce(false)}
            className={clsx(styles.cartMenuContainer, {
                [styles.carteMenuContainer_animate]: cartStore.isCartOpen,
            })}
        >
            {props.children}
        </div>
    );
});
