import React from "react";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { authStore } from "users/auth/_stores/authStore";
import { tokenStore } from "users/_stores/tokenStore";
import { InputTextFront } from "payments/_components/InputTextFront";
import styles from "./_css/signInFormFront.module.css";
import clsx from "clsx";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";

type TFormData = {
    email: string;
    password: string;
};

export function SignInFormFront() {
    const { t } = useTranslation();
    const { handleSubmit, register, errors } = useForm<TFormData>({
        mode: "onBlur",
    });

    const { loading, setPromise } = useLoadingFromPromise();

    const onSubmit = (values: TFormData) => {
        const promise = authStore.signIn(values.email, values.password);
        setPromise(promise);
        promise.then(async () => {
            await tokenStore.refreshToken();
        });
    };
    const isLoading = loading?.status === "LOADING";

    return (
        <div className={styles.container}>
            <ErrorBlock error={loading?.error} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={"mt_20"}>
                    <InputTextFront
                        placeholder={t("words.email")}
                        name="email"
                        ref={register({
                            required: t<string>("errors.forms.required"),
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                                message: t("errors.forms.emailInvalid"),
                            },
                        })}
                        error={errors.email}
                        disabled={isLoading}
                    />
                </div>
                <div className={"mt_20"}>
                    <InputTextFront
                        placeholder={t("words.password")}
                        name="password"
                        password
                        ref={register({
                            required: t<string>("errors.forms.required"),
                        })}
                        error={errors.password}
                        disabled={isLoading}
                    />
                </div>
                <Link
                    to={URLS.auth.askResetPassword()}
                    className={clsx("flex justifyContent_center m_20", styles.resetPassword)}
                >
                    {t("paymentPage.resetPassword")}
                </Link>
                <div className="flex_center_center mb_15">
                    <UiButton
                        className={styles.button}
                        type="submit"
                        disabled={isLoading}
                        variant="contained"
                        color="primary"
                    >
                        {t(isLoading ? "loaders.message" : "auth.verifyAccount.signIn")}
                    </UiButton>
                </div>
            </form>
        </div>
    );
}
