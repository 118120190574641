import React from "react";
import clsx from "clsx";
import styles from "./_css/trustedUsPromotePage.module.css";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import cogir from "../assets/images/promotePage/trust/cogir.webp";
import mondev from "../assets/images/promotePage/trust/mondev.webp";
import brivia from "../assets/images/promotePage/trust/brivia.webp";
import broccoloni from "../assets/images/promotePage/trust/broccoloni.webp";
import lokalia from "../assets/images/promotePage/trust/lokalia.webp";
import samcon from "../assets/images/promotePage/trust/samcon.webp";
import six from "../assets/images/promotePage/trust/six.webp";
import robin from "../assets/images/promotePage/trust/robin.webp";
import caApp from "../assets/images/promotePage/trust/canadianAppartment.png";

const LOGO: { url: string }[] = [
    { url: cogir },
    { url: mondev },
    { url: brivia },
    { url: broccoloni },
    { url: lokalia },
    { url: samcon },
    { url: six },
    { url: robin },
    { url: caApp },
    // { url: mcl },
];

export function PromoteTrustedUs() {
    const { t } = useTranslation();

    return (
        <div className={clsx("position_relative mt_60", styles.container)}>
            <div className={clsx(styles.content)}>
                <div className={styles.title}>{t("promotePage.trusted").toLocaleUpperCase()}</div>
                <Swiper
                    loop
                    speed={1200}
                    autoplay={{ delay: 2000, disableOnInteraction: true }}
                    slidesPerView={3}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        750: {
                            slidesPerView: 2,
                        },
                        1100: {
                            slidesPerView: 3,
                        },
                    }}
                    className={styles.swiper}
                >
                    {LOGO.map((logo, index) => {
                        return (
                            <SwiperSlide key={index} className={"flex"}>
                                <img src={logo.url} className={styles.logo} alt={"logo"} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
            <div className={clsx("position_absolute", styles.background)} />
        </div>
    );
}
