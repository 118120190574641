import React, { useEffect, useRef, useState } from "react";
import { pagesStore } from "pages/_stores/pagesStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { BlogCard } from "home/sections/BlogCard";
import backgroundBlog from "../../assets/images/background_blog_homepage.webp";
import clsx from "clsx";
import styles from "./_css/blog.module.css";
import { useTranslation } from "react-i18next";
import { useScrollPosition, useWindowSize } from "_common/_utils/hookUtils";
import { PAGE_REF } from "pages/PageRegular";
import { LazyLoadImage } from "react-lazy-load-image-component";

export function Blog() {
    const { height } = useWindowSize();
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const scrollPosition = useScrollPosition(PAGE_REF);
    const ref = useRef(null);
    useEffect(() => {
        if (scrollPosition) {
            setIsVisible(((ref.current as unknown) as HTMLElement)?.offsetTop - height <= scrollPosition);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollPosition]);
    return (
        <LoadableFromLoading
            loading={pagesStore.getLatestArticles(3)}
            renderer={(_status, _error, latestArticles) => {
                if (!latestArticles) return null;
                return (
                    <div className={styles.container}>
                        <div className={styles.titleContent}>
                            <h2 className={styles.title}> {t("homePage.blog.title").toLocaleUpperCase()}</h2>
                        </div>
                        <div className={clsx(styles.content, "flex_row_center position_relative")} ref={ref}>
                            <div className={styles.contentImage}>
                                <LazyLoadImage
                                    className={clsx(styles.image, {
                                        [styles.animationLeft]: isVisible,
                                    })}
                                    src={backgroundBlog}
                                    alt={"image background"}
                                />
                            </div>
                            <div
                                className={clsx(styles.articles, "position_absolute", {
                                    [styles.animationTop]: isVisible,
                                })}
                            >
                                {latestArticles.map((article) => (
                                    <BlogCard
                                        key={article._id}
                                        date={article.localized.publishedAt}
                                        title={article.localized.title}
                                        text={article.localized.description}
                                        image={article.localized.image}
                                        url={article.localized.url}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                );
            }}
        />
    );
}
