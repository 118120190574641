import React, { PropsWithChildren } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import styles from "./_css/filePickerFormProjectPage.module.css";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { PropertyFileStore } from "properties/_stores/PropertyFilesStore";
import { TPropertyMdl } from "properties/_models/PropertyMdl";
import { observer } from "mobx-react";
import { storeFile } from "_common/_utils/fileUtils";
import _ from "lodash";

export type FilePickerProps = PropsWithChildren<{
    value?: string[];
    onChange?: (value: string[]) => void;
    onlyImage?: boolean;
    maxNbFiles?: number;
    options?: DropzoneOptions;
    placeholder?: string;
    store: PropertyFileStore;
    lastSavedItem: TPropertyMdl;
}>;

export const FilePicker = observer((props: FilePickerProps) => {
    const { getRootProps, getInputProps } = useDropzone({
        accept: props.onlyImage ? "image/*" : undefined,
        ...props.options,
        onDropAccepted: (files) => {
            let newFiles = [...(props.value ?? []), ...files.map((file) => storeFile(file))];
            if (props.maxNbFiles) newFiles = _.takeRight(newFiles, props.maxNbFiles);
            props.onChange ? props.onChange(newFiles) : "";
        },
    });

    return (
        <UiButton variant={"contained"} color={"primary"} className={styles.button} onClick={getRootProps().onClick}>
            {props.children}
            +
            <input {...getInputProps()} />
        </UiButton>
    );
});
