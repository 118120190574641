import React from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { useTranslation } from "react-i18next";
import styles from "../_css/propertyOptions.module.css";

export function PropertyOptions() {
    const { t } = useTranslation();
    const { property } = usePropertyStore();
    if (!property.features && !property.amenities) return null;
    const features = property.features ? Object.entries(property.features) : [];
    const amenities = property.amenities ? Object.entries(property.amenities) : [];
    const options = features.concat(amenities);
    return (
        <div className="flex-1 mt_15">
            <div className={styles.columns}>
                <div className={styles.column}>
                    {options
                        .sort((a, b) => (t(`property.features.${a?.[0]}`) > t(`property.features.${b?.[0]}`) ? 1 : -1))
                        .map(([optionKey, display]) => {
                            if (!optionKey || !display) return null;
                            return <li key={optionKey}>{t(`property.features.${optionKey}`)}</li>;
                        })}
                </div>
            </div>
            <div className={styles.columnsMobile}>
                <div className={styles.column}>
                    {options
                        .sort((a, b) => (t(`property.features.${a?.[0]}`) > t(`property.features.${b?.[0]}`) ? 1 : -1))
                        .map(([optionKey, display]) => {
                            if (!optionKey || !display) return null;
                            return <li key={optionKey}>{t(`property.features.${optionKey}`)}</li>;
                        })}
                </div>
            </div>
        </div>
    );
}
