import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/checkboxAgree.module.css";

type Props = {
    checked: boolean;
    onChange: (bool: boolean) => void;
};

export function CheckboxAgree(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={clsx("flex_row_center cursor_pointer", styles.content)}>
            <input
                className={styles.checkbox}
                name={"agree"}
                type={"checkbox"}
                id={"agree"}
                checked={props.checked}
                onChange={() => props.onChange(!props.checked)}
            />
            <label htmlFor={"agree"} className={"cursor_pointer"}>
                {t("propertyPage.cta.agree")}
            </label>
        </div>
    );
}
