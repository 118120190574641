import React from "react";
import { PropertiesPage } from "properties/listing/PropertiesPage";
import { PropertiesPageContext } from "properties/listing/PropertiesPageContext";
import { propertiesRentingStore } from "properties/_stores/propertiesRentingStore";

export function RentPage() {
    return (
        <PropertiesPageContext store={propertiesRentingStore}>
            <PropertiesPage />
        </PropertiesPageContext>
    );
}
