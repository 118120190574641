import { fetchUtils } from "shared/_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { tokenStore } from "users/_stores/tokenStore";
import { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import { SIGN_UP_FROM, SIGN_UP_TYPE, USER_ROLES } from "users/_models/UserMdl";
import i18next from "i18next";

class AuthStore {
    signIn(email: string, password: string) {
        return fetchUtils
            .post<{ token: string }>(sharedConfig.apiUrl + "/auth/signIn", {
                email,
                password,
            })
            .then(({ data }) => tokenStore.setToken(data.token));
    }

    signUp(
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        notification?: boolean,
        heardAboutUs?: string,
        roles: USER_ROLES[],
        companyName: string,
    ) {
        return fetchUtils
            .post<{ token: string }>(sharedConfig.apiUrl + "/auth/signUp", {
                email,
                password,
                firstName,
                lastName,
                notification,
                heardAboutUs,
                companyName,
                roles,
                lang: i18next.language,
            })
            .then(({ data }) => tokenStore.setToken(data.token));
    }

    resetPasswordAsAdmin(password: string, userId: string) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/auth/resetPasswordAsAdmin", {
            password,
            userId,
        });
    }

    askResetPassword(email: string) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/auth/askResetPassword", { email });
    }

    resetPassword(token: string, password: string) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/auth/resetPassword", { token, password });
    }

    signOut() {
        tokenStore.setToken(undefined);
        window.location.reload();
    }

    verify(search: string) {
        return fetchUtils.get<{ token: string }>(sharedConfig.apiUrl + "/auth/verify" + search);
    }

    googleAuth(user: Partial<GoogleLoginResponse> | Partial<GoogleLoginResponseOffline>) {
        if ("profileObj" in user) {
            const userData = {
                email: user.profileObj?.email,
                firstName: user.profileObj?.givenName,
                lastName: user.profileObj?.familyName,
                lang: i18next.language,
                signUpType: SIGN_UP_TYPE.GOOGLE,
            };
            return fetchUtils
                .post<{ token: string }>(sharedConfig.apiUrl + "/auth/googleAuth", userData)
                .then(({ data }) => tokenStore.setToken(data.token));
        }
        return undefined;
    }

    facebookAuth(user: ReactFacebookLoginInfo & { first_name: string; last_name: string }) {
        const userData = {
            email: user.email,
            firstName: user.first_name,
            lastName: user.last_name,
            lang: i18next.language,
            signUpType: SIGN_UP_TYPE.FACEBOOK,
        };
        return fetchUtils
            .post<{ token: string }>(sharedConfig.apiUrl + "/auth/facebookAuth", userData)
            .then(({ data }) => tokenStore.setToken(data.token));
    }

    signUpCTAForm(
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        notification: boolean,
        signUpType: SIGN_UP_TYPE,
        signUpFrom: SIGN_UP_FROM,
        propertyId?: string,
    ) {
        return fetchUtils.post(sharedConfig.apiUrl + "/auth/createUserForm", {
            email,
            password,
            firstName,
            lastName,
            notification,
            lang: i18next.language,
            signUpType,
            signUpFrom,
            propertyId,
        });
    }
}

const authStore = new AuthStore();
export { authStore };
