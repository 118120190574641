import React from "react";
import { TMenuColumn } from "main/header/_menu/MenuContext";
import clsx from "clsx";
import styles from "./_css/subMenuMobile.module.css";
import { refreshPage } from "_common/_utils/pageUtils";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { panelStore } from "main/header/panel/_stores/panelStore";

function SubMenuMobileColumn({ column }: { column: TMenuColumn }) {
    return (
        <div>
            {column.items.map((item, index) => {
                return (
                    <div className={styles.link} key={index}>
                        <ExternalOrNavLink
                            isBuyingOrIsRentingUrl={true}
                            url={item.to}
                            onClick={() => {
                                panelStore.close();
                                refreshPage(item.to);
                            }}
                        >
                            {item.label}
                        </ExternalOrNavLink>
                    </div>
                );
            })}
        </div>
    );
}

export function SubMenuMobile({ columns, hidden }: { columns: TMenuColumn[]; hidden?: boolean }) {
    return (
        <div
            className={clsx(styles.container, {
                ["hidden"]: hidden,
            })}
        >
            {columns.map((column, index) => {
                return (
                    <div key={index}>
                        <div className={styles.title}>{column.title}</div>
                        <SubMenuMobileColumn column={column} />
                    </div>
                );
            })}
        </div>
    );
}
