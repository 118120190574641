import React from "react";
import { AdvancedResearches } from "advancedResearches/AdvancedResearches";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { PROPERTY_PURPOSE, PROPERTY_TYPE_BY_PURPOSE } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { getSearchResultPageUrlFromGeoZonePurposeAndType } from "_common/_utils/geoZoneUtils";
import { isBuyingPage } from "_common/_utils/pageUtils";
import { useLocation } from "react-router-dom";

export const AdvancedResearchesSRPsGeozones = observer(() => {
    const { t } = useTranslation();
    const location = useLocation();

    if (!geoZonesStore.geoZone) return null;

    void geoZonesStore.fetchChildrenGeozone(geoZonesStore.geoZone._id);
    const childrenGeoZone = toJS(geoZonesStore.children);
    const purpose = isBuyingPage(location.pathname) ? PROPERTY_PURPOSE.BUY : PROPERTY_PURPOSE.RENT;

    const links = [];

    for (const type of Object.values(PROPERTY_TYPE_BY_PURPOSE[purpose])) {
        const translatedType = t(`property.typesUrl.${type}`);

        links.push({
            label: `${t("advancedResearches." + type.toLowerCase())} ${t(
                "advancedResearches." + purpose?.toLowerCase(),
            ).toLowerCase()} ${geoZonesStore.geoZone.name.split(",")[0]}`,
            to: getSearchResultPageUrlFromGeoZonePurposeAndType(geoZonesStore.geoZone, purpose, translatedType),
        });
    }

    if (childrenGeoZone?.[geoZonesStore.geoZone._id]) {
        links.push(
            ...childrenGeoZone[geoZonesStore.geoZone._id].map((geoZone) => ({
                label: geoZone.name.split(",")[0],
                to: getSearchResultPageUrlFromGeoZonePurposeAndType(geoZone, purpose),
            })),
        );
    }

    return <AdvancedResearches links={links} />;
});
