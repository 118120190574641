import React, { PropsWithChildren } from "react";
import { MAP_OR_LIST_SELECTED, usePropertiesPageContext } from "properties/PropertiesPageContext";
import { Grid } from "@material-ui/core";

export const GridPropertyCard = ({ children, isContained }: PropsWithChildren<{ isContained?: boolean }>) => {
    const { mapOrListSelected } = usePropertiesPageContext();
    if (mapOrListSelected === MAP_OR_LIST_SELECTED.MAP) {
        return (
            <Grid item xs={12} md={6}>
                {children}
            </Grid>
        );
    }

    return (
        <Grid item xs={12} sm={6} md={4} lg={4} xl={isContained ? 4 : 3}>
            {children}
        </Grid>
    );
};
