import React from "react";
import styles from "./_css/formProject.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import {
    PARKING_TYPE,
    PROPERTY_PURPOSE,
    PROPERTY_STATUS,
    PROPERTY_SUBTYPE,
    PROPERTY_TYPE,
} from "properties/_models/PropertyMdl";
import { SelectEnumFormProject } from "projects/component/SelectEnumFormProject";
import { Controller, useFormContext } from "react-hook-form";
import { InputNumberFront } from "payments/_components/InputNumberFront";
import { RadioProjectForm } from "projects/component/RadioProjectForm";
import { InputNumberProjectPage } from "projects/component/InputNumberProjectPage";

type Props = {
    stepSelected: number;
};

export function ProjectSecondStepForm(props: Props) {
    const { t } = useTranslation();
    const form = useFormContext();
    return (
        <div
            className={clsx(styles.container, "p_40", {
                [styles.open]: props.stepSelected === 2,
                [styles.close]: props.stepSelected !== 2,
            })}
        >
            <div className={"flex_row justifyContent_spaceBetween"}>
                <div className={clsx(styles.title, "mb_20")}> {t("projectPage.details")}</div>
            </div>
            <div className={clsx(styles.form)}>
                <div className={clsx("flex_column justifyContent_spaceBetween", styles.column, styles.firstColumn)}>
                    <Controller
                        control={form.control}
                        name={"type"}
                        as={SelectEnumFormProject}
                        label={t("projectPage.select.types.label")}
                        value={form.watch("type") ?? ""}
                        onValueChange={(item: string) => form.setValue("type", item)}
                        enum={Object.values(PROPERTY_TYPE)}
                        labelKey={"projectPage.select.types"}
                    />
                    <Controller
                        control={form.control}
                        name={"subType"}
                        as={SelectEnumFormProject}
                        label={t("projectPage.select.subTypes.label")}
                        value={form.watch("subType") ?? ""}
                        onValueChange={(item: string) => form.setValue("subType", item)}
                        enum={Object.values(PROPERTY_SUBTYPE)}
                        labelKey={"projectPage.select.subTypes"}
                    />
                    <Controller
                        control={form.control}
                        name={"status"}
                        as={SelectEnumFormProject}
                        label={t("projectPage.select.status.label")}
                        value={form.watch("status") ?? ""}
                        onValueChange={(item: string) => form.setValue("status", item)}
                        enum={Object.values(PROPERTY_STATUS)}
                        labelKey={"projectPage.select.status"}
                    />
                    <Controller
                        control={form.control}
                        name={"parking"}
                        as={SelectEnumFormProject}
                        label={t("projectPage.select.parkingTypes.label")}
                        value={form.watch("parking") ?? ""}
                        onValueChange={(item: string) => form.setValue("parking", item)}
                        enum={Object.values(PARKING_TYPE)}
                        labelKey={"projectPage.select.parkingTypes"}
                    />
                    <Controller
                        control={form.control}
                        as={SelectEnumFormProject}
                        label={t("projectPage.select.category")}
                        name={"purpose"}
                        value={form.watch("purpose") ?? ""}
                        onValueChange={(e: string | boolean) => form.setValue("purpose", e)}
                        enum={Object.values(PROPERTY_PURPOSE)}
                        labelKey={"projectPage.select.purpose"}
                    />
                </div>
                <div className={clsx("flex_column justifyContent_spaceBetween", styles.column, styles.secondColumn)}>
                    <div className={clsx("flex_row", styles.numbersInputs)}>
                        <div className={clsx(styles.level, "mr_20 flex_row_center")}>
                            <Controller
                                control={form.control}
                                name={"nbOfLevels"}
                                defaultValue={form.watch("nbOfLevels")}
                                value={form.watch("nbOfLevels")}
                                onValueChange={(e: number) => form.setValue("nbOfLevels", e)}
                                as={InputNumberFront}
                            />
                            <div className={clsx(styles.labelNbInput, "ml_20")}>{t("comparePage.nbOfLevels")}</div>
                        </div>
                        <div className={clsx(styles.unit, "flex_row_center")}>
                            <Controller
                                name={"nbOfUnits"}
                                as={InputNumberFront}
                                control={form.control}
                                defaultValue={form.watch("nbOfUnits")}
                                value={form.watch("nbOfUnits")}
                                onValueChange={(e: number) => form.setValue("nbOfUnits", e)}
                            />
                            <div className={clsx(styles.labelNbInput, "ml_20")}>{t("comparePage.nbOfUnits")}</div>
                        </div>
                    </div>
                    <div className={clsx(styles.showPrice, "flex_row_center")}>
                        <div className={styles.extraLabel}> {t("projectPage.showPrice")} </div>
                        <Controller
                            as={RadioProjectForm}
                            name={"priceIsNotDisplayed"}
                            items={[
                                { label: t("words.yes"), value: false },
                                { label: t("words.no"), value: true },
                            ]}
                            value={form.watch("priceIsNotDisplayed")}
                            onValueChange={(e: string | boolean) => form.setValue("priceIsNotDisplayed", e)}
                        />
                    </div>
                    <div className={"flex_row justifyContent_spaceBetween"}>
                        <div className={styles.labelNbInput}> {t("words.price")} </div>
                        <div className={clsx(styles.rowInputNumber, "flex_row_center mr_80")}>
                            <div className={clsx(styles.priceMin, "flex_row")}>
                                <Controller
                                    as={InputNumberProjectPage}
                                    name={"price.min"}
                                    value={parseInt(form.watch("price.min"))}
                                    onValueChange={(e: number) => form.setValue("price.min", e)}
                                    extralabel={"$"}
                                />
                            </div>
                            <div className={clsx("ml_20 mr_20 text_bold", styles.to)}> {t("words.to")} </div>
                            <div className={clsx(styles.priceMax, "flex_row")}>
                                <Controller
                                    as={InputNumberProjectPage}
                                    name={"price.max"}
                                    value={parseInt(form.watch("price.max"))}
                                    onValueChange={(e: number) => form.setValue("price.max", e)}
                                    extralabel={"$"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"flex_row justifyContent_spaceBetween"}>
                        <div className={styles.labelNbInput}> {t("words.area")} </div>
                        <div className={clsx(styles.rowInputNumber, "flex_row_center mr_80")}>
                            <div className={clsx(styles.surfaceMin, "flex_row_center")}>
                                <Controller
                                    as={InputNumberProjectPage}
                                    name={"squareSurface.min"}
                                    value={parseInt(form.watch("squareSurface.min"))}
                                    onValueChange={(e: number) => form.setValue("squareSurface.min", e)}
                                    extralabel={"pi²"}
                                />
                            </div>
                            <div className={clsx("ml_20 mr_20 text_bold", styles.to)}> {t("words.to")} </div>
                            <div className={clsx(styles.surfaceMax, "flex_row_center")}>
                                <Controller
                                    as={InputNumberProjectPage}
                                    name={"squareSurface.max"}
                                    value={parseInt(form.watch("squareSurface.max"))}
                                    onValueChange={(e: number) => form.setValue("squareSurface.max", e)}
                                    extralabel={"pi²"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"flex_row justifyContent_spaceBetween"}>
                        <div className={styles.labelNbInput}> {t("projectPage.numberRooms")} </div>
                        <div className={clsx(styles.rowInputNumber, "flex_row_center mr_80")}>
                            <div className={clsx(styles.chambreMin, "flex_row_center")}>
                                <Controller
                                    as={InputNumberProjectPage}
                                    name={"bedrooms.min"}
                                    value={parseInt(form.watch("bedrooms.min"))}
                                    onValueChange={(e: number) => form.setValue("bedrooms.min", e)}
                                />
                            </div>
                            <div className={clsx("ml_20 mr_20 text_bold", styles.to)}> {t("words.to")} </div>
                            <div className={clsx(styles.chambreMax, "flex_row_center")}>
                                <Controller
                                    as={InputNumberProjectPage}
                                    name={"bedrooms.max"}
                                    value={parseInt(form.watch("bedrooms.max"))}
                                    onValueChange={(e: number) => form.setValue("bedrooms.max", e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"flex_row justifyContent_spaceBetween"}>
                        <div className={styles.labelNbInput}> {t("projectPage.numberSDB")} </div>
                        <div className={clsx(styles.rowInputNumber, "flex_row_center mr_80")}>
                            <div className={clsx(styles.sdbMin, "flex_row_center")}>
                                <Controller
                                    as={InputNumberProjectPage}
                                    name={"bathrooms.min"}
                                    defaultValue={form.watch("bathrooms.min")}
                                    value={parseInt(form.watch("bathrooms.min"))}
                                    onValueChange={(e: number) => form.setValue("bathrooms.min", e)}
                                />
                            </div>
                            <div className={clsx("ml_20 mr_20 text_bold", styles.to)}> {t("words.to")} </div>
                            <div className={clsx(styles.sdbMax, "flex_row_center")}>
                                <Controller
                                    as={InputNumberProjectPage}
                                    name={"bathrooms.max"}
                                    defaultValue={form.watch("bathrooms.max")}
                                    value={parseInt(form.watch("bathrooms.max"))}
                                    onValueChange={(e: number) => form.setValue("bathrooms.max", e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
