import React, { useState } from "react";
import styles from "./_css/menuItemFront.module.css";
import clsx from "clsx";
import { cartStore } from "Cart/_stores/cartStore";
import { observer } from "mobx-react";
import { PAYMENT_TYPE, TStripeProductListingMdl } from "payments/_models/paymentStripeMdl";
import sharedConfig from "_configs/sharedConfig";
import { useTranslation } from "react-i18next";
import paymentStore from "payments/_stores/paymentStore";

type Props = {
    item: TStripeProductListingMdl;
    value?: number;
};

export const MenuItemFront = observer((props: Props) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(1);
    const price = paymentStore.getPriceFromProductRegionAndRecurring(props.item, PAYMENT_TYPE.ONE_TIME);
    return (
        <div className={clsx(styles.container, "flex_row_center")}>
            <div className={clsx(styles.label, "flex_row justifyContent_spaceBetween flex-1")}>
                <div>{t(`paymentPage.products.options.${props.item.productLabel}`)}</div>
                {props.item.id === sharedConfig.stripe.products.INTEGRATION_CRM ? (
                    <div>{t("projectPage.product.options.dependOfCrm")}</div>
                ) : (
                    <div className={styles.price}>{price.unit_amount ? price.unit_amount / 100 + " $ " : 0}</div>
                )}
            </div>
            <div className={clsx("flex_row_center justifyContent_spaceAround", styles.btn)}>
                <div
                    className={"cursor_pointer"}
                    onClick={() => {
                        cartStore.deleteItemInCart({
                            productId: props.item.id,
                            priceId: price.id,
                            price: price.unit_amount ?? 0,
                            name: props.item.name,
                            quantity: -1,
                            type: PAYMENT_TYPE.ONE_TIME,
                        });
                        cartStore.setIsCartOpen(true);
                        value > 0 ? setValue(value - 1) : "";
                    }}
                >
                    -
                </div>
                <div>{value === 1 ? value : value - 1}</div>
                <div
                    className={"cursor_pointer"}
                    onClick={() => {
                        cartStore.setIsCartOpen(true);
                        const price = paymentStore.getPriceFromProductRegionAndRecurring(
                            props.item,
                            PAYMENT_TYPE.ONE_TIME,
                        );
                        cartStore.addCart({
                            productId: props.item.id,
                            priceId: price.id,
                            price: price.unit_amount ?? 0,
                            name: props.item.name,
                            quantity: 1,
                            type: PAYMENT_TYPE.ONE_TIME,
                        });
                        setValue(value + 1);
                    }}
                >
                    +
                </div>
            </div>
        </div>
    );
});
