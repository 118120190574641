import React from "react";
import { Meta } from "_common/_utils/Meta";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { URLS } from "_configs/URLS";
import { PROPERTY_PURPOSE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { formatPrice } from "_common/_utils/currencyUtils";
import { URLS_ALTERNATE } from "_configs/URLS_ALTERNATE";

type Props = {
    property: TPropertyListingMdl;
};

export function PropertyMeta(props: Props) {
    const { t } = useTranslation();

    const metaTitle = t("propertyPage.meta.title", {
        title: props.property.localized.title,
        purpose: t(`propertyPage.meta.purpose.${props.property.purpose}`),
        city: props.property.address.city,
        province: props.property.address.province,
        propertyType: t(`property.types.${props.property.type}`),
    });

    const description = t("propertyPage.meta.description", {
        title: props.property.localized.title,
        purpose: t(`propertyPage.meta.purpose.${props.property.purpose}`),
        address: `${props.property.address.city} ${props.property.address.neighbourhood ?? ""} ${
            props.property.address.postalCode
        }
                ${props.property.address.province}`,
        price: formatPrice(props.property.price.min, false, props.property.purpose === PROPERTY_PURPOSE.BUY),
        propertyType: t(`property.types.${props.property.type}`),
        metaDescription:
            props.property.localized.meta?.title && props.property.localized.meta.title !== ""
                ? props.property.localized.meta?.description
                : t("meta.description"),
        city: props.property.address.city,
    });

    const title =
        props.property.localized.meta?.title && props.property.localized.meta.title !== ""
            ? props.property.localized.meta.title
            : metaTitle;
    const image = props.property.localized.meta?.image ?? props.property?.photos?.[0]?.url;
    const url = URLS.property(props.property.localized.urlAlias);
    const alternateUrls = [];
    for (const lang of Object.keys(sharedConfig.languages)) {
        alternateUrls.push({
            url:
                sharedConfig.appUrl +
                URLS_ALTERNATE.property(props.property?.urlAlias?.[lang] ?? props.property.localized.urlAlias, lang),
            lang: lang as TLang,
        });
    }

    return (
        <Meta
            title={title}
            description={description}
            image={image}
            url={url}
            alternateUrls={alternateUrls}
            noIndex={!props.property.published}
        />
    );
}
