import React from "react";
import styles from "./_css/togglePayment.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

type Props = {
    setIsAnnual: () => void;
    isAnnual: boolean;
};

export function TogglePayment(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.container, "flex_row")} onClick={() => props.setIsAnnual()}>
            <div
                className={clsx(styles.selectedPlaceholder, {
                    [styles.selectedPlaceholderBis]: props.isAnnual,
                })}
            />
            <div className={clsx(styles.halfSection, "flex_center_center p_15")}>
                <div className={"fontWeight_bold"}>{t("paymentPage.products.mensualPayment")}</div>
            </div>
            <div className={clsx(styles.halfSection, "flex_center_center p_15")}>
                <div className={"fontWeight_bold"}>{t("paymentPage.products.immediatPayment")}</div>
            </div>
        </div>
    );
}
