import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/swiper-bundle.min.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "./_css/unitFloorPlans.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useUnitStore } from "units/UnitContext";
import { isImage } from "_common/_utils/fileUtils";
import { useWindowSize } from "_common/_utils/slider";
import { GALLERY_TABS } from "properties/gallery/PropertyOrUnitGalleryPage";

export function UnitFloorPlans() {
    const unitStore = useUnitStore();
    const windowSize = useWindowSize();
    const { t } = useTranslation();
    if (unitStore.unit.floorPlans.length === 0) return null;
    return (
        <div id="unitFloorPlans" className={clsx("mt_20")}>
            <h2 className={styles.title}>{t("unitPage.floorPlan")}</h2>
            <div className={clsx("position_relative", styles.container)}>
                <Swiper navigation slidesPerView={1} loop>
                    {unitStore.unit.floorPlans.map((image, index) => {
                        return (
                            <SwiperSlide key={index} className={"flex_row justifyContent_center"}>
                                {isImage(image.url) ? (
                                    <LazyLoadImage
                                        onClick={() => unitStore.openGallery(0, GALLERY_TABS.FLOOR_PLAN)}
                                        alt={`${unitStore.unit.localized.title}-floor-plan-${index}`}
                                        title={`${unitStore.unit.localized.title} photo floor plan ${index}`}
                                        effect={"blur"}
                                        className={styles.photo}
                                        src={image.url}
                                    />
                                ) : (
                                    <embed src={image.url} width={windowSize.isMobile ? 260 : 600} height="500" />
                                )}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
}
