import React, { useEffect } from "react";
import { Container, NoSsr } from "@material-ui/core";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { Orders } from "users/orders/Orders";
import { ordersStore } from "payments/_stores/ordersStore";

export function OrdersPage() {
    const { t } = useTranslation();
    useEffect(() => {
        ordersStore.fetchOrders();
    }, []);
    return (
        <NoSsr>
            <Container className="mb_40">
                <Breadcrumb currentItem={{ itemLabel: t("menu.profile") }} />
                <LoadableFromLoading
                    loading={ordersStore.ordersState}
                    renderer={() => {
                        return <Orders />;
                    }}
                />
            </Container>
        </NoSsr>
    );
}
