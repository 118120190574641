import React from "react";
import styles from "./_css/virtualAssistantStepProgression.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

type Props = {
    step: number;
};

const StepLabel = ({ step, currentStep }: { step: number; currentStep: number }) => {
    const { t } = useTranslation();

    return (
        <div className={"flex_row_center"}>
            <div
                className={clsx(styles.number, {
                    [styles.activeNumber]: currentStep >= step,
                })}
            >
                {step}
            </div>

            <div
                className={clsx(styles.title, {
                    [styles.activeTitle]: currentStep >= step,
                })}
            >
                {t("virtualAssistant.step")} {step}
            </div>
        </div>
    );
};

export function VirtualAssistantStepProgression({ step }: Props) {
    return (
        <div className={styles.container}>
            <div>
                <div className={styles.mobileContent}>
                    <StepLabel currentStep={step} step={step} />
                </div>
                <div className={styles.content}>
                    <StepLabel currentStep={step} step={1} />
                    <div
                        className={clsx(styles.bar, {
                            [styles.activeBar]: step > 1,
                        })}
                    />
                    <StepLabel currentStep={step} step={2} />
                    <div
                        className={clsx(styles.bar, {
                            [styles.activeBar]: step > 2,
                        })}
                    />
                    <StepLabel currentStep={step} step={3} />
                </div>
                <div className={styles.megaBarContainer}>
                    <div className={styles.megaBarBg} />
                    <div
                        className={clsx(styles.megaBar, {
                            [styles.stepOneMegaBar]: step === 1,
                            [styles.stepTwoMegaBar]: step === 2,
                            [styles.stepThreeMegaBar]: step >= 3,
                        })}
                    />
                </div>
            </div>
        </div>
    );
}
