import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { ListStore } from "_common/list/ListStore";
import { PROPERTY_PURPOSE, PROPERTY_TYPE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { PropertiesStore } from "properties/_stores/propertiesStore";
import { TLang } from "_configs/sharedConfig";

export function usePropertiesPageMeta(propertiesStore: PropertiesStore, listStore: ListStore<TPropertyListingMdl>) {
    const { t, i18n } = useTranslation();
    const { region, cityForMeta, isProvincePage, isExistingPropertyType } = useSearchPageParams();
    let propertyType;
    let isCustomSeoText;
    const type = listStore.filters.find((filter: TFilter) => filter.id === "type");
    if (type) {
        const filters = JSON.parse(JSON.stringify(type.value));
        propertyType = filters[0];
    }
    let metaTitle =
        propertiesStore.purpose === PROPERTY_PURPOSE.BUY
            ? t("propertiesPage.meta.buyGenericMetaTitle")
            : t("propertiesPage.meta.rentGenericMetaTitle");
    let metaDescription =
        propertiesStore.purpose === PROPERTY_PURPOSE.BUY
            ? t("propertiesPage.meta.buyGenericMetaDescription")
            : t("propertiesPage.meta.buyGenericMetaDescription");
    let title =
        propertiesStore.purpose === PROPERTY_PURPOSE.BUY
            ? t("propertiesPage.meta.buyH1Title")
            : t("propertiesPage.meta.rentH1Title");
    let seoText =
        propertiesStore.purpose === PROPERTY_PURPOSE.BUY
            ? t("propertiesPage.meta.buyGenericText")
            : t("propertiesPage.meta.rentGenericText");
    if (isProvincePage) {
        const geoZoneName = propertiesStore.addressParams?.address
            ? propertiesStore.addressParams?.address.split(",")[0]
            : propertiesStore.addressParams?.neighbourhood
            ? propertiesStore.addressParams.neighbourhood
            : propertiesStore.addressParams?.city &&
              propertiesStore.addressParams.city.toLowerCase() === cityForMeta?.toLowerCase()
            ? propertiesStore.addressParams.city
            : propertiesStore.addressParams.region &&
              propertiesStore.addressParams.region.toLowerCase() === cityForMeta?.toLowerCase()
            ? propertiesStore.addressParams.region
            : cityForMeta;
        if (propertyType && isExistingPropertyType) {
            metaTitle = t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.title.` + propertyType, {
                geoZoneName: geoZoneName,
            });
            metaDescription = t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.description.` + propertyType, {
                geoZoneName: geoZoneName,
            });
            title = t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.` + propertyType, {
                geoZoneName: geoZoneName,
            });
            const seoKey = `propertiesPage.meta.geoZone.${propertiesStore.purpose}.description.variants.${propertyType}.${geoZonesStore.geoZone?.variant}`;
            seoText = i18n.exists(seoKey, {
                geoZoneName: geoZoneName,
            })
                ? t(seoKey, {
                      geoZoneName: geoZoneName,
                  })
                : "";
        } else {
            metaTitle = t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.title.generic`, {
                geoZoneName: geoZoneName,
            });
            metaDescription = t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.description.generic`, {
                geoZoneName: geoZoneName,
            });
            title = t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.h1.generic`, {
                geoZoneName: geoZoneName,
            });
            seoText = t(`propertiesPage.meta.geoZone.${propertiesStore.purpose}.seo.generic`, {
                geoZoneName: geoZoneName,
            });
        }
    } else {
        if (propertyType && isExistingPropertyType) {
            metaTitle = t(`propertiesPage.meta.${propertiesStore.purpose}.title.` + propertyType);
            metaDescription = t(`propertiesPage.meta.${propertiesStore.purpose}.description.` + propertyType);
            title = t(`propertiesPage.meta.${propertiesStore.purpose}.h1.` + propertyType);
            seoText = t(`propertiesPage.meta.${propertiesStore.purpose}.seo.` + propertyType);
        }
    }

    if (geoZonesStore.geoZone && propertiesStore.purpose) {
        if (type && isExistingPropertyType) {
            if (
                geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                    propertyType as PROPERTY_TYPE
                ]?.title
            ) {
                title =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                        propertyType as PROPERTY_TYPE
                    ]?.title;
            }
            if (
                geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                    propertyType as PROPERTY_TYPE
                ]?.meta?.title
            ) {
                metaTitle =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                        propertyType as PROPERTY_TYPE
                    ]?.meta?.title ?? "";
            }
            if (
                geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                    propertyType as PROPERTY_TYPE
                ]?.text
            ) {
                isCustomSeoText = true;
                seoText =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                        propertyType as PROPERTY_TYPE
                    ]?.text;
            }
            if (
                geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                    propertyType as PROPERTY_TYPE
                ]?.meta?.description
            ) {
                metaDescription =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.[
                        propertyType as PROPERTY_TYPE
                    ]?.meta?.description ?? "";
            }
        } else {
            if (geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.title) {
                title = geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.title;
            }

            if (geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.text) {
                isCustomSeoText = true;
                seoText = geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.text;
            }

            if (geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.meta?.title) {
                metaTitle =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.meta
                        ?.title ?? "";
            }

            if (
                geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.meta
                    ?.description
            ) {
                metaDescription =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[propertiesStore.purpose]?.meta
                        ?.description ?? "";
            }
        }
    } else if (region && cityForMeta) {
        title = t("propertiesPage.meta.title", { region, city: cityForMeta });
    }
    return { title, metaTitle, seoText, metaDescription, isCustomSeoText, propertyType };
}
