import React, { useState } from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import styles from "../../properties/gallery/_css/propertyGallery.module.css";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/swiper-bundle.min.css";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useUnitStore } from "units/UnitContext";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { Label } from "properties/label/Label";
import { PROPERTY_PURPOSE, PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { PropertyOrUnitGallerySecondary } from "properties/gallery/PropertyOrUnitGallerySecondary";
import { PropertyOrUnitGalleryThumbnails } from "properties/gallery/PropertyOrUnitGalleryThumbnails";
import { PropertyOrUnitGallerySwiper } from "properties/gallery/PropertyOrUnitGallerySwiper";

SwiperCore.use([Navigation, Thumbs]);

export const UnitGallery = observer(() => {
    const [indexGallery, setIndexGallery] = useState(0);
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>();
    const propertyStore = usePropertyStore();
    const unitStore = useUnitStore();
    const { t } = useTranslation();

    if (!unitStore.unit || !unitStore.unit.photos) return null;
    if (unitStore.unit.photos.length === 0) return null;

    let lengthImgAndVideo = unitStore.unit.photos.length;
    const indexForVideoOrVisit3D = [];
    if (unitStore.unit.video) {
        indexForVideoOrVisit3D.push(lengthImgAndVideo + 1);
        lengthImgAndVideo += 1;
    }
    if (unitStore.unit.video2) {
        indexForVideoOrVisit3D.push(lengthImgAndVideo + 1);
        lengthImgAndVideo += 1;
    }
    if (unitStore.unit.visit3D) {
        indexForVideoOrVisit3D.push(lengthImgAndVideo + 1);
        lengthImgAndVideo += 1;
    }

    return (
        <div id={"unitGallery"} className={clsx(styles.container, "flex_row")}>
            <div className={clsx("flex_column position_relative", styles.contentSliders)}>
                <div
                    className={clsx("position_absolute flex_row", styles.labels, {
                        [styles.hiddenBtns]: indexForVideoOrVisit3D.find((index) => index === indexGallery),
                    })}
                >
                    {unitStore.unit.sold ? (
                        <Label
                            className={styles.purpose}
                            label={
                                propertyStore.property.purpose === PROPERTY_PURPOSE.BUY
                                    ? propertyStore.property.type === PROPERTY_TYPE.condo
                                        ? t("property.soldOutCondo")
                                        : t("property.soldOut")
                                    : t("property.rented")
                            }
                            bgColor={"#b4880e"}
                        />
                    ) : (
                        <Label className={styles.purpose} purpose={propertyStore.property.purpose} />
                    )}
                </div>
                <PropertyOrUnitGallerySwiper
                    indexGallery={indexGallery}
                    thumbsSwiper={thumbsSwiper}
                    setIndexGallery={setIndexGallery}
                    lengthImgAndVideo={lengthImgAndVideo}
                    indexForVideoOrVisit3D={indexForVideoOrVisit3D}
                    resource={MAIN_RESOURCE.UNITS}
                />
                <PropertyOrUnitGalleryThumbnails
                    indexGallery={indexGallery}
                    setThumbsSwiper={(swipe) => setThumbsSwiper(swipe)}
                    resource={MAIN_RESOURCE.UNITS}
                />
            </div>
            <PropertyOrUnitGallerySecondary resource={MAIN_RESOURCE.UNITS} />
        </div>
    );
});
