import React from "react";
import clsx from "clsx";
import styles from "properties/gallery/_css/propertyGallery.module.css";
import { ToggleToFavoriteBtn } from "properties/ToggleToFavoriteBtn";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { CompareBtn } from "compare/CompareBtn";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import iconImg from "../../assets/images/imageIcon.svg";
import { useUnitStore } from "units/UnitContext";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";

type Props = {
    indexForVideoOrVisit3D: number[];
    indexGallery: number;
    lenghtImgAndVideo: number;
    resource: MAIN_RESOURCE;
};

export function ItemGalleryIcons({ indexForVideoOrVisit3D, indexGallery, lenghtImgAndVideo, resource }: Props) {
    const unitStore = useUnitStore();
    const propertyStore = usePropertyStore();
    const store = resource === MAIN_RESOURCE.PROPERTIES ? propertyStore : unitStore;
    const item = resource === MAIN_RESOURCE.PROPERTIES ? propertyStore.property : unitStore.unit;
    return (
        <>
            <div
                className={clsx("position_absolute flex_row", styles.actions, {
                    [styles.hiddenBtns]: indexForVideoOrVisit3D.find((index) => index === indexGallery),
                })}
            >
                <ToggleToFavoriteBtn item={item} type={resource} withoutText />
            </div>
            <div
                className={clsx("position_absolute flex_row_center gap_20", styles.bottomActions, {
                    [styles.hiddenBtns]: indexForVideoOrVisit3D.find((index) => index === indexGallery),
                })}
            >
                <CompareBtn type={MAIN_RESOURCE.UNITS} id={item._id} isItemPage notAbsolute />
                <UiButton
                    onClick={() => store.openGallery(0)}
                    className={clsx("p_5", styles.button)}
                    variant={"contained"}
                    color={"primary"}
                >
                    <span className={"ml_5 mr_10 flex_row"}>
                        <div className={"fontWeight_bold"}>{indexGallery}</div>
                        {"/" + lenghtImgAndVideo}
                    </span>
                    <img src={iconImg} alt={"iconImg"} />
                </UiButton>
            </div>
        </>
    );
}
