import React from "react";
import { Helmet } from "react-helmet-async";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { useTranslation } from "react-i18next";
import thumbnail from "../../assets/images/thumbnail.webp";

type Props = {
    title?: string;
    description?: string;
    image?: string;
    url?: string;
    alternateUrls?: { lang: TLang; url: string }[];
    noIndex?: boolean;
};

export function Meta(props: Props) {
    const { t } = useTranslation();
    return (
        <Helmet>
            <title>{props.title}</title>
            {props.noIndex && <meta name="robots" content="noindex, nofollow" />}
            <meta name="title" content={props.title ?? t("meta.title")} />
            <meta name="og:title" content={props.title ?? t("meta.title")} />
            <meta name="twitter:title" content={props.title ?? t("meta.title")} />

            <meta name="description" content={props.description ?? t("meta.description")} />
            <meta name="og:description" content={props.description ?? t("meta.description")} />
            <meta name="twitter:description" content={props.description ?? t("meta.description")} />

            <meta name="image" content={props.image ?? thumbnail} />
            <meta name="og:image" content={props.image ?? thumbnail} />
            <meta name="twitter:image" content={props.image ?? thumbnail} />

            <meta name="twitter:card" content="summary" />
            <meta name="og:url" content={props.url?.startsWith("http") ? props.url : sharedConfig.appUrl + props.url} />
            {props.url && (
                <link
                    rel="canonical"
                    href={props.url?.startsWith("http") ? props.url : sharedConfig.appUrl + props.url}
                />
            )}
            {props.alternateUrls?.map(({ lang, url }) => {
                return (
                    <link
                        key={lang}
                        rel="alternate"
                        type="text/html"
                        hrefLang={lang}
                        href={url}
                        title={sharedConfig.languages[lang].title}
                    />
                );
            })}
        </Helmet>
    );
}
