import React from "react";
import styles from "./_css/toggleSignInOrSignUp.module.css";
import clsx from "clsx";

type Props = {
    signUp: boolean;
    setSignUp: (value: boolean) => void;
};

export function ToggleSignInOrSignUp(props: Props) {
    return (
        <div className={clsx(styles.container, "flex_row mt_30")} onClick={() => props.setSignUp(!props.signUp)}>
            <div
                className={clsx(styles.selectedPlaceholder, {
                    [styles.selectedPlaceholderBis]: props.signUp,
                })}
            />
            <div className={clsx(styles.halfSection, "flex_column_center p_10 justifyContent_center")}>
                <div className={"fontWeight_bold"}>Connexion</div>
            </div>
            <div className={clsx(styles.halfSection, "flex_column_center p_10 ph_20 justifyContent_center")}>
                <div className={"fontWeight_bold"}>Inscription</div>
            </div>
        </div>
    );
}
