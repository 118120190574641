import React from "react";
import styles from "./_css/advantagesPromotePage.module.css";
import desktop from "../assets/images/promotePage/desktop.webp";
import apchaLogo from "../assets/images/promotePage/apchaLogo.png";
import acqLogo from "../assets/images/promotePage/acqLogo.png";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

export function PromoteAdvantages() {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.subContent}>
                    <img className={styles.image} src={desktop} alt="ilustration" />
                    <div className={styles.title}> {t("promotePage.advantage")}</div>
                    <ul className={styles.list}>
                        <li className={styles.li}> {t("promotePage.listAdvantage.1")}</li>
                        <li className={styles.li}> {t("promotePage.listAdvantage.2")}</li>
                        <li className={styles.li}> {t("promotePage.listAdvantage.3")}</li>
                        <li className={styles.li}> {t("promotePage.listAdvantage.4")}</li>
                        <li className={styles.li}> {t("promotePage.listAdvantage.5")}</li>
                        <li className={styles.li}> {t("promotePage.listAdvantage.6")}</li>
                        <li className={styles.li}> {t("promotePage.listAdvantage.7")}</li>
                    </ul>
                    <div className={styles.bottomText}>
                        <div className={styles.bottomTitle}>{t("promotePage.member")}</div>
                        <div className={styles.contentLogo}>
                            <img src={acqLogo} alt={"logo acq"} className={clsx("flex mr_60", styles.logoacq)} />
                            <img src={apchaLogo} alt={"logo apcha"} className={clsx("flex", styles.logoapcha)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
