import React, { useEffect, useRef } from "react";
import styles from "placements/_css/placementSrpsCard.module.css";
import { PLACEMENT_EVENT, TPlacementListingLocalizedMdl } from "placements/_models/PlacementsMdl";
import { useLocation } from "react-router";
import { useOnScreen } from "_common/_utils/useOnScreen";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

type Props = {
    placement: TPlacementListingLocalizedMdl;
};

export function PlacementSrpsCard({ placement }: Props) {
    const url = useLocation().pathname;
    const { t } = useTranslation();
    const ref = useRef<HTMLAnchorElement>(null);
    const { isIntersecting } = useOnScreen(ref, 0.5);
    useEffect(() => {
        if (isIntersecting) {
            void analyticsUtils.tracksEventOfItem(PLACEMENT_EVENT.PRINTED_SRPS, placement._id, url);
        }
    }, [isIntersecting]);
    if (!placement.localized || (!placement.localized.image && !placement.localized.gifUrl)) return null;
    return (
        <div className={clsx("flex_column justifyContent_spaceBetween alignItems_center", styles.container)}>
            <div className={clsx(styles.title, "text_xxsmall")}>{t("placements.srps.label")}</div>
            <a
                href={placement.localized.url}
                target={"_blank"}
                rel="noopener noreferrer nofollow"
                className={styles.imgContainer}
                ref={ref}
                onClick={() => {
                    void analyticsUtils.tracksEventOfItem(PLACEMENT_EVENT.VISITED_SRPS, placement._id, url);
                }}
            >
                {placement.localized.gifUrl ? (
                    <img src={placement.localized.gifUrl} alt={placement.image} className={styles.img} />
                ) : (
                    <img src={placement.localized.image} alt={placement.image} className={styles.img} />
                )}
            </a>
        </div>
    );
}
