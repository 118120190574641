export const ADMIN_PATH = "/vistoo-admin";

export const ADMIN_URLS = {
    home: () => ADMIN_PATH,
    dashboard: () => `${ADMIN_PATH}/dashboard`,

    users: {
        base: () => `${ADMIN_PATH}/users`,
        new: () => `${ADMIN_PATH}/users/edit`,
        edit: (id: string) => `${ADMIN_PATH}/users/edit/${id}`,
    },

    pages: {
        base: () => `${ADMIN_PATH}/pages`,
        new: () => `${ADMIN_PATH}/pages/edit`,
        edit: (id: string) => `${ADMIN_PATH}/pages/edit/${id}`,
    },

    properties: {
        base: () => `${ADMIN_PATH}/properties`,
        new: () => `${ADMIN_PATH}/properties/edit`,
        edit: (id: string) => `${ADMIN_PATH}/properties/edit/${id}`,
    },

    units: {
        base: () => `${ADMIN_PATH}/units`,
        new: () => `${ADMIN_PATH}/units/edit`,
        edit: (id: string) => `${ADMIN_PATH}/units/edit/${id}`,
    },

    geoZones: {
        base: () => `${ADMIN_PATH}/geoZones`,
        new: () => `${ADMIN_PATH}/geoZones/edit`,
        edit: (id: string) => `${ADMIN_PATH}/geoZones/edit/${id}`,
    },

    messages: {
        base: () => `${ADMIN_PATH}/messages`,
        edit: (id: string) => `${ADMIN_PATH}/messages/edit/${id}`,
    },

    developerNotifications: {
        base: () => `${ADMIN_PATH}/developerNotifications`,
        edit: (id: string) => `${ADMIN_PATH}/developerNotifications/edit/${id}`,
    },

    orders: {
        base: () => `${ADMIN_PATH}/orders`,
        edit: (id: string) => `${ADMIN_PATH}/orders/edit/${id}`,
    },

    settings: {
        base: () => `${ADMIN_PATH}/settings`,
    },
    showcases: {
        base: () => `${ADMIN_PATH}/showcases`,
    },

    placements: {
        base: () => `${ADMIN_PATH}/placements`,
        dashboard: () => `${ADMIN_PATH}/dashboard-placements`,
    },

    crawler: {
        dashboard: () => `${ADMIN_PATH}/dashboard-crawler`,
    },

    menu: {
        base: () => `${ADMIN_PATH}/menu`,
    },
};
