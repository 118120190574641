import React from "react";
import FacebookLogin from "react-facebook-login";
import { useHistory } from "react-router";
import { authStore } from "users/auth/_stores/authStore";
import { toast } from "react-toastify";
import { getErrorMessage } from "_common/errors/errorUtils";
import { URLS } from "_configs/URLS";
import { appConfig } from "_configs/appConfig";
import styles from "./_css/facebookAuth.module.css";
import { IconComponent } from "components/base/icon/IconComponent";
import clsx from "clsx";

type Props = {
    backUrl?: string;
    onSuccess?: (success: boolean) => void;
};

export function FacebookAuth(props: Props) {
    const history = useHistory();
    return (
        <div className={clsx("flex_row_center", styles.container)}>
            <div className="flex_center_center" style={{ height: 40, width: 40, color: "#1877f2" }}>
                <IconComponent icon={"facebook"} />
            </div>
            <FacebookLogin
                appId={appConfig.facebook.appId}
                fields={"name,email,first_name,last_name"}
                cssClass={styles.none}
                callback={(response) => {
                    const promise = authStore.facebookAuth(response);
                    promise
                        .then(
                            () => {
                                if (props.onSuccess) props.onSuccess(true);
                                history.push(props.backUrl ?? URLS.home());
                                if (props.backUrl) history.go(0);
                            },
                            (e) => {
                                toast.error(getErrorMessage(e));
                            },
                        )
                        .catch((e) => {
                            toast.error(e);
                        });
                }}
            />
        </div>
    );
}
