import React, { useState } from "react";
import styles from "./_css/checkboxInputFormProject.module.css";
import { TOptionConfigDef } from "_configs/propertyConfig";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

type Props = {
    item: TOptionConfigDef;
    onChange?: (value: boolean) => void;
    value?: boolean;
};

export function CheckboxInputFormProject(props: Props) {
    const { t } = useTranslation();

    const [checked, setChecked] = useState(false);

    return (
        <div>
            <div className={clsx(styles.item, "flex_row_center cursor_pointer")}>
                <div>
                    <input
                        checked={checked}
                        type={"checkbox"}
                        className={styles.checkbox}
                        onChange={() => {
                            setChecked(!checked);
                            if (props.onChange) props.onChange(!checked);
                        }}
                    />
                </div>
                <div
                    className={styles.label}
                    onClick={() => {
                        setChecked(!checked);
                        if (props.onChange) props.onChange(!checked);
                    }}
                >
                    {t(`property.features.${props.item.key}`)}
                </div>
            </div>
        </div>
    );
}
