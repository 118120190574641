import React from "react";
import { IPageMdl, PAGE_TYPE } from "pages/_models/PageMdl";
import { ComponentRenderer } from "components/ComponentRenderer";
import { PageRendererContext } from "pages/PageContext";
import { PageRendererStore } from "pages/_stores/PageRendererStore";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { TLang } from "_configs/sharedConfig";
import { Container } from "@material-ui/core";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { PageMeta } from "pages/PageMeta";
import { useGetItemsForBreadCrumb, useHomePage } from "_common/_utils/hookUtils";
import { BlogPage } from "blog/BlogPage";

type Props = {
    page: IPageMdl;
};

export function PageRenderer(props: Props) {
    const isHomePage = useHomePage();
    const items = useGetItemsForBreadCrumb(props.page);
    const localizedPage = props.page.localized[i18nextInstance.language as TLang];
    if (!localizedPage) return null;

    const isBlogPage = props.page.type === PAGE_TYPE.ARTICLE;
    if (isBlogPage) {
        return <BlogPage page={props.page} />;
    }

    return (
        <PageRendererContext pageRendererStore={new PageRendererStore()}>
            <PageMeta page={props.page} />
            {!isHomePage && (
                <Container className={"mt_10"}>
                    <Breadcrumb currentItem={{ itemLabel: localizedPage.title }} items={items} />
                </Container>
            )}
            {localizedPage.component && <ComponentRenderer component={localizedPage.component} isRoot />}
        </PageRendererContext>
    );
}
