import React from "react";

export function ArrowReverseCtaIcon() {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M7.75 14.25L1 7.5L7.75 0.75" stroke="#c4c4c4" />
                <path d="M1.75 7.5L16 7.5" stroke="#c4c4c4" />
            </g>
        </svg>
    );
}
