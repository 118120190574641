import { getI18nExpByLang } from "_common/_utils/pageUtils";
import i18next from "i18next";

export function formatPrice(price: number, alreadyDivided = false, withDecimal = false) {
    let displayPrice = Math.round(price);
    displayPrice = alreadyDivided ? displayPrice : displayPrice / 100;
    const language = getI18nExpByLang(i18next.language, `currency.${i18next.language}`);
    const formatter = new Intl.NumberFormat(language, {
        style: "currency",
        currency: "CAD",
        minimumFractionDigits: withDecimal ? 2 : 0,
    });
    let formatPrice = formatter.format(displayPrice);
    if (language !== "fr-CA") {
        formatPrice = formatter
            .format(displayPrice)
            .replaceAll(",", !withDecimal ? " " : ",")
            .replace("$", " $ ");
        if (formatPrice.includes("CA")) {
            formatPrice = formatPrice.replace("CA", "");
        }
    }
    return formatPrice;
}
