import React from "react";
import i18next from "i18next";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { useHistory } from "react-router-dom";
import { HeaderBlock } from "home/sections/HeaderBlock";
import { PopularCities } from "home/sections/PopularCities";
import { EstatePartner } from "home/sections/EstatePartner";
import { Blog } from "home/sections/Blog";
import styles from "./_css/homePage.module.css";
import { Meta } from "_common/_utils/Meta";
import { PromoteTrustedUs } from "advertise/PromoteTrustedUs";
import { FeaturedPropertiesHomePage } from "home/sections/FeaturedPropertiesHomePage";

// const LazyFeaturedPropertiesHomePage = lazy(() => import("home/sections/FeaturedPropertiesHomePage"));
export function HomePage() {
    const history = useHistory();
    const meta: {
        title: string;
        description: string;
        url: string;
        alternateUrls: { lang: TLang; url: string }[];
    } = {
        title: i18next.t("homePage.meta.title"),
        description: i18next.t("homePage.meta.description"),
        url: sharedConfig.appUrl + history.location.pathname,
        alternateUrls: [],
    };

    for (const lang of Object.keys(sharedConfig.languages)) {
        if (lang === "en") {
            meta.alternateUrls.push({ url: sharedConfig.appUrl + "/", lang: lang as TLang });
        } else {
            meta.alternateUrls.push({ url: sharedConfig.languages[lang as TLang].baseUrl, lang: lang as TLang });
        }
    }

    return (
        <div className={styles.container}>
            <Meta title={meta.title} description={meta.description} url={meta.url} alternateUrls={meta.alternateUrls} />
            <HeaderBlock />
            <FeaturedPropertiesHomePage />
            <PopularCities />
            <EstatePartner />
            <Blog />
            <PromoteTrustedUs />
        </div>
    );
}
