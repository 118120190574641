import React from "react";
import styles from "./_css/formProject.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { TPropertyMdl } from "properties/_models/PropertyMdl";
import { PropertyFileStore } from "properties/_stores/PropertyFilesStore";
import { RESOURCE_KEY } from "admin/_common/photos/AdminPhotosStore";
import { InputTextFront } from "payments/_components/InputTextFront";
import { ProjectPropertyFilesPicker } from "projects/ProjectPropertyFilesPicker";

type Props = {
    stepSelected: number;
    property: TPropertyMdl;
    setProperty: (value: TPropertyMdl) => void;
};

export const ProjectFourthStepForm = (props: Props) => {
    const { t } = useTranslation();
    const form = useFormContext();
    const store = new PropertyFileStore(props.property);

    return (
        <div
            className={clsx(styles.container, "p_40", {
                [styles.open]: props.stepSelected === 4,
                [styles.close]: props.stepSelected !== 4,
            })}
        >
            <div className={"flex_row justifyContent_spaceBetween"}>
                <div className={clsx(styles.title, "mb_20")}> {t("projectPage.mediaContent")}</div>
            </div>
            <div className={"pv_20"}>
                <ProjectPropertyFilesPicker
                    value={[]}
                    store={store}
                    resource={RESOURCE_KEY.FLOOR_PLANS}
                    property={props.property}
                    setProperty={props.setProperty}
                />
                <ProjectPropertyFilesPicker
                    value={[]}
                    store={store}
                    resource={RESOURCE_KEY.PHOTOS}
                    property={props.property}
                    setProperty={props.setProperty}
                />
                <div className={clsx(styles.textDownloadFiles, "mb_40")}>{t("projectPage.textDownloadFileVideo")}</div>
                <Controller
                    control={form.control}
                    name={"video"}
                    as={InputTextFront}
                    placeholder={t("projectPage.video")}
                    className={"mb_20"}
                />
                <Controller
                    control={form.control}
                    name={"video2"}
                    as={InputTextFront}
                    placeholder={t("projectPage.video")}
                    className={"mb_20"}
                />
                <Controller
                    control={form.control}
                    name={"visit3D"}
                    as={InputTextFront}
                    placeholder={t("projectPage.3DTour")}
                />
            </div>
        </div>
    );
};
