import { useEffect, useState } from "react";

export function useOnScreen(
    ref: React.RefObject<HTMLElement>,
    percentOfEltOnScreenToBeConsiderAsIntersection?: number,
) {
    const [isIntersecting, setIntersecting] = useState(false);
    const [intersectionRatio, setIntersectionRatio] = useState(0);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting);
                setIntersectionRatio(entry.intersectionRatio);
            },
            { threshold: [0, 0.25, 0.5, 0.75, 1] },
        );
        if (!ref.current) return;
        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, []);

    return {
        isIntersecting: percentOfEltOnScreenToBeConsiderAsIntersection
            ? intersectionRatio > percentOfEltOnScreenToBeConsiderAsIntersection
            : isIntersecting,
        intersectionRatio,
    };
}
