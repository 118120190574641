import { appConfig } from "_configs/appConfig";
import sharedConfig from "_configs/sharedConfig";
import TagManager from "react-gtm-module";
import { ADMIN_PATH } from "admin/_configs/ADMIN_URLS";
import ReactGA from "react-ga";
import { fetchUtils } from "_common/_utils/fetchUtils";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { unitsStore } from "units/_stores/unitsStore";
import i18next from "i18next";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { PROPERTY_EVENT } from "properties/_models/PropertyMdl";
import { UNIT_EVENT } from "units/_models/UnitMdl";
import { TEventMdl } from "events/_models/EventMdl";
import { computed, observable } from "mobx";
import { PLACEMENT_PRINTED_EVENT, PLACEMENT_VISITED_EVENT } from "placements/_models/PlacementsMdl";

type TCookies = "declined" | "accepted" | null;

class AnalyticsUtils {
    readonly enabled = !sharedConfig.isDev;

    @observable
    private cookies: TCookies = __BROWSER__ ? (localStorage.getItem("cookies") as TCookies) : null;

    constructor(private apiPath = `${sharedConfig.apiUrl}/analytics`) {
        if (!__BROWSER__) {
            return;
        }
        TagManager.initialize(appConfig.googleGtm);
        this._loadReCaptcha();
        if (this.cookies === "accepted") {
            this._loadTrackingLibraries();
        }
    }

    acceptCookies() {
        this.cookies = "accepted";
        localStorage.setItem("cookies", this.cookies);
        this._loadTrackingLibraries();
    }

    private _loadTrackingLibraries() {
        ReactGA.initialize(appConfig.google.analytics, { debug: !sharedConfig.isProd, testMode: !sharedConfig.isProd });
        if (sharedConfig.isProd) {
            this._loadBaidu();
            this._loadMetaPixel();
            this._loadClarity();
        }
    }

    declineCookies() {
        this.cookies = "declined";
        localStorage.setItem("cookies", this.cookies);
    }

    @computed
    get hasSetCookiesPolicy() {
        return this.cookies !== null;
    }

    setUserInfo(_email: string) {
        // after signing in
    }

    trackPage(url: string) {
        if (url.startsWith(ADMIN_PATH) || this.cookies !== "accepted") {
            return;
        }

        try {
            ReactGA.ga("set", "page", url);
            ReactGA.pageview(url);
        } catch (err) {
            console.error(err);
        }
    }

    async tracksPropertiesAndUnitsPage(url: string) {
        const urlInArr = url.split("/");
        const lang = i18next.language;
        if (url.startsWith(`/${lang}/${getI18nExpByLang(lang, "routes.properties")}`)) {
            const propertyAlias = urlInArr[3];
            if (urlInArr.length < 5) {
                let property;
                const results = await propertiesStore.getByAliasUrl(propertyAlias, lang);
                if (results && "data" in results) property = results.data;
                else property = results;
                if (property) {
                    void fetchUtils.post(`${this.apiPath}/tracks/${lang}/${MAIN_RESOURCE.PROPERTIES}/${property._id}`);
                }
            } else {
                const unitAlias = urlInArr[5];
                const unit = await unitsStore.getByAliasUrl(unitAlias, propertyAlias, lang);
                if (unit) {
                    void fetchUtils.post(`${this.apiPath}/tracks/${urlInArr[1]}/${MAIN_RESOURCE.UNITS}/${unit._id}`);
                }
            }
        }
    }

    async tracksEventOfItem(
        eventType: PROPERTY_EVENT | UNIT_EVENT | PLACEMENT_PRINTED_EVENT | PLACEMENT_VISITED_EVENT,
        itemId: string,
        url?: string,
    ) {
        const sessionId = localStorage.getItem("SESSION_ID");
        return fetchUtils.post<TEventMdl>(`${this.apiPath}/createEventForItem/${itemId}`, {
            eventType,
            url,
            sessionId,
        });
    }

    private _loadClarity() {
        if (!sharedConfig.isProd) return;
        (function (c, l, a, r, i, t, y) {
            c[a] =
                c[a] ||
                function () {
                    (c[a].q = c[a].q || []).push(arguments);
                };
            t = l.createElement(r);
            t.async = 1;
            t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0];
            y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "h95f0rkz5r");
    }

    private _loadReCaptcha() {
        if (!sharedConfig.isProd) return;
        if (window && !window?.grecaptcha) {
            const script = document.createElement("script");
            script.src = "https://www.google.com/recaptcha/api.js?render=" + sharedConfig.reCaptcha.siteKey;
            // script.addEventListener("load", handleLoaded);
            document.body.appendChild(script);
        }
    }

    private _loadBaidu() {
        if (!sharedConfig.isProd) return;
        const _hmt = _hmt || [];
        (function () {
            const hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?7012698048ba475502fac42d021e922e";
            const s = document.getElementsByTagName("script")[0];
            s.parentNode?.insertBefore(hm, s);
        })();
    }

    private _loadMetaPixel() {
        if (!sharedConfig.isProd) return;
        (function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
        (window as any).fbq("init", "263519496559534");
        (window as any).fbq("track", "PageView");
    }
}

const analyticsUtils = new AnalyticsUtils();
export { analyticsUtils };
