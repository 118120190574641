import React from "react";
import { ButtonV2 } from "_common/ui/mui/buttons/ButtonV2";
import { useTranslation } from "react-i18next";
import styles from "./_css/cookieModal.module.css";
import { Dialog } from "_common/ui/dialogs/Dialog";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onAccept: () => void;
    onDecline: () => void;
};

export function CookieModal(props: Props) {
    const { t } = useTranslation();

    return (
        <Dialog
            closeCross
            closeSize="large"
            open={props.isOpen}
            onClose={() => props.onClose()}
            maxWidth="xl"
            fullWidth
            fullScreen
            withoutPlaceholder
        >
            <div className={styles.container}>
                <div className={"flex_column_center"}>
                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: t("cookieBanner.contentMore") }} />
                    <div className={"flex_row_center gap_20 mt_50"}>
                        <ButtonV2 onClick={props.onAccept} variant={"contained"}>
                            {t("cookieBanner.accept")}
                        </ButtonV2>
                        <ButtonV2 onClick={props.onDecline} variant={"outlined"}>
                            {t("cookieBanner.refuse")}
                        </ButtonV2>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
