import React, { useState } from "react";
import { USER_ROLES } from "users/_models/UserMdl";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { authStore } from "users/auth/_stores/authStore";
import { toast } from "react-toastify";
import { getErrorMessage } from "_common/errors/errorUtils";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { InputTextFront } from "payments/_components/InputTextFront";
import styles from "./_css/signUpFormFront.module.css";
import clsx from "clsx";

type TFormData = {
    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    notification: boolean;
    heardAboutUs: string;
    roles: USER_ROLES[];
    company: string;
    phone: string;
};

export function SignUpFormFront() {
    const { t } = useTranslation();
    const form = useForm<TFormData>({
        mode: "onBlur",
    });

    const { loading, setPromise } = useLoadingFromPromise();
    const [isLoading, setIsLoading] = useState(!!loading?.isLoading);

    const onSubmit = (values: TFormData) => {
        setIsLoading(true);
        const promise = authStore.signUp(
            values.email,
            values.password,
            values.firstName,
            values.lastName,
            values.notification,
            values.heardAboutUs,
            values.roles,
            values.company,
        );
        setPromise(promise);
        promise
            .then(
                () => {
                    toast.success(t("auth.signUp.successfullyRegistered"), { position: toast.POSITION.TOP_CENTER });
                },
                (e) => {
                    toast.error(getErrorMessage(e));
                    loading?.setError(e);
                    setIsLoading(!!loading?.isLoading);
                },
            )
            .catch((e) => {
                toast.error(e);
                setIsLoading(false);
            });
    };

    return (
        <div className={styles.container}>
            <ErrorBlock error={loading?.error} />
            <FormProvider {...form}>
                <div className={clsx(styles.lineForm, "flex justifyContent_spaceBetween")}>
                    <InputTextFront
                        placeholder={t("words.firstName")}
                        name="firstName"
                        ref={form.register({
                            required: t<string>("errors.forms.required"),
                        })}
                        error={form.errors.firstName}
                        disabled={isLoading}
                    />
                    <InputTextFront
                        placeholder={t("words.lastName")}
                        name="lastName"
                        ref={form.register({
                            required: t<string>("errors.forms.required"),
                        })}
                        error={form.errors.lastName}
                        disabled={isLoading}
                    />
                </div>
                <div className={"mt_20"}>
                    <InputTextFront placeholder={t("words.companyName")} name="companyName" disabled={isLoading} />
                </div>
                <div className={clsx(styles.lineForm, "flex justifyContent_spaceBetween")}>
                    <InputTextFront
                        placeholder={t("words.email")}
                        name="email"
                        ref={form.register({
                            required: t<string>("errors.forms.required"),
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                                message: t("errors.forms.emailInvalid"),
                            },
                        })}
                        error={form.errors.email}
                        disabled={isLoading}
                    />
                    <InputTextFront
                        placeholder={t("words.phone")}
                        name="phone"
                        error={form.errors.phone}
                        disabled={isLoading}
                    />
                </div>
                <div className={"mt_20"}>
                    <InputTextFront
                        password
                        placeholder={t("words.password")}
                        name="password"
                        ref={form.register({
                            required: t<string>("errors.forms.required"),
                            minLength: {
                                value: 6,
                                message: t("auth.signUp.passwordsLength"),
                            },
                        })}
                        error={form.errors.password}
                        disabled={isLoading}
                    ></InputTextFront>
                </div>
                <div className={"mt_20"}>
                    <InputTextFront
                        placeholder={t("auth.signUp.passwordConfirmation")}
                        name="confirmPassword"
                        password
                        ref={form.register({
                            required: t<string>("errors.forms.required"),
                            minLength: {
                                value: 8,
                                message: t<string>("auth.signUp.passwordsLength"),
                            },
                            validate: (value) =>
                                value !== form.watch("password")
                                    ? t<string>("auth.signUp.passwordsMismatch")
                                    : undefined,
                        })}
                        error={form.errors.confirmPassword}
                        disabled={isLoading}
                    ></InputTextFront>
                </div>

                <div className={clsx("flex_center_center mt_40 mb_30", styles.contentBtn)}>
                    <UiButton
                        className={styles.button}
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={isLoading}
                        variant="contained"
                        color="primary"
                    >
                        {t(isLoading ? "loaders.message" : "paymentPage.signUp")}
                    </UiButton>
                </div>
            </FormProvider>
        </div>
    );
}
