import React from "react";
import { Dayjs } from "dayjs";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import styles from "./_css/blogCard.module.css";
import clsx from "clsx";

type Props = {
    date?: Dayjs | string;
    title?: string;
    text?: string;
    image?: string;
    url?: string;
};

export function BlogCard(props: Props) {
    const lang = i18next.language;
    const { t } = useTranslation();

    return (
        <div className={clsx("m_20", styles.content)}>
            <a href={sharedConfig.languages[lang as TLang].baseUrl + "/" + t("routes.blog") + props.url}>
                <div className={clsx(styles.subContent, "flex_row")}>
                    <div className={clsx("flex_column ml_20 mt_20", styles.column)}>
                        <h2 className={clsx("fontWeight_bold", styles.title)}>{props.title?.toLocaleUpperCase()}</h2>
                        <div className={clsx(styles.text)}>
                            {props.text}
                            <div className={styles.textGradient} />
                        </div>
                    </div>
                    <div className={clsx(styles.btnSeeMore, "flex_column")}>
                        <div className={clsx(styles.btn)}>{t("news.seeMore")} </div>
                    </div>
                </div>
            </a>
        </div>
    );
}
