import React from "react";
import { ProjectFirstStepForm } from "projects/ProjectFirstStepForm";
import { ProjectSecondStepForm } from "projects/ProjectSecondStepForm";
import { ProjectThirdStepForm } from "projects/ProjectThirdStepForm";
import { ProjectFourthStepForm } from "projects/ProjectFourthStepForm";
import styles from "./_css/formProject.module.css";
import { TPropertyMdl } from "properties/_models/PropertyMdl";
import clsx from "clsx";

type Props = {
    stepSelected: number;
    property: TPropertyMdl;
    setProperty: (value: TPropertyMdl) => void;
};

export function ProjectForm(props: Props) {
    return (
        <div
            className={clsx(styles.content, {
                [styles.contentOverflowVisible]: props.stepSelected === 1,
                [styles.contentOverflowVisible]: props.stepSelected === 2,
                [styles.contentOverflowScroll]: props.stepSelected === 3,
                [styles.contentOverflowScroll]: props.stepSelected === 4,
            })}
        >
            <ProjectFirstStepForm stepSelected={props.stepSelected} property={props.property} />
            <ProjectSecondStepForm stepSelected={props.stepSelected} />
            <ProjectThirdStepForm stepSelected={props.stepSelected} />
            <ProjectFourthStepForm
                stepSelected={props.stepSelected}
                property={props.property}
                setProperty={props.setProperty}
            />
        </div>
    );
}
