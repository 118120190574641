import React from "react";
import CurrencyInput from "react-currency-input-field";
import styles from "./_css/inputNumberProjectPage.module.css";

type Props = {
    label?: string;
    value: number;
    onValueChange: (value: number) => void;
    extralabel?: string;
};

export function InputNumberProjectPage(props: Props) {
    return (
        <div className={"flex_row"}>
            {props.label && <div className={styles.label}> {props.label} </div>}
            <CurrencyInput
                className={styles.input}
                value={props.value}
                onValueChange={(value, _name) => {
                    if (typeof value === "string") {
                        props.onValueChange(parseInt(value));
                    }
                }}
                suffix={props.extralabel ? " " + props.extralabel : undefined}
            />
        </div>
    );
}
