import React, { lazy } from "react";
import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/featuredProperties.module.css";
import Suspenser from "_common/loaders/Suspenser";
import { PropertyCardLoader } from "properties/PropertyCardLoader";

const LazyFeaturedPropertiesSections = lazy(() => import("home/sections/FeaturedPropertiesSections"));
export const FeaturedPropertiesHomePage = () => {
    const { t } = useTranslation();
    return (
        <Container className={styles.container}>
            <h2 className={styles.title}>{t("propertyPage.featuresPropertiesHomePage")}</h2>
            <Suspenser
                fallback={
                    <div className={clsx(styles.placeholderContainer, "flex_row justifyContent_spaceBetween")}>
                        <PropertyCardLoader />
                        <PropertyCardLoader />
                        <PropertyCardLoader />
                    </div>
                }
            >
                <LazyFeaturedPropertiesSections purpose={"BOTH"} />
            </Suspenser>
        </Container>
    );
};
