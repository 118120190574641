import { action, observable } from "mobx";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { unitsStore } from "units/_stores/unitsStore";

class ComparisonStore {
    @observable properties: string[] = [];
    @observable units: string[] = [];
    @observable allPropertiesLoadedLoadingState: LoadingStateMdl<boolean> = new LoadingStateMdl<boolean>();
    @observable allUnitsLoadedLoadingState: LoadingStateMdl<boolean> = new LoadingStateMdl<boolean>();
    @observable propertyIdsLoadingState: { [key: string]: LoadingStateMdl<TPropertyListingMdl> } = {};
    @observable unitIdsLoadingState: { [key: string]: LoadingStateMdl<TUnitListingMdl> } = {};
    @observable lastItemUpdated: MAIN_RESOURCE = MAIN_RESOURCE.PROPERTIES;
    @observable overlayIsDisplay = false;

    constructor() {
        if (__BROWSER__) {
            this.init();
        }
    }

    @action
    async fetchProperties() {
        if (!this.allPropertiesLoadedLoadingState.isLoading) {
            this.allPropertiesLoadedLoadingState.startLoading();
            let allPropertiesLoaded = true;
            for (const propertyId of this.properties) {
                if (!this.propertyIdsLoadingState?.[propertyId]) {
                    this.propertyIdsLoadingState[propertyId] = new LoadingStateMdl<TPropertyListingMdl>();
                }
                if (
                    !this.propertyIdsLoadingState[propertyId].isSucceeded &&
                    !this.propertyIdsLoadingState[propertyId].isLoading
                ) {
                    this.propertyIdsLoadingState[propertyId].startLoading();
                    const property = await propertiesStore.getAsync(propertyId);
                    if (!property) {
                        this.propertyIdsLoadingState?.[propertyId].setError({ key: "no results" });
                        allPropertiesLoaded = false;
                        break;
                    }
                    this.propertyIdsLoadingState?.[propertyId].setSuccess(property);
                }
            }
            if (allPropertiesLoaded) this.allPropertiesLoadedLoadingState.setSuccess(true);
            else this.allPropertiesLoadedLoadingState.setError({ key: "no results" });
        }
        return this.allPropertiesLoadedLoadingState;
    }

    @action
    async fetchUnits() {
        if (!this.allUnitsLoadedLoadingState.isLoading) {
            this.allUnitsLoadedLoadingState.startLoading();
            let allUnitsLoaded = true;
            for (const unitId of this.units) {
                if (!this.unitIdsLoadingState?.[unitId]) {
                    this.unitIdsLoadingState[unitId] = new LoadingStateMdl<TUnitListingMdl>();
                }
                if (!this.unitIdsLoadingState[unitId].isSucceeded && !this.unitIdsLoadingState[unitId].isLoading) {
                    this.unitIdsLoadingState[unitId].startLoading();
                    const unit = await unitsStore.getAsync(unitId);
                    if (!unit) {
                        this.unitIdsLoadingState?.[unitId].setError({ key: "no results" });
                        allUnitsLoaded = false;
                        break;
                    }
                    this.unitIdsLoadingState?.[unitId].setSuccess(unit);
                }
            }
            if (allUnitsLoaded) this.allUnitsLoadedLoadingState.setSuccess(true);
            else this.allUnitsLoadedLoadingState.setError({ key: "no results" });
        }
        return this.allUnitsLoadedLoadingState;
    }

    @action addItem(type: MAIN_RESOURCE, itemId: string) {
        switch (type) {
            case MAIN_RESOURCE.UNITS:
                if (this.units.includes(itemId)) return;
                this.units.push(itemId);
                this.lastItemUpdated = MAIN_RESOURCE.UNITS;
                break;
            case MAIN_RESOURCE.PROPERTIES:
                if (this.properties.includes(itemId)) return;
                this.properties.push(itemId);
                this.lastItemUpdated = MAIN_RESOURCE.PROPERTIES;
                break;
        }
        this.save();
    }

    @action deleteItem(type: MAIN_RESOURCE, itemId: string) {
        switch (type) {
            case MAIN_RESOURCE.UNITS:
                if (!this.units.includes(itemId)) return;
                this.units.splice(this.units.indexOf(itemId), 1);
                this.units.length === 0
                    ? (this.lastItemUpdated = MAIN_RESOURCE.PROPERTIES)
                    : (this.lastItemUpdated = MAIN_RESOURCE.UNITS);
                break;
            case MAIN_RESOURCE.PROPERTIES:
                if (!this.properties.includes(itemId)) return;
                this.properties.splice(this.properties.indexOf(itemId), 1);
                this.properties.length === 0
                    ? (this.lastItemUpdated = MAIN_RESOURCE.UNITS)
                    : (this.lastItemUpdated = MAIN_RESOURCE.PROPERTIES);
                break;
        }
        this.save();
    }

    @action deleteItems(type: MAIN_RESOURCE) {
        switch (type) {
            case MAIN_RESOURCE.UNITS:
                this.units = [];
                break;
            case MAIN_RESOURCE.PROPERTIES:
                this.properties = [];
                break;
        }
        this.save();
    }

    @action showItemTypeOverlay(type: MAIN_RESOURCE) {
        return (this.lastItemUpdated = type);
    }

    @action setOverlayIsDisplayed(boolean: boolean) {
        this.overlayIsDisplay = boolean;
    }

    private save() {
        if (localStorage.getItem("compareItems")) localStorage.removeItem("compareItems");
        localStorage.setItem("compareItems", JSON.stringify({ properties: this.properties, units: this.units }));
    }

    private init() {
        const itemsString = localStorage.getItem("compareItems");
        if (itemsString) {
            const items = JSON.parse(itemsString);
            this.properties = items.properties;
            this.units = items.units;
        }
    }
}

const comparisonStore = new ComparisonStore();
export { comparisonStore };
