import React, { PropsWithChildren } from "react";
import styles from "./_css/filePreviewFormProjectPage.module.css";
import { TImage } from "_common/types/baseTypes";
import Cross from "../component/crossFormProjectPage.svg";

type Props = {
    url: string;
    onDelete?: () => void;
    file?: TImage;
};

export function FilePreviewFormProjectPage(props: PropsWithChildren<Props>) {
    const splitFile = props.file?.url.split("/");
    const fileName = splitFile ? splitFile[splitFile.length - 1].substring(0, 20) : "";
    return (
        <div className={styles.container}>
            <div className={styles.title}>{fileName}</div>
            {props.onDelete && (
                <div
                    className={styles.delete}
                    onClick={(event) => {
                        event.stopPropagation();
                        props.onDelete?.();
                    }}
                >
                    <img src={Cross} height={12} width={12} alt={"cross"} />
                </div>
            )}
        </div>
    );
}
