import React, { ReactNode } from "react";
import { Spinner } from "_common/loaders/Spinner";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

type Props = {
    text?: string;
    className?: string;
    noSpinner?: boolean;
    noText?: boolean;
    contentRender?: ReactNode;
};

export function Loader(props: Props) {
    const { t } = useTranslation();
    if (props.noSpinner && props.noText) return null;
    if (props.contentRender) return <>{props.contentRender}</>;
    return (
        <div className={clsx(props.className, "flex_center_center p_20 flex_column")}>
            {!props.noSpinner && <Spinner />}
            {!props.noText && (
                <div className="mt_40 text_small_semiBold textAlign_center">{props.text ?? t("loaders.message")}</div>
            )}
        </div>
    );
}
