import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { TPlacementListingLocalizedMdl } from "placements/_models/PlacementsMdl";
import styles from "./_css/placementSliderCard.module.css";
import { Price } from "components/propertyOrUnit/Price";

type Props = {
    placement: TPlacementListingLocalizedMdl;
};

export function PlacementSliderCard({ placement }: Props) {
    const { t } = useTranslation();
    if (!placement?.property) return null;
    return (
        <div>
            <div className={clsx("flex_row_center", styles.card)}>
                <div className={"flex_column flex-1"}>
                    <div className={styles.content}>
                        <div>
                            {placement.property.priceIsNotDisplayed ? (
                                <div className={styles.price}>{t("propertiesPage.card.priceOnRequest")}</div>
                            ) : (
                                <Price
                                    priceSmall
                                    fromSmall
                                    capitalize
                                    color={"blue"}
                                    purpose={placement.property.purpose}
                                    price={placement.property.price.min}
                                    withTaxes={placement.property.taxIsIncluded}
                                    className={"flex_row flexWrap_wrap"}
                                />
                            )}
                        </div>
                        <div className={clsx(styles.title, "mt_10")}>{placement.property?.localized.title}</div>
                        <div className={clsx(styles.address, "mt_10")}>
                            {placement.property.address.city + "," + placement.property.address.province}
                        </div>
                    </div>
                </div>
                <div className={clsx("flex-1  flex justifyContent_flexEnd", styles.img)}>
                    <LazyLoadImage
                        className={styles.img}
                        src={placement.property.photos?.[0]?.url}
                        alt={placement.property.photos?.[0]?.alt}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "https://via.placeholder.com/300?text=Vistoo.com";
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
