import {
    PACKAGE_KEY,
    PROPERTY_PURPOSE,
    PROPERTY_TYPE,
    TAddressMdl,
    TLocation,
    TPropertyListingMdl,
} from "properties/_models/PropertyMdl";
import { TMeta } from "pages/_models/PageMdl";
import { Dayjs } from "dayjs";
import { TGoogleLocation } from "maps/Map";
import { TLang } from "_configs/sharedConfig";

export enum GEO_ZONE_TYPE {
    PROVINCE = "PROVINCE",
    CITY = "CITY",
    NEIGHBORHOOD = "NEIGHBORHOOD",
}

export type TGeoZoneBaseMdl = {
    _id: string;
    osmId?: string;
    name: string;
    slug: string;
    address: TAddressMdl;
    location: TLocation;
    zoneLocations: TGoogleLocation[];
    type?: GEO_ZONE_TYPE;
    isRegion?: boolean;
    linkedRegionId?: string;
    parentId?: string;
    premium?: {
        [purpose in PROPERTY_PURPOSE]: {
            [packageKey in PACKAGE_KEY]: TPropertyListingMdl[];
        } & { none: TPropertyListingMdl[] };
    };
    variant: number;
    updatedAt?: Dayjs;
    createdAt?: Dayjs;
};

export type TFaq = {
    question: string;
    answer: string;
};

export interface IGeoZoneLocalized {
    localized: {
        [lang in TLang]: {
            [purpose in PROPERTY_PURPOSE]: {
                [propertyType in PROPERTY_TYPE]: {
                    meta: TMeta;
                    text: string;
                    title: string;
                    faq: TFaq[];
                    srps: {
                        highlights: string;
                        advanced: string;
                        faq: string;
                    };
                };
            } & {
                meta: TMeta;
                text: string;
                title: string;
                faq: TFaq[];
                srps: {
                    highlights: string;
                    advanced: string;
                    faq: string;
                };
            };
        };
    };
}

export interface TGeoZoneMdl extends TGeoZoneBaseMdl {
    localized: {
        [lang in TLang]: {
            [purpose in PROPERTY_PURPOSE]: {
                [propertyType in PROPERTY_TYPE]: {
                    meta: TMeta;
                    text: string;
                    title: string;
                    faq: TFaq[];
                    srps: {
                        highlights: string;
                        advanced: string;
                        faq: string;
                    };
                };
            } & {
                meta: TMeta;
                text: string;
                title: string;
                faq: TFaq[];
                srps: {
                    highlights: string;
                    advanced: string;
                    faq: string;
                };
            };
        };
    };
}

export type TGeoZoneInfosMdl = Pick<TGeoZoneMdl, "_id" | "linkedRegionId" | "address" | "name" | "isRegion" | "type">;

const defaultLocalized = {};
for (const purpose of Object.keys(PROPERTY_PURPOSE)) {
    // @ts-expect-error
    if (!defaultLocalized[purpose]) {
        // @ts-expect-error
        defaultLocalized[purpose] = {
            meta: { title: "", description: "", faq: [] },
            text: "",
            title: "",
            faq: [],
            srps: {
                highlights: "",
                advanced: "",
                faq: "",
            },
        };
    }
    for (const propertyType of Object.keys(PROPERTY_TYPE)) {
        // @ts-expect-error
        if (!defaultLocalized[purpose][propertyType]) {
            // @ts-expect-error
            defaultLocalized[purpose][propertyType] = {
                meta: { title: "", description: "", faq: [] },
                text: "",
                title: "",
                faq: [],
                srps: {
                    highlights: "",
                    advanced: "",
                    faq: "",
                },
            };
        }
    }
}

export const defaultGeoZone: Partial<TGeoZoneMdl> = {
    osmId: "",
    name: "",
    slug: "",
    address: {
        city: "",
        province: "",
        neighbourhood: "",
    },
    location: {
        type: "Point",
        coordinates: [-73.5617, 45.5089],
    },
    type: GEO_ZONE_TYPE.CITY,
    zoneLocations: [],
    localized: {
        // @ts-expect-error
        en: defaultLocalized,
        // @ts-expect-error
        fr: defaultLocalized,
        // @ts-expect-error
        es: defaultLocalized,
        // @ts-expect-error
        zh: defaultLocalized,
    },
};
