import React from "react";
import clsx from "clsx";
import styles from "./_css/label.module.css";
import { useTranslation } from "react-i18next";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";

type Props = {
    purpose?: PROPERTY_PURPOSE;
    className?: string;
    bgColor?: string;
    label?: string;
    textColor?: string;
};

export function Label(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={clsx("flex_row", props.className)}>
            <div
                className={clsx(styles.default, {
                    [styles.RENT]: props.purpose === PROPERTY_PURPOSE.RENT,
                    [styles.BUY]: props.purpose === PROPERTY_PURPOSE.BUY,
                })}
                style={{ backgroundColor: props.bgColor, color: props.textColor ?? "white" }}
            >
                {props.label ?? t("propertyPage." + props.purpose)}
            </div>
        </div>
    );
}
