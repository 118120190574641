import { action, computed, observable } from "mobx";
import { PAYMENT_TYPE, PRICING_ZONE, PRODUCT_NAME, TStripeProductListingMdl } from "payments/_models/paymentStripeMdl";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import sharedConfig from "_configs/sharedConfig";
import { fetchUtils } from "_common/_utils/fetchUtils";
import { getResourceInitialStateValue } from "_common/_utils/initialStateUtils";
import { Stripe } from "stripe";

export const MAIN_PRODUCTS = [
    sharedConfig.stripe.products.ELITE,
    sharedConfig.stripe.products.PREMIUM,
    sharedConfig.stripe.products.ESSENTIEL,
    sharedConfig.stripe.products.BASE,
];

export const OPTION_PRODUCTS = [
    sharedConfig.stripe.products.MANDARIN_TRANSLATE,
    sharedConfig.stripe.products.SPANISH_TRANSLATE,
    sharedConfig.stripe.products.VBOOST_INFOLETTRE,
    sharedConfig.stripe.products.SEM,
    sharedConfig.stripe.products.SOCIAL_NETWORK,
    // sharedConfig.stripe.products.INTEGRATION_RENTSYNC,
    sharedConfig.stripe.products.INTEGRATION_PLANPOINT,
    sharedConfig.stripe.products.INTEGRATION_CRM,
];

class PaymentStore {
    @observable pricingZone = PRICING_ZONE.CA;
    @observable products: TStripeProductListingMdl[] = [];
    @observable productState: LoadingStateMdl<TStripeProductListingMdl[] | undefined> = new LoadingStateMdl<
        TStripeProductListingMdl[] | undefined
    >();

    constructor() {
        this.onInit();
    }

    @computed get mainProducts(): TStripeProductListingMdl[] {
        return this.products
            .filter((product) => MAIN_PRODUCTS.includes(product.id))
            .sort((productA, productB) => MAIN_PRODUCTS.indexOf(productA.id) - MAIN_PRODUCTS.indexOf(productB.id));
    }

    @computed get options(): TStripeProductListingMdl[] {
        return this.products
            .filter((product) => OPTION_PRODUCTS.includes(product.id))
            .sort((optionA, optionB) => OPTION_PRODUCTS.indexOf(optionA.id) - OPTION_PRODUCTS.indexOf(optionB.id));
    }

    getPriceFromProductRegionAndRecurring(
        product: TStripeProductListingMdl,
        recurring = PAYMENT_TYPE.RECURRING,
    ): Stripe.Price {
        const price = product.prices.find(
            (price) =>
                price.id ===
                sharedConfig.stripe.priceOfProducts[this.pricingZone]?.[product.name.toLowerCase() as PRODUCT_NAME]?.[
                    recurring
                ],
        );
        return price ?? product.prices[0];
    }

    listProducts() {
        if (!this.productState.isLoading && !this.productState.isSucceeded) {
            this.productState.startLoading();
            const url = sharedConfig.apiUrl + "/stripes/listProducts";

            const promise = fetchUtils.get<TStripeProductListingMdl[]>(url);

            promise
                .then(({ data }) => {
                    this.products = data;
                    this.productState.setSuccess(data);
                })
                .catch((e) => console.error(e));
        }
    }

    async fetchProductsAndPrices() {
        this.listProducts();
    }

    @action setPricingZone(value: PRICING_ZONE) {
        this.pricingZone = value;
    }
    onInit() {
        const initialProducts = getResourceInitialStateValue("products") as { items: TStripeProductListingMdl[] };
        if (initialProducts?.items.length) {
            this.products = initialProducts.items;
            this.productState.setSuccess(initialProducts.items);
        }
    }
}

const paymentStore = new PaymentStore();
export default paymentStore;
