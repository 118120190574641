import React from "react";
import clsx from "clsx";
import styles from "./_css/galleryPageTabsButtons.module.css";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { toJS } from "mobx";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { GALLERY_TABS } from "properties/gallery/PropertyOrUnitGalleryPage";
import { UnitStore } from "units/_stores/UnitStore";
import { PropertyStore } from "properties/_stores/PropertyStore";
import { useTranslation } from "react-i18next";

type Props = {
    item: TUnitListingMdl | TPropertyListingMdl;
    store: UnitStore | PropertyStore;
    isUnit: boolean;
};

export function GalleryPageTabsButtons({ item, store, isUnit }: Props) {
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.tabs, "flex_row")}>
            {Object.values(GALLERY_TABS).map((tab) => {
                if ((tab === GALLERY_TABS.TOUR_3D || tab === GALLERY_TABS.VIDEO) && !item?.[tab]) {
                    return null;
                }
                if (tab === GALLERY_TABS.STREET_VIEW && (item as TPropertyListingMdl)?.isStreetViewDisabled) {
                    return null;
                }
                if (
                    Array.isArray(toJS(item[tab as keyof (TUnitListingMdl | TPropertyListingMdl)])) &&
                    item[tab as keyof (TUnitListingMdl | TPropertyListingMdl)].length === 0
                ) {
                    return null;
                }
                return (
                    <div
                        key={tab}
                        className={clsx(styles.tab, {
                            [styles.tabSelected]: store.openedGalleryTab === tab,
                        })}
                        onClick={() => {
                            store.setOpenedGalleryTab(tab);
                        }}
                    >
                        {t(`${isUnit ? "unitPage" : "propertyPage"}.gallery.tabs.${tab}`)}
                    </div>
                );
            })}
        </div>
    );
}
