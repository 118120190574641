import { i18nextInstance } from "_common/i18n/IntlProvider";

export const DASHBOARD_PATH = "/dashboard";

export const DASHBOARD_URLS = {
    myListing: (lang: string) =>
        `/${lang || i18nextInstance.language}${DASHBOARD_PATH}/${i18nextInstance.t("routes.myListing")}`,
    promotion: (lang: string) =>
        `/${lang || i18nextInstance.language}${DASHBOARD_PATH}/${i18nextInstance.t("routes.promotion")}`,
    builderProfile: (lang: string) =>
        `/${lang || i18nextInstance.language}${DASHBOARD_PATH}/${i18nextInstance.t("routes.builderProfile")}`,
    developerNotifications: (lang: string) =>
        `/${lang || i18nextInstance.language}${DASHBOARD_PATH}/${i18nextInstance.t("routes.developerNotifications")}`,
    home: (lang?: string) => `/${lang || i18nextInstance.language}${DASHBOARD_PATH}`,
};
