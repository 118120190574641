import React from "react";
import styles from "home/sections/_css/cityCard.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { TPopularCity } from "_configs/popularCitiesList";
import i18next from "i18next";
import { TLang } from "_configs/sharedConfig";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
    city: TPopularCity;
};

export function CityCard(props: Props) {
    const { t } = useTranslation();
    const lang = i18next.language;
    return (
        <div className={"position_relative m_10"}>
            <LazyLoadImage src={props.city.image} alt={"popularCity"} className={styles.img} />
            <div className={clsx("position_absolute", styles.content)}>
                <div className={styles.contentTitle}>
                    <div className={clsx("mb_20", styles.title, styles.subContent)}>
                        {props.city.name.toLocaleUpperCase()}
                    </div>
                </div>
                <div className={clsx(styles.subContent, "flex_row_center justifyContent_center")}>
                    <a className={clsx("mr_10 pr_10 pl_10", styles.btn)} href={props.city.urlSale(lang as TLang)}>
                        {t("homePage.popularCities.forSale")}
                    </a>
                    <a className={clsx("ml_10 pr_10 pl_10", styles.btn)} href={props.city.urlRent(lang as TLang)}>
                        {t("homePage.popularCities.forRent")}
                    </a>
                </div>
            </div>
        </div>
    );
}
