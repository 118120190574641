import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { DialogProps } from "@material-ui/core/Dialog/Dialog";
import { UiButton } from "shared/_common/ui/mui/buttons/UiButton";
import styles from "./_css/confirmDialog.module.css";

type Props = Omit<DialogProps, "children"> & {
    title?: string;
    message: string;
    agreeLabel: string;
    onAgree: () => void;
    disagreeLabel: string;
    onDisagree: () => void;
    isDeleteButton?: boolean;
};

export function ConfirmationDialog({
    title,
    message,
    agreeLabel,
    onAgree,
    disagreeLabel,
    onDisagree,
    isDeleteButton,
    ...dialogProps
}: Props) {
    return (
        <Dialog {...dialogProps} className={styles.container}>
            {title && <DialogTitle className={styles.title}>{title}</DialogTitle>}
            <DialogContent>
                <DialogContentText className={styles.text}>{message}</DialogContentText>
            </DialogContent>
            <DialogActions className={styles.actions}>
                <UiButton className={styles.btn} onClick={onDisagree}>
                    {disagreeLabel}
                </UiButton>
                <UiButton
                    className={styles.btn}
                    onClick={onAgree}
                    variant="contained"
                    color="primary"
                    isDeleteButton={isDeleteButton}
                >
                    {agreeLabel}
                </UiButton>
            </DialogActions>
        </Dialog>
    );
}
