import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { action, computed, observable } from "mobx";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { GALLERY_TABS } from "properties/gallery/PropertyOrUnitGalleryPage";

export class UnitStore {
    @observable unit: TUnitListingMdl;
    @observable isGalleryOpened = -1;
    @observable openedGalleryTab = GALLERY_TABS.GALLERY;
    @observable imageModalIsOpen = -1;
    @observable typeOfImage: "photos" | "floorPlans" = "photos";

    constructor(unit: TUnitListingMdl) {
        this.unit = unit;
    }

    @computed get isForBuyingPurpose() {
        return this.unit.property.purpose === PROPERTY_PURPOSE.BUY;
    }

    @computed get isForRentingPurpose() {
        return this.unit.property.purpose === PROPERTY_PURPOSE.RENT;
    }

    @action setOpenedImageModal(typeOfImage: "photos" | "floorPlans", imageIndex?: number) {
        this.typeOfImage = typeOfImage;
        this.imageModalIsOpen = imageIndex ?? 0;
    }

    @action closeImageModal() {
        this.imageModalIsOpen = -1;
    }

    @action openGallery(imageIdx = 0, tabName = GALLERY_TABS.GALLERY) {
        this.setOpenedGalleryTab(tabName);
        this.isGalleryOpened = imageIdx;
    }

    @action setOpenedGalleryTab(tabName = GALLERY_TABS.GALLERY) {
        this.openedGalleryTab = tabName;
    }

    closeGallery() {
        this.isGalleryOpened = -1;
    }
}
