import React, { CSSProperties, useRef } from "react";
import clsx from "clsx";
import styles from "./_css/newsCardComponent.module.css";
import { TComponent } from "pages/_models/PageMdl";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import sharedConfig, { TLang } from "_configs/sharedConfig";

export type NewsCardComponentProps = {
    date?: Dayjs | string;
    title?: string;
    text?: string;
    image?: string;
    url?: string;
    style?: CSSProperties;
    $component?: TComponent;
};

export function NewsCardComponent(props: NewsCardComponentProps) {
    const mouseDownStart = useRef(0);
    const { t } = useTranslation();
    const lang = i18next.language;
    const date = typeof props.date === "string" ? dayjs(props.date) : props.date;
    return (
        <a
            style={props.style}
            className={styles.container}
            onMouseDown={() => (mouseDownStart.current = Date.now())}
            href={sharedConfig.languages[lang as TLang].baseUrl + "/" + t("routes.blog") + props.url}
        >
            <div className={styles.content}>
                <div className={clsx(styles.image, "pt_20")} style={{ backgroundImage: `url(${props.image})` }}>
                    {date && <span className={clsx(styles.date, "mh_20")}>{date.format("DD/MM/YYYY")}</span>}
                </div>
                <h2 className={"ph_20 pt_20 fontWeight_bold"}>{props.title}</h2>
                <div className={clsx(styles.text, "ph_20")}>{props.text}</div>
                <div className={clsx(styles.readMore, "flex_column justifyContent_center fontWeight_bold ml_20")}>
                    <div className="flex_row_center">
                        <div className={styles.textGradient} />
                        {t("news.seeMore")} <i className="icon-arrow-right1" />
                    </div>
                </div>
            </div>
        </a>
    );
}
