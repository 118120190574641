import React, { forwardRef, PropsWithChildren } from "react";
import { ButtonProps } from "@material-ui/core";
import { UiButton } from "_common/ui/mui/buttons/UiButton";

type ButtonNotificationProps = ButtonProps & {};

const ButtonNotification = forwardRef<HTMLDivElement, ButtonNotificationProps>(
    (props: PropsWithChildren<ButtonNotificationProps>, ref) => {
        return (
            <UiButton {...props} ref={ref}>
                {props.children}
            </UiButton>
        );
    },
);

export { ButtonNotification };
