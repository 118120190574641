import React, { CSSProperties } from "react";
import styles from "./_css/ctaSection.module.css";
import { Container } from "@material-ui/core";
import clsx from "clsx";
import { TComponent } from "pages/_models/PageMdl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ChildrenComponentRenderer } from "components/ChildrenComponentRenderer";

type Props = {
    bgTitle: string;
    bgSrc: string;
    children?: TComponent[];
    className?: string;
    style?: CSSProperties;
    $component: TComponent;
};

export function CtaSection({ children, bgSrc, bgTitle, className, $component, ...props }: Props) {
    return (
        <div className="position_relative">
            <LazyLoadImage alt={bgTitle} className={styles.picture} src={bgSrc} />
            <div {...props} className={clsx(styles.section, className)}>
                <Container className={clsx("flex_column_center", styles.container)}>
                    <ChildrenComponentRenderer propName="children" parent={$component}>
                        {children ?? []}
                    </ChildrenComponentRenderer>
                </Container>
            </div>
        </div>
    );
}
