import React, { CSSProperties, useState } from "react";
import { TComponent } from "pages/_models/PageMdl";
import { ChildrenComponentRenderer } from "components/ChildrenComponentRenderer";

type Props = {
    text?: string;
    style?: CSSProperties;
    children: TComponent[];
    $component: TComponent;
};

export function CollapsedText({ children, $component, ...props }: Props) {
    const [opened, setOpened] = useState(false);
    return (
        <div {...props}>
            <div className="mb_10" onClick={() => setOpened(!opened)} style={{ cursor: "pointer" }}>
                {props.text ?? ""}
            </div>
            <div hidden={!opened}>
                <ChildrenComponentRenderer propName="children" parent={$component}>
                    {children ?? []}
                </ChildrenComponentRenderer>
            </div>
        </div>
    );
}
