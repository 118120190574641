import React, { RefObject, useImperativeHandle, useRef, useState } from "react";
import clsx from "clsx";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import styles from "./_css/priceFilter.module.css";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { observer } from "mobx-react";
import { ListStore } from "_common/list/ListStore";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { getIsCommercialFilter, updateSearchFilter } from "properties/searchBar/filters/_utils/filtersUtils";
import { FilterButtons } from "properties/searchBar/filters/FilterButtons";
import { fetchSearchProperties } from "_common/_utils/searchUtils";
import { ExposedFiltersFunctions } from "properties/searchBar/MobileFilters";
import { useClickOutside } from "_common/_utils/hookUtils";
import { InputFiltersAutocomplete } from "properties/searchBar/filters/InputFiltersAutocomplete";
import { PRICE_DATA, priceRangeToFilters } from "properties/searchBar/filters/_utils/priceUtils";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { TOpItems } from "admin/_common/filters/OpPicker";
import { Checkbox } from "@material-ui/core";

type Props = {
    filter: {
        key: string;
        labelKey: string;
    };
    onFiltered?: (isFiltered: boolean) => void;
    listStore: ListStore<TPropertyListingMdl>;
};

const _PriceFilter = React.forwardRef<ExposedFiltersFunctions, Props>((props: Props, ref) => {
    const propertiesStore = usePropertiesStore();
    const history = useHistory();
    const { t } = useTranslation();
    const listStore = props.listStore;
    const defaultMin = listStore.filters?.[listStore.getFilterIndex(`${props.filter.key}.max`)]?.value
        ? (listStore.filters?.[listStore.getFilterIndex(`${props.filter.key}.max`)]?.value / 100).toString()
        : "";
    const defaultMax = listStore.filters?.[listStore.getFilterIndex(`${props.filter.key}.min`)]?.value
        ? (listStore.filters?.[listStore.getFilterIndex(`${props.filter.key}.min`)]?.value / 100).toString()
        : "";
    const defaultPriceIsNotDisplayed =
        listStore.filters?.[listStore.getFilterIndex("priceIsNotDisplayed")]?.value ?? true;
    const [min, setMin] = useState<string>(defaultMin);
    const [max, setMax] = useState<string>(defaultMax);
    const [showPriceIsNotDisplayed, setShowPriceIsNotDisplayed] = useState<boolean>(defaultPriceIsNotDisplayed);
    const isCommercial = getIsCommercialFilter(listStore.filters);
    const applyFilters = () => {
        if (defaultMin === min && defaultMax === max && showPriceIsNotDisplayed === defaultPriceIsNotDisplayed) return;

        listStore.removeFilterByFilterId(`price.max`);
        listStore.removeFilterByFilterId(`priceIsNotDisplayed`);
        listStore.removeFilterByFilterId(`price.min`);
        const filterPrice = priceRangeToFilters({
            min,
            max,
            filterKey: props.filter.key,
        });
        if (!showPriceIsNotDisplayed) {
            filterPrice.push({
                id: "priceIsNotDisplayed",
                type: TFilterType.BOOLEAN,
                value: false,
                op: "eq" as TOpItems,
            });
        }
        updateSearchFilter(listStore, filterPrice).then(() => {
            fetchSearchProperties(propertiesStore, props.listStore, history);
        });
    };

    const resetFilters = () => {
        setMin("");
        setMax("");
        applyFilters();
    };

    useImperativeHandle(
        ref,
        () => ({
            applyFilters,
            resetFilters,
        }),
        [min, max],
    );

    const containerRef: RefObject<HTMLDivElement> = useRef(null);
    useClickOutside(
        containerRef,
        () => {
            setTimeout(() => {
                applyFilters();
            }, 100);
        },
        "MuiAutocomplete-option",
    );

    if (!propertiesStore.purpose) return null;
    return (
        <div id={"contentSliderRange"} className={clsx(styles.container, "position_relative p_10")} ref={containerRef}>
            <div className={"p_10"}>{t("searchBar.filter.price")}</div>
            <div className={clsx(styles.inputContainer, "flex_row_center gap_10 ph_5 pb_5")}>
                <InputFiltersAutocomplete
                    value={min}
                    onChange={(value) => setMin(value)}
                    options={PRICE_DATA[propertiesStore.purpose][isCommercial ? "commercial" : "default"].min.filter(
                        (data) => !data.value || !max || parseInt(data.value) <= parseInt(max),
                    )}
                    startAdornment={<span className={"text_small fontWeight_normal"}>$</span>}
                    placeholder={"No min"}
                />
                <InputFiltersAutocomplete
                    value={max}
                    onChange={(value) => setMax(value)}
                    options={PRICE_DATA[propertiesStore.purpose][isCommercial ? "commercial" : "default"].max.filter(
                        (data) => !data.value || !min || parseInt(data.value) >= parseInt(min),
                    )}
                    startAdornment={<span className={"text_small fontWeight_normal"}>$</span>}
                    placeholder={"No max"}
                />
            </div>
            <div
                className={clsx("flex_row_center cursor_pointer text_xsmall")}
                onClick={() => setShowPriceIsNotDisplayed(!showPriceIsNotDisplayed)}
            >
                <Checkbox className={styles.check} checked={showPriceIsNotDisplayed} />
                <div>{t(`searchBar.filter.showPriceIsNotDisplayed`)}</div>
            </div>
            <FilterButtons className={"mt_20"} onReset={resetFilters} onApply={applyFilters} />
        </div>
    );
});

export const PriceFilter = observer(_PriceFilter);
