import React, { useState } from "react";
import styles from "./_css/formProject.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { InputTextFront } from "payments/_components/InputTextFront";
import { SelectEnumFormProject } from "projects/component/SelectEnumFormProject";
import { InputTextFrontProjectForm } from "payments/_components/InputTextFrontProjectForm";
import { InputTextFrontDoubleProjectPage } from "payments/_components/InputTextFrontDoubleProjectForm";
import dayjs from "dayjs";
import { InputDateFrontProjectForm } from "projects/component/InputDateFrontProjectForm";
import { CA_PROVINCES } from "_common/regions/regions";
import { useOrderContext } from "projects/OrderContext";
import { URLS } from "_configs/URLS";
import { useHistory } from "react-router";
import { refreshPage } from "_common/_utils/pageUtils";
import { ConfirmationDialog } from "_common/ui/dialogs/ConfirmationDialog";
import { PROPERTY_SUBTYPE, PROPERTY_TYPE, TPropertyMdl } from "properties/_models/PropertyMdl";
import { reformatLocalizedPropertyToProperty } from "_common/_utils/propertyUtils";
import { getErrorMessage } from "_common/errors/errorUtils";
import { toast } from "react-toastify";
import { propertiesStore } from "properties/_stores/propertiesStore";

type Props = {
    stepSelected: number;
    property: TPropertyMdl;
};

export function ProjectFirstStepForm(props: Props) {
    const { t } = useTranslation();
    const form = useFormContext();
    const { orderStore, selectedPropertyId } = useOrderContext();
    const history = useHistory();

    const [open, setOpen] = useState(false);
    const [propertyId, setPropertyId] = useState("");

    function handleSubmit(item: TPropertyMdl) {
        item = reformatLocalizedPropertyToProperty(item);
        if (!item.order) {
            item.order = props.property.order;
        }
        item = {
            ...item,
            _id: props.property._id,
            price: {
                min: item.price.min * 100,
                max: item.price?.max ? item.price?.max * 100 : 0,
            },
            type: item?.type ? item?.type : PROPERTY_TYPE.condo,
            subType: item?.subType ? item?.subType : PROPERTY_SUBTYPE.duplex,
            order: { isCompleted: false },
        };
        propertiesStore.editProperty(item).then(
            () => {
                history.push({
                    pathname: URLS.project(orderStore.order._id),
                    search: `?propertyId=${propertyId}`,
                });
            },
            (error) => toast.error(getErrorMessage(error)),
        );
    }

    const selectedProperty = orderStore.order.properties.find((property) => property._id === selectedPropertyId);

    return (
        <div
            className={clsx(styles.container, "p_40", {
                [styles.open]: props.stepSelected === 1,
                [styles.close]: props.stepSelected !== 1,
            })}
        >
            <div className={clsx(styles.header, "flex_row justifyContent_spaceBetween")}>
                <div className={clsx(styles.title, "mb_25")}> {t("projectPage.info")}</div>
                <div className={styles.SelectEnumFormProject}>
                    <SelectEnumFormProject
                        onValueChange={(propertyId: string) => {
                            setPropertyId(propertyId);
                            setOpen(true);
                        }}
                        label={t("projectPage.subscription")}
                        value={selectedPropertyId ?? orderStore.order.properties[0]._id}
                        defaultLabelValue={
                            selectedProperty?.localized.en.title ?? orderStore.order.properties[0].localized.en.title
                        }
                        enum={orderStore.order.properties.flatMap((property) => {
                            return property?.order?.isCompleted
                                ? []
                                : [
                                      {
                                          value: property._id,
                                          label: property.localized.en.title,
                                      },
                                  ];
                        })}
                        labelKey={""}
                    />
                </div>
            </div>
            <div className={styles.form}>
                <div className={clsx("flex_row justifyContent_spaceBetween ", styles.contentForm)}>
                    <div className={clsx("flex_column justifyContent_spaceBetween alignItems_center", styles.column)}>
                        <div className={styles.item}>
                            <Controller
                                control={form.control}
                                name={"localized.en.title"}
                                as={InputTextFrontProjectForm}
                                placeholder={t("projectPage.titleProperty")}
                                error={form.errors.localized?.en?.title}
                                rules={{ required: true }}
                                required
                            />
                        </div>
                        <Controller control={form.control} name={"localized[fr].title"} as={InputTextFront} hidden />
                        <Controller control={form.control} name={"localized[es].title"} as={InputTextFront} hidden />
                        <Controller control={form.control} name={"localized[zh].title"} as={InputTextFront} hidden />
                        <div className={styles.item}>
                            <Controller
                                control={form.control}
                                as={InputTextFrontProjectForm}
                                placeholder={t("admin.property.address")}
                                name="address.street"
                            />
                        </div>
                        <div className={styles.item}>
                            <Controller
                                control={form.control}
                                as={InputTextFrontProjectForm}
                                placeholder={t("address.neighbourhood")}
                                name="address.neighbourhood"
                            />
                        </div>
                        <div className={styles.item}>
                            <Controller
                                control={form.control}
                                as={InputTextFrontDoubleProjectPage}
                                placeholder={t("address.postalCode")}
                                name="address.postalCode"
                                type={"postalCode"}
                            />
                        </div>
                        <div className={clsx(styles.item, "mt_20")}>
                            <div className={styles.contentSelect}>
                                <Controller
                                    control={form.control}
                                    name={"address.province"}
                                    as={SelectEnumFormProject}
                                    label={t("address.province")}
                                    value={form.watch("address.province") ?? ""}
                                    onValueChange={(item: string) => form.setValue("address.province", item)}
                                    enum={Object.values(CA_PROVINCES)}
                                    labelKey={"provinces"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={clsx("flex_column justifyContent_spaceBetween", styles.column)}>
                        <div className={styles.item}>
                            <Controller
                                control={form.control}
                                name={"deliveryDates.0"}
                                defaultValue={dayjs().startOf("day")}
                                as={InputDateFrontProjectForm}
                                label={t("admin.property.deliveryDates")}
                            />
                        </div>

                        <div className={styles.item}>
                            <Controller
                                control={form.control}
                                as={InputTextFrontDoubleProjectPage}
                                placeholder={t("projectPage.courrielForLead")}
                                name="contacts[0].contactMail"
                                type={"email"}
                            />
                        </div>
                        <div className={styles.item}>
                            <Controller
                                control={form.control}
                                as={InputTextFrontDoubleProjectPage}
                                placeholder={t("projectPage.courrielForVistoo")}
                                name="contactMail"
                                type={"email"}
                            />
                        </div>
                        <div className={styles.item}>
                            <Controller
                                control={form.control}
                                as={InputTextFrontDoubleProjectPage}
                                placeholder={t("projectPage.phoneForVistoo")}
                                name="contactPhone"
                                error={form.errors.contactPhone}
                                type={"phone"}
                            />
                        </div>
                        <div className={styles.item}>
                            <Controller
                                control={form.control}
                                as={InputTextFrontDoubleProjectPage}
                                placeholder={t("projectPage.phoneForLead")}
                                name="contacts[0].contactPhone"
                                type={"phone"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationDialog
                title={t("projectPage.confirmTitle")}
                open={open}
                message={t("projectPage.confirmText")}
                agreeLabel={t("projectPage.confirm")}
                onAgree={form.handleSubmit(async (item: TPropertyMdl) => handleSubmit(item))}
                disagreeLabel={t("projectPage.cancel")}
                onDisagree={() => {
                    history.push({
                        pathname: URLS.project(orderStore.order._id),
                        search: `?propertyId=${propertyId}`,
                    });
                    refreshPage(URLS.project(orderStore.order._id) + `?propertyId=${propertyId}`);
                }}
            />
        </div>
    );
}
