import React from "react";
import { userStore } from "users/_stores/userStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { SavedSearchesList } from "users/profile/SavedSearchesList";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export function SavedSearchesPage() {
    if (!userStore.favoritesSearchesState.isSucceeded) userStore.fetchFavoritesSearches();
    const { t } = useTranslation();

    return (
        <Container className={"mb_40"}>
            <Breadcrumb currentItem={{ itemLabel: t("menu.savedSearch") }} />
            <div className={"mt_20"}>
                <LoadableFromLoading
                    loading={userStore.favoritesSearchesState}
                    renderer={(_status, error) => {
                        if (error) return null;
                        return <SavedSearchesList favorites={userStore.favoritesSearches} />;
                    }}
                />
            </div>
        </Container>
    );
}
