import React, { useState } from "react";
import styles from "./_css/headerPromotePage.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import imageBackground from "../assets/images/promotePage/image_maquette.webp";
import { PromoteFormModal } from "payments/_components/PromoteFormModal";

export function PromoteHeader() {
    const { t } = useTranslation();
    const [formModalIsOpen, setFormModalIsOpen] = useState(false);
    return (
        <div className={clsx("position_relative flex_center_center", styles.container)}>
            <div className={styles.overlay} />
            <img src={imageBackground} className={styles.image} alt={"imageBackground"} />
            <div className={clsx("flex_column_center justifyContent_spaceAround", styles.content)}>
                <div className={styles.titleContent}>{t("promotePage.titlePage")}</div>
                <div onClick={() => setFormModalIsOpen(true)} className={clsx("flex_center_center", styles.contentBtn)}>
                    {t("promotePage.contactUs")}
                </div>
            </div>
            <PromoteFormModal
                open={formModalIsOpen}
                onClose={() => {
                    setFormModalIsOpen(false);
                }}
            />
        </div>
    );
}
