import React from "react";
import clsx from "clsx";

type Props = {
    hidden: boolean;
    children: React.ReactNode;
};

export function PropertyGalleryTab(props: Props) {
    return <div className={clsx({ ["hidden"]: props.hidden })}>{props.children}</div>;
}
