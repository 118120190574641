import { DivComponent } from "components/base/div/DivComponent";
import { TitleComponent } from "components/base/title/TitleComponent";
import { TextComponent } from "components/base/text/TextComponent";
import { TComponentsConfigs } from "components/ComponentRenderer";
import { ImageComponent } from "components/base/image/ImageComponent";
import { CardComponent } from "components/base/card/CardComponent";
import { CtaComponent } from "components/base/cta/CtaComponent";
import { VideoComponent } from "components/base/video/VideoComponent";
import { ItemsListComponent } from "components/base/itemsList/itemsListComponent";
import { QuestionComponent } from "components/base/question/QuestionComponent";

export const BASE_COMPONENTS_CONFIGS: TComponentsConfigs = {
    div: { component: DivComponent },
    title: { component: TitleComponent },
    text: { component: TextComponent },
    question: { component: QuestionComponent },
    img: { component: ImageComponent },
    video: { component: VideoComponent },
    card: { component: CardComponent },
    cta: { component: CtaComponent },
    itemsList: { component: ItemsListComponent },
};
