import React from "react";
import { pagesStore } from "pages/_stores/pagesStore";
import { ListStorePaginator } from "admin/_common/list/ListStorePaginator";
import { ListStore } from "_common/list/ListStore";
import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { NewsCardComponent, NewsCardComponentProps } from "components/news/newsCard/NewsCardComponent";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { TLang } from "_configs/sharedConfig";
import i18next from "i18next";

export enum ITEM_MODEL {
    PAGE = "page",
    ARTICLE = "article",
}

function renderItem<T>(type: ITEM_MODEL, item: T) {
    const lang = i18next.language;
    const props: NewsCardComponentProps = {};
    switch (type) {
        case ITEM_MODEL.ARTICLE:
            props.title = item.localized[lang as TLang]?.title;
            props.date = item.localized[lang as TLang]?.publishedAt;
            props.text = item.localized[lang as TLang]?.description;
            props.image = item.localized[lang as TLang]?.image;
            props.url = item.localized[lang as TLang]?.url;
            return (
                <Grid key={item._id} item xs={12} md={6} lg={4}>
                    <NewsCardComponent {...props} />
                </Grid>
            );
    }
    return null;
}

type PaginatedItemsComponentProps = {
    listStore: ListStore<any>;
    itemType: ITEM_MODEL;
};

const PaginatedItemsComponent = observer((props: PaginatedItemsComponentProps) => {
    return (
        <Grid container spacing={3}>
            {props.listStore.paginatedItems.map((item) => renderItem(props.itemType, item))}
        </Grid>
    );
});

type ItemsComponentProps = {
    store: BaseResourceStore<any>;
    listStore: ListStore<any>;
    itemType: ITEM_MODEL;
};

export const ItemsComponent = observer((props: ItemsComponentProps) => {
    const loadingState = props.listStore.currentLoadingState;
    if (!loadingState) return null;
    return (
        <div>
            <LoadableFromLoading
                loading={loadingState}
                renderer={(_status, error) => {
                    if (error) {
                        return <ErrorBlock error={error} />;
                    }
                    return <PaginatedItemsComponent listStore={props.listStore} itemType={props.itemType} />;
                }}
            />
            {props.listStore.paginatedItems.length > 3 && (
                <div className="mt_20">
                    <ListStorePaginator listStore={props.listStore} />
                </div>
            )}
        </div>
    );
});

type Props = {
    itemModel: ITEM_MODEL;
    pageSize: number;
    page?: number;
};

export function ItemsListComponent({ itemModel, pageSize, page }: Props) {
    const listStore = pagesStore.getListStore(
        itemModel === ITEM_MODEL.PAGE ? "pagesList" : "articlesList",
        pageSize,
        undefined,
        undefined,
        undefined,
        undefined,
        page,
    );

    return (
        <div className={"pb_20"}>
            <ItemsComponent itemType={itemModel} listStore={listStore} store={pagesStore} />
        </div>
    );
}
