import React, { useEffect, useState } from "react";
import { Loader } from "_common/loaders/Loader";
import styles from "./_css/map.module.css";
import { TPropertyListingMdl, TPropertyMdl } from "properties/_models/PropertyMdl";
import { GoogleMap, GoogleMapProps, InfoWindow, Marker, StreetViewPanorama } from "@react-google-maps/api";
import { useMap, useMousePosition } from "_common/_utils/hookUtils";
import { observer } from "mobx-react";
import { PropertyCard } from "properties/PropertyCard";
import { MapPropertyMarker } from "maps/MapPropertyMarker";

export type TGoogleLocation = { lat: number; lng: number };
export type TGoogleLocationString = { lat: string; lng: string };
export type TMapCoordinates = { n: number; s: number; e: number; w: number };

type Props = {
    defaultCenter: TGoogleLocation;
    isDrawingBoundaries?: boolean;
    onDrawingBoundariesSuccess?: (boundaries: TGoogleLocation[]) => void;
    areMarkersVisible?: boolean;
    radius?: number;
    zoom?: number;
    isOnSearchingPage?: boolean;
    properties?: TPropertyListingMdl[] | TPropertyMdl[];
    polygon?: TGoogleLocation[];
    boundaries?: TGoogleLocation[];
    onSetMarker?: (location: TGoogleLocation) => void;
    propertySelected?: TPropertyListingMdl | undefined;
    onPropertySelected?: (property: TPropertyListingMdl | undefined) => void;
} & GoogleMapProps;

const DEFAULT_OPTIONS = {
    fullscreenControl: false,
    streetViewControl: false,
};

const POLYGON_NAME = "searchingZone";

function drawPolygon(polygon: TGoogleLocation[], map?: google.maps.Map, editable?: boolean) {
    if (polygon && polygon.length > 0) {
        map.data.forEach((feature) => {
            map.data.remove(feature);
        });
        const bounds = new (window as any).google.maps.LatLngBounds();
        polygon.map((location) => {
            bounds.extend(new (window as any).google.maps.LatLng(location.lat, location.lng));
        });
        map.fitBounds(bounds, 0);
        const geojson = {
            type: "FeatureCollection",
            features: [
                {
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: editable
                            ? [[...polygon.map((location) => [location.lng, location.lat])]]
                            : [
                                  [
                                      [0, 90],
                                      [180, 90],
                                      [180, -90],
                                      [0, -90],
                                      [-180, -90],
                                      [-180, 0],
                                      [-180, 90],
                                      [0, 90],
                                  ],
                                  [...polygon.map((location) => [location.lng, location.lat])],
                              ],
                    },
                    properties: {
                        name: POLYGON_NAME,
                    },
                },
            ],
        };
        map.data.addGeoJson(geojson);
        map.data.setStyle((feature) => {
            return feature.getProperty("name") === POLYGON_NAME
                ? {
                      strokeColor: "#013a67",
                      strokeWeight: 2,
                  }
                : {};
        });
    }
}

const MapComponent = React.forwardRef(({ isOnSearchingPage, ...props }: Props, ref) => {
    const [mapWidth, setMapWidth] = useState(0);
    const [_mapHeight, setMapHeight] = useState(0);
    const [cardXPosition, setCardXPosition] = useState("right");
    const { isLoaded } = useMap();
    const { x } = useMousePosition();
    const [_map, setMap] = useState<google.maps.Map | undefined>();

    useEffect(() => {
        x + 250 > mapWidth ? setCardXPosition("left") : setCardXPosition("right");
    }, [props.propertySelected]);

    useEffect(() => {
        if (_map && !props.polygon) {
            _map.data.forEach((feature) => {
                if (feature.getProperty("name") === POLYGON_NAME) {
                    _map.data.remove(feature);
                }
            });
        } else if (_map && props.polygon) {
            drawPolygon(props.polygon, _map);
        }
    }, [props.polygon]);

    return isLoaded ? (
        <GoogleMap
            {...props}
            mapContainerStyle={props.mapContainerStyle}
            options={{
                ...DEFAULT_OPTIONS,
                ...props.options,
            }}
            onClick={(e: { latLng: { lat: () => number; lng: () => number } }) => {
                if (props.isDrawingBoundaries) {
                    let boundaries = props.boundaries;
                    if (boundaries) {
                        if (props.boundaries && props.boundaries.length === 0) {
                            boundaries = [
                                { lat: e.latLng.lat(), lng: e.latLng.lng() },
                                { lat: e.latLng.lat(), lng: e.latLng.lng() },
                            ];
                        } else {
                            const originPoint = JSON.parse(JSON.stringify(boundaries[0]));
                            boundaries.splice(boundaries.length - 1, 1);
                            boundaries.push({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                            boundaries.push(originPoint);
                        }
                        props.onDrawingBoundariesSuccess ? props.onDrawingBoundariesSuccess(boundaries) : "";
                        if (props.boundaries && props.boundaries.length > 3) {
                            drawPolygon(boundaries, _map, props.isDrawingBoundaries);
                        }
                    }
                }
                if (props.onSetMarker) {
                    props.onSetMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                }
            }}
            center={props.center}
            onLoad={(map) => {
                if (props.polygon) {
                    drawPolygon(props.polygon, map);
                }
                setMap(map);
            }}
            zoom={props.zoom}
            ref={(map) => {
                if (map) {
                    setMapWidth((map.mapRef as HTMLElement).offsetWidth - (map.mapRef as HTMLElement).offsetLeft);
                    setMapHeight(window.innerHeight - (map.mapRef as HTMLElement).offsetTop);
                    ref(map);
                }
            }}
        >
            {props.options && props.options.streetView && <StreetViewPanorama {...props.options.streetView} />}
            {props.areMarkersVisible &&
                props.properties &&
                props.properties.map((property, index) => {
                    if (!property) return null;
                    return (
                        <MapPropertyMarker
                            onPropertySelected={(property) =>
                                props.onPropertySelected ? props.onPropertySelected(property) : ""
                            }
                            property={property}
                            key={index}
                        />
                    );
                })}
            {props.areMarkersVisible &&
                props.boundaries &&
                props.boundaries.map((marker, index) => {
                    if (!marker) return null;
                    return (
                        <Marker
                            onClick={() => {
                                const boundaries = JSON.parse(JSON.stringify(props.boundaries));
                                if (index > 0 && index < boundaries.length - 1) {
                                    boundaries.splice(index, 1);
                                    props.onDrawingBoundariesSuccess
                                        ? props.onDrawingBoundariesSuccess(boundaries)
                                        : "";
                                    drawPolygon(boundaries, _map, props.isDrawingBoundaries);
                                }
                            }}
                            key={index}
                            position={marker}
                        />
                    );
                })}
            {props.propertySelected && (
                <InfoWindow
                    options={{
                        disableAutoPan: true,
                        pixelOffset: new google.maps.Size(cardXPosition === "left" ? -280 : 0, 0),
                    }}
                    position={{
                        lat: props.propertySelected?.location.coordinates[1],
                        lng: props.propertySelected?.location.coordinates[0],
                    }}
                >
                    <PropertyCard
                        isMapCard
                        property={props.propertySelected}
                        onMouseLeave={() => (props.onPropertySelected ? props.onPropertySelected(undefined) : "")}
                        onMouseOver={() =>
                            props.onPropertySelected ? props.onPropertySelected(props.propertySelected) : ""
                        }
                    />
                </InfoWindow>
            )}
        </GoogleMap>
    ) : (
        <Loader className={styles.container} />
    );
});

export const Map = observer(MapComponent);
