import React, { useState } from "react";
import styles from "./_css/productCard.module.css";
import clsx from "clsx";
import { PAYMENT_TYPE, TStripeProductListingMdl } from "payments/_models/paymentStripeMdl";
import { InputNumberFront } from "payments/_components/InputNumberFront";
import { useTranslation } from "react-i18next";
import { ProductHead } from "payments/_components/ProductHead";
import { cartStore, TLine } from "Cart/_stores/cartStore";
import { observer } from "mobx-react";
import { TogglePayment } from "payments/_components/TogglePayment";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import arrowDown from "../assets/images/arrowDown.svg";
import arrowUp from "../assets/images/arrowUp.svg";
import paymentStore from "payments/_stores/paymentStore";

type Props = {
    product: TStripeProductListingMdl;
    addCart: (line: TLine) => void;
};

export const ProductCard = observer((props: Props) => {
    const [quantity, setQuantity] = useState(
        cartStore.cart.find((line) => line.name === props.product?.name)?.quantity ?? 1,
    );
    const [isAnnual, setIsAnnual] = useState(false);
    const price = paymentStore.getPriceFromProductRegionAndRecurring(props.product, PAYMENT_TYPE.RECURRING);

    const [openInMobile, setOpenInMobile] = useState(false);

    const { t } = useTranslation();
    return (
        <div
            className={clsx(styles.container, "flex-1 flex_column_center", {
                [styles.openInMobile]: openInMobile,
            })}
        >
            <div className={styles.header} onClick={() => setOpenInMobile(!openInMobile)}>
                <ProductHead
                    isAnnual={isAnnual}
                    name={props.product.name}
                    price={price.unit_amount ? price.unit_amount / 100 : 0}
                />
                <div className={clsx("flex_row justifyContent_center", styles.contentInputNumber)}>
                    <InputNumberFront
                        defaultValue={quantity}
                        value={quantity}
                        onValueChange={(e) => setQuantity(e)}
                        max={30}
                    />
                    <div className={"ml_20"}>{t("paymentPage.projects")}</div>
                </div>
                <div
                    className={clsx(styles.mobileOnly, {
                        [styles.arrow]: !openInMobile,
                        [styles.arrowReverse]: openInMobile,
                    })}
                    onClick={() => setOpenInMobile(!openInMobile)}
                >
                    {!openInMobile ? (
                        <img src={arrowDown} alt={"arrow down"} />
                    ) : (
                        <img src={arrowUp} alt={"arrow up"} />
                    )}
                </div>
            </div>
            <ul className={clsx(styles.list, "ml_20 mt_25")}>
                {props.product.optionKeys.map((optionKey) => {
                    return <li key={optionKey}>{t(`paymentPage.products.options.${optionKey}`)}</li>;
                })}
            </ul>
            <div className={clsx("mb_25", styles.contentToggle)}>
                <div className={clsx("flex justifyContent_flexEnd", styles.textSave)}>{t("paymentPage.saveTen")}</div>
                <TogglePayment setIsAnnual={() => setIsAnnual(!isAnnual)} isAnnual={isAnnual} />
            </div>
            <UiButton
                id={isAnnual ? "Annually" + props.product.productLabel : "Monthly" + props.product.productLabel}
                variant={"contained"}
                color={"primary"}
                className={styles.button}
                onClick={() => {
                    cartStore.setIsCartOpenDebounce(true);
                    const price = paymentStore.getPriceFromProductRegionAndRecurring(
                        props.product,
                        isAnnual ? PAYMENT_TYPE.ONE_TIME : PAYMENT_TYPE.RECURRING,
                    );

                    if (!price?.unit_amount) return null;
                    props.addCart({
                        productId: props.product.id,
                        priceId: price.id,
                        price: price.unit_amount,
                        quantity,
                        name: props.product.name,
                        type: isAnnual ? PAYMENT_TYPE.ONE_TIME : PAYMENT_TYPE.RECURRING,
                    });
                    setTimeout(() => {
                        cartStore.setIsCartOpenDebounce(false);
                    }, 2000);
                }}
            >
                {t("paymentPage.addCart")}
            </UiButton>
        </div>
    );
});
