import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import { LoadingStateMdl } from "shared/_common/loaders/_models/LoadingStateMdl";
import { TErrMdl } from "shared/_common/errors/_models/ErrMdl";
import { Loadable } from "shared/_common/loaders/Loadable";

type Props<TValue> = {
    loading: LoadingStateMdl<TValue>;
    renderer: (status: "IDLE" | "SUCCEEDED", error?: TErrMdl, value?: TValue) => ReactNode;
    fallback?: ReactNode;
    noSpinner?: boolean;
    noText?: boolean;
    text?: string;
    className?: string;
};

function _LoadableFromLoading<TValue>(props: Props<TValue>) {
    if (!props.loading) return null;
    return (
        <Loadable
            status={props.loading.status}
            error={props.loading.error}
            value={props.loading.value}
            renderer={props.renderer}
            noSpinner={props.noSpinner}
            noText={props.noText}
            text={props.text}
            className={props.className}
            fallback={props.fallback}
        />
    );
}

export const LoadableFromLoading = observer(_LoadableFromLoading);
