import React, { useState } from "react";
import styles from "./_css/propertyOrUnitCta.module.css";
import clsx from "clsx";
import { userStore } from "users/_stores/userStore";
import { useTranslation } from "react-i18next";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { PropertyAppointmentBtn } from "properties/propertyPage/PropertyAppointmentBtn";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { PROPERTY_EVENT } from "properties/_models/PropertyMdl";
import { useUnitStore } from "units/UnitContext";
import { UNIT_EVENT } from "units/_models/UnitMdl";
import { InputTextCta } from "properties/propertyPage/_components/InputTextCta";
import { TextAreaCta } from "properties/propertyPage/_components/TextareaCta";
import { CheckboxAgree } from "properties/propertyPage/_components/CheckboxAgree";
import { observer } from "mobx-react";
import { RecaptchaDisclaimer } from "components/misc/contactUs/recaptchaDisclaimer";
import { PropertyOrUnitCtaFromExternalSource } from "properties/propertyPage/PropertyOrUnitCtaFromExternalSource";
import { CallCtaIcon } from "properties/propertyPage/_components/CallCtaIcon";
import { SIGN_UP_FROM, SIGN_UP_TYPE } from "users/_models/UserMdl";

export type TAskForVisitOrInfoForm = {
    name: string;
    phone: string;
    email: string;
    date: string;
    message?: string;
    propertyId?: string;
    unitId?: string;
    lang?: string;
    notification?: boolean;
    signUpType?: SIGN_UP_TYPE;
    signUpFrom?: SIGN_UP_FROM;
};

const PropertyOrUnitCta = observer(() => {
    const propertyStore = usePropertyStore();
    const unitStore = useUnitStore();
    const [phoneIsVisible, setPhoneIsVisible] = useState(false);
    const [hasAgreedNotification, setHasAgreedNotification] = useState(false);
    const { t } = useTranslation();

    if (
        propertyStore.property.source?.platform === "centris" ||
        propertyStore.property.source?.platform === "duproprio"
    ) {
        return <PropertyOrUnitCtaFromExternalSource link={propertyStore.property.localized.propertyLink} />;
    }

    const form = useForm<TAskForVisitOrInfoForm>({
        mode: "onBlur",
        defaultValues: {
            name: userStore.isLogged ? userStore.user?.firstName + " " + userStore.user?.lastName : "",
            phone: userStore.isLogged ? userStore.user?.phone : "",
            email: userStore.isLogged ? userStore.user?.email : "",
            message: t("propertyPage.cta.message") + propertyStore.property.localized.title + ".",
        },
    });

    const hiddenPhone = propertyStore.property?.contactPhone.toString().slice(0, -4) + "XXXX";
    const appointingFormIsDisabled =
        propertyStore.property.developer?.isAppointingFormDisabled || propertyStore.property.isAppointingFormDisabled;
    if (appointingFormIsDisabled && propertyStore.property.isContactFormDisabled) return null;

    return (
        <FormProvider {...form}>
            <div className={styles.container}>
                <div
                    className={clsx(styles.title, "mb_15")}
                    dangerouslySetInnerHTML={{ __html: t("propertyPage.cta.info") }}
                />
                <div className={styles.form}>
                    <div className={"mt_25"}>
                        <Controller
                            as={InputTextCta}
                            control={form.control}
                            style={{ borderColor: form.watch("email") ? "#004378" : "#7A7E8D" }}
                            placeholder={t("words.email")}
                            name={"email"}
                            rules={{
                                required: t<string>("errors.forms.required"),
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                                    message: t("errors.forms.emailInvalid"),
                                },
                            }}
                        />
                    </div>
                    <div className={"mt_25"}>
                        <Controller
                            as={InputTextCta}
                            style={{ borderColor: form.watch("name") ? "#004378" : "#7A7E8D" }}
                            control={form.control}
                            placeholder={t("words.firstAndLastName")}
                            name={"name"}
                        />
                    </div>
                    <div className={"mt_25"}>
                        <Controller
                            as={InputTextCta}
                            style={{ borderColor: form.watch("phone") ? "#004378" : "#7A7E8D" }}
                            control={form.control}
                            placeholder={t("words.phone")}
                            name={"phone"}
                        />
                    </div>
                    <div className={"mt_25"}>
                        <Controller
                            name={"message"}
                            as={TextAreaCta}
                            placeholder={"Message"}
                            style={{ borderColor: form.watch("message") ? "#004378" : "#7A7E8D" }}
                            control={form.control}
                            rows={5}
                        />
                    </div>
                </div>
                <div className={"mt_25"}>
                    <CheckboxAgree checked={hasAgreedNotification} onChange={setHasAgreedNotification} />
                </div>
                <div className={"mt_25"}>
                    <PropertyAppointmentBtn hasAgreedNotification={hasAgreedNotification} />
                </div>
                <div className="mt_25">
                    <RecaptchaDisclaimer />
                </div>
            </div>
            {propertyStore.property.contactPhone && (
                <div className={styles.phoneContainer}>
                    <div className={styles.orContainer}>
                        <div className={styles.or}>{t("words.OR")}</div>
                    </div>
                    <div className={clsx("flex_row_center mb_5 justifyContent_center")}>
                        <a
                            href={`tel:${propertyStore.property.contactPhone}`}
                            className={clsx("flex_row_center", styles.text, styles.phoneTextContainer)}
                            onClick={() => {
                                if (phoneIsVisible) return;
                                setPhoneIsVisible(!phoneIsVisible);

                                Object.keys(unitStore).length > 0
                                    ? analyticsUtils.tracksEventOfItem(UNIT_EVENT.BUILDER_PHONE, unitStore.unit._id)
                                    : analyticsUtils.tracksEventOfItem(
                                          PROPERTY_EVENT.BUILDER_PHONE,
                                          propertyStore.property._id,
                                      );
                            }}
                        >
                            <CallCtaIcon />
                            <div className={"flex_row_center mt_3"}>
                                <div className={"text_bold ml_5"}>{t("words.call")}</div>
                                <div className={clsx("ml_5")}>
                                    {!phoneIsVisible ? hiddenPhone : propertyStore.property.contactPhone}
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            )}
        </FormProvider>
    );
});

export default PropertyOrUnitCta;
