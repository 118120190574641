import React, { useEffect, useState } from "react";
import { ProductsSection } from "payments/ProductsSection";
import { PricingBreadcrumb } from "payments/PricingBreadcrumb";
import { OptionsSection } from "payments/OptionsSection";
import { HelpSection } from "payments/HelpSection";
import { PromoteFormModal } from "payments/_components/PromoteFormModal";
import { ComparePricesModal } from "payments/_components/ComparePricesModal";
import { PromoteTrustedUs } from "advertise/PromoteTrustedUs";
import { useLocation } from "react-router-dom";
import styles from "./_css/pricingPage.module.css";
import { PRICING_ZONE } from "payments/_models/paymentStripeMdl";
import paymentStore from "payments/_stores/paymentStore";
import { Helmet } from "react-helmet-async";
import sharedConfig from "_configs/sharedConfig";

export const itemsForBreadcrumb = [1, 2, 3, 4];

export function PricingPage() {
    const location = useLocation();
    const pricingZone = location.pathname.split("/")[3] as PRICING_ZONE;
    useEffect(() => {
        paymentStore.setPricingZone(pricingZone);
        void paymentStore.fetchProductsAndPrices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [formModalIsOpen, setFormModalIsOpen] = useState(false);
    const [compareModalIOpen, setCompareModalIsOpen] = useState(false);
    if (sharedConfig.isProd) return null;
    return (
        <div className={styles.container}>
            <Helmet>
                <meta name="robots" content="NOINDEX,NOFOLLOW" />
            </Helmet>
            <div className={styles.breadCrumbContainer}>
                <PricingBreadcrumb items={itemsForBreadcrumb} currentItem={0} />
            </div>
            <ProductsSection />
            <OptionsSection />
            <HelpSection openForm={() => setFormModalIsOpen(true)} openCompare={() => setCompareModalIsOpen(true)} />
            <PromoteFormModal
                open={formModalIsOpen}
                onClose={() => {
                    setFormModalIsOpen(false);
                }}
            />
            <ComparePricesModal
                open={compareModalIOpen}
                onClose={() => {
                    setCompareModalIsOpen(false);
                }}
            />
            <PromoteTrustedUs />
        </div>
    );
}
