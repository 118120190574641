import React from "react";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { URLS } from "_configs/URLS";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { JsonLdBreadCrumb } from "breadcrumb/JsonLdBreadCrumb";

export function PropertyBreadcrumb() {
    const { t } = useTranslation();
    const { property } = usePropertyStore();

    const urlType = t("property.typesUrl." + property.type);
    const items = [
        {
            itemLabel: property.address.province,
            url: URLS[property.purpose === PROPERTY_PURPOSE.RENT ? "rent" : "buy"](
                undefined,
                reformatStringForUrls(property.address.province),
                urlType,
            ),
            forceReload: true,
            pageTitle: t(`propertiesPage.meta.geoZone.${property.purpose}.h1.` + property.type, {
                geoZoneName: property.geozones?.[0]?.address?.city,
            }),
        },
        {
            itemLabel: property.address.city,
            url: URLS[property.purpose === PROPERTY_PURPOSE.RENT ? "rent" : "buy"](
                undefined,
                reformatStringForUrls(property.address.province),
                reformatStringForUrls(property.address.city),
                urlType,
            ),
            forceReload: true,
            pageTitle: t(`propertiesPage.meta.geoZone.${property.purpose}.h1.` + property.type, {
                geoZoneName: property?.geozones?.[1]?.address.city,
            }),
        },
    ];

    if (property.address.neighbourhood) {
        items.push({
            itemLabel: property.address.neighbourhood,
            url: URLS[property.purpose === PROPERTY_PURPOSE.RENT ? "rent" : "buy"](
                undefined,
                reformatStringForUrls(property.address.province),
                reformatStringForUrls(property.address.city),
                reformatStringForUrls(property.address.neighbourhood),
                urlType,
            ),
            forceReload: true,
            pageTitle: t(`propertiesPage.meta.geoZone.${property.purpose}.h1.` + property.type, {
                geoZoneName: property?.geozones?.[2]?.address.neighbourhood,
            }),
        });
    }

    return (
        <>
            <JsonLdBreadCrumb items={items} currentItem={{ itemLabel: property.localized.title }} />
            <Breadcrumb items={items} currentItem={{ itemLabel: property.localized.title }} />
        </>
    );
}
