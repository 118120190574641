import { fetchUtils } from "_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { TAskForVisitOrInfoForm } from "properties/propertyPage/PropertyOrUnitCta";
import { TContactForm } from "components/misc/contactUs/ContactFormComponent";
import { TContactFooterForm } from "main/footer/FooterSubscribe";
import { TContactPromoteForm } from "payments/_components/PromoteForm";
import i18next from "i18next";
import { TVirtualAssistantSummary } from "virtualAssistant/VirtualAssistantFormStepper";

export enum NOTIFICATION_TYPE {
    ASK_INFO = "askForInfo",
    ASK_VISIT = "askForVisit",
    CONTACT = "contact",
    CONTACT_PROMOTE = "contactPromote",
    ASSISTANT_VIRTUAL = "assistantVirtual",
}

class NotifierStore {
    constructor(private apiPath = `${sharedConfig.apiUrl}/notifier`) {}

    async askForInfo(data: TAskForVisitOrInfoForm) {
        const recaptcha = await this.getReCaptchaToken();
        return fetchUtils.post(this.apiPath + "/askForInfo", { ...data, lang: i18next.language, recaptcha });
    }

    async contact(data: TContactForm) {
        const recaptcha = await this.getReCaptchaToken();
        return fetchUtils.post(this.apiPath + "/contact", { ...data, lang: i18next.language, recaptcha });
    }

    async contactFromFooter(data: TContactFooterForm) {
        const recaptcha = await this.getReCaptchaToken();
        return fetchUtils.post(this.apiPath + "/contactFromFooter", {
            ...data,
            lang: i18next.language,
            recaptcha,
        });
    }

    async contactFromPromote(data: TContactPromoteForm) {
        const recaptcha = await this.getReCaptchaToken();
        return fetchUtils.post(this.apiPath + "/contactFromPromote", { ...data, lang: i18next.language, recaptcha });
    }

    async assistantVirtualSummary(data: TVirtualAssistantSummary) {
        const recaptcha = await this.getReCaptchaToken();
        return fetchUtils.post(this.apiPath + "/virtualAssistantSummary", {
            ...data,
            lang: i18next.language,
            recaptcha,
        });
    }

    async assistantVirtualNotifications(
        propertyIds: string[],
        id: { firstName: string; lastName: string; email: string; phone: string; comment: string },
    ) {
        const recaptcha = await this.getReCaptchaToken();
        return fetchUtils.post(this.apiPath + "/virtualAssistantNotifications", {
            propertyIds: propertyIds,
            lang: i18next.language,
            firstName: id.firstName,
            lastName: id.lastName,
            email: id.email,
            phone: id.phone,
            comment: id.comment,
            recaptcha,
        });
    }

    async getReCaptchaToken() {
        if (!sharedConfig.isProd) return "dev-recaptcha";
        return new Promise((resolve, reject) => {
            (window as any).grecaptcha.ready(() => {
                (window as any).grecaptcha
                    .execute(sharedConfig.reCaptcha.siteKey, { action: "submit" })
                    .then((token: string) => {
                        resolve(token);
                        console.info("recaptcha success");
                    })
                    .catch((error: Error) => {
                        console.info("recaptcha error");
                        reject(error);
                    });
            });
        });
    }
}

const notifierStore = new NotifierStore();
export { notifierStore };
