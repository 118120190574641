import React from "react";
import { comparisonStore } from "shared/compare/_store/ComparisonStore";
import styles from "./_css/compareOverlay.module.css";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { observer } from "mobx-react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ComparedItemPhoto } from "compare/ComparedItemPhoto";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { URLS } from "_configs/URLS";
import { IconComponent } from "components/base/icon/IconComponent";

type Props = {
    type: MAIN_RESOURCE;
};

export const CompareOverlay = observer((props: Props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const nbOfUnit = comparisonStore.units.length;
    const nbOfProperties = comparisonStore.properties.length;
    if (nbOfUnit === 0 && nbOfProperties === 0) return null;
    if (comparisonStore.lastItemUpdated !== props.type) return null;
    switch (props.type) {
        case MAIN_RESOURCE.PROPERTIES:
            if (!nbOfProperties) return null;
            break;
        case MAIN_RESOURCE.UNITS:
            if (!nbOfUnit) return null;
            break;
    }
    const isPropertyOverlay = props.type === MAIN_RESOURCE.PROPERTIES;
    return (
        <div className={styles.container}>
            <div
                className={clsx(styles.closeIcon, "flex_center_center", {
                    [styles.openIconContainer]: comparisonStore.overlayIsDisplay,
                })}
                onClick={() => comparisonStore.setOverlayIsDisplayed(!comparisonStore.overlayIsDisplay)}
            >
                <IconComponent
                    className={clsx({ [styles.openIcon]: comparisonStore.overlayIsDisplay })}
                    icon={"icon-chevrons-right"}
                />
            </div>
            <div className={clsx(styles.content, { [styles.isOpen]: comparisonStore.overlayIsDisplay })}>
                <h3 className={"textAlign_center m_0"}>{props.type.charAt(0).toUpperCase() + props.type.slice(1)}</h3>
                <div className={clsx("flex_column_center", styles.imgContainer)}>
                    {comparisonStore[props.type].map((itemId, index) => {
                        if (index > 2) return null;
                        return <ComparedItemPhoto key={index} itemId={itemId} type={props.type} />;
                    })}
                </div>
                <div className={"flex_column_center"}>
                    <UiButton
                        className="mt_10"
                        color={"primary"}
                        variant={"contained"}
                        onClick={() => {
                            history.push(URLS.compare(props.type));
                            comparisonStore.setOverlayIsDisplayed(false);
                        }}
                    >
                        {t("comparePage.compare")} ({isPropertyOverlay ? nbOfProperties : nbOfUnit})
                    </UiButton>
                    {nbOfUnit !== 0 && nbOfProperties !== 0 && (
                        <div
                            className={clsx("mt_10", styles.link)}
                            onClick={() => {
                                history.push(
                                    URLS.compare(isPropertyOverlay ? MAIN_RESOURCE.UNITS : MAIN_RESOURCE.PROPERTIES),
                                );
                                comparisonStore.showItemTypeOverlay(
                                    isPropertyOverlay ? MAIN_RESOURCE.UNITS : MAIN_RESOURCE.PROPERTIES,
                                );
                                comparisonStore.setOverlayIsDisplayed(false);
                            }}
                        >
                            {t("comparePage.compare")}{" "}
                            {isPropertyOverlay ? MAIN_RESOURCE.UNITS : MAIN_RESOURCE.PROPERTIES}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});
