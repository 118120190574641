import React, { useContext } from "react";
import { PropertiesStore } from "properties/_stores/propertiesStore";

const PropertiesPageCtxt = React.createContext<PropertiesStore>({} as PropertiesStore);

type Props = {
    store: PropertiesStore;
};

export function usePropertiesStore() {
    return useContext(PropertiesPageCtxt);
}

export function PropertiesPageContext(props: React.PropsWithChildren<Props>) {
    return <PropertiesPageCtxt.Provider value={props.store}>{props.children}</PropertiesPageCtxt.Provider>;
}
