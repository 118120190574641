import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/checkboxAgreeFooter.module.css";

type Props = {
    checked: boolean;
    onChange: (bool: boolean) => void;
};

export function CheckboxAgreeFooter(props: Props) {
    const { t } = useTranslation();
    return (
        <label className={clsx("flex_row", styles.content)}>
            <input
                className={styles.checkbox}
                type={"checkbox"}
                checked={props.checked}
                onChange={() => props.onChange(!props.checked)}
            />
            <div> {t("footer.agree")}</div>
        </label>
    );
}
