import { TOrderListingMdl } from "admin/orders/_models/OrderMdl";
import { observable } from "mobx";
import { TPropertyListingMdl, TPropertyMdl } from "properties/_models/PropertyMdl";

export class OrderStore {
    @observable order: TOrderListingMdl;

    constructor(order: TOrderListingMdl) {
        this.order = order;
    }

    updatePaymentStatusPropertyInOrder(property: TPropertyListingMdl | TPropertyMdl) {
        this.order.properties;
        const oldPropertyIndex = this.order.properties.findIndex((oldProperty) => oldProperty._id === property._id);
        if (oldPropertyIndex !== -1 && !!this.order.properties[oldPropertyIndex]?.order) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            this.order.properties[oldPropertyIndex].order.isCompleted = true;
        }
    }

    getUncompletedPropertiesInOrder() {
        return this.order.properties.filter((property) => !property.order?.isCompleted);
    }
}
