import { PAYMENT_TYPE, PRICING_ZONE, PRODUCT_NAME } from "payments/_models/paymentStripeMdl";

export const STRIPE_PRODUCTS_KEY = {
    prod: {
        VBOOST_INFOLETTRE: "prod_NBAl6kyYCJz1Vx",
        INTEGRATION_CRM: "prod_KwLfyhoAqKamQq",
        INTEGRATION_PLANPOINT: "prod_NBAOispJNZmyeG",
        INTEGRATION_RENTSYNC: "prod_NBARYgSHFVJZtV",
        SOCIAL_NETWORK: "prod_NBASNcALWtMmOk",
        SEM: "prod_NBAk92Udc951b7",
        SPANISH_TRANSLATE: "prod_NBAlhTJIim2bHJ",
        MANDARIN_TRANSLATE: "prod_NBAm7OXqUrKuCl",
        ELITE: "prod_NBAmqYRzlJvQoy",
        PREMIUM: "prod_NBApNUV7SPE53o",
        ESSENTIEL: "prod_NBAp3Hzhy8ITHn",
        BASE: "prod_NBAseY8jdoI1Tl",
    },
    test: {
        VBOOST_INFOLETTRE: "prod_MbKQM1PXwoH2DR",
        INTEGRATION_CRM: "prod_MbKWdDFBR7BcvD",
        INTEGRATION_PLANPOINT: "prod_MbKU3jFGETJ9CS",
        INTEGRATION_RENTSYNC: "prod_MbKUMg0tUU8mOY",
        SOCIAL_NETWORK: "prod_MbKTo0uNv1VHZ1",
        SEM: "prod_MbKS6nYG1qs5tU",
        SPANISH_TRANSLATE: "prod_MbKPr0dlLoZ2uF",
        MANDARIN_TRANSLATE: "prod_MbKP3RbwlIuz4q",
        ELITE: "prod_MbKK5TwFLSJgSM",
        PREMIUM: "prod_MbKJBMwQKUXXfH",
        ESSENTIEL: "prod_MbKHMGDKD7fhE7",
        BASE: "prod_MbKDNQwrQ6xIE7",
    },
};

export const STRIPE_OPTIONS_PRICES_KEY = {
    prod: {
        VBOOST_INFOLETTRE: "price_1MQoPpHkolZqePJHtG9EJVbo",
        INTEGRATION_CRM: "price_1MCqsXHkolZqePJHkbUJlbC5",
        INTEGRATION_PLANPOINT: "price_1MQo3uHkolZqePJH0P5yi25s",
        INTEGRATION_RENTSYNC: "price_1MQo6iHkolZqePJHlGJ7Dvw4",
        SOCIAL_NETWORK: "price_1MQo7kHkolZqePJHyfeDdQed",
        SEM: "price_1MQoPMHkolZqePJH4dGccVwp",
        SPANISH_TRANSLATE: "price_1MQoQOHkolZqePJHJaAjina3",
        MANDARIN_TRANSLATE: "price_1MQoQlHkolZqePJHoz9cW38X",
    },
    test: {
        VBOOST_INFOLETTRE: "price_1Ls7m4HkolZqePJHCWnmr6TT",
        INTEGRATION_CRM: "price_1MQodqHkolZqePJHmVhEmY5a",
        INTEGRATION_PLANPOINT: "price_1Ls7plHkolZqePJHJ1cEWnhZ",
        INTEGRATION_RENTSYNC: "price_1Ls7pEHkolZqePJHSatyXQOF",
        SOCIAL_NETWORK: "price_1Ls7oTHkolZqePJH8YbtyMiF",
        SEM: "price_1Ls7nCHkolZqePJHm9plBs3d",
        SPANISH_TRANSLATE: "price_1Ls7l9HkolZqePJHCMp8F9rq",
        MANDARIN_TRANSLATE: "price_1Ls7kkHkolZqePJHzz0Ocy5T",
    },
};

interface TStripeProductsPricesKey {
    [key: string]: {
        [zone in typeof PRICING_ZONE[keyof typeof PRICING_ZONE]]: {
            [name in typeof PRODUCT_NAME[keyof typeof PRODUCT_NAME]]: {
                [subscription in typeof PAYMENT_TYPE[keyof typeof PAYMENT_TYPE]]: string;
            };
        };
    };
}

export const STRIPE_PRODUCTS_PRICES_KEY: TStripeProductsPricesKey = {
    test: {
        [PRICING_ZONE.CA]: {
            [PRODUCT_NAME.BASE]: {
                [PAYMENT_TYPE.RECURRING]: "price_1Ls7Z6HkolZqePJH95tpiDZ8",
                [PAYMENT_TYPE.ONE_TIME]: "price_1Ls7Z6HkolZqePJHt6jy3eep",
            },
            [PRODUCT_NAME.PREMIUM]: {
                [PAYMENT_TYPE.RECURRING]: "price_1Ls7f2HkolZqePJHIb7qsIvX",
                [PAYMENT_TYPE.ONE_TIME]: "price_1Ls7f2HkolZqePJHxQEuie5M",
            },
            [PRODUCT_NAME.ESSENTIEL]: {
                [PAYMENT_TYPE.RECURRING]: "price_1Ls7dGHkolZqePJHGQVIUpyc",
                [PAYMENT_TYPE.ONE_TIME]: "price_1Ls7dGHkolZqePJH42Gs7P4k",
            },
            [PRODUCT_NAME.ELITE]: {
                [PAYMENT_TYPE.RECURRING]: "price_1Ls7gGHkolZqePJHJ4ctw4MN",
                [PAYMENT_TYPE.ONE_TIME]: "price_1Ls7gGHkolZqePJHIdUBrwn0",
            },
        },
        [PRICING_ZONE.QC]: {
            [PRODUCT_NAME.BASE]: {
                [PAYMENT_TYPE.RECURRING]: "price_1Ls7bXHkolZqePJHPtiz8aHB",
                [PAYMENT_TYPE.ONE_TIME]: "price_1Ls7bXHkolZqePJHeSVBiWJ0",
            },
            [PRODUCT_NAME.PREMIUM]: {
                [PAYMENT_TYPE.RECURRING]: "price_1Ls7f2HkolZqePJHqaeOCJdJ",
                [PAYMENT_TYPE.ONE_TIME]: "price_1Ls7f2HkolZqePJHHX4L8nUK",
            },
            [PRODUCT_NAME.ESSENTIEL]: {
                [PAYMENT_TYPE.RECURRING]: "price_1Ls7dGHkolZqePJHNATftMCb",
                [PAYMENT_TYPE.ONE_TIME]: "price_1Ls7dGHkolZqePJHuYsUU1nl",
            },
            [PRODUCT_NAME.ELITE]: {
                [PAYMENT_TYPE.RECURRING]: "price_1Ls7gGHkolZqePJHaTBPuHti",
                [PAYMENT_TYPE.ONE_TIME]: "price_1Ls7gGHkolZqePJH3SZTJGdB",
            },
        },
    },
    prod: {
        [PRICING_ZONE.CA]: {
            base: {
                [PAYMENT_TYPE.RECURRING]: "price_1MQoWSHkolZqePJHyHN2zGUa",
                [PAYMENT_TYPE.ONE_TIME]: "price_1MQoWSHkolZqePJHf1B2IiMG",
            },
            premium: {
                [PAYMENT_TYPE.RECURRING]: "price_1MQoTTHkolZqePJHrkKdPj0O",
                [PAYMENT_TYPE.ONE_TIME]: "price_1MQoTTHkolZqePJHKu1LFZyr",
            },
            essentiel: {
                [PAYMENT_TYPE.RECURRING]: "price_1MQoU8HkolZqePJHRjSRF9No",
                [PAYMENT_TYPE.ONE_TIME]: "price_1MQoU8HkolZqePJH0TfQU62H",
            },
            elite: {
                [PAYMENT_TYPE.RECURRING]: "price_1MQoRFHkolZqePJHvPpOo0KI",
                [PAYMENT_TYPE.ONE_TIME]: "price_1MQoRFHkolZqePJHGdNajX20",
            },
        },
        [PRICING_ZONE.QC]: {
            base: {
                [PAYMENT_TYPE.RECURRING]: "price_1MQoWSHkolZqePJHKVpG8q4C",
                [PAYMENT_TYPE.ONE_TIME]: "price_1MQoWSHkolZqePJH5oTvUdZE",
            },
            premium: {
                [PAYMENT_TYPE.RECURRING]: "price_1MQoTSHkolZqePJHPdD7zOzn",
                [PAYMENT_TYPE.ONE_TIME]: "price_1MQoTTHkolZqePJHyKJngrKW",
            },
            essentiel: {
                [PAYMENT_TYPE.RECURRING]: "price_1MQoU8HkolZqePJH69pDcUx9",
                [PAYMENT_TYPE.ONE_TIME]: "price_1MQoU8HkolZqePJHYYi2Gfi9",
            },
            elite: {
                [PAYMENT_TYPE.RECURRING]: "price_1MQoRFHkolZqePJHRFxXsL57",
                [PAYMENT_TYPE.ONE_TIME]: "price_1MQoREHkolZqePJHQRsTBsrU",
            },
        },
    },
};

export enum OPTIONS_DETAILS {
    ANNOUNCE = "ANNOUNCE",
    CUSTOMER = "CUSTOMER",
    DISPLAY = "DISPLAY",
    RAPPEL = "RAPPEL",
    DASHBOARD = "DASHBOARD",
    LETTER_ANNUAL = "LETTER_ANNUAL",
    SOCIAL = "SOCIAL",
    CRM = "CRM",
    FIRST_PAGE_NEIGHBORHOOD = "FIRST_PAGE_NEIGHBORHOOD",
    FIRST_PAGE_CITY = "FIRST_PAGE_CITY",
    LETTER_USERS = "LETTER_USERS",
    CAROUSEL_VEDETTE = "CAROUSEL_VEDETTE",
    CAROUSEL = "CAROUSEL",
}
