import React from "react";
import { Select } from "@material-ui/core";
import styles from "./_css/uiSelect.module.css";
import clsx from "clsx";

export const UiSelect = React.forwardRef(
    (
        {
            disabledAltStyle,
            placeholder,
            ...props
        }: React.ComponentProps<typeof Select> & { disabledAltStyle?: boolean },
        ref,
    ) => {
        return (
            <Select
                MenuProps={{
                    disableScrollLock: true,
                }}
                ref={ref}
                {...props}
                className={clsx(styles.container, props.className)}
                classes={{
                    select: styles.select,
                    icon: styles.icon,
                    disabled: disabledAltStyle ? styles.disabled : undefined,
                }}
            />
        );
    },
);
