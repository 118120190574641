import sharedConfig, { TLang } from "_configs/sharedConfig";
import { universalLanguageDetect } from "@unly/universal-language-detector";

export function detectLanguage(pathname: string, acceptLanguageHeader?: string) {
    if (pathname.startsWith("/en")) return sharedConfig.languages.en;
    if (pathname.startsWith("/fr") || pathname === "/") return sharedConfig.languages.fr;
    if (pathname.startsWith("/es")) return sharedConfig.languages.es;
    if (pathname.startsWith("/zh")) return sharedConfig.languages.zh;
    const lang = universalLanguageDetect({
        supportedLanguages: ["en", "fr", "es", "zh"],
        fallbackLanguage: "en",
        acceptLanguageHeader,
    }) as TLang;
    return sharedConfig.languages[lang];
}
