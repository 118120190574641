import React from "react";
import { useParams } from "react-router";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { PageNotFound } from "pages/PageNotFound";
import { unitsStore } from "units/_stores/unitsStore";
import { UnitView } from "units/UnitView";
import i18next from "i18next";

export function UnitPage() {
    const { unitAlias, propertyAlias } = useParams();

    const unitOrFetch = unitsStore.getByAliasUrl(unitAlias, propertyAlias, i18next.language, "");
    const { loading } = useLoadingFromPromise(!(unitOrFetch && "localized" in unitOrFetch) ? unitOrFetch : undefined);
    if (unitOrFetch && "localized" in unitOrFetch) {
        return <UnitView unit={unitOrFetch} />;
    }

    if (!loading) return <PageNotFound />;
    return (
        <LoadableFromLoading
            loading={loading}
            renderer={(_status, error, unit) => {
                if (error) {
                    return <PageNotFound />;
                }
                if (!unit) return <PageNotFound />;

                return <UnitView unit={unit} />;
            }}
        />
    );
}
