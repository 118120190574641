import React from "react";
import styles from "./_css/multiSelect.module.css";
import { Chip, TextField } from "@material-ui/core";
import clsx from "clsx";
import { Autocomplete } from "@material-ui/lab";

type Props<T extends { _id: string; name: string }> = {
    options: Partial<T>[];
    value?: string[];
    onChange?: (selected: string[]) => void;
    placeholder: string;
    chipsLabel: string;
    maxSelections?: number;
    showSelectionCounter?: boolean;
};

const MultiSelect = <T extends { _id: string; name: string }>({
    maxSelections,
    onChange,
    value,
    placeholder,
    chipsLabel,
    options = [],
    showSelectionCounter,
}: Props<T>) => {
    if (!value) return null;

    const handleChange = (_event: React.ChangeEvent<{}>, newValue: (string | undefined)[]) => {
        if (!maxSelections || newValue.length <= maxSelections) {
            onChange?.(newValue.filter((value): value is string => value !== null));
        }
    };

    const handleDelete = (valueToDelete: string) => () => {
        onChange?.(value.filter((value) => value !== valueToDelete));
    };

    return (
        <div className={clsx("flex_row_center flexWrap_wrap", styles.container)}>
            <div className={"flex-1"}>
                <Autocomplete
                    multiple
                    options={options.map((option) => option._id)}
                    getOptionLabel={(option) => options.find((opt) => opt._id === option)?.name || ""}
                    value={value}
                    onChange={handleChange}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" label={placeholder} placeholder={placeholder} />
                    )}
                    renderTags={() => null}
                    style={{ width: 330 }}
                />
            </div>
            <div className={clsx("flex_column flex-1")}>
                <div className={styles.selectedZone}>
                    {chipsLabel}
                    {showSelectionCounter && (
                        <>
                            {" "}
                            ({value.length}/{maxSelections})
                        </>
                    )}
                </div>
                <div className={clsx("flex_row_center flexWrap_wrap", styles.chips)}>
                    {value.map((value) => {
                        const option = options.find((opt) => opt._id === value);
                        return (
                            <Chip
                                key={value}
                                label={option?.name}
                                onDelete={handleDelete(value)}
                                style={{
                                    padding: "20px 0px",
                                    borderRadius: "100px",
                                    margin: "5px",
                                    backgroundColor: "#004378",
                                    color: "#fff",
                                }}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MultiSelect;
