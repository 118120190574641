import React from "react";

export function CallCtaIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.50898 0.672296C4.29844 0.163703 3.74336 -0.107001 3.21289 0.0379212L0.806641 0.694171C0.330859 0.825421 0 1.25745 0 1.74964C0 8.51448 5.48516 13.9996 12.25 13.9996C12.7422 13.9996 13.1742 13.6688 13.3055 13.193L13.9617 10.7867C14.1066 10.2563 13.8359 9.7012 13.3273 9.49066L10.7023 8.39691C10.2566 8.21097 9.73984 8.33948 9.43633 8.71409L8.33164 10.0621C6.40664 9.15159 4.84805 7.593 3.9375 5.668L5.28555 4.56605C5.66016 4.2598 5.78867 3.74573 5.60273 3.30003L4.50898 0.675031V0.672296Z"
                fill="#00375C"
            />
        </svg>
    );
}
