import React from "react";
import { useTranslation } from "react-i18next";
import { TableHead, TableRow } from "@material-ui/core";

const LABELS = [
    {
        labelKey: "order.id",
    },
    {
        labelKey: "",
    },
    {
        labelKey: "",
    },
];

export function OrderHead() {
    const { t } = useTranslation();
    return (
        <TableRow>
            {LABELS.map((label, i) => {
                return <TableHead key={i}>{label.labelKey ? t(label.labelKey) : " "}</TableHead>;
            })}
        </TableRow>
    );
}
