import React from "react";
import { PromoteHeader } from "advertise/PromoteHeader";
import { PromoteAdvantages } from "advertise/PromoteAdvantages";
import { PromoteWithVistoo } from "advertise/PromoteWithVistoo";
import { PromoteTrustedUs } from "advertise/PromoteTrustedUs";

export function AdvertisePage() {
    return (
        <div>
            <PromoteHeader />
            <PromoteAdvantages />
            <PromoteWithVistoo />
            <PromoteTrustedUs />
        </div>
    );
}
