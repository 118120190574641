import React from "react";
import { TPropertyMdl } from "properties/_models/PropertyMdl";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import check from "../../assets/images/promotePage/check.svg";
import { TableCell, TableRow } from "@material-ui/core";
import { URLS } from "_configs/URLS";

type Props = {
    orderId: string;
    properties: TPropertyMdl[];
};

export function OrderProperties(props: Props) {
    const { t } = useTranslation();
    return (
        <>
            {props.properties.map((property) => {
                if (!property.order) return null;

                return (
                    <TableRow key={property._id}>
                        <TableCell>{property.localized.en.title}</TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell>
                            {property.order.isCompleted ? <img width={16} src={check} alt="check" /> : ""}
                        </TableCell>
                        <TableCell>
                            {!property.order.isCompleted && (
                                <Link to={URLS.project(props.orderId) + "?propertyId=" + property._id}>
                                    {t("order.complete")}
                                </Link>
                            )}
                        </TableCell>
                    </TableRow>
                );
            })}
        </>
    );
}
