import React from "react";
import { CheckBox } from "_common/ui/forms/CheckBox";
import { useTranslation } from "react-i18next";

type Props = {
    className?: string;
    containerClassName?: string;
    value?: string | string[];
    items: { value: any; label: string }[];
    onChange?: (value: string | string[]) => void;
    multiple?: boolean;
};

export const CheckBoxInput = React.forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
    const { t } = useTranslation();
    return (
        <div ref={ref} className={props.containerClassName}>
            {props.items.map((item) => {
                let isChecked = false;

                if (props.multiple) {
                    isChecked = !!props.value?.includes(item.value);
                } else isChecked = item.value === props.value;

                return (
                    <div key={item.value} className={props.className}>
                        <CheckBox
                            key={item.value}
                            value={item.value}
                            checked={isChecked}
                            onCheck={(valueItem) => {
                                if (props.multiple) {
                                    const index = props.value?.indexOf(valueItem) ?? 0;
                                    if (props.value?.includes(valueItem)) {
                                        props.onChange?.([
                                            ...props.value.slice(0, index),
                                            ...props.value.slice(index + 1),
                                        ]);
                                    } else {
                                        props.onChange?.([...props.value, valueItem]);
                                    }
                                    return;
                                }

                                props.onChange?.(valueItem);
                            }}
                            label={t(item.label)}
                        />
                    </div>
                );
            })}
        </div>
    );
});
