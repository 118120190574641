import React from "react";
import clsx from "clsx";
import styles from "./_css/radioFormProjectPage.module.css";

type Props = {
    items: { label: string; value: boolean }[];
    value: string | boolean;
    onValueChange: (value: string | boolean) => void;
};

export function RadioProjectForm(props: Props) {
    return (
        <div className={"flex_row alignItems_center"}>
            {props.items.map((item, i) => {
                return (
                    <div key={i} className={"flex_row_center mr_20"}>
                        <label
                            htmlFor={item.label}
                            onClick={() => props.onValueChange(item.value)}
                            className={clsx("mr_10", styles.label)}
                        >
                            {" "}
                            {item.label}
                        </label>
                        <input
                            type={"radio"}
                            name={item.label}
                            value={item.value}
                            onChange={() => props.onValueChange(item.value)}
                            checked={props.value === item.value}
                        />
                    </div>
                );
            })}
        </div>
    );
}
