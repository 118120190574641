import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { ISettingsMdl } from "settings/_models/SettingsMdl";
import { action, observable } from "mobx";
import { fetchUtils } from "_common/_utils/fetchUtils";

class SettingsStore extends BaseResourceStore<ISettingsMdl> {
    @observable filterOpened = "";
    @observable isFullPageLoaderOpened = false;

    constructor() {
        super("settings");
    }

    @action close() {
        if (this.filterOpened) {
            this.filterOpened = "";
        }
    }

    @action setFilterIsOpened(value: string) {
        this.filterOpened = value;
    }

    @action toggleFullPageLoader() {
        this.isFullPageLoaderOpened = !this.isFullPageLoaderOpened;
    }

    incrementSettingsStats(type: "residential" | "commercial" | "cookies.accept" | "cookies.decline") {
        return fetchUtils.post(`${this.apiPath}/increments-stat/` + type);
    }
}

const settingsStore = new SettingsStore();
export { settingsStore };
