import { TDef } from "admin/pages/builder/props/values/ValueEditor";
import { PROPERTY_PURPOSE, PROPERTY_TYPE } from "properties/_models/PropertyMdl";

export enum GROUP_FEATURES {
    PARKING = "parking",
    POOL = "pool",
}

export type TOptionConfigDef = TDef & {
    key: string;
    required: boolean;
    type: string;
    optionType: OPTION_TYPE;
    types?: PROPERTY_TYPE[];
    group?: GROUP_FEATURES;
    purpose?: PROPERTY_PURPOSE[];
    filter?: boolean;
};

export enum OPTION_TYPE {
    AMENITIES = "amenities",
    FEATURES = "features",
}

export enum OPTION_CATEGORIES {
    TRANSPORT = "transport",
    LOCAL_SERVICE = "localService",
    CHARACTER = "caracter",
}

export enum PROPERTY_OPTION {
    bar = "bar",
    bbqArea = "bbqArea",
    bicycleStorage = "bicycleStorage",
    businessCenter = "businessCenter",
    commonLaundry = "commonLaundry",
    communityGarden = "communityGarden",
    concierge = "concierge",
    eventRoom = "eventRoom",
    exteriorPool = "exteriorPool",
    firepit = "firepit",
    garden = "garden",
    gym = "gym",
    hotelServices = "hotelServices",
    interiorPool = "interiorPool",
    jacuzzi = "jacuzzi",
    loungeArea = "loungeArea",
    marketplace = "marketplace",
    multimediaRoom = "multimediaRoom",
    playgroundArea = "playgroundArea",
    receptionDeskLobby = "receptionDeskLobby",
    receptionHall = "receptionHall",
    shoppingCenter = "shoppingCenter",
    spa = "spa",
    tennisCourt = "tennisCourt",
    terrace = "terrace",
    walkingTrails = "walkingTrails",
    yogaLounge = "yogaLounge",
    cable = "cable",
    internet = "internet",
    telephone = "telephone",
    lockers = "lockers",
    washStation = "washStation",
    hotWater = "hotWater",
    airConditioner = "airConditioner",
    electricity = "electricity",
    furnished = "furnished",
    indoorParking = "indoorParking",
    entertainmentRoom = "entertainmentRoom",

    appliancesIncluded = "appliancesIncluded",
    balcony = "balcony",
    bikePathAccess = "bikePathAccess",
    centralAirConditioner = "centralAirConditioner",
    centralVacuum = "centralVacuum",
    closeToAHighway = "closeToAHighway",
    closeToPublicTransport = "closeToPublicTransport",
    composting = "composting",
    controlledAccess = "controlledAccess",
    electricVehicleChargingStation = "electricVehicleChargingStation",
    elevator = "elevator",
    energyCostIncluded = "energyCostIncluded",
    fireplace = "fireplace",
    furnishedEquipped = "furnishedEquipped",
    garbageChute = "garbageChute",
    intercom = "intercom",
    leedCertification = "leedCertification",
    outdoorParking = "outdoorParking",
    coveredParking = "coveredParking",
    petFriendly = "petFriendly",
    recyclage = "recyclage",
    securityCameras = "securityCameras",
    smartHome = "smartHome",
    solarPanels = "solarPanels",
    undergroundParking = "undergroundParking",
    wallMountAirConditioner = "wallMountAirConditioner",
    waterfront = "waterfront",
    mobilityAccess = "mobilityAccess",
    basementFinished = "basementFinished",
    gameRoom = "gameRoom",
    outdoorFireplace = "outdoorFireplace",
    collectiveKitchen = "collectiveKitchen",
    carsharing = "carsharing",
    bicycleWorkshop = "bicycleWorkshop",
    diningRoomWithTableService = "diningRoomWithTableService",
    library = "library",
    craftRoom = "craftRoom",
    entertainmentAndLeisureService = "entertainmentAndLeisureService",
    multiServiceCenter = "multiServiceCenter",
    coffee = "coffee",
    convenienceStore = "convenienceStore",
    hairdresser = "hairdresser",
    beautician = "beautician",
    movieTheater = "movieTheater",
    housekeeping = "housekeeping",
    laundryRoom = "laundryRoom",
    readingCorner = "readingCorner",
    pharmacy = "pharmacy",
    internetLounge = "internetLounge",
    storageRoom = "storageRoom",
    panoramicLounge = "panoramicLounge",
    hourSecurity = "hourSecurity",
    heating = "heating",
    nursingStaffOnSite7DaysAWeek = "nursingStaffOnSite7DaysAWeek",
    healthCareUnit = "healthCareUnit",
    cognitiveHealthCareUnit = "cognitiveHealthCareUnit",
    waterfrontTerrace = "waterfrontTerrace",
    theatre = "theatre",
    billiardRoom = "billiardRoom",
    puzzleRoom = "puzzleRoom",
    seamstress = "seamstress",
    bistro = "bistro",
    sportsBar = "sportsBar",
    bowlingAlley = "bowlingAlley",
    communityHall = "communityHall",
    hammam = "hammam",
    doubleGarage = "doubleGarage",
}

export enum UNIT_OPTION {
    appliancesIncluded = "appliancesIncluded",
    directAccessToTheStreet = "directAccessToTheStreet",
    furnishedEquipped = "furnishedEquipped",
    locker = "locker",
    mezzanine = "mezzanine",
    unitOn2Floors = "unitOn2Floors",
    unitOn3Floors = "unitOn3Floors",
    washerDryerInlet = "washerDryerInlet",
    privateArea = "privateArea",
    privateYard = "privateYard",
}

export const PROPERTY_AMENITIES: TOptionConfigDef[] = [
    {
        key: PROPERTY_OPTION.bar,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.AMENITIES,
    },
    {
        key: PROPERTY_OPTION.bbqArea,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.AMENITIES,
    },
    {
        key: PROPERTY_OPTION.bicycleStorage,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.AMENITIES,
    },
    {
        key: PROPERTY_OPTION.businessCenter,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.AMENITIES,
    },
    {
        key: PROPERTY_OPTION.commonLaundry,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.AMENITIES,
    },
    { key: PROPERTY_OPTION.communityGarden, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.concierge, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.eventRoom, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    {
        key: PROPERTY_OPTION.exteriorPool,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.AMENITIES,
        purpose: [PROPERTY_PURPOSE.BUY, PROPERTY_PURPOSE.RENT],
        group: GROUP_FEATURES.POOL,
    },
    { key: PROPERTY_OPTION.firepit, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.garden, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    {
        key: PROPERTY_OPTION.gym,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.AMENITIES,
        purpose: [PROPERTY_PURPOSE.RENT, PROPERTY_PURPOSE.BUY],
    },
    { key: PROPERTY_OPTION.hotelServices, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    {
        key: PROPERTY_OPTION.interiorPool,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.AMENITIES,
        purpose: [PROPERTY_PURPOSE.BUY, PROPERTY_PURPOSE.RENT],
        group: GROUP_FEATURES.POOL,
    },
    { key: PROPERTY_OPTION.jacuzzi, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.loungeArea, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.marketplace, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.multimediaRoom, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.playgroundArea, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.receptionDeskLobby, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.receptionHall, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.shoppingCenter, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.spa, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.tennisCourt, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.terrace, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.walkingTrails, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.yogaLounge, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.gameRoom, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.outdoorFireplace, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.collectiveKitchen, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.carsharing, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.bicycleWorkshop, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.entertainmentRoom, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    {
        key: PROPERTY_OPTION.diningRoomWithTableService,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.AMENITIES,
    },
    { key: PROPERTY_OPTION.library, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.craftRoom, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    {
        key: PROPERTY_OPTION.entertainmentAndLeisureService,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.AMENITIES,
    },
    { key: PROPERTY_OPTION.multiServiceCenter, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.coffee, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.convenienceStore, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.hairdresser, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.beautician, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.movieTheater, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.housekeeping, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.laundryRoom, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.readingCorner, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.pharmacy, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.internetLounge, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    {
        key: PROPERTY_OPTION.storageRoom,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.AMENITIES,
        purpose: [PROPERTY_PURPOSE.RENT],
    },
    { key: PROPERTY_OPTION.panoramicLounge, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.hourSecurity, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.heating, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    {
        key: PROPERTY_OPTION.nursingStaffOnSite7DaysAWeek,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.AMENITIES,
    },
    { key: PROPERTY_OPTION.healthCareUnit, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    {
        key: PROPERTY_OPTION.cognitiveHealthCareUnit,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.AMENITIES,
    },
    { key: PROPERTY_OPTION.waterfrontTerrace, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.theatre, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.billiardRoom, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.puzzleRoom, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.seamstress, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.bistro, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.sportsBar, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.bowlingAlley, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
    { key: PROPERTY_OPTION.communityHall, required: false, type: "boolean", optionType: OPTION_TYPE.AMENITIES },
];

export const PROPERTY_FEATURES: TOptionConfigDef[] = [
    {
        key: PROPERTY_OPTION.appliancesIncluded,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
        purpose: [PROPERTY_PURPOSE.RENT],
    },
    {
        key: PROPERTY_OPTION.balcony,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
    },
    {
        key: PROPERTY_OPTION.bikePathAccess,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
    },
    {
        key: PROPERTY_OPTION.centralAirConditioner,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
    },
    {
        key: PROPERTY_OPTION.centralVacuum,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
    },
    { key: PROPERTY_OPTION.closeToAHighway, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.closeToPublicTransport, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.composting, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.controlledAccess, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    {
        key: PROPERTY_OPTION.electricVehicleChargingStation,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
    },
    {
        key: PROPERTY_OPTION.elevator,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
        purpose: [PROPERTY_PURPOSE.RENT, PROPERTY_PURPOSE.BUY],
    },
    { key: PROPERTY_OPTION.energyCostIncluded, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.fireplace, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    {
        key: PROPERTY_OPTION.furnishedEquipped,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
    },
    { key: PROPERTY_OPTION.garbageChute, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.intercom, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.leedCertification, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    {
        key: PROPERTY_OPTION.outdoorParking,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
        purpose: [PROPERTY_PURPOSE.BUY, PROPERTY_PURPOSE.RENT],
        group: GROUP_FEATURES.PARKING,
    },
    {
        key: PROPERTY_OPTION.indoorParking,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
        purpose: [PROPERTY_PURPOSE.BUY, PROPERTY_PURPOSE.RENT],
        group: GROUP_FEATURES.PARKING,
    },
    {
        key: PROPERTY_OPTION.coveredParking,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
    },
    {
        key: PROPERTY_OPTION.petFriendly,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
        purpose: [PROPERTY_PURPOSE.RENT],
    },
    { key: PROPERTY_OPTION.recyclage, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.securityCameras, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.smartHome, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.solarPanels, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    {
        key: PROPERTY_OPTION.undergroundParking,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
        purpose: [PROPERTY_PURPOSE.BUY, PROPERTY_PURPOSE.RENT],
        group: GROUP_FEATURES.PARKING,
    },
    {
        key: PROPERTY_OPTION.wallMountAirConditioner,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
    },
    { key: PROPERTY_OPTION.waterfront, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.mobilityAccess, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: PROPERTY_OPTION.internet, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    {
        key: PROPERTY_OPTION.basementFinished,
        required: false,
        type: "boolean",
        types: [PROPERTY_TYPE.house],
        optionType: OPTION_TYPE.FEATURES,
    },
    {
        key: PROPERTY_OPTION.doubleGarage,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
    },
];

export const UNIT_FEATURES: TOptionConfigDef[] = [
    { key: UNIT_OPTION.appliancesIncluded, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: UNIT_OPTION.directAccessToTheStreet, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: UNIT_OPTION.furnishedEquipped, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    {
        key: UNIT_OPTION.locker,
        required: false,
        type: "boolean",
        optionType: OPTION_TYPE.FEATURES,
        purpose: [PROPERTY_PURPOSE.RENT],
    },
    { key: UNIT_OPTION.mezzanine, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: UNIT_OPTION.unitOn2Floors, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: UNIT_OPTION.unitOn3Floors, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: UNIT_OPTION.washerDryerInlet, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: UNIT_OPTION.privateArea, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
    { key: UNIT_OPTION.privateYard, required: false, type: "boolean", optionType: OPTION_TYPE.FEATURES },
];

export const PROPERTY_FEATURES_AMENITIES = PROPERTY_FEATURES.concat(PROPERTY_AMENITIES);

export type TPremiumPlan = {
    id: number;
    price: number;
    months: number;
    titleKey: string;
    textKey: string;
};

export const PROPERTIES_PREMIUM_PLANS: TPremiumPlan[] = [
    {
        id: 1,
        price: 1500,
        months: 1,
        titleKey: "titleKey1",
        textKey: "textKey1",
    },
    {
        id: 2,
        price: 5000,
        months: 4,
        titleKey: "titleKey4",
        textKey: "textKey4",
    },
    {
        id: 3,
        price: 9000,
        months: 12,
        titleKey: "titleKey12",
        textKey: "textKey12",
    },
];
