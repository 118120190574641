import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/swiper-bundle.min.css";
import { PropertyCard } from "properties/PropertyCard";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import clsx from "clsx";

export function FeaturedProperties({
    properties,
    pageBuilder,
}: {
    properties: TPropertyListingMdl[];
    pageBuilder?: boolean;
}) {
    return (
        <div id="featuredProperties" className={"position_relative ph_10"}>
            <Swiper
                navigation={{
                    nextEl: `.swiper-button-next-featured`,
                    prevEl: `.swiper-button-prev-featured`,
                }}
                loop
                slidesPerView={"auto"}
                breakpoints={{
                    1100: {
                        slidesPerView: 3,
                    },
                    750: {
                        slidesPerView: 2,
                    },
                    0: {
                        slidesPerView: 1,
                    },
                }}
            >
                {properties.map((property) => {
                    if (!property) return;
                    return (
                        <SwiperSlide key={property._id}>
                            <div className={clsx("ph_10")}>
                                <PropertyCard isFeaturedCard pageBuilder={pageBuilder} property={property} />
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className="swiper-button-prev-featured"></div>
            <div className="swiper-button-next-featured"></div>
        </div>
    );
}
