import levis from "../assets/images/popularCities/resizedLevis-min.webp";
import quebec from "../assets/images/popularCities/resizedQuebec-min.webp";
import sherbrooke from "../assets/images/popularCities/resizedsherbrooke-min.webp";
import popularCity1 from "../assets/images/popularCities/resizedpopularCities_1-min.webp";
import popularCity2 from "../assets/images/popularCities/resizedpopularCities_2-min.webp";
import popularCity3 from "../assets/images/popularCities/resizedpopularCities_3-min.webp";
import popularCity4 from "../assets/images/popularCities/resizedpopularCities_4-min.webp";
import brossard from "../assets/images/popularCities/resizedBrossard-min.webp";
import montreal from "../assets/images/popularCities/resizedmontreal-min.webp";
import laval from "../assets/images/popularCities/resizedlaval.webp";
import { URLS } from "_configs/URLS";
import { TLang } from "_configs/sharedConfig";

export type TPopularCity = {
    name: string;
    urlRent: (lang: TLang) => string;
    urlSale: (lang: TLang) => string;
    image: string;
};

export const POPULAR_CITIES_MENU: TPopularCity[] = [
    {
        name: "Montréal",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "montreal")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "montreal")}`,
        image: montreal,
    },
    {
        name: "Ville de Québec",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "ville_de_quebec")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "ville_de_quebec")}`,
        image: quebec,
    },
    {
        name: "Gatineau",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "gatineau")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "gatineau")}`,
        image: popularCity3,
    },
    {
        name: "Sherbrooke",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "sherbrooke")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "sherbrooke")}`,
        image: sherbrooke,
    },
    {
        name: "Trois-Rivières",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "trois-rivieres")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "trois-rivieres")}`,
        image: popularCity4,
    },
    {
        name: "Lévis",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "levis")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "levis")}`,
        image: levis,
    },
    {
        name: "Ottawa",
        urlRent: (lang) => `${URLS.rent(lang, "on", "ottawa")}`,
        urlSale: (lang) => `${URLS.buy(lang, "on", "ottawa")}`,
        image: laval,
    },
    {
        name: "Toronto",
        urlRent: (lang) => `${URLS.rent(lang, "on", "toronto")}`,
        urlSale: (lang) => `${URLS.buy(lang, "on", "toronto")}`,
        image: popularCity2,
    },
    {
        name: "Laval",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "laval")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "laval")}`,
        image: laval,
    },
    {
        name: "Brossard",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "brossard")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "brossard")}`,
        image: brossard,
    },
    {
        name: "Rive-Nord",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "rive-nord_de_montreal")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "rive-nord_de_montreal")}`,
        image: popularCity1,
    },
    {
        name: "Rive-Sud",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "rive-sud_de_montreal")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "rive-sud_de_montreal")}`,
        image: popularCity2,
    },
];

export const POPULAR_CITIES: TPopularCity[] = [
    {
        name: "Mauricie",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "mauricie")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "mauricie")}`,
        image: popularCity4,
    },
    {
        name: "Estrie",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "estrie")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "estrie")}`,
        image: sherbrooke,
    },
    {
        name: "Outaouais",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "outaouais")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "outaouais")}`,
        image: popularCity3,
    },
    {
        name: "Lanaudière",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "lanaudiere")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "lanaudiere")}`,
        image: popularCity1,
    },
    {
        name: "Laurentides",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "laurentides")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "laurentides")}`,
        image: laval,
    },
    {
        name: "Montérégie",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "monteregie")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "monteregie")}`,
        image: brossard,
    },
    {
        name: "Centre-du-Québec",
        urlRent: (lang) => `${URLS.rent(lang, "qc", "centre-du-quebec")}`,
        urlSale: (lang) => `${URLS.buy(lang, "qc", "centre-du-quebec")}`,
        image: popularCity2,
    },
];
