import React from "react";

type Props = {
    size?: number;
    color?: string;
};

export function CartIcon(props: Props) {
    return (
        <svg
            width={props.size ?? 24}
            height={props.size ?? 24}
            viewBox={`0 0 24 24`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.3201 6.96001C16.3201 4.30905 14.651 2.64001 12.0001 2.64001C9.34909 2.64001 7.68005 4.30905 7.68005 6.96001"
                stroke={props.color ? props.color : "#004378"}
                strokeWidth="1.44"
                strokeLinecap="square"
            />
            <path
                d="M4.64891 9.11999H19.3512C19.7181 9.11999 20.0263 9.39585 20.0668 9.76048L21.1868 19.8405C21.2342 20.267 20.9003 20.64 20.4712 20.64H3.52891C3.09978 20.64 2.76592 20.267 2.81331 19.8405L3.93331 9.76048C3.97382 9.39585 4.28203 9.11999 4.64891 9.11999Z"
                stroke={props.color ? props.color : "#004378"}
                strokeWidth="1.44"
            />
        </svg>
    );
}
