import React from "react";
import { OPTIONS_DETAILS } from "_configs/stripeProductConfig";
import styles from "./_css/comparePricesArray.module.css";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import check from "../../assets/images/promotePage/check.svg";
import { PAYMENT_TYPE } from "payments/_models/paymentStripeMdl";
import paymentStore from "payments/_stores/paymentStore";
import { ComparePricesHeadCell } from "payments/_components/ComparePricesHeadCell";

export function ComparePricesArray() {
    const { t } = useTranslation();
    const products = paymentStore.mainProducts.reverse();
    return (
        <div id="comparePriceModal" className={clsx(styles.container)}>
            <div className={clsx(styles.firstRow, "flex_row")}>
                <div className={styles.firstColumn} />
                {products.map((product) => {
                    const price = paymentStore.getPriceFromProductRegionAndRecurring(product, PAYMENT_TYPE.RECURRING);
                    return (
                        <div key={product.id} className={styles.headCell}>
                            <ComparePricesHeadCell
                                name={product.name}
                                price={price.unit_amount ? price.unit_amount / 100 : 0}
                            />
                        </div>
                    );
                })}
            </div>
            <div>
                {Object.values(OPTIONS_DETAILS).map((option, idx) => {
                    const odd = idx % 2 === 0;
                    return (
                        <div key={option} className={clsx(styles.row, "flex_row_center")}>
                            <div className={clsx(styles.firstColumn)}>
                                {t(`paymentPage.products.optionsDetails.${option}`)}
                            </div>
                            <div className={clsx(styles.mainCell, "flex_center_center", { [styles.oddRow]: odd })}>
                                {(products[0].optionKeys.find((_option) => _option === option) || idx < 7) && (
                                    <>
                                        <img src={check} alt="check" width={24} className={styles.hiddenDesktop} />
                                        <img src={check} alt="check" width={30} className={styles.hiddenMobile} />
                                    </>
                                )}
                            </div>
                            <div className={clsx(styles.mainCell, "flex_center_center", { [styles.oddRow]: odd })}>
                                {products[1].optionKeys.find((_option) => _option === option || idx < 10) && (
                                    <>
                                        <img src={check} alt="check" width={24} className={styles.hiddenDesktop} />
                                        <img src={check} alt="check" width={30} className={styles.hiddenMobile} />
                                    </>
                                )}
                            </div>
                            <div className={clsx(styles.mainCell, "flex_center_center", { [styles.oddRow]: odd })}>
                                {products[2].optionKeys.find((_option) => _option === option || idx < 12) && (
                                    <>
                                        <img src={check} alt="check" width={24} className={styles.hiddenDesktop} />
                                        <img src={check} alt="check" width={30} className={styles.hiddenMobile} />
                                    </>
                                )}
                            </div>
                            <div className={clsx(styles.mainCell, "flex_center_center", { [styles.oddRow]: odd })}>
                                <img src={check} alt="check" width={24} className={styles.hiddenDesktop} />
                                <img src={check} alt="check" width={30} className={styles.hiddenMobile} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
