import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { TOpItems } from "admin/_common/filters/OpPicker";
import { TFilter } from "admin/_common/filters/TFilter";

type TPriceData = {
    [purpose in PROPERTY_PURPOSE]: Record<
        "default" | "commercial",
        { min: { value: string; label: string }[]; max: { value: string; label: string }[] }
    >;
};

export const PRICE_DATA: TPriceData = {
    [PROPERTY_PURPOSE.BUY]: {
        default: {
            min: [
                { value: "", label: "No min" },
                { value: "250000", label: "250K" },
                { value: "500000", label: "500K" },
                { value: "800000", label: "800K" },
                { value: "1000000", label: "1M" },
                { value: "1200000", label: "1.2M" },
                { value: "1600000", label: "1.6M" },
                { value: "1800000", label: "1.8M" },
            ],
            max: [
                { value: "", label: "No max" },
                { value: "250000", label: "250K" },
                { value: "500000", label: "500K" },
                { value: "800000", label: "800K" },
                { value: "1000000", label: "1M" },
                { value: "1200000", label: "1.2M" },
                { value: "1600000", label: "1.6M" },
                { value: "1800000", label: "1.8M" },
            ],
        },
        commercial: {
            min: [
                { value: "", label: "No min" },
                { value: "100000", label: "100K" },
                { value: "250000", label: "250K" },
                { value: "500000", label: "500K" },
                { value: "1000000", label: "1M" },
                { value: "1500000", label: "1.5M" },
                { value: "2000000", label: "2M" },
                { value: "5000000", label: "5M" },
                { value: "10000000", label: "10M" },
            ],
            max: [
                { value: "", label: "No min" },
                { value: "100000", label: "100K" },
                { value: "250000", label: "250K" },
                { value: "500000", label: "500K" },
                { value: "1000000", label: "1M" },
                { value: "1500000", label: "1.5M" },
                { value: "2000000", label: "2M" },
                { value: "5000000", label: "5M" },
                { value: "10000000", label: "10M" },
            ],
        },
    },
    [PROPERTY_PURPOSE.RENT]: {
        default: {
            min: [
                { value: "", label: "No min" },
                { value: "200", label: "200" },
                { value: "400", label: "400" },
                { value: "600", label: "600" },
                { value: "800", label: "800" },
                { value: "1000", label: "1 000" },
                { value: "2000", label: "2 000" },
                { value: "4000", label: "4 000" },
                { value: "6000", label: "6 000" },
            ],
            max: [
                { value: "", label: "No max" },
                { value: "200", label: "200" },
                { value: "400", label: "400" },
                { value: "600", label: "600" },
                { value: "800", label: "800" },
                { value: "1000", label: "1 000" },
                { value: "2000", label: "2 000" },
                { value: "4000", label: "4 000" },
                { value: "6000", label: "6 000" },
            ],
        },
        commercial: {
            min: [
                { value: "", label: "No min" },
                { value: "1000", label: "1 000" },
                { value: "2500", label: "2 500" },
                { value: "5000", label: "5 000" },
                { value: "10000", label: "1 0000" },
                { value: "25000", label: "25 000" },
                { value: "50000", label: "50 000" },
                { value: "75000", label: "75 000" },
                { value: "100000", label: "100 000" },
            ],
            max: [
                { value: "", label: "No min" },
                { value: "1000", label: "1 000" },
                { value: "2500", label: "2 500" },
                { value: "5000", label: "5 000" },
                { value: "10000", label: "1 0000" },
                { value: "25000", label: "25 000" },
                { value: "50000", label: "50 000" },
                { value: "75000", label: "75 000" },
                { value: "100000", label: "100 000" },
            ],
        },
    },
};

export function priceRangeToFilters(
    payload: {
        filterKey?: string;
        max: string;
        min: string;
    } = {
        filterKey: "price",
        max: "0",
        min: "0",
    },
) {
    const filters: TFilter[] = [];
    const { filterKey, max, min } = payload;
    const priceMax = parseInt(max) * 100;
    const priceMin = parseInt(min) * 100;
    const reverseMaxMin = !isNaN(priceMax) && !isNaN(priceMin) && priceMax < priceMin;
    if (!isNaN(priceMax)) {
        filters.push({
            id: `${filterKey ?? "price"}.min`,
            type: TFilterType.NUMBER,
            value: reverseMaxMin ? priceMin : priceMax,
            op: "lte" as TOpItems,
        });
    }
    if (!isNaN(priceMin)) {
        filters.push({
            id: `${filterKey ?? "price"}.max`,
            type: TFilterType.NUMBER,
            value: reverseMaxMin ? priceMax : priceMin,
            op: "gte" as TOpItems,
        });
    }
    return filters;
}
