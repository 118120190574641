import React from "react";
import clsx from "clsx";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { IconComponent } from "components/base/icon/IconComponent";
import { useTranslation } from "react-i18next";
import styles from "./_css/editCardBtn.module.css";

type Props = {
    link: string;
    isOverCard: boolean;
    onEdit?: () => void;
    onClose?: () => void;
};

export function EditCardBtn(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={clsx("position_absolute flex_center_center", { [styles.isEditable]: props.isOverCard })}>
            <Tooltip title={t("dashboard.listing.seeProperty")} aria-label="add">
                <IconButton
                    onClick={() => {
                        const win = window.open(props.link, "_blank");
                        if (win) win.focus();
                    }}
                    className={"mh_10"}
                >
                    <IconComponent style={{ color: "white" }} icon={"visibility"} />
                </IconButton>
            </Tooltip>
            <IconButton
                onClick={() => {
                    props.onClose?.();
                    props.onEdit?.();
                }}
                className={"mh_10"}
            >
                <IconComponent style={{ color: "white" }} icon={"edit"} />
            </IconButton>
        </div>
    );
}
